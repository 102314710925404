export const SCHOOL_SUGGESTIONS: [string, string[]][] = [
    [
        'staatlich',
        [
            'Alice-Salomon-Hochschule Berlin (ASH)',
            'Berliner Hochschule für Technik (BHT)',
            'Brandenburgische Technische Universität Cottbus-Senftenberg (BTU)',
            'Helmut Schmidt Universität (Bundeswehr Hamburg)',
            'Universität Bundeswehr München',
            'Charité - Universitätsmedizin Berlin',
            'DFFB - Deutsche Film- und Fernsehakademie Berlin',
            'Europa-Universität Viadrina Frankfurt (Oder) (EUV)',
            'Evangelische Hochschule Berlin (EHB)',
            'Fachhochschule für Finanzen Brandenburg (FHF)',
            'Fachhochschule Potsdam (FHP)',
            'Freie Universität Berlin (FU)',
            'Hochschule für Musik Hanns Eisler (HfM)',
            'Hochschule des Bundes für öffentliche Verwaltung (HS Bund)',
            'Hochschule der Polizei des Landes Brandenburg (HPol)',
            'Hochschule für nachhaltige Entwicklung Eberswalde (HNEE)',
            'Hochschule für Schauspielkunst Ernst Busch (HfS)',
            'Hochschulübergreifendes Zentrum Tanz Berlin (HZT)',
            'Hochschule für Technik und Wirtschaft Berlin (HTW)',
            'Humboldt-Universität zu Berlin (HU)',
            'Hochschule für Wirtschaft und Recht Berlin (HWR)',
            'Katholische Hochschule für Sozialwesen Berlin (KHSB)',
            'Filmuniversität Babelsberg Konrad Wolf',
            'weißensee kunsthochschule berlin (KHB)',
            'Technische Hochschule Brandenburg (THB)',
            'Technische Hochschule Wildau (TH Wildau)',
            'Technische Universität Berlin (TUB)',
            'Universität der Künste Berlin (UdK Berlin)',
            'Universität Potsdam (UP)',
        ],
    ],
    [
        'privat',
        [
            'Akkon Hochschule für Humanwissenschaften',
            'AMD Akademie Mode & Design Berlin',
            'Bard College Berlin - A Liberal Arts University',
            'Barenboim-Said Akademie gGmbH',
            'bbw Hochschule',
            'Berlin International - University of Applied Sciences',
            'BSP Business & Law School Berlin - Hochschule für Management und Recht',
            'CODE University of Applied Sciences',
            'DBU Digital Business University',
            'Deutsche Film- und Fernsehakademie Berlin GmbH',
            'DHGS Deutsche Hochschule für Gesundheit und Sport',
            'ESAB - Fachhochschule für Sport und Management Potsdam',
            'ESCP Europe - Wirtschaftshochschule Berlin',
            'ESMT European School of Management and Technology',
            'EU|FH Berlin Europäische Fachhochschule',
            'Fachhochschule Clara Hoffbauer Potsdam',
            'FHM Fachhochschule des Mittelstandes Berlin',
            'FOM Hochschule des Mittelstandes',
            'GISMA Business School',
            'GISMA Business School University of Applied Sciences',
            'GIU German International University',
            'Hertie School of Governance',
            'HMKW Berlin Hochschule für Medien, Kommunikation und Wirtschaft',
            'HMU Health and Medical University Potsdam',
            'Hochschule Fresenius',
            'Hochschule für angewandtes Management',
            'Hochschule Macromedia University of Applied Sciences',
            'HSAP - Hochschule für angewandte Pädagogik',
            'IB Hochschule für Gesundheit und Soziales',
            'iba University of Cooperative Education',
            'IPU International Psychoanalytic University Berlin',
            'ism International School of Management',
            'IU Internationale Hochschule',
            'MD.H Mediadesign Hochschule für Kommunikation und Informatik',
            'Medizinische Hochschule Brandenburg Theodor Fontane',
            'MSB Medical School Berlin - Hochschule für Gesundheit und Medizin',
            'PHB Psychologische Hochschule Berlin',
            'Quadriga Hochschule Berlin GmbH',
            'Sigmund Freud PrivatUniversität Berlin',
            'SRH Berlin - University of Applied Sciences',
            'Steinbeis-Hochschule Berlin',
            'Theologische Hochschule Elstal',
            'Touro University Berlin',
            'UE University of Europe for Applied Sciences',
            'VICTORIA | Internationale Hochschule',
            'XU Exponential University of Applied Sciences',
            'DMA - die medienakademie in Berlin',
            'CBS International Business School',
        ],
    ],
    [
        'dual',
        [
            'Hochschule der Bundesagentur für Arbeit (HdBA)',
            'Hochschule der Deutschen Bundesbank (HSBB)',
            'Hochschule für Archivwissenschaft (HfA)',
            'Hochschule des Bundes für öffentliche Verwaltung (HS Bund)',
        ],
    ],
];

export const FIELD_OF_STUDY_SUGGESTIONS: [string, string[]][] = [
    [
        'Informatik / IT',
        [
            'Softwareentwicklung',
            'Künstliche Intelligenz / Machine Learning',
            'Datenanalyse / Data Science',
            'IT-Sicherheit / Cybersecurity',
            'Netzwerktechnik / Systemadministration',
            'Cloud Computing',
            'Mobile App-Entwicklung',
            'Embedded Systems',
            'IT-Projektmanagement',
            'Wirtschaftsinformatik',
            'Technische Informatik',
            'Medieninformatik',
            'Consulting',
        ],
    ],
    [
        'Ingenieurwesen & Technik',
        [
            'Abfall- und Wasserwesen',
            'Architektur',
            'Bauingenieurwesen',
            'Bekleidungstechnik',
            'Biotechnologie',
            'Consulting',
            'Computertechnik',
            'Computational Engineering',
            'Elektrotechnik',
            'Energie-, Umwelt- und Verfahrenstechnik',
            'Fahrzeugbau und -technik',
            'Logistik',
            'Luft- und Raumfahrttechnik',
            'Maschinenbau',
            'Mechatronik',
            'Produktions- und Fertigungstechnik',
            'Verkehrswesen',
            'Werkstoffwissenschaften',
            'Wirtschaftsingenieurwesen',
        ],
    ],
    [
        'Wirtschaftswissenschaften',
        [
            'Betriebswirtschaftslehre (BWL)',
            'Marketing, Kommunikatin',
            'Controlling',
            'Personalmanagement',
            'Supply Chain Management',
            'Unternehmensführung',
            'Volkswirtschaftslehre (VWL)',
            'Wirtschaftspolitik',
            'Internationale Wirtschaft',
            'Finanzmärkte',
        ],
    ],
    [
        'Naturwissenschaften & Umwelt',
        [
            'Anthropologie',
            'Astronomie',
            'Biochemie',
            'Biologie',
            'Chemie',
            'Geowissenschaften',
            'Lebensmittelwissenschaften',
            'Mathematik',
            'Materialwissenschaften und Werkstoffwissenschaften',
            'Physik',
            'Umweltwissenschaften',
        ],
    ],
    [
        'Geistes- und Sozialwissenschaften',
        [
            'Archäologie',
            'Bildung, Erziehung & Pädagogik',
            'Lehramt',
            'Ethnologie',
            'Geschichte',
            'Medien-, Kunst-, Theater- und Musikwissenschaften',
            'Philosophie & Ethik',
            'Politik',
            'Psychologie',
            'Recht',
            'Soziologie',
            'Sprach- und Literaturwissenschaften',
            'Religion',
        ],
    ],
    [
        'Medizin / Gesundheitswesen',
        [
            'Humanmedizin',
            'Pharmazie',
            'Pflegewissenschaften',
            'Public Health / Gesundheitsmanagement',
            'Sport & Fitness',
        ],
    ],
    [
        'Agrar-, Forst-, Ernährungswissenschaften & Veterinärmedizin',
        ['Agrarwissenschaft', 'Umwelt- und Landschaftsgestaltung', 'Forst- und Holzwirtschaft', 'Veterinärmedizin'],
    ],
    ['Event, Tourismus & Hotel', []],
];

export const DEGREE_SUGGESTIONS: [string, string[]][] = [
    ['Hochschulabschlüsse', ['Bachelor', 'Master', 'Diplom', 'Staatsexamen', 'Promotion']],
    ['Berufsausbildungen', ['Duale Ausbildung', 'Schulische Ausbildung', 'Meister/Techniker', 'Fachwirt/Betriebswirt']],
    [
        'Weitere Qualifikationen',
        ['Fachschulabschluss', 'Berufsakademie-Abschluss', 'Weiterbildungszertifikate', 'Anderer Abschluss'],
    ],
];

export const EMPLOYMENT_TYPE_SUGGESTIONS = [
    'Vollzeit',
    'Teilzeit',
    'Praktikum',
    'Werkstudierendenjob',
    'Trainee',
    'Abschlussarbeit (Bachelor/Master)',
    'Promotion',
    'Gründungspartner*in',
    'Minijob',
    'Freiberuflich',
    'Anderes',
];

export const SESSION_FORMAT_SUGGESTIONS = ['Vortrag', 'Workshop', 'Aktivität', 'Beratung', 'Sonstiges'];

export const INDUSTRY_SUGGESTIONS = [
    'Banken, Finanzdienstleistungen, Versicherungen und Immoblien',
    'Bauwesen, Architektur, Vermessung und Gebäudetechnik',
    'Dienstleistungen',
    'Elektronik, Mechatronik und Anlagenbau',
    'Energie, Umwelttechnik und Nachhaltigkeit',
    'Ernährungswirtschaft',
    'Event, Tourismus und Gastronomie',
    'Forschung und Entwicklung',
    'Geistes- und Sozialwissenschaften',
    'Gesundheit, Pflege und Medizin',
    'Handel, Vertrieb und E-Commerce',
    'Ingenieurwesen, Technik und Technologie',
    'IT, Softwareentwicklung und digitale Infrastruktur',
    'Landwirtschaft, Natur, Umwelt und Forstwirtschaft',
    'Management und Beratung',
    'Marketing und Kommunikation',
    'Medien, Kunst, Kultur und Design',
    'Metall, Maschinen- und Fahzeugbau',
    'Naturwissenschaften',
    'Produktion, Fertigung und Rohstoffgewinnung',
    'Recht, Steuern, Finanzen und Controlling',
    'Schutz und Sicherheit',
    'Soziales, Bildung und Pädagogik',
    'Start-ups und Innovation',
    'Verkehr, Logistik und Mobilität',
    'Wirtschaft und öffentliche Verwaltung',
];

export const LOCATION_SCOPE_SUGGESTIONS = ['Lokal', 'Regional', 'National', 'International'];

export const JOB_QUALIFICATION_SUGGESTIONS = [
    'Praktikum',
    'Bachelorstudium',
    'Masterstudium',
    'Promotion',
    'Berufseinsteiger',
    'Berufserfahrene',
];

export const JOB_REMOTE_WORK_SUGGESTIONS = ['Vollständig remote', 'Teilweise remote', 'Nicht remote'];

export const JOB_REMUNERATION_TYPE_SUGGESTIONS = ['Vergütet', 'Nach Tarif vergütet', 'Unvergütet'];

export const ORGANIZATION_SIZE_SUGGESTIONS = [
    '1-10 Mitarbeiter',
    '11-50 Mitarbeiter',
    '51-200 Mitarbeiter',
    '201-500 Mitarbeiter',
    '501-1000 Mitarbeiter',
    '1000+ Mitarbeiter',
];

export const ORGANIZATION_TYPE_SUGGESTIONS = [
    'Start-up',
    'KMU',
    'Großunternehmen',
    'Non-Profit',
    'Öffentlicher Dienst',
    'Selbstständig',
    'Freiberuflich',
    'Sonstiges',
];

export const ORGANIZATION_LANGUAGE_SUGGESTIONS = [
    'Deutsch',
    'Englisch',
    'Französisch',
    'Spanisch',
    'Italienisch',
    'Portugiesisch',
    'Niederländisch',
    'Russisch',
    'Chinesisch',
    'Japanisch',
    'Sonstige',
];
