import * as React from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';

import { useMutation, useQuery } from '@tanstack/react-query';

import {
    logEventAcceptTrackingMutation,
    logEventRejectTrackingMutation,
    logEventTrackingStatusQuery,
} from '../data/queries.ts';
import Button from './Button.tsx';

const TRACKING_COOKIE_STORAGE_KEY = 'trackingAccepted';

const CookieBar = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('components');

    const { data: trackingStatus, isLoading } = useQuery(logEventTrackingStatusQuery());

    const { mutateAsync: acceptTracking } = useMutation(logEventAcceptTrackingMutation());
    const { mutateAsync: rejectTracking } = useMutation(logEventRejectTrackingMutation());

    const handleAccept = async () => {
        try {
            await acceptTracking();
            localStorage.setItem(TRACKING_COOKIE_STORAGE_KEY, 'true');
            toast.success(t('cookieBar.acceptSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('cookieBar.acceptError'));
        }
    };

    const handleReject = async () => {
        try {
            await rejectTracking();
            localStorage.removeItem(TRACKING_COOKIE_STORAGE_KEY);
            toast.success(t('cookieBar.rejectSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('cookieBar.rejectError'));
        }
    };

    if (isLoading || trackingStatus.trackingAccepted != null || localStorage.getItem(TRACKING_COOKIE_STORAGE_KEY))
        return null;

    return (
        <div className="fixed bottom-3 w-full max-w-[70rem] bg-black/85 backdrop-blur text-white text-sm rounded-xl px-5 pl-4.5 py-4 flex gap-4 items-center left-1/2 transform -translate-x-1/2 z-50">
            <div>
                <Trans t={t} i18nKey="cookieBar.text" />
            </div>
            <div className="flex gap-2">
                <Button variant="primary" className="whitespace-nowrap" onClick={handleAccept}>
                    {t('cookieBar.acceptButton')}
                </Button>
                <Button
                    variant="outline"
                    className="whitespace-nowrap bg-transparent text-white hover:bg-black/50 active:bg-black/60 border-white/50"
                    onClick={handleReject}
                >
                    {t('cookieBar.rejectButton')}
                </Button>
            </div>
        </div>
    );
};

export default CookieBar;
