import {
    AtSignIcon,
    BellIcon,
    BuildingIcon,
    ChevronDownIcon,
    ExternalLinkIcon,
    GraduationCapIcon,
    KeyRoundIcon,
    SettingsIcon,
    UserIcon,
} from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import DropdownMenu from '../../../../components/DropdownMenu.tsx';
import { useViewerEventsQuery } from '../../../../components/Header.tsx';
import Image from '../../../../components/Image.tsx';
import Popover from '../../../../components/Popover.tsx';
import { eventListQuery, userDetailQuery } from '../../../../data/queries.ts';
import { isRegularUser } from '../../../../utils/loaders.ts';
import url from '../../../../utils/url';
import { HOME_PATH, SITE_EVENT_DETAIL_PATH, SITE_USER_DETAIL_PATH } from '../../../site/routes.tsx';
import { siteUrl, useEventId } from '../../../site/utils.ts';
import Admin from '../../components/Admin.tsx';
import {
    ADMIN_USER_SETTINGS_ACCOUNT_PATH,
    ADMIN_USER_SETTINGS_CONTACT_PATH,
    ADMIN_USER_SETTINGS_EDUCATION_PATH,
    ADMIN_USER_SETTINGS_EXPERIENCE_PATH,
    ADMIN_USER_SETTINGS_NOTIFICATIONS_PATH,
    ADMIN_USER_SETTINGS_PASSWORD_PATH,
    ADMIN_USER_SETTINGS_PROFILE_PATH,
} from '../routes.tsx';

const PublicProfileSection = () => {
    const { t } = useTranslation('admin');

    const { data: viewer } = useQuery(userDetailQuery());
    // either specific to current environment or the first one that user is participating in
    const { data: events, isLoading } = useViewerEventsQuery();
    const event = events?.length === 1 ? events?.[0] : null;

    return (
        <>
            <Admin.NavigationLabel>{t('userSettingsPage.navigation.profile')}</Admin.NavigationLabel>

            <Admin.NavigationLink to={url(ADMIN_USER_SETTINGS_PROFILE_PATH)}>
                <span className="flex gap-2 items-center">
                    <UserIcon /> {t('userSettingsPage.navigation.general')}
                </span>
            </Admin.NavigationLink>

            <Admin.NavigationLink to={url(ADMIN_USER_SETTINGS_CONTACT_PATH)}>
                <span className="flex gap-2 items-center">
                    <AtSignIcon /> {t('userSettingsPage.navigation.contact')}
                </span>
            </Admin.NavigationLink>

            <Admin.NavigationLink to={url(ADMIN_USER_SETTINGS_EXPERIENCE_PATH)}>
                <span className="flex gap-2 items-center">
                    <BuildingIcon /> {t('userSettingsPage.navigation.experience')}
                </span>
            </Admin.NavigationLink>

            <Admin.NavigationLink to={url(ADMIN_USER_SETTINGS_EDUCATION_PATH)}>
                <span className="flex gap-2 items-center">
                    <GraduationCapIcon /> {t('userSettingsPage.navigation.education')}
                </span>
            </Admin.NavigationLink>

            {event ? (
                <Admin.NavigationItem asChild>
                    <a
                        className="flex justify-between items-center"
                        href={siteUrl(SITE_USER_DETAIL_PATH, {
                            userId: viewer?.id,
                            eventId: event.slug || event.id,
                        })}
                        target="_blank"
                    >
                        <span>{t('userSettingsPage.navigation.viewProfile')}</span>
                        <ExternalLinkIcon />
                    </a>
                </Admin.NavigationItem>
            ) : events && events?.length > 1 ? (
                <DropdownMenu>
                    <DropdownMenu.Trigger asChild>
                        <Admin.NavigationItem>
                            <div className="flex justify-between items-center w-full">
                                <span>{t('userSettingsPage.navigation.viewProfile')}</span>
                                <ChevronDownIcon />
                            </div>
                        </Admin.NavigationItem>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Content align="start" sideOffset={4}>
                        {events?.map((event) => (
                            <DropdownMenu.Item asChild>
                                <a
                                    className="select-none px-4 py-3 flex gap-3 justify-between"
                                    href={siteUrl(SITE_USER_DETAIL_PATH, {
                                        userId: viewer?.id,
                                        eventId: event.slug || event.id,
                                    })}
                                    target="_blank"
                                >
                                    {event?.logo?.url ? (
                                        <Image src={event?.logo?.url} className="object-contain max-h-5 duration-0" />
                                    ) : (
                                        <span className="text-sm font-medium">{event.name}</span>
                                    )}

                                    <ExternalLinkIcon />
                                </a>
                            </DropdownMenu.Item>
                        ))}
                    </DropdownMenu.Content>
                </DropdownMenu>
            ) : null}
        </>
    );
};

const AccountSection = () => {
    const { t } = useTranslation('admin');

    return (
        <>
            <Admin.NavigationLabel>{t('userSettingsPage.navigation.settings')}</Admin.NavigationLabel>

            <Admin.NavigationLink to={url(ADMIN_USER_SETTINGS_ACCOUNT_PATH)}>
                <span className="flex gap-2 items-center">
                    <SettingsIcon /> {t('userSettingsPage.navigation.account')}
                </span>
            </Admin.NavigationLink>

            <Admin.NavigationLink to={url(ADMIN_USER_SETTINGS_NOTIFICATIONS_PATH)}>
                <span className="flex gap-2 items-center">
                    <BellIcon /> {t('userSettingsPage.navigation.notifications')}
                </span>
            </Admin.NavigationLink>
        </>
    );
};

const AccessSection = () => {
    const { t } = useTranslation('admin');

    return (
        <>
            <Admin.NavigationLabel>{t('userSettingsPage.navigation.access')}</Admin.NavigationLabel>

            <Admin.NavigationLink to={url(ADMIN_USER_SETTINGS_PASSWORD_PATH)}>
                <span className="flex gap-2 items-center">
                    <KeyRoundIcon /> {t('userSettingsPage.navigation.password')}
                </span>
            </Admin.NavigationLink>
        </>
    );
};

const UserSettingsPage = (props: any) => {
    const { data: viewer } = useQuery(userDetailQuery());

    return (
        <div className="flex gap-8 h-full">
            <Admin.Navigation>
                {viewer && isRegularUser(viewer) && (
                    <>
                        <PublicProfileSection />
                        <Admin.NavigationSeparator />
                    </>
                )}
                <AccountSection />
                <Admin.NavigationSeparator />
                <AccessSection />
            </Admin.Navigation>

            <Admin.Content>
                <Outlet />
            </Admin.Content>
        </div>
    );
};

export default UserSettingsPage;
