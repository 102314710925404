import { TFunction } from 'i18next';
import { NotebookIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import Avatar from '../../../components/Avatar.tsx';
import Image from '../../../components/Image.tsx';
import LinkButton from '../../../components/LinkButton.tsx';
import Pagination from '../../../components/Pagination.tsx';
import { LogEventType, SavedUser, UserNote, UserProfile } from '../../../data/models.ts';
import {
    eventDetailQuery,
    savedUserListQuery,
    userDetailQuery,
    userNoteListQuery,
    userProfileSearchQuery,
} from '../../../data/queries.ts';
import { isAdminUser, isAuthenticated, isOrganizationUser } from '../../../utils/loaders.ts';
import url from '../../../utils/url';
import useLogEvent from '../../../utils/useLogEvent.ts';
import EmptyState from '../../admin/components/EmptyState.tsx';
import { ADMIN_LOGIN_PATH } from '../../auth/routes.tsx';
import { SimpleFilterSidebar, useSearchParamsFilters } from '../components/FilterSidebar.tsx';
import { SaveUserRibbon } from '../components/SaveUserButton.tsx';
import { SITE_USER_DETAIL_PATH } from '../routes.tsx';
import { mergeUserProfile, siteUrl, useEventId } from '../utils.ts';

const UserProfileCard = ({
    userProfile,
    savedUser,
    notesCount,
}: {
    userProfile: UserProfile;
    savedUser?: SavedUser;
    notesCount?: number;
}) => {
    const { t } = useTranslation('site');
    const eventId = useEventId();
    return (
        <div className="relative group">
            <SaveUserRibbon user={userProfile.user!} eventId={eventId} savedUser={savedUser} className="pt-7" />

            <Link
                className="flex gap-6 flex-1 bg-base-50 rounded-xl p-8 pr-16 hover:bg-base-100 transition-colors h-full items-center"
                to={siteUrl(SITE_USER_DETAIL_PATH, { userId: userProfile.userId, eventId })}
            >
                <Avatar user={userProfile} className="h-16 w-16" />
                <span className="flex flex-col gap-0.5">
                    <span className="font-medium text-lg">
                        {userProfile.firstName} {userProfile.lastName}
                    </span>
                    <span className="text-base-500">{userProfile.headline}</span>

                    {notesCount && (
                        <div className="flex flex-wrap items-start gap-2 mt-2">
                            <div className="bg-theme-100 text-theme-700 font-medium rounded-lg px-3 py-1 text-sm flex items-center gap-1.5">
                                <NotebookIcon className="!w-3.5 !h-3.5" />
                                <span>{t('userProfileCard.notesCount', { count: notesCount })}</span>
                            </div>
                        </div>
                    )}
                </span>
            </Link>
        </div>
    );
};

const formatLabel = (t: TFunction<'site'>) => (filterKey: string) =>
    ({
        search: t('userListPage.filters.search'),
        isSaved: t('userListPage.filters.isSaved'),
        location: t('userListPage.filters.location'),
        fieldOfStudy: t('userListPage.filters.fieldOfStudy'),
        school: t('userListPage.filters.school'),
        degree: t('userListPage.filters.degree'),
        company: t('userListPage.filters.company'),
        languageCode: t('userListPage.filters.languageCode'),
    })[filterKey] || filterKey;

const formatValue = (t: TFunction<'site'>) => (filterKey: string, value: string) => {
    if (filterKey === 'isSaved') {
        return value === 'true'
            ? t('userListPage.filters.isSavedOptions.true')
            : t('userListPage.filters.isSavedOptions.false');
    } else if (filterKey === 'languageCode') {
        // @ts-ignore
        return t(`common:languageCode.${value}`) as string;
    }
    return value;
};

const UserListPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const location = useLocation();

    const { data: viewer } = useQuery(userDetailQuery());
    const { data: event } = useQuery(eventDetailQuery(eventId!));

    const { filters, onToggleFilter, searchParams, setSearchParams } = useSearchParamsFilters([
        'search',
        'isSaved',
        'location',
        'languageCode',
        'fieldOfStudy',
        'school',
        'degree',
        'company',
    ]);
    const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
    const pageSize = 20;

    const { data: userProfilesData } = useQuery({
        ...userProfileSearchQuery({ eventId, page, pageSize, isPublic: true, ...filters }),
        placeholderData: keepPreviousData,
        enabled: isAuthenticated(viewer!),
    });

    const { data: savedUsers } = useQuery({
        ...savedUserListQuery(viewer ? { eventId, userId: viewer.id } : { eventId }),
        placeholderData: keepPreviousData,
        enabled: isAuthenticated(viewer!),
    });
    const savedUsersByUserId = Object.fromEntries(savedUsers?.map((u) => [u.targetUserId, u]) || []);

    const { data: notes } = useQuery({
        ...userNoteListQuery({ eventId }),
        placeholderData: keepPreviousData,
        enabled: isOrganizationUser(viewer!),
    });
    const notesByTargetUserId: Record<string, UserNote[]> =
        notes?.reduce(
            (acc, note) => {
                if (!acc[note.targetUserId]) acc[note.targetUserId] = [];
                acc[note.targetUserId].push(note);
                return acc;
            },
            {} as Record<string, UserNote[]>
        ) || {};

    const userProfiles =
        userProfilesData?.results?.map((userProfile: UserProfile) =>
            mergeUserProfile(userProfile.user!, userProfile)
        ) || [];

    const handlePageChange = (newPage: number) => {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
    };

    const createLogEvent = useLogEvent();
    useEffect(() => {
        createLogEvent(LogEventType.USER_SEARCH, { ...filters, page, pageSize });
    }, [filters, page, pageSize]);

    if (isAuthenticated(viewer!) && !userProfilesData) return null;

    return (
        <div className={className} {...props}>
            <main className="flex justify-center bg-white px-6 py-24">
                <div className="w-full max-w-[1120px] flex flex-col gap-32">
                    {!(isOrganizationUser(viewer!) || isAdminUser(viewer!)) ? (
                        <EmptyState
                            title={t('userListPage.notAuthenticatedState.title')}
                            description={t('userListPage.notAuthenticatedState.description')}
                            button={
                                <LinkButton variant="outline" to={`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`}>
                                    {t('userListPage.notAuthenticatedState.button')}
                                </LinkButton>
                            }
                        />
                    ) : (
                        <section className="flex flex-col gap-14">
                            <h2 className="font-semibold text-3xl">
                                {t('userListPage.title', { usersCount: userProfiles.length, eventName: event?.name })}
                            </h2>

                            <div className="flex gap-10">
                                <div className="w-80 flex-none border-r border-base-100 pr-9">
                                    <SimpleFilterSidebar
                                        searchParams={searchParams}
                                        setSearchParams={setSearchParams}
                                        filtersData={userProfilesData?.filters || {}}
                                        filters={filters}
                                        onToggleFilter={onToggleFilter}
                                        formatLabel={formatLabel(t)}
                                        formatValue={formatValue(t)}
                                    />
                                </div>

                                <div className="flex flex-col gap-5 flex-1">
                                    {userProfiles?.map((userProfile: UserProfile) => (
                                        <UserProfileCard
                                            key={userProfile.id}
                                            userProfile={userProfile}
                                            savedUser={savedUsersByUserId[userProfile.userId] || null}
                                            notesCount={notesByTargetUserId[userProfile.userId]?.length}
                                        />
                                    ))}

                                    <div className="mt-5">
                                        <Pagination
                                            page={page}
                                            pageSize={pageSize}
                                            total={userProfilesData?.count ? Number(userProfilesData?.count) : 0}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </main>
        </div>
    );
};

export default UserListPage;
