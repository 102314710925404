import React from 'react';
import { Outlet, useMatch, useMatches } from 'react-router-dom';

import Footer from '../../../components/Footer.tsx';
import Header from '../../../components/Header.tsx';
import Admin from './Admin.tsx';

const AdminLayout = () => {
    const matches = useMatches();
    const lastMatch = matches[matches.length - 1];

    const { hideFooter = false } = lastMatch?.handle || ({} as any);

    return (
        <Admin>
            <Header />

            <Admin.Main>
                <Outlet />
            </Admin.Main>

            {!hideFooter && <Footer />}
        </Admin>
    );
};

export default AdminLayout;
