import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { ErrorAlert, InfoAlert, WarningAlert } from '../../components/Alert.tsx';
import { spaceDetailQuery } from '../../data/queries.ts';

export const useSpaceId = () => {
    const { data: space } = useQuery({ ...spaceDetailQuery(), staleTime: Infinity });
    return space?.id!;
};

export const useLimitAlert = ({
    limit,
    totalCount,
    limitTranslationKey,
    limitReachedTranslationKey,
    displayThreshold = 0.8,
}: {
    limit?: number;
    totalCount?: number;
    limitTranslationKey?: string;
    limitReachedTranslationKey?: string;
    displayThreshold?: number;
}) => {
    const { t } = useTranslation('admin');

    const isLimitReached = totalCount != null && limit != null && totalCount >= limit;

    const LimitAlert = (props: React.ComponentPropsWithRef<'div'>) => {
        const relativeProgress = totalCount != null && limit != null ? totalCount / limit : 0;

        if (totalCount == null || limit == null || relativeProgress < displayThreshold) return null;

        const Comp = totalCount >= limit ? ErrorAlert : totalCount / limit >= 0.8 ? WarningAlert : InfoAlert;

        return (
            <Comp {...props}>
                {isLimitReached && limitReachedTranslationKey ? (
                    // @ts-ignore
                    <Trans t={t} i18nKey={limitReachedTranslationKey} values={{ limit, totalCount }} />
                ) : (
                    // @ts-ignore
                    <Trans t={t} i18nKey={limitTranslationKey} values={{ limit, totalCount }} />
                )}
            </Comp>
        );
    };

    return {
        isLimitReached,
        LimitAlert,
    };
};
