import {
    conversationDetailQuery,
    conversationListQuery,
    eventDetailQuery,
    eventListQuery,
    eventPageDetailQuery,
    eventPageListQuery,
    eventSessionDetailQuery,
    eventSessionSearchQuery,
    jobDetailQuery,
    logEventTrackingStatusQuery,
    organizationDetailQuery,
    organizationProfileDetailQuery,
    organizationProfileJobListQuery,
    organizationProfileJobSearchQuery,
    organizationProfileListQuery,
    organizationProfileUserListQuery,
    userDetailQuery,
    userProfileListQuery,
    userProfileSearchQuery,
} from '../../data/queries.ts';
import { isUuid } from '../../data/utils.ts';
import ensureQueriesData from '../../utils/ensureQueriesData.ts';
import { isCustomDomain } from './utils.ts';

export const homeLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({
        events: eventListQuery(),
    });

    const eventId = data.events[0]?.id;
    if (!eventId) return data;

    const eventData = await ensureQueriesData({
        event: eventDetailQuery(eventId),
    });

    return { ...data, ...eventData };
};

export const eventDetailLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({
        event: eventDetailQuery(params.eventId),
        trackingStatus: logEventTrackingStatusQuery(),
    });

    if (isCustomDomain() && !data?.event?.id) {
        throw new Response('Not Found', { status: 404, statusText: 'Not Found' });
    }

    return data;
};

export const jobDetailLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({
        event: eventDetailQuery(params.eventId),
        job: jobDetailQuery(params.jobId),
        profileJob: organizationProfileJobListQuery({ jobId: params.jobId }),
    });

    const organizationProfileId = data.profileJob[0]?.organizationProfileId;
    if (!organizationProfileId) return data;

    const organizationProfileData = await ensureQueriesData({
        organizationProfile: organizationProfileDetailQuery(organizationProfileId),
    });

    return { ...data, ...organizationProfileData };
};

export const jobListLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({ event: eventDetailQuery(params.eventId) });
    if (!data.event?.id) return data;

    const eventData = await ensureQueriesData({
        profileJobs: organizationProfileJobSearchQuery({ eventId: data.event.id, page: 1, pageSize: 20 }),
    });

    return { ...data, ...eventData };
};

export const organizationDetailLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({ event: eventDetailQuery(params.eventId) });
    if (!data.event?.id) return data;

    const eventData = await ensureQueriesData({
        organization: organizationDetailQuery(params.organizationId),
        organizationProfiles: organizationProfileListQuery({
            eventId: data.event.id,
            organizationId: params.organizationId,
        }),
        organizationProfileJobs: organizationProfileJobListQuery({
            eventId: data.event.id,
            organizationId: params.organizationId,
        }),
        organizationProfileUsers: organizationProfileUserListQuery({
            eventId: data.event.id,
            organizationId: params.organizationId,
        }),
    });

    return { ...data, ...eventData };
};

export const organizationListLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({ event: eventDetailQuery(params.eventId) });
    if (!data.event?.id) return data;

    const eventData = await ensureQueriesData({
        organizationProfiles: organizationProfileListQuery({ eventId: data.event.id, isPublic: true }),
    });

    return { ...data, ...eventData };
};

export const userDetailLoader = ({ params }: any) =>
    ensureQueriesData({
        event: eventDetailQuery(params.eventId),
        user: userDetailQuery(params.userId!),
        userProfiles: userProfileListQuery({ userId: params.userId }),
    });

export const userListLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({ viewer: userDetailQuery(), event: eventDetailQuery(params.eventId) });
    if (!data.event?.id) return data;

    const eventData = await ensureQueriesData({
        users: userProfileSearchQuery({ eventId: data.event.id, page: 1, pageSize: 20, isPublic: true }),
    });

    return { ...data, ...eventData };
};

export const sessionListLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({ event: eventDetailQuery(params.eventId) });
    if (!data.event?.id) return data;

    const eventData = await ensureQueriesData({
        eventSessionSearch: eventSessionSearchQuery({ eventId: data.event.id, page: 1, pageSize: 20, isPublic: true }),
    });

    return { ...data, ...eventData };
};

export const sessionDetailLoader = ({ params }: any) =>
    ensureQueriesData({
        event: eventDetailQuery(params.eventId),
        eventSession: eventSessionDetailQuery(params.sessionId),
    });

export const pageDetailLoader = ({ params }: any) =>
    ensureQueriesData({
        event: eventDetailQuery(params.eventId),
        page: isUuid(params.pageId)
            ? eventPageDetailQuery(params.pageId)
            : eventPageListQuery({ eventId: params.eventId, slug: params.pageId }),
    });

export const conversationsLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({ event: eventDetailQuery(params.eventId), events: eventListQuery() });
    if (!data.event?.id) return data;

    const eventData = await ensureQueriesData({
        conversations: conversationListQuery({ eventId: data.event.id }),
    });

    return { ...data, ...eventData };
};

export const conversationDetailLoader = async ({ params }: any) => {
    const data = await ensureQueriesData({
        event: eventDetailQuery(params.eventId),
        conversation: conversationDetailQuery(params.conversationId),
        events: eventListQuery(),
    });
    if (!data.event?.id) return data;

    const eventData = await ensureQueriesData({
        conversations: conversationListQuery({ eventId: data.event.id }),
    });

    return { ...data, ...eventData };
};
