import { RouteObject } from 'react-router-dom';

import { authorizedLoader, isAuthenticated } from '../../utils/loaders.ts';
import Site from './components/Site.tsx';
import {
    conversationDetailLoader,
    conversationsLoader,
    eventDetailLoader,
    homeLoader,
    jobDetailLoader,
    jobListLoader,
    organizationDetailLoader,
    organizationListLoader,
    pageDetailLoader,
    sessionDetailLoader,
    sessionListLoader,
    userDetailLoader,
    userListLoader,
} from './loaders.ts';
import EventDetailPage from './pages/EventDetailPage.tsx';
import HomePage from './pages/HomePage.tsx';
import JobDetailPage from './pages/JobDetailPage.tsx';
import JobListPage from './pages/JobListPage.tsx';
import MessagesPage, { AddConversationFormDialog } from './pages/MessagesPage.tsx';
import OrganizationDetailPage from './pages/OrganizationDetailPage.tsx';
import OrganizationListPage from './pages/OrganizationListPage.tsx';
import PageDetailPage from './pages/PageDetailPage.tsx';
import ScheduledEmailsPage from './pages/ScheduledEmailsPage.tsx';
import SessionDetailPage from './pages/SessionDetailPage.tsx';
import SessionListPage from './pages/SessionListPage.tsx';
import UserDetailPage from './pages/UserDetailPage.tsx';
import UserListPage from './pages/UserListPage.tsx';
import { isCustomDomain } from './utils.ts';

// /events/:eventId
export const HOME_PATH = '/';
export const SITE_EVENT_DETAIL_PATH = '/events/:eventId';
export const SITE_ORGANIZATION_LIST_PATH = '/events/:eventId/organizations';
export const SITE_ORGANIZATION_DETAIL_PATH = '/events/:eventId/organizations/:organizationId';
export const SITE_USER_LIST_PATH = '/events/:eventId/users';
export const SITE_USER_DETAIL_PATH = '/events/:eventId/users/:userId';
export const SITE_JOB_LIST_PATH = '/events/:eventId/jobs';
export const SITE_JOB_DETAIL_PATH = '/events/:eventId/jobs/:jobId';
export const SITE_SESSIONS_LIST_PATH = '/events/:eventId/sessions';
export const SITE_SESSIONS_DETAIL_PATH = '/events/:eventId/sessions/:sessionId';

export const SITE_PAGE_DETAIL_PATH = '/events/:eventId/pages/:pageId';

export const SITE_MESSAGES_PATH = '/events/:eventId/messages';
export const SITE_CONVERSATION_ADD_PATH = '/events/:eventId/messages/add';
export const SITE_CONVERSATION_DETAIL_PATH = '/events/:eventId/messages/:conversationId';

export const ADMIN_SCHEDULED_EMAILS_PATH = '/admin/scheduled-emails';
export const ADMIN_SCHEDULED_EMAILS_ADD_PATH = '/admin/scheduled-emails/add';
export const ADMIN_SCHEDULED_EMAILS_DETAIL_PATH = '/admin/scheduled-emails/:emailId';

export const SITE_TERMS_PATH = '/terms';

export default [
    !isCustomDomain()
        ? {
              handle: {
                  title: 'Startseite',
              },
              path: HOME_PATH,
              element: <HomePage />,
              loader: homeLoader,
          }
        : null,
    {
        handle: {
            title: 'Veranstaltung',
        },
        path: SITE_EVENT_DETAIL_PATH,
        element: <EventDetailPage />,
        loader: eventDetailLoader,
    },
    {
        element: <Site />,
        loader: eventDetailLoader,
        children: [
            {
                handle: {
                    title: 'Unternehmen',
                },
                path: SITE_ORGANIZATION_LIST_PATH,
                element: <OrganizationListPage />,
                loader: organizationListLoader,
            },
            {
                handle: {
                    title: 'Unternehmen',
                },
                path: SITE_ORGANIZATION_DETAIL_PATH,
                element: <OrganizationDetailPage />,
                loader: organizationDetailLoader,
            },
            {
                handle: {
                    title: 'Teilnehmer',
                },
                path: SITE_USER_LIST_PATH,
                element: <UserListPage />,
                loader: userListLoader,
            },
            {
                handle: {
                    title: 'Teilnehmer',
                },
                path: SITE_USER_DETAIL_PATH,
                element: <UserDetailPage />,
                loader: userDetailLoader,
            },
            {
                handle: {
                    title: 'Jobs',
                },
                path: SITE_JOB_LIST_PATH,
                element: <JobListPage />,
                loader: jobListLoader,
            },
            {
                handle: {
                    title: 'Job',
                },
                path: SITE_JOB_DETAIL_PATH,
                element: <JobDetailPage />,
                loader: jobDetailLoader,
            },
            {
                handle: {
                    title: 'Programm',
                },
                path: SITE_SESSIONS_LIST_PATH,
                element: <SessionListPage />,
                loader: sessionListLoader,
            },
            {
                handle: {
                    title: 'Programm',
                },
                path: SITE_SESSIONS_DETAIL_PATH,
                element: <SessionDetailPage />,
                loader: sessionDetailLoader,
            },
            {
                handle: {
                    title: (data: any) => data?.page?.[0]?.title || data?.page?.title || 'Seite',
                },
                path: SITE_PAGE_DETAIL_PATH,
                element: <PageDetailPage />,
                loader: pageDetailLoader,
            },
            {
                handle: {
                    title: 'Nachrichten',
                    hideNavigation: true,
                    hideFooter: true,
                },
                path: SITE_MESSAGES_PATH,
                element: <MessagesPage />,
                loader: authorizedLoader(conversationsLoader, isAuthenticated),
                children: [
                    {
                        handle: {
                            title: 'Neue Nachricht',
                            hideNavigation: true,
                            hideFooter: true,
                        },
                        path: SITE_CONVERSATION_ADD_PATH,
                        element: <AddConversationFormDialog />,
                    },
                ],
            },
            {
                handle: {
                    title: 'Nachrichten',
                    hideNavigation: true,
                    hideFooter: true,
                },
                path: SITE_CONVERSATION_DETAIL_PATH,
                element: <MessagesPage />,
                loader: authorizedLoader(conversationDetailLoader, isAuthenticated),
            },
            {
                handle: {
                    title: 'Geplante E-Mails',
                    hideNavigation: true,
                    hideFooter: true,
                },
                path: ADMIN_SCHEDULED_EMAILS_PATH,
                element: <ScheduledEmailsPage />,
                loader: authorizedLoader(conversationsLoader, isAuthenticated),
            },
            {
                handle: {
                    title: 'Geplante E-Mails',
                    hideNavigation: true,
                    hideFooter: true,
                },
                path: ADMIN_SCHEDULED_EMAILS_ADD_PATH,
                element: <ScheduledEmailsPage add />,
                loader: authorizedLoader(conversationsLoader, isAuthenticated),
            },
            {
                handle: {
                    title: 'Geplante E-Mails',
                    hideNavigation: true,
                    hideFooter: true,
                },
                path: ADMIN_SCHEDULED_EMAILS_DETAIL_PATH,
                element: <ScheduledEmailsPage />,
                loader: authorizedLoader(conversationDetailLoader, isAuthenticated),
            },
        ],
    },
].filter(Boolean) as RouteObject[];
