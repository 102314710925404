import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { classnames } from '@nicoknoll/utils';
import { useQuery } from '@tanstack/react-query';

import { CountEntry, DateEntry } from '../../../../data/models.ts';
import { eventAnalyticsQuery } from '../../../../data/queries.ts';
import { formatDate } from '../../../../utils/time.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import { ADMIN_EVENT_ANALYTICS_PATH } from '../routes.tsx';

const ChartCard = ({ className, ...props }: React.ComponentProps<'div'>) => {
    return <div className={classnames('bg-base-100 rounded-xl p-6 flex flex-col gap-2', className)} {...props} />;
};

const ChartCardGrid = ({ title, className, ...props }: React.ComponentProps<'div'>) => {
    return <div className={classnames('grid grid-cols-3 gap-5', className)} {...props} />;
};

const TextChartCard = ({
    title,
    value,
    limit,
    ...props
}: React.ComponentProps<'div'> & { title: string; value?: number; limit?: number }) => {
    return (
        <ChartCard {...props}>
            <div className="text-base-600">{title}</div>
            <div className="flex gap-1 items-end">
                <div className="text-4xl font-semibold">{value ? Math.round(value * 100) / 100 : 0}</div>
                {limit && <div className="text-base-600 block text-base pb-0.5">/ {limit}</div>}
            </div>
        </ChartCard>
    );
};

const DateChartCard = ({
    title,
    data,
    ...props
}: { title: string; data: DateEntry[] } & React.ComponentProps<'div'>) => {
    return (
        <ChartCard {...props}>
            <div className="text-base-600 mb-2">{title}</div>
            <ResponsiveContainer width="100%" height={250}>
                <BarChart data={data}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                        dataKey="date"
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        tick={{ fontSize: 10 }}
                        tickFormatter={(value) =>
                            formatDate(new Date(value), undefined, { year: undefined, month: 'short' })
                        }
                    />

                    <Bar radius={8} barSize={24} dataKey="count" className="fill-theme-500">
                        <LabelList dataKey="count" position="top" offset={8} className="fill-base-900" fontSize={10} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </ChartCard>
    );
};

const CountChartCard = ({
    title,
    data,
    ...props
}: { title: string; data: CountEntry[] } & React.ComponentProps<'div'>) => {
    return (
        <ChartCard {...props}>
            <div className="text-base-600 mb-2">{title}</div>
            <ResponsiveContainer width="100%" height={250}>
                <BarChart data={data} layout="vertical">
                    <CartesianGrid horizontal={false} />
                    <YAxis
                        dataKey="value"
                        type="category"
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        tick={{ fontSize: 10 }}
                        width={160}
                    />
                    <XAxis
                        type="number"
                        tickLine={false}
                        axisLine={false}
                        tick={{ fontSize: 10 }}
                        domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.1)]}
                        allowDecimals={false}
                    />
                    <Bar radius={8} barSize={24} dataKey="count" className="fill-theme-500">
                        <LabelList
                            dataKey="count"
                            position="right"
                            offset={8}
                            className="fill-base-900"
                            fontSize={10}
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </ChartCard>
    );
};

const AttendeesChartGrid = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();
    const { data: eventAnalytics } = useQuery(eventAnalyticsQuery(eventId!));

    return (
        <ChartCardGrid title={t('eventDetailPage.analyticsTab.attendeesLabel')}>
            <TextChartCard title={t('eventDetailPage.analyticsTab.attendeesLabel')} value={eventAnalytics?.attendees} />
            <TextChartCard
                title={t('eventDetailPage.analyticsTab.savedAttendeesLabel')}
                value={eventAnalytics?.savedAttendees}
            />
            <TextChartCard
                title={t('eventDetailPage.analyticsTab.sessionAttendeesLabel')}
                value={eventAnalytics?.sessionAttendees}
            />

            <DateChartCard
                title={t('eventDetailPage.analyticsTab.signupsByDayLabel')}
                data={eventAnalytics?.signupsByDay || []}
                className="col-span-3"
            />
        </ChartCardGrid>
    );
};

const OrganizationsChartGrid = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();
    const { data: eventAnalytics } = useQuery(eventAnalyticsQuery(eventId!));

    return (
        <ChartCardGrid title={t('eventDetailPage.analyticsTab.organizationsLabel')}>
            <TextChartCard
                title={t('eventDetailPage.analyticsTab.organizationsLabel')}
                value={eventAnalytics?.organizations}
            />
            <TextChartCard
                title={t('eventDetailPage.analyticsTab.savedOrganizationsLabel')}
                value={eventAnalytics?.savedOrganizations}
            />

            <TextChartCard
                title={t('eventDetailPage.analyticsTab.contactPersonsLabel')}
                value={eventAnalytics?.contactPersons}
            />
            <CountChartCard
                title={t('eventDetailPage.analyticsTab.mostSavedOrganizationsLabel')}
                data={eventAnalytics?.mostSavedOrganizations || []}
                className="col-span-3"
            />

            <CountChartCard
                title={t('eventDetailPage.analyticsTab.mostViewedOrganizationsLabel')}
                data={eventAnalytics?.mostViewedOrganizations || []}
                className="col-span-3"
            />

            <CountChartCard
                title={t('eventDetailPage.analyticsTab.organizationSearchTermsLabel')}
                data={eventAnalytics?.organizationSearchTerms || []}
                className="col-span-3"
            />
        </ChartCardGrid>
    );
};

const JobsChartGrid = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();
    const { data: eventAnalytics } = useQuery(eventAnalyticsQuery(eventId!));

    return (
        <ChartCardGrid title={t('eventDetailPage.analyticsTab.jobsLabel')}>
            <TextChartCard title={t('eventDetailPage.analyticsTab.jobsLabel')} value={eventAnalytics?.jobs} />
            <TextChartCard title={t('eventDetailPage.analyticsTab.savedJobsLabel')} value={eventAnalytics?.savedJobs} />

            <CountChartCard
                title={t('eventDetailPage.analyticsTab.mostSavedJobsLabel')}
                data={eventAnalytics?.mostSavedJobs || []}
                className="col-span-3"
            />

            <CountChartCard
                title={t('eventDetailPage.analyticsTab.mostViewedJobsLabel')}
                data={eventAnalytics?.mostViewedJobs || []}
                className="col-span-3"
            />

            <CountChartCard
                title={t('eventDetailPage.analyticsTab.jobSearchTermsLabel')}
                data={eventAnalytics?.jobSearchTerms || []}
                className="col-span-3"
            />
        </ChartCardGrid>
    );
};

const SessionsChartGrid = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();
    const { data: eventAnalytics } = useQuery(eventAnalyticsQuery(eventId!));

    return (
        <ChartCardGrid title={t('eventDetailPage.analyticsTab.sessionsLabel')}>
            <TextChartCard title={t('eventDetailPage.analyticsTab.sessionsLabel')} value={eventAnalytics?.sessions} />
            <TextChartCard
                title={t('eventDetailPage.analyticsTab.savedSessionsLabel')}
                value={eventAnalytics?.savedSessions}
            />

            <CountChartCard
                title={t('eventDetailPage.analyticsTab.mostSavedSessionsLabel')}
                data={eventAnalytics?.mostSavedSessions || []}
                className="col-span-3"
            />

            <CountChartCard
                title={t('eventDetailPage.analyticsTab.mostViewedSessionsLabel')}
                data={eventAnalytics?.mostViewedSessions || []}
                className="col-span-3"
            />

            <CountChartCard
                title={t('eventDetailPage.analyticsTab.sessionSearchTermsLabel')}
                data={eventAnalytics?.sessionSearchTerms || []}
                className="col-span-3"
            />
        </ChartCardGrid>
    );
};

const ConversationsChartGrid = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();
    const { data: eventAnalytics } = useQuery(eventAnalyticsQuery(eventId!));

    return (
        <ChartCardGrid title={t('eventDetailPage.analyticsTab.conversationsLabel')}>
            <TextChartCard
                title={t('eventDetailPage.analyticsTab.conversationsLabel')}
                value={eventAnalytics?.conversations}
            />
            <TextChartCard title={t('eventDetailPage.analyticsTab.messagesLabel')} value={eventAnalytics?.messages} />
            <TextChartCard
                title={t('eventDetailPage.analyticsTab.conversationPerAttendeeLabel')}
                value={eventAnalytics?.conversationsPerAttendee}
            />
            <TextChartCard
                title={t('eventDetailPage.analyticsTab.conversationPerOrganizationLabel')}
                value={eventAnalytics?.conversationsPerOrganization}
            />

            <CountChartCard
                title={t('eventDetailPage.analyticsTab.mostConversationsOrganizationsLabel')}
                data={eventAnalytics?.mostConversationsOrganizations || []}
                className="col-span-3"
            />
        </ChartCardGrid>
    );
};

const CHART_GRIDS: Record<string, React.ComponentType> = {
    attendees: AttendeesChartGrid,
    organizations: OrganizationsChartGrid,
    jobs: JobsChartGrid,
    sessions: SessionsChartGrid,
    conversations: ConversationsChartGrid,
};

const AnalyticsTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();

    const chartGridKeys = Object.keys(CHART_GRIDS);

    const { chartGrid = chartGridKeys[0] } = useParams();
    const ChartGrid = CHART_GRIDS[chartGrid!];

    return (
        <div className="w-full flex flex-col gap-10 justify-start">
            <Admin.CardNavigationWrapper>
                <Admin.Card className="flex-none flex flex-col gap-5">
                    <h1 className="text-3xl font-semibold">{t('eventDetailPage.analyticsTab.title')}</h1>
                </Admin.Card>

                <Admin.CardNavigation>
                    {chartGridKeys.map((key) => (
                        <Admin.CardNavigationLink
                            key={key}
                            active={chartGrid === key}
                            to={url(ADMIN_EVENT_ANALYTICS_PATH, { eventId, chartGrid: key })}
                        >
                            {/* @ts-ignore */}
                            {t(`eventDetailPage.analyticsTab.${key}Label`)}
                        </Admin.CardNavigationLink>
                    ))}
                </Admin.CardNavigation>
            </Admin.CardNavigationWrapper>

            <ChartGrid />
        </div>
    );
};

export default AnalyticsTab;
