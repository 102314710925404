import { PlusIcon } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { FileField, FileInput, Form, TextField } from '@nicoknoll/forms';

import Button from '../../../components/Button.tsx';
import Dialog from '../../../components/Dialog.tsx';
import FormError from '../../../components/FormError.tsx';
import FormSubmitButton from '../../../components/FormSubmitButton.tsx';
import { User } from '../../../data/models.ts';
import { setFormErrors } from '../../../utils/forms.ts';

export const CONVERSATION_FORM_DEFAULT_VALUES = {
    text: '',
    attachment: '',
};

export const AttachmentInput = ({ value, onChange, ...props }: { value: any; onChange: (value: any) => void }) => {
    const { t } = useTranslation('site');

    if (value) {
        return <FileInput value={value} onChange={onChange} {...props} />;
    } else {
        return (
            <Button variant="outline" type="button" className="relative pl-2.5">
                <input type="file" className="absolute w-full h-full opacity-0" onChange={onChange} value="" />
                <div className="flex gap-1.5 items-center">
                    <PlusIcon />
                    <span>{t('baseConversationFormDialog.chooseFileButton')}</span>
                </div>
            </Button>
        );
    }
};

const BaseConversationFormDialog = ({
    receiver,
    isOpen = true,
    onIsOpenChange,
    onSubmit,
    onCancel,
}: {
    receiver?: User;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onSubmit?: (data: any) => void;
    onCancel?: () => void;
}) => {
    const { t } = useTranslation('site');

    const formMethods = useForm({ defaultValues: { ...CONVERSATION_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(onSubmit?.(values));
            toast.success(t('baseConversationFormDialog.submitSuccess'));
        } catch (error) {
            setFormErrors(t, formMethods, error);
            toast.error(t('baseConversationFormDialog.submitError'));
        } finally {
            setIsLoading(false);
        }
    };

    const receiverName = `${receiver?.firstName || ''} ${receiver?.lastName || ''}`.trim() || receiver?.email || '';

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">{t('baseConversationFormDialog.title', { receiverName })}</h1>
                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex flex-col gap-5 flex-none">
                    <FormError />

                    <Form.Field name="text">
                        <TextField inputClassName="h-32" />
                    </Form.Field>

                    <div className="flex gap-2 p-3 justify-between bg-base-50 rounded-md">
                        <Form.Field name="attachment">
                            <FileField widget={AttachmentInput} />
                        </Form.Field>
                    </div>

                    <div className="flex gap-2 justify-between">
                        <Button variant="ghost" onClick={onCancel} type="button">
                            {t('baseConversationFormDialog.cancelButton')}
                        </Button>

                        <FormSubmitButton variant="primary" type="submit" loading={isLoading}>
                            {t('baseConversationFormDialog.submitButton')}
                        </FormSubmitButton>
                    </div>
                </Form>
            </Dialog.Content>
        </Dialog>
    );
};

export default BaseConversationFormDialog;
