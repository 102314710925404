import { Navigate } from 'react-router-dom';

import { authorized, authorizedLoader, isAdminUser, isOrganizationUser } from '../../../utils/loaders.ts';
import { userEditLoader, userTabLoader } from '../organization-settings/loaders.ts';
import { filesTabLoader } from './loaders.ts';
import SpaceSettingsPage from './pages/SpaceSettingsPage.tsx';
import FilesTab from './tabs/FilesTab.tsx';
import UsersTab, { AddUserFormDialog, EditUserFormDialog } from './tabs/UsersTab.tsx';

export const ADMIN_SPACE_SETTINGS_PATH = '/admin/space/settings';
export const ADMIN_SPACE_SETTINGS_FILES_PATH = '/admin/space/settings/files';
export const ADMIN_SPACE_SETTINGS_USERS_PATH = '/admin/space/settings/users';
export const ADMIN_SPACE_SETTINGS_USERS_ADD_PATH = '/admin/space/settings/users/add';
export const ADMIN_SPACE_SETTINGS_USERS_EDIT_PATH = '/admin/space/settings/users/:userId/edit';

export default [
    {
        path: ADMIN_SPACE_SETTINGS_PATH,
        element: <SpaceSettingsPage />,
        loader: authorized(isAdminUser),
        children: [
            {
                index: true,
                element: <Navigate to={ADMIN_SPACE_SETTINGS_FILES_PATH} replace={true} />,
            },

            {
                handle: {
                    title: 'Gemeinsame Dateien',
                },
                path: ADMIN_SPACE_SETTINGS_FILES_PATH,
                element: <FilesTab />,
                loader: authorizedLoader(filesTabLoader, isAdminUser),
            },
            {
                handle: {
                    title: 'Benutzer',
                },
                path: ADMIN_SPACE_SETTINGS_USERS_PATH,
                element: <UsersTab />,
                children: [
                    {
                        handle: {
                            title: 'Benutzer hinzufügen',
                        },
                        path: ADMIN_SPACE_SETTINGS_USERS_ADD_PATH,
                        element: <AddUserFormDialog />,
                    },
                    {
                        handle: {
                            title: 'Benutzer bearbeiten',
                        },
                        path: ADMIN_SPACE_SETTINGS_USERS_EDIT_PATH,
                        element: <EditUserFormDialog />,
                    },
                ],
            },
        ],
    },
];
