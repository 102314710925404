import { LockIcon } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatches } from 'react-router-dom';

import CookieBar from '../../../components/CookieBar.tsx';
import Footer from '../../../components/Footer.tsx';
import Header from '../../../components/Header.tsx';
import Tooltip from '../../../components/Tooltip.tsx';
import {
    SITE_EVENT_DETAIL_PATH,
    SITE_JOB_LIST_PATH,
    SITE_ORGANIZATION_LIST_PATH,
    SITE_SESSIONS_LIST_PATH,
    SITE_USER_LIST_PATH,
} from '../routes.tsx';
import { siteUrl, useEventId } from '../utils.ts';
import Navigation from './Navigation.tsx';

const Site = () => {
    const matches = useMatches();
    const lastMatch = matches[matches.length - 1];

    const { hideNavigation = false, hideFooter = false } = lastMatch?.handle || ({} as any);

    return (
        <div className="antialiased min-h-[100vh] flex flex-col">
            <Header />

            {!hideNavigation && <SiteNavigation />}

            {/*<div className="min-h-[100vh] bg-white">*/}
            <Outlet />
            {/*</div>*/}

            {!hideFooter && <Footer />}

            <CookieBar />
        </div>
    );
};

export const SiteNavigation = (props: React.ComponentPropsWithRef<typeof Navigation>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();

    return (
        <Navigation {...props}>
            <Navigation.Link to={siteUrl(SITE_EVENT_DETAIL_PATH, { eventId })} end>
                {t('siteNavigation.home')}
            </Navigation.Link>
            <Navigation.Link to={siteUrl(SITE_SESSIONS_LIST_PATH, { eventId })}>
                {t('siteNavigation.sessions')}
            </Navigation.Link>

            <Navigation.Link to={siteUrl(SITE_ORGANIZATION_LIST_PATH, { eventId })}>
                {t('siteNavigation.organizations')}
            </Navigation.Link>
            <Tooltip>
                <Tooltip.Trigger asChild>
                    <Navigation.Link to={siteUrl(SITE_USER_LIST_PATH, { eventId })}>
                        <span className="flex gap-2 items-center">
                            <LockIcon />
                            <span>{t('siteNavigation.users')}</span>
                        </span>
                    </Navigation.Link>
                </Tooltip.Trigger>
                <Tooltip.Content>
                    <span className="flex gap-1.5 items-center">
                        <LockIcon className="!w-3 !h-3" strokeWidth={2.5} />
                        <span>{t('siteNavigation.usersTooltip')}</span>
                    </span>
                </Tooltip.Content>
            </Tooltip>
            <Navigation.Link to={siteUrl(SITE_JOB_LIST_PATH, { eventId })}>{t('siteNavigation.jobs')}</Navigation.Link>
        </Navigation>
    );
};

export default Site;
