import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { eventPageDetailQuery, eventPageListQuery } from '../../../data/queries.ts';
import { isUuid } from '../../../data/utils.ts';
import { useEventId } from '../utils.ts';

const PageDetailPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { pageId } = useParams();

    // slug support
    const { data: pageById } = useQuery({ ...eventPageDetailQuery(pageId!), enabled: isUuid(pageId!) });
    const { data: pagesBySlug } = useQuery({
        ...eventPageListQuery({ eventId, slug: pageId }),
        enabled: !isUuid(pageId!),
    });
    const page = pageById || pagesBySlug?.[0];

    // useLogEventOnMount(LogEventType.EVENT_SESSION_VIEW, { targetId: pageId });

    return (
        <div className={className} {...props}>
            <main className="flex justify-center bg-white px-6 py-24">
                <div className="w-full max-w-[1120px] flex flex-col gap-32">
                    <section className="flex gap-10 -mb-6">
                        <div className="flex flex-col gap-24 w-8/12">
                            <section className="flex flex-col gap-10">
                                <h1 className="font-semibold text-5xl text-balance leading-snug">{page?.title}</h1>

                                <div
                                    className="styled"
                                    dangerouslySetInnerHTML={{
                                        __html: page?.content || `<p>${t('sessionDetailPage.noDescription')}</p>`,
                                    }}
                                />
                            </section>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default PageDetailPage;
