import { PlusIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { Form, SelectField, StringField, TextField } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import Dialog from '../../../../components/Dialog.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import LinkButton from '../../../../components/LinkButton.tsx';
import MoreMenu from '../../../../components/MoreMenu.tsx';
import WysiwygInput from '../../../../components/WysiwygInput.tsx';
import { Job, TRANSLATABLE_JOB_KEYS, createTranslatable } from '../../../../data/models.ts';
import {
    fileListQuery,
    jobCreateMutation,
    jobDeleteMutation,
    jobDetailQuery,
    jobListQuery,
    jobUpdateMutation,
    organizationDetailQuery,
    organizationLimitsQuery,
} from '../../../../data/queries.ts';
import {
    EMPLOYMENT_TYPE_SUGGESTIONS,
    JOB_QUALIFICATION_SUGGESTIONS,
    JOB_REMOTE_WORK_SUGGESTIONS,
    JOB_REMUNERATION_TYPE_SUGGESTIONS,
} from '../../../../data/suggestions.ts';
import { valuesToOptions } from '../../../../data/utils.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import JobCard from '../../components/JobCard.tsx';
import TranslatableFormField from '../../components/TranslatableFormField.tsx';
import { useLimitAlert } from '../../utils.tsx';
import {
    ADMIN_ORGANIZATION_SETTINGS_JOBS_ADD_PATH,
    ADMIN_ORGANIZATION_SETTINGS_JOBS_EDIT_PATH,
    ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH,
} from '../routes.tsx';

const JOB_FORM_DEFAULT_VALUES = createTranslatable(
    {
        title: '',
        summary: '',
        description: '',
        employmentType: '',
        qualification: '',
        remoteWork: '',
        remunerationType: '',
        location: '',
        url: '',
    },
    TRANSLATABLE_JOB_KEYS
);

const BaseJobFormDialog = ({
    isCreate = false,
    job,
    isOpen = true,
    onIsOpenChange,
    onSubmit,
    onCancel,
}: {
    isCreate: boolean;
    job?: Job;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onSubmit?: (data: any) => void;
    onCancel?: () => void;
}) => {
    const { t } = useTranslation('admin');

    const formMethods = useForm({ defaultValues: pickFormValues(JOB_FORM_DEFAULT_VALUES, job), mode: 'onTouched' });
    useEffect(() => {
        formMethods.reset(pickFormValues(JOB_FORM_DEFAULT_VALUES, job));
    }, [job]);

    const { data: files } = useQuery(fileListQuery({ pageSize: 100, organizationId: job?.organizationId }));
    const [isLoading, setIsLoading] = useState(false);

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());
    const { data: limits } = useQuery(organizationLimitsQuery(viewerOrganization?.id!));
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: limits?.jobsCount,
        limit: limits?.maxJobs,
        limitTranslationKey: 'organizationSettingsPage.jobFormDialog.limitAlert',
        limitReachedTranslationKey: 'organizationSettingsPage.jobFormDialog.limitReachedAlert',
    });

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(onSubmit?.(values));
            toast.success(
                isCreate
                    ? t('organizationSettingsPage.jobFormDialog.createSuccess')
                    : t('organizationSettingsPage.jobFormDialog.updateSuccess')
            );
        } catch (error) {
            setFormErrors(t, formMethods, error);
            toast.error(
                isCreate
                    ? t('organizationSettingsPage.jobFormDialog.createError')
                    : t('organizationSettingsPage.jobFormDialog.updateError')
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[50rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">
                    {isCreate
                        ? t('organizationSettingsPage.jobFormDialog.titleCreate')
                        : t('organizationSettingsPage.jobFormDialog.titleUpdate')}
                </h1>

                {isCreate && <LimitAlert />}

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <FormError />

                    <TranslatableFormField
                        name="title"
                        rules={{ required: t('organizationSettingsPage.jobFormDialog.titleRequired') }}
                    >
                        <StringField label={t('organizationSettingsPage.jobFormDialog.titleLabel')} required />
                    </TranslatableFormField>

                    <TranslatableFormField
                        name="summary"
                        rules={{ required: t('organizationSettingsPage.jobFormDialog.summaryRequired') }}
                    >
                        <TextField
                            label={t('organizationSettingsPage.jobFormDialog.summaryLabel')}
                            required
                            maxLength={140}
                        />
                    </TranslatableFormField>

                    <TranslatableFormField name="description">
                        <TextField
                            label={t('organizationSettingsPage.jobFormDialog.descriptionLabel')}
                            widget={WysiwygInput}
                            // @ts-ignore
                            files={files}
                        />
                    </TranslatableFormField>

                    <Form.Field
                        name="employmentType"
                        rules={{ required: t('organizationSettingsPage.jobFormDialog.employmentTypeRequired') }}
                    >
                        <SelectField
                            label={t('organizationSettingsPage.jobFormDialog.employmentTypeLabel')}
                            options={valuesToOptions(EMPLOYMENT_TYPE_SUGGESTIONS)}
                            hideSearch
                            required
                        />
                    </Form.Field>

                    <Form.Field name="qualification">
                        <SelectField
                            label={t('organizationSettingsPage.jobFormDialog.qualificationLabel')}
                            options={valuesToOptions(JOB_QUALIFICATION_SUGGESTIONS)}
                            hideSearch
                        />
                    </Form.Field>

                    <Form.Field name="remoteWork">
                        <SelectField
                            label={t('organizationSettingsPage.jobFormDialog.remoteWorkLabel')}
                            options={valuesToOptions(JOB_REMOTE_WORK_SUGGESTIONS)}
                            hideSearch
                        />
                    </Form.Field>

                    <Form.Field name="remunerationType">
                        <SelectField
                            label={t('organizationSettingsPage.jobFormDialog.remunerationTypeLabel')}
                            options={valuesToOptions(JOB_REMUNERATION_TYPE_SUGGESTIONS)}
                            hideSearch
                        />
                    </Form.Field>

                    <Form.Field
                        name="location"
                        rules={{ required: t('organizationSettingsPage.jobFormDialog.locationRequired') }}
                    >
                        <StringField label={t('organizationSettingsPage.jobFormDialog.locationLabel')} required />
                    </Form.Field>

                    <Form.Field name="url">
                        <StringField label={t('organizationSettingsPage.jobFormDialog.urlLabel')} />
                    </Form.Field>

                    <div className="flex justify-end gap-2">
                        <Button variant="ghost" onClick={onCancel} type="button">
                            {t('organizationSettingsPage.jobFormDialog.cancelButton')}
                        </Button>

                        <FormSubmitButton variant="primary" type="submit" loading={isLoading} disabled={isLimitReached}>
                            {isCreate
                                ? t('organizationSettingsPage.jobFormDialog.createButton')
                                : t('organizationSettingsPage.jobFormDialog.updateButton')}
                        </FormSubmitButton>
                    </div>
                </Form>
            </Dialog.Content>
        </Dialog>
    );
};

export const AddJobFormDialog = (props: any) => {
    const navigate = useNavigate();

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());

    const { mutateAsync: createJob } = useMutation(jobCreateMutation());
    return (
        <BaseJobFormDialog
            isCreate
            {...props}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH);
                }
            }}
            onSubmit={async (data) => {
                await createJob({ data: { ...data, organizationId: viewerOrganization?.id }, translatable: true });
                return navigate(ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH);
            }}
            onCancel={() => navigate(ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH)}
        />
    );
};

export const EditJobFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { jobId } = useParams();

    const { data: job } = useQuery(jobDetailQuery(jobId!, { translatable: true }));
    const { mutateAsync: updateJob } = useMutation(jobUpdateMutation());

    return (
        <BaseJobFormDialog
            {...props}
            job={job}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH);
                }
            }}
            onSubmit={async (data) => {
                await updateJob({ id: jobId!, data, translatable: true });
                return navigate(ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH);
            }}
            onCancel={() => navigate(ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH)}
        />
    );
};

const JobsTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());
    const { data: jobs } = useQuery(jobListQuery({ organizationId: viewerOrganization?.id }));

    const { mutateAsync: deleteJob } = useMutation(jobDeleteMutation());

    const handleDeleteJob = async (jobId: string) => {
        try {
            await deleteJob({ id: jobId });
            toast.success(t('organizationSettingsPage.jobsTab.deleteSuccess'));
        } catch (error) {
            toast.error(t('organizationSettingsPage.jobsTab.deleteError'));
        }
    };

    return (
        <>
            <Admin.Card className="flex gap-4 flex-none justify-between">
                <h1 className="text-3xl font-semibold">{t('organizationSettingsPage.jobsTab.title')}</h1>
                {jobs && jobs?.length > 0 && (
                    <div>
                        <LinkButton to={url(ADMIN_ORGANIZATION_SETTINGS_JOBS_ADD_PATH)} className="flex gap-1">
                            <PlusIcon />
                            {t('organizationSettingsPage.jobsTab.createButton')}
                        </LinkButton>
                    </div>
                )}
            </Admin.Card>

            {jobs && jobs?.length > 0 ? (
                jobs.map((job) => (
                    <JobCard
                        className="flex-none"
                        key={job.id}
                        job={job}
                        button={
                            <MoreMenu>
                                <MoreMenu.Item asChild>
                                    <Link to={url(ADMIN_ORGANIZATION_SETTINGS_JOBS_EDIT_PATH, { jobId: job.id })}>
                                        {t('organizationSettingsPage.jobsTab.editButton')}
                                    </Link>
                                </MoreMenu.Item>
                                <MoreMenu.Item danger onClick={() => handleDeleteJob(job.id)}>
                                    {t('organizationSettingsPage.jobsTab.deleteButton')}
                                </MoreMenu.Item>
                            </MoreMenu>
                        }
                    />
                ))
            ) : (
                <EmptyState
                    className="h-full"
                    title={t('organizationSettingsPage.jobsTab.noJobs.title')}
                    description={t('organizationSettingsPage.jobsTab.noJobs.description')}
                    button={
                        <LinkButton to={url(ADMIN_ORGANIZATION_SETTINGS_JOBS_ADD_PATH)} className="flex gap-1">
                            <PlusIcon />
                            {t('organizationSettingsPage.jobsTab.createButton')}
                        </LinkButton>
                    }
                />
            )}

            <Outlet />
        </>
    );
};

export default JobsTab;
