import { Parser } from '@json2csv/plainjs';

const downloadCsv = (data: Record<string, any>, filename: string = 'data.csv') => {
    const parser = new Parser({
        fields: Object.keys(data[0]),
        header: true,
    });

    const csv = parser.parse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

export default downloadCsv;
