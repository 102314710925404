import React from 'react';

import { classnames } from '@nicoknoll/utils';
import { Slot } from '@radix-ui/react-slot';

import { Slottable } from './Button.tsx';

const Anchor = ({ className, asChild, ...props }: React.ComponentPropsWithRef<'a'> & Slottable) => {
    const Comp = asChild ? Slot : 'a';

    return (
        <Comp
            className={classnames(
                'text-theme-500 decoration-theme-500/30 cursor-pointer underline underline-offset-2 hover:text-theme-700 transition-colors',
                className
            )}
            {...props}
        />
    );
};

export default Anchor;
