import { Navigate } from 'react-router-dom';

import { authorized, authorizedLoader, isOrganizationUser } from '../../../utils/loaders.ts';
import {
    contactTabLoader,
    filesTabLoader,
    jobEditLoader,
    jobTabLoader,
    profileTabLoader,
    userEditLoader,
    userTabLoader,
} from './loaders.ts';
import OrganizationSettingsPage from './pages/OrganizationSettingsPage.tsx';
import ContactTab from './tabs/ContactTab.tsx';
import FilesTab from './tabs/FilesTab.tsx';
import JobsTab, { AddJobFormDialog, EditJobFormDialog } from './tabs/JobsTab.tsx';
import ProfileTab from './tabs/ProfileTab.tsx';
import UsersTab, { AddUserFormDialog, EditUserFormDialog } from './tabs/UsersTab.tsx';

export const ADMIN_ORGANIZATION_SETTINGS_PATH = '/admin/organization/settings';
export const ADMIN_ORGANIZATION_SETTINGS_PROFILE_PATH = '/admin/organization/settings/profile';
export const ADMIN_ORGANIZATION_SETTINGS_CONTACT_PATH = '/admin/organization/settings/contact';
export const ADMIN_ORGANIZATION_SETTINGS_FILES_PATH = '/admin/organization/settings/files';
export const ADMIN_ORGANIZATION_SETTINGS_USERS_PATH = '/admin/organization/settings/users';
export const ADMIN_ORGANIZATION_SETTINGS_USERS_ADD_PATH = '/admin/organization/settings/users/add';
export const ADMIN_ORGANIZATION_SETTINGS_USERS_DETAIL_PATH = '/admin/organization/settings/users/:userId';
export const ADMIN_ORGANIZATION_SETTINGS_USERS_EDIT_PATH = '/admin/organization/settings/users/:userId/edit';
export const ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH = '/admin/organization/settings/jobs';
export const ADMIN_ORGANIZATION_SETTINGS_JOBS_ADD_PATH = '/admin/organization/settings/jobs/add';
export const ADMIN_ORGANIZATION_SETTINGS_JOBS_DETAIL_PATH = '/admin/organization/settings/jobs/:jobId';
export const ADMIN_ORGANIZATION_SETTINGS_JOBS_EDIT_PATH = '/admin/organization/settings/jobs/:jobId/edit';

export default [
    {
        path: ADMIN_ORGANIZATION_SETTINGS_PATH,
        element: <OrganizationSettingsPage />,
        loader: authorized(isOrganizationUser),
        children: [
            {
                index: true,
                element: <Navigate to={ADMIN_ORGANIZATION_SETTINGS_PROFILE_PATH} replace={true} />,
            },
            {
                handle: {
                    title: 'Profil',
                },
                path: ADMIN_ORGANIZATION_SETTINGS_PROFILE_PATH,
                element: <ProfileTab />,
                loader: authorizedLoader(profileTabLoader, isOrganizationUser),
            },
            {
                handle: {
                    title: 'Kontakt',
                },
                path: ADMIN_ORGANIZATION_SETTINGS_CONTACT_PATH,
                element: <ContactTab />,
                loader: authorizedLoader(contactTabLoader, isOrganizationUser),
            },
            {
                handle: {
                    title: 'Dateien',
                },
                path: ADMIN_ORGANIZATION_SETTINGS_FILES_PATH,
                element: <FilesTab />,
                loader: authorizedLoader(filesTabLoader, isOrganizationUser),
            },
            {
                handle: {
                    title: 'Jobs',
                },
                path: ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH,
                element: <JobsTab />,
                loader: authorizedLoader(jobTabLoader, isOrganizationUser),
                children: [
                    {
                        handle: {
                            title: 'Job hinzufügen',
                        },
                        path: ADMIN_ORGANIZATION_SETTINGS_JOBS_ADD_PATH,
                        element: <AddJobFormDialog />,
                    },
                    {
                        handle: {
                            title: 'Job bearbeiten',
                        },
                        path: ADMIN_ORGANIZATION_SETTINGS_JOBS_EDIT_PATH,
                        element: <EditJobFormDialog />,
                        loader: authorizedLoader(jobEditLoader, isOrganizationUser),
                    },
                ],
            },
            {
                handle: {
                    title: 'Benutzer',
                },
                path: ADMIN_ORGANIZATION_SETTINGS_USERS_PATH,
                element: <UsersTab />,
                loader: authorizedLoader(userTabLoader, isOrganizationUser),
                children: [
                    {
                        handle: {
                            title: 'Benutzer hinzufügen',
                        },
                        path: ADMIN_ORGANIZATION_SETTINGS_USERS_ADD_PATH,
                        element: <AddUserFormDialog />,
                    },
                    {
                        handle: {
                            title: 'Benutzer bearbeiten',
                        },
                        path: ADMIN_ORGANIZATION_SETTINGS_USERS_EDIT_PATH,
                        element: <EditUserFormDialog />,
                        loader: authorizedLoader(userEditLoader, isOrganizationUser),
                    },
                ],
            },
        ],
    },
];
