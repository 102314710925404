import { FacebookIcon, InstagramIcon, LinkIcon, LinkedinIcon, TwitterIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CheckboxGroup, Form, MultiSelectField, SelectField, StringField, Widget } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import { EventDay } from '../../../../data/models.ts';
import {
    eventDetailQuery,
    organizationDetailQuery,
    organizationProfileDetailQuery,
    organizationProfileUpdateMutation,
} from '../../../../data/queries.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import { formatDate } from '../../../../utils/time.ts';
import Admin from '../../components/Admin.tsx';

const CONTACT_FORM_DEFAULT_VALUES = {
    eventDayIds: [],
    offlineLocation: '',
    website: '',
    linkedin: '',
    facebook: '',
    twitter: '',
    instagram: '',
};

const ContactTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { profileId } = useParams();

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());
    const { data: organizationProfile } = useQuery(organizationProfileDetailQuery(profileId!));
    const { data: event } = useQuery({
        ...eventDetailQuery(organizationProfile?.eventId),
        enabled: !!organizationProfile?.eventId,
    });

    const { mutateAsync: updateOrganizationProfile } = useMutation(organizationProfileUpdateMutation());

    const formMethods = useForm({
        defaultValues: pickFormValues(CONTACT_FORM_DEFAULT_VALUES, organizationProfile),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(CONTACT_FORM_DEFAULT_VALUES, organizationProfile));
    }, [organizationProfile]);

    const handleSubmit = async (values: any) => {
        try {
            await updateOrganizationProfile({ id: profileId!, data: values });
            toast.success(t('organizationProfileDetailPage.contactTab.submitSuccess'));
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('organizationProfileDetailPage.contactTab.submitError'));
        }
    };

    const handleResetToDefaultProfile = () => {
        formMethods.reset({
            ...pickFormValues(CONTACT_FORM_DEFAULT_VALUES, viewerOrganization),
            offlineLocation: '',
            eventDayIds: [],
        });
    };

    return (
        <Admin.Card className="flex flex-col gap-7">
            <h1 className="text-3xl font-semibold">{t('organizationProfileDetailPage.contactTab.title')}</h1>

            <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                <div className="flex-1 flex justify-between items-center gap-5 rounded-xl bg-base-50 p-5">
                    <span className="text-sm text-base-600">
                        {t('organizationProfileDetailPage.generalTab.resetToDefaultProfileNote')}
                    </span>

                    <div className="flex-none">
                        <Button onClick={handleResetToDefaultProfile} type="button" loadingDelay={100}>
                            {t('organizationProfileDetailPage.generalTab.resetToDefaultProfileButton')}
                        </Button>
                    </div>
                </div>

                <FormError />

                <Form.Field name="eventDayIds">
                    <MultiSelectField
                        label={t('organizationProfileDetailPage.contactTab.eventDaysLabel')}
                        options={
                            event?.days.map((day: EventDay) => ({
                                label: formatDate(new Date(day.date), undefined, { weekday: 'long' }),
                                value: day.id,
                            })) || []
                        }
                        widget={CheckboxGroup}
                        open={true}
                    />
                </Form.Field>

                <Form.Field name="offlineLocation">
                    <StringField
                        label={t('organizationProfileDetailPage.contactTab.offlineLocationLabel')}
                        placeholder={t('organizationProfileDetailPage.contactTab.offlineLocationPlaceholder')}
                    />
                </Form.Field>

                <Form.Field name="website">
                    <StringField
                        label={t('organizationProfileDetailPage.contactTab.websiteLabel')}
                        placeholder="https://example.com"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <LinkIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <Form.Field name="linkedin">
                    <StringField
                        label={t('organizationProfileDetailPage.contactTab.linkedinLabel')}
                        placeholder="https://linkedin.com/company/example"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <LinkedinIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <Form.Field name="facebook">
                    <StringField
                        label={t('organizationProfileDetailPage.contactTab.facebookLabel')}
                        placeholder="https://facebook.com/example"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <FacebookIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <Form.Field name="twitter">
                    <StringField
                        label={t('organizationProfileDetailPage.contactTab.twitterLabel')}
                        placeholder="https://twitter.com/example"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <TwitterIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <Form.Field name="instagram">
                    <StringField
                        label={t('organizationProfileDetailPage.contactTab.instagramLabel')}
                        placeholder="https://instagram.com/example"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <InstagramIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <div className="flex justify-end">
                    <FormSubmitButton variant="primary" type="submit">
                        {t('organizationProfileDetailPage.contactTab.submitButton')}
                    </FormSubmitButton>
                </div>
            </Form>
        </Admin.Card>
    );
};

export default ContactTab;
