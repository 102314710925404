import { Navigate, useParams } from 'react-router-dom';

import { authorized, authorizedLoader, isAdminUser } from '../../../utils/loaders.ts';
import url from '../../../utils/url';
import {
    advancedTabLoader,
    eventDetailLoader,
    eventListLoader,
    generalTabLoader,
    organizationsTabLoader,
} from './loaders.ts';
import EventDetailPage from './pages/EventDetailPage.tsx';
import EventListPage, { AddEventFormDialog } from './pages/EventListPage.tsx';
import AdvancedTab from './tabs/AdvancedTab.tsx';
import AnalyticsTab from './tabs/AnalyticsTab.tsx';
import EventSessionsTab, { AddEventSessionFormDialog, EditEventSessionFormDialog } from './tabs/EventSessionsTab.tsx';
import GeneralTab from './tabs/GeneralTab.tsx';
import OrganizationsTab from './tabs/OrganizationsTab.tsx';
import EventPagesTab, { AddEventPageFormDialog, EditEventPageFormDialog } from './tabs/PagesTab.tsx';

export const ADMIN_EVENT_LIST_PATH = '/admin/events';
export const ADMIN_EVENT_ADD_PATH = '/admin/events/add';
export const ADMIN_EVENT_DETAIL_PATH = '/admin/events/:eventId';
export const ADMIN_EVENT_GENERAL_PATH = '/admin/events/:eventId/general';
export const ADMIN_EVENT_ADVANCED_PATH = '/admin/events/:eventId/advanced';
export const ADMIN_EVENT_SESSIONS_PATH = '/admin/events/:eventId/sessions';
export const ADMIN_EVENT_SESSIONS_ADD_PATH = '/admin/events/:eventId/sessions/add';
export const ADMIN_EVENT_SESSIONS_EDIT_PATH = '/admin/events/:eventId/sessions/:sessionId/edit';
export const ADMIN_EVENT_ORGANIZATIONS_PATH = '/admin/events/:eventId/organizations';
export const ADMIN_EVENT_PAGES_PATH = '/admin/events/:eventId/pages';
export const ADMIN_EVENT_PAGES_ADD_PATH = '/admin/events/:eventId/pages/add';
export const ADMIN_EVENT_PAGES_EDIT_PATH = '/admin/events/:eventId/pages/:pageId/edit';
export const ADMIN_EVENT_ANALYTICS_PATH = '/admin/events/:eventId/analytics/:chartGrid?';

const DetailRedirect = () => {
    const { eventId } = useParams();
    return <Navigate to={url(ADMIN_EVENT_GENERAL_PATH, { eventId })} replace={true} />;
};

export default [
    {
        handle: {
            title: 'Veranstaltungen',
        },
        path: ADMIN_EVENT_LIST_PATH,
        element: <EventListPage />,
        loader: authorizedLoader(eventListLoader, isAdminUser),
        children: [
            {
                handle: {
                    title: 'Veranstaltun hinzufügen',
                },
                path: ADMIN_EVENT_ADD_PATH,
                element: <AddEventFormDialog />,
                loader: authorized(isAdminUser),
            },
        ],
    },
    {
        handle: {
            title: 'Veranstaltung',
        },
        path: ADMIN_EVENT_DETAIL_PATH,
        element: <EventDetailPage />,
        loader: authorizedLoader(eventDetailLoader, isAdminUser),
        children: [
            {
                handle: {
                    title: 'Allgemein',
                },
                index: true,
                element: <DetailRedirect />,
            },
            {
                handle: {
                    title: 'Allgemein',
                },
                path: ADMIN_EVENT_GENERAL_PATH,
                element: <GeneralTab />,
                loader: authorizedLoader(generalTabLoader, isAdminUser),
            },
            {
                handle: {
                    title: 'Erweitert',
                },
                path: ADMIN_EVENT_ADVANCED_PATH,
                element: <AdvancedTab />,
                loader: authorizedLoader(advancedTabLoader, isAdminUser),
            },
            {
                handle: {
                    title: 'Programm',
                },
                path: ADMIN_EVENT_SESSIONS_PATH,
                element: <EventSessionsTab />,
                loader: authorized(isAdminUser),
                children: [
                    {
                        handle: {
                            title: 'Programmpunkt hinzufügen',
                        },
                        path: ADMIN_EVENT_SESSIONS_ADD_PATH,
                        element: <AddEventSessionFormDialog />,
                        loader: authorized(isAdminUser),
                    },
                    {
                        handle: {
                            title: 'Programmpunkt bearbeiten',
                        },
                        path: ADMIN_EVENT_SESSIONS_EDIT_PATH,
                        element: <EditEventSessionFormDialog />,
                        loader: authorized(isAdminUser),
                    },
                ],
            },
            {
                handle: {
                    title: 'Unternehmen',
                },
                path: ADMIN_EVENT_ORGANIZATIONS_PATH,
                element: <OrganizationsTab />,
                loader: authorizedLoader(organizationsTabLoader, isAdminUser),
            },

            {
                handle: {
                    title: 'Seiten',
                },
                path: ADMIN_EVENT_PAGES_PATH,
                element: <EventPagesTab />,
                loader: authorized(isAdminUser),
                children: [
                    {
                        handle: {
                            title: 'Seite hinzufügen',
                        },
                        path: ADMIN_EVENT_PAGES_ADD_PATH,
                        element: <AddEventPageFormDialog />,
                        loader: authorized(isAdminUser),
                    },
                    {
                        handle: {
                            title: 'Seite bearbeiten',
                        },
                        path: ADMIN_EVENT_PAGES_EDIT_PATH,
                        element: <EditEventPageFormDialog />,
                        loader: authorized(isAdminUser),
                    },
                ],
            },
            {
                handle: {
                    title: 'Statistiken',
                },
                path: ADMIN_EVENT_ANALYTICS_PATH,
                element: <AnalyticsTab />,
                loader: authorized(isAdminUser),
            },
        ],
    },
];
