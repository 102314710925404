import { PlusIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import LinkButton from '../../../../components/LinkButton.tsx';
import Schedule, { layoutDayCalendar } from '../../../../components/Schedule.tsx';
import { EventSession } from '../../../../data/models.ts';
import {
    eventDetailQuery,
    eventLimitsQuery,
    eventSessionCreateMutation,
    eventSessionDeleteMutation,
    eventSessionDetailQuery,
    eventSessionListQuery,
    eventSessionUpdateMutation,
} from '../../../../data/queries.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import { useLimitAlert } from '../../utils.tsx';
import BaseEventSessionFormDialog from '../components/BaseEventSessionFormDialog.tsx';
import {
    ADMIN_EVENT_SESSIONS_ADD_PATH,
    ADMIN_EVENT_SESSIONS_EDIT_PATH,
    ADMIN_EVENT_SESSIONS_PATH,
} from '../routes.tsx';

export const AddEventSessionFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const { data: event } = useQuery(eventDetailQuery(eventId!));

    const { mutateAsync: createSession } = useMutation(eventSessionCreateMutation());

    return (
        <BaseEventSessionFormDialog
            isCreate
            {...props}
            event={event}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(url(ADMIN_EVENT_SESSIONS_PATH, { eventId: eventId }));
                }
            }}
            onSubmit={async (data) => {
                await createSession({ data: { ...data, eventId }, translatable: true });
                return navigate(url(ADMIN_EVENT_SESSIONS_PATH, { eventId: eventId }));
            }}
            onCancel={() => navigate(url(ADMIN_EVENT_SESSIONS_PATH, { eventId: eventId }))}
        />
    );
};

export const EditEventSessionFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { sessionId, eventId } = useParams();
    const { data: event } = useQuery(eventDetailQuery(eventId!));

    const { data: session } = useQuery(eventSessionDetailQuery(sessionId!, { translatable: true }));
    const { mutateAsync: updateSession } = useMutation(eventSessionUpdateMutation());

    const { mutateAsync: deleteSession } = useMutation(eventSessionDeleteMutation());

    return (
        <BaseEventSessionFormDialog
            {...props}
            session={session}
            event={event}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(url(ADMIN_EVENT_SESSIONS_PATH, { eventId: eventId }));
                }
            }}
            onSubmit={async (data) => {
                await updateSession({ id: sessionId!, data, translatable: true });
                return navigate(url(ADMIN_EVENT_SESSIONS_PATH, { eventId: eventId }));
            }}
            onDelete={async () => {
                await deleteSession({ id: sessionId!, translatable: true });
                return navigate(url(ADMIN_EVENT_SESSIONS_PATH, { eventId: eventId }));
            }}
            onCancel={() => navigate(url(ADMIN_EVENT_SESSIONS_PATH, { eventId: eventId }))}
        />
    );
};

const Session = ({
    className,
    session,
    disabled,
    highlighted,
    ...props
}: React.ComponentPropsWithRef<'a'> & { session: EventSession; disabled?: boolean; highlighted?: boolean }) => {
    const { eventId } = useParams();
    const Comp = disabled ? 'span' : Link;
    return (
        <Schedule.Session asChild highlighted={highlighted} disabled={disabled} className={className}>
            <Comp to={url(ADMIN_EVENT_SESSIONS_EDIT_PATH, { eventId, sessionId: session.id })} {...props}>
                <Schedule.SessionDate datetime={new Date(session.startDatetime)} />
                <Schedule.SessionTitle>{session.title}</Schedule.SessionTitle>
            </Comp>
        </Schedule.Session>
    );
};

const EventSessionsTab = () => {
    const { t, i18n } = useTranslation('admin');

    const { eventId } = useParams();
    const { data: eventSessions } = useQuery(eventSessionListQuery({ eventId, organizationProfileId: 'null' }));

    // TODO: timezones ?
    const dates = eventSessions
        ?.flatMap((s) => [s.startDatetime, s.endDatetime])
        .map((date) => new Date(date).toISOString().split('T')[0]);
    const days = [...new Set(dates)].map((dateStr) => new Date(dateStr)).sort((a, b) => a.getTime() - b.getTime());

    const [selectedDay, setSelectedDay] = useState<Date | null>(null);

    useEffect(() => {
        if (days.length > 0 && !selectedDay) {
            setSelectedDay(days[0]);
        }
    }, [eventSessions]);

    const columnSessions = layoutDayCalendar<EventSession>(eventSessions || [], selectedDay ? selectedDay : undefined);

    const { data: limits } = useQuery(eventLimitsQuery(eventId!));
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: limits?.sessionsCount,
        limit: limits?.maxSessions,
        limitTranslationKey: 'eventDetailPage.eventSessionsTab.limitAlert',
        limitReachedTranslationKey: 'eventDetailPage.eventSessionsTab.limitReachedAlert',
    });

    return (
        <>
            <div className={classnames('w-full flex flex-col gap-10 ', days.length === 0 && 'h-full')}>
                <Admin.CardNavigationWrapper>
                    <Admin.Card className="flex gap-4 flex-none justify-between">
                        <h1 className="text-3xl font-semibold">{t('eventDetailPage.eventSessionsTab.title')}</h1>
                        {days.length > 0 && (
                            <div>
                                <LinkButton
                                    to={url(ADMIN_EVENT_SESSIONS_ADD_PATH, { eventId: eventId })}
                                    className="flex gap-1 items-center"
                                    disabled={isLimitReached}
                                >
                                    <PlusIcon />
                                    {t('eventDetailPage.eventSessionsTab.createButton')}
                                </LinkButton>
                            </div>
                        )}
                    </Admin.Card>

                    {days.length > 0 && (
                        <Admin.CardNavigation>
                            {days.map((day) => (
                                <Admin.CardNavigationItem
                                    key={day.toISOString()}
                                    active={selectedDay?.toISOString() === day.toISOString()}
                                    onClick={() => setSelectedDay(day)}
                                    className="cursor-pointer"
                                >
                                    {day.toLocaleDateString(i18n.language, {
                                        weekday: 'short',
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric',
                                    })}
                                </Admin.CardNavigationItem>
                            ))}
                        </Admin.CardNavigation>
                    )}
                </Admin.CardNavigationWrapper>

                <LimitAlert />

                {days.length > 0 ? (
                    <Schedule>
                        {columnSessions.map((columnSession) => {
                            return (
                                <Schedule.ColumnSession key={columnSession.session.id} columnSession={columnSession}>
                                    <Session
                                        session={columnSession.session}
                                        highlighted={columnSession?.session?.organizationProfileId === null}
                                    />
                                </Schedule.ColumnSession>
                            );
                        })}
                    </Schedule>
                ) : (
                    <EmptyState
                        title={t('eventDetailPage.eventSessionsTab.noSessions.title')}
                        description={t('eventDetailPage.eventSessionsTab.noSessions.description')}
                        button={
                            <LinkButton
                                to={url(ADMIN_EVENT_SESSIONS_ADD_PATH, { eventId: eventId })}
                                className="flex gap-1 items-center"
                            >
                                <PlusIcon />
                                {t('eventDetailPage.eventSessionsTab.createButton')}
                            </LinkButton>
                        }
                    />
                )}
            </div>

            <Outlet />
        </>
    );
};

export default EventSessionsTab;
