import { capitalize } from 'lodash';
import React, { useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, useFormContext } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';

import { DEFAULT_LANGUAGE_CODE, SUPPORTED_LANGUAGE_CODES, SupportedLanguageCode } from '../../../data/models.ts';

const TranslatableFormField = ({ name, children, rules, ...props }: React.ComponentPropsWithRef<typeof Form.Field>) => {
    const id = useId();
    const { t } = useTranslation('common');
    const { formState } = useFormContext();

    const [locale, setLocale] = useState<SupportedLanguageCode>(DEFAULT_LANGUAGE_CODE);

    useEffect(() => {
        if (formState.isSubmitting) {
            setLocale(DEFAULT_LANGUAGE_CODE);
        }
    }, [formState.isSubmitting]);

    if (!React.isValidElement(children)) {
        throw new Error('TranslatableFormField children must be a valid React element');
    }

    const { label, ...fieldProps } = children.props as any;

    return (
        <div className="flex flex-col">
            <div className="flex flex-col ">
                <Field.Label required={fieldProps.required} htmlFor={fieldProps.id || id}>
                    {label}
                </Field.Label>

                <div className="flex gap-0.5 items-center pt-1 pb-1.5 rounded-md">
                    {SUPPORTED_LANGUAGE_CODES.map((key) => (
                        <button
                            key={key}
                            onClick={() => setLocale(key)}
                            className={classnames(
                                'text-base-400 font-medium text-xs px-1.5 py-0.5 rounded border border-transparent',
                                key === locale && 'text-theme-700 bg-theme-50'
                            )}
                            type="button"
                        >
                            {t(`languageCode.${key}`)}
                        </button>
                    ))}
                </div>
            </div>

            <div className="flex flex-col gap-2">
                {SUPPORTED_LANGUAGE_CODES.map((key, index) => (
                    <div className={classnames(key === locale ? 'flex gap-1' : 'hidden')} key={key}>
                        <Form.Field
                            key={key}
                            name={`${name}${key !== DEFAULT_LANGUAGE_CODE ? capitalize(key) : ''}`}
                            {...props}
                            rules={{
                                ...(rules || {}),
                                required: key === DEFAULT_LANGUAGE_CODE ? rules?.required : undefined,
                            }}
                        >
                            {React.cloneElement(children, {
                                label: '',
                                ...fieldProps,
                                required: key === DEFAULT_LANGUAGE_CODE ? fieldProps.required : undefined,
                                id: fieldProps.id || id,
                                className: classnames(fieldProps.className, 'flex-1'),
                                placeholder: fieldProps.placeholder != null ? fieldProps.placeholder : label,
                            })}
                        </Form.Field>

                        <div className="leading-8"></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TranslatableFormField;
