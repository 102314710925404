import { TFunction } from 'i18next';
import { uniqBy } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import Pagination from '../../../components/Pagination.tsx';
import { Job, LogEventType, OrganizationProfile, OrganizationProfileJob } from '../../../data/models.ts';
import { organizationProfileJobSearchQuery, savedJobListQuery, userDetailQuery } from '../../../data/queries.ts';
import useLogEvent from '../../../utils/useLogEvent.ts';
import { SimpleFilterSidebar, useSearchParamsFilters } from '../components/FilterSidebar.tsx';
import JobCard from '../components/JobCard.tsx';
import { useEventId } from '../utils.ts';

const formatLabel = (t: TFunction<'site'>) => (filterKey: string) =>
    ({
        search: t('jobListPage.filters.search'),
        isSaved: t('jobListPage.filters.isSaved'),
        employmentType: t('jobListPage.filters.employmentType'),
        qualification: t('jobListPage.filters.qualification'),
        remoteWork: t('jobListPage.filters.remoteWork'),
        remunerationType: t('jobListPage.filters.remunerationType'),
        location: t('jobListPage.filters.location'),
        organizationName: t('jobListPage.filters.organizationName'),
    })[filterKey] || filterKey;

const formatValue = (t: TFunction<'site'>) => (filterKey: string, value: string) => {
    if (filterKey === 'isSaved') {
        return value === 'true'
            ? t('jobListPage.filters.isSavedOptions.true')
            : t('jobListPage.filters.isSavedOptions.false');
    }
    return value;
};

const JobListPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());

    const { filters, onToggleFilter, searchParams, setSearchParams } = useSearchParamsFilters([
        'search',
        'isSaved',
        'employmentType',
        'qualification',
        'remoteWork',
        'remunerationType',
        'organizationName',
        'location',
    ]);
    const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
    const pageSize = 20;

    const { data: profileJobsData } = useQuery({
        ...organizationProfileJobSearchQuery({ eventId, page, pageSize, ...filters }),
        placeholderData: keepPreviousData,
    });
    const jobsAndOrganizationProfiles: [Job, OrganizationProfile][] =
        profileJobsData?.results?.map((profileJob: OrganizationProfileJob) => [
            profileJob.job!,
            profileJob?.organizationProfile!,
        ]) || [];
    const uniqueOrganizations = uniqBy(
        jobsAndOrganizationProfiles,
        ([, organizationProfile]: [Job, OrganizationProfile]) => organizationProfile.organizationId
    );

    const { data: savedJobs } = useQuery({
        ...savedJobListQuery({ eventId, userId: viewer?.id! }),
        placeholderData: keepPreviousData,
        enabled: !!viewer,
    });
    const savedJobsById = Object.fromEntries(savedJobs?.map((u) => [u.jobId, u]) || []);

    const handlePageChange = (newPage: number) => {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
    };

    const createLogEvent = useLogEvent();
    useEffect(() => {
        createLogEvent(LogEventType.JOB_SEARCH, { ...filters, page, pageSize });
    }, [filters, page, pageSize]);

    if (!profileJobsData) return null;

    return (
        <div className={className} {...props}>
            <main className="flex justify-center bg-white px-6 py-24">
                <div className="w-full max-w-[1120px] flex flex-col gap-32">
                    <section className="flex flex-col gap-14">
                        <h2 className="font-semibold text-3xl">
                            {t('jobListPage.title', {
                                jobsCount: profileJobsData?.count,
                                organizationsCount: uniqueOrganizations.length,
                            })}
                        </h2>
                        <div className="flex gap-10">
                            <div className="w-80 flex-none border-r border-base-100 pr-9">
                                <SimpleFilterSidebar
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    filtersData={profileJobsData?.filters || {}}
                                    filters={filters}
                                    onToggleFilter={onToggleFilter}
                                    formatLabel={formatLabel(t)}
                                    formatValue={formatValue(t)}
                                />
                            </div>

                            <div className="flex flex-col gap-5 flex-1">
                                <div className="grid grid-cols-2 auto-rows-auto gap-10">
                                    {jobsAndOrganizationProfiles?.map(
                                        ([job, organizationProfile]: [Job, OrganizationProfile]) => (
                                            <JobCard
                                                key={job.id}
                                                job={job}
                                                organizationProfile={organizationProfile}
                                                savedJob={savedJobsById[job.id] || null}
                                            />
                                        )
                                    )}
                                </div>
                                <div className="mt-5">
                                    <Pagination
                                        page={page}
                                        pageSize={pageSize}
                                        total={profileJobsData?.count ? Number(profileJobsData?.count) : 0}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default JobListPage;
