import { formatDistanceToNow, intlFormatDistance } from 'date-fns';
import {
    ArrowRightIcon,
    CalendarClockIcon,
    CompassIcon,
    FacebookIcon,
    InstagramIcon,
    LanguagesIcon,
    LinkIcon,
    LinkedinIcon,
    NotebookIcon,
    StickyNoteIcon,
    TwitterIcon,
} from 'lucide-react';
import * as assert from 'node:assert';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { classnames, url, useControllableState } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Anchor from '../../../components/Anchor.tsx';
import Avatar from '../../../components/Avatar.tsx';
import Button from '../../../components/Button.tsx';
import Image from '../../../components/Image.tsx';
import LinkButton from '../../../components/LinkButton.tsx';
import MoreMenu from '../../../components/MoreMenu.tsx';
import { Education, Experience, LogEventType, User } from '../../../data/models.ts';
import {
    conversationCreateMutation,
    conversationListQuery,
    userDetailQuery,
    userNoteListQuery,
    userProfileListQuery,
} from '../../../data/queries.ts';
import { getDateFnsLocale } from '../../../i18n.ts';
import { getOrCreateFileId } from '../../../utils/files.ts';
import { isAdminUser, isAuthenticated, isOrganizationUser } from '../../../utils/loaders.ts';
import { formatDate, formatDateTime } from '../../../utils/time.ts';
import useLogEvent, { useLogEventOnMount } from '../../../utils/useLogEvent.ts';
import EmptyState from '../../admin/components/EmptyState.tsx';
import { ADMIN_LOGIN_PATH } from '../../auth/routes.tsx';
import BaseConversationFormDialog from '../components/BaseConversationFormDialog.tsx';
import InfoBox from '../components/InfoBox.tsx';
import { SaveUserButton } from '../components/SaveUserButton.tsx';
import UserNotesDialog, { UserNoteCard } from '../components/UserNotesDialog.tsx';
import { SITE_CONVERSATION_DETAIL_PATH, SITE_MESSAGES_PATH, SITE_USER_DETAIL_PATH } from '../routes.tsx';
import { mergeUserProfile, siteUrl, useEventId } from '../utils.ts';

const getUsername = (url: string) => {
    if (!url) return '';

    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
};

export const SendMessageFormDialog = ({
    receiver,
    ...props
}: React.ComponentPropsWithRef<typeof BaseConversationFormDialog> & { receiver: User }) => {
    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());

    const navigate = useNavigate();
    const { mutateAsync: createConversation } = useMutation(conversationCreateMutation());

    const createLogEvent = useLogEvent();

    const handleSubmit = async (values: any) => {
        const attachmentId = await getOrCreateFileId(
            values.attachment,
            isOrganizationUser(viewer!) ? { organizationId: viewer?.organizationId } : { userId: viewer?.id }
        );

        const conversation = await createConversation({
            data: {
                receiverId: receiver!.id,
                eventId,
                ...values,
                attachmentId,
            },
        });
        void createLogEvent(LogEventType.CONVERSATION_STARTED, { targetId: conversation.id, receiverId: receiver!.id });
        void createLogEvent(LogEventType.CONVERSATION_MESSAGE_SENT, {
            targetId: conversation.id,
            hasText: !!values.text,
            hasAttachment: !!values.attachment,
        });
        return navigate(siteUrl(SITE_CONVERSATION_DETAIL_PATH, { eventId, conversationId: conversation.id }));
    };

    return <BaseConversationFormDialog receiver={receiver} onSubmit={handleSubmit} {...props} />;
};

const SendMessageButton = ({
    receiver,
    className,
    ...props
}: React.ComponentPropsWithRef<'button'> & { receiver: User }) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());
    const receiverId = receiver?.id;

    const canReceiveMessages = Boolean(receiverId && receiverId !== viewer?.id);

    const { data: conversations, isLoading } = useQuery({
        ...conversationListQuery({ userId: receiverId }),
        enabled: canReceiveMessages,
    });
    const conversation = conversations?.filter((c) => c.participantIds.includes(viewer?.id!))?.[0];
    const otherConversations = conversations?.filter((c) => !c.participantIds.includes(viewer?.id!)) || [];

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const action = searchParams.get('action');

    const showSendMessageForm = action === 'message';

    return (
        <div className="flex flex-col gap-3">
            <LinkButton
                variant={conversation || isLoading ? 'outline' : 'primary'}
                className="text-base min-h-9"
                loading={isLoading ? true : undefined}
                disabled={receiverId === viewer?.id || !isOrganizationUser(viewer!)}
                to={
                    conversation
                        ? siteUrl(SITE_CONVERSATION_DETAIL_PATH, { eventId, conversationId: conversation?.id })
                        : siteUrl(SITE_USER_DETAIL_PATH, { eventId, userId: receiverId }) + `?action=message`
                }
            >
                {conversation ? t('userDetailPage.gotoConversationButton') : t('userDetailPage.sendMessageButton')}
            </LinkButton>

            {!conversation && otherConversations && otherConversations.length > 0 && (
                <Anchor className="text-sm text-center text-balance" asChild>
                    <Link to={siteUrl(SITE_MESSAGES_PATH, { eventId }) + `?receiver=${receiverId}`}>
                        {t('userDetailPage.otherConversationsNote', { count: otherConversations?.length })}
                    </Link>
                </Anchor>
            )}

            {showSendMessageForm && (
                <SendMessageFormDialog
                    receiver={receiver}
                    onIsOpenChange={() => navigate(siteUrl(SITE_USER_DETAIL_PATH, { userId: receiverId, eventId }))}
                    onCancel={() => navigate(siteUrl(SITE_USER_DETAIL_PATH, { userId: receiverId, eventId }))}
                />
            )}
        </div>
    );
};

const ExperienceCard = ({
    className,
    experience,
    ...props
}: React.ComponentPropsWithRef<'div'> & { experience: Experience }) => {
    return (
        <div className={classnames('bg-base-50 rounded-xl p-6 flex gap-4 flex-1', className)} {...props}>
            <div className="flex gap-5 flex-col flex-1">
                <div className="flex gap-1 flex-col flex-1">
                    <h2 className="text-lg font-semibold">{experience.position}</h2>
                    {(experience.company || experience.employmentStatus) && (
                        <p className="text-base-800">
                            {experience.company && experience.company}
                            {experience.company && experience.employmentStatus && ', '}
                            {experience.employmentStatus && experience.employmentStatus}
                        </p>
                    )}
                    {(experience.startDate || experience.endDate) && (
                        <p className="text-base-500">
                            {experience.startDate && formatDate(experience.startDate)}
                            {experience.startDate && experience.endDate && ' - '}
                            {experience.endDate && formatDate(experience.endDate)}
                        </p>
                    )}
                </div>

                {experience.description && <p className="text-base-800">{experience.description}</p>}
            </div>
        </div>
    );
};

const EducationCard = ({
    className,
    education,
    ...props
}: React.ComponentPropsWithRef<'div'> & { education: Education }) => {
    return (
        <div className={classnames('bg-base-50 rounded-xl p-6 flex gap-4 flex-1', className)} {...props}>
            <div className="flex gap-5 flex-col flex-1">
                <div className="flex gap-1 flex-col flex-1">
                    <h2 className="text-lg font-semibold">{education.school}</h2>
                    {(education.degree || education.fieldOfStudy) && (
                        <p className="text-base-800">
                            {education.degree && education.degree}
                            {education.degree && education.fieldOfStudy && ', '}
                            {education.fieldOfStudy && education.fieldOfStudy}
                        </p>
                    )}
                    {(education.startDate || education.endDate) && (
                        <p className="text-base-500">
                            {education.startDate && formatDate(education.startDate)}
                            {education.startDate && education.endDate && ' - '}
                            {education.endDate && formatDate(education.endDate)}
                        </p>
                    )}
                </div>

                {education.description && <p className="text-base-800">{education.description}</p>}
            </div>
        </div>
    );
};

const UserDetailPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { userId } = useParams();
    const { data: viewer } = useQuery(userDetailQuery());
    const location = useLocation();
    const navigate = useNavigate();

    const { data: userProfilesData } = useQuery({
        ...userProfileListQuery({ userId }),
        enabled: isAuthenticated(viewer!),
    });
    const userProfileData = userProfilesData?.[0];
    const userProfile = userProfileData ? mergeUserProfile(userProfileData?.user!, userProfileData) : null;

    const { data: notes } = useQuery(userNoteListQuery({ eventId, targetUserId: userId }));

    const [searchParams] = useSearchParams();
    const action = searchParams.get('action');
    const showUserNotesDialog = action === 'note';

    const profileExperiences: Experience[] = userProfileData?.experiences || [];
    const profileEducations: Education[] = userProfileData?.educations || [];

    useLogEventOnMount(LogEventType.USER_VIEW, { targetId: userId });

    if (!(isOrganizationUser(viewer!) || isAdminUser(viewer!) || (viewer && viewer.id === userId))) {
        return (
            <div className={className} {...props}>
                <main className="flex justify-center bg-white px-6 py-24">
                    <div className="w-full max-w-[1120px] flex flex-col gap-32">
                        <EmptyState
                            title={t('userDetailPage.notAuthenticatedState.title')}
                            description={t('userDetailPage.notAuthenticatedState.description')}
                            button={
                                <LinkButton variant="outline" to={`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`}>
                                    {t('userDetailPage.notAuthenticatedState.button')}
                                </LinkButton>
                            }
                        />
                    </div>
                </main>
            </div>
        );
    }

    return (
        <>
            <div className={className} {...props}>
                <div className="flex justify-center bg-base-50 px-6">
                    <div className="w-full max-w-[1120px] h-72 py-5 items-center flex gap-10">
                        <Avatar user={userProfile} className="w-32 h-32" />

                        <div className="flex flex-col gap-3">
                            <h1 className="font-semibold text-5xl">
                                {userProfile?.firstName} {userProfile?.lastName}
                            </h1>
                            <span className="text-base-600 text-2xl">{userProfile?.headline}</span>
                        </div>
                    </div>
                </div>

                <main className="flex justify-center bg-white px-6 py-24">
                    <div className="w-full max-w-[1120px] flex flex-col gap-32">
                        <div className="flex gap-10">
                            <div className="flex flex-col gap-24 w-8/12">
                                {notes && notes.length > 0 && (
                                    <div className="flex flex-col gap-4">
                                        <UserNoteCard note={notes[0]} hideMoreMenu />

                                        <div className="flex justify-start">
                                            <LinkButton
                                                variant="ghost"
                                                className="flex items-center gap-2 text-theme-600 hover:text-theme-700 hover:bg-theme-50 active:bg-theme-100"
                                                to={
                                                    siteUrl(SITE_USER_DETAIL_PATH, { eventId, userId }) + '?action=note'
                                                }
                                            >
                                                <NotebookIcon />
                                                <span>
                                                    {t('userDetailPage.showAllNotesButton', { count: notes.length })}
                                                </span>
                                                <ArrowRightIcon />
                                            </LinkButton>
                                        </div>
                                    </div>
                                )}

                                {userProfile?.careerObjective && (
                                    <section className="flex flex-col gap-10">
                                        <h2 className="font-semibold text-3xl">
                                            {t('userDetailPage.careerObjective')}
                                        </h2>

                                        <div className="styled">
                                            <p>{userProfile?.careerObjective}</p>
                                        </div>
                                    </section>
                                )}

                                {userProfile?.description && (
                                    <section className="flex flex-col gap-10">
                                        <h2 className="font-semibold text-3xl">{t('userDetailPage.description')}</h2>

                                        <div
                                            className="styled"
                                            dangerouslySetInnerHTML={{
                                                __html: userProfile?.description,
                                            }}
                                        />
                                    </section>
                                )}

                                {userProfile?.skills && (
                                    <section className="flex flex-col gap-10">
                                        <h2 className="font-semibold text-3xl">{t('userDetailPage.skills')}</h2>

                                        <div
                                            className="styled"
                                            dangerouslySetInnerHTML={{
                                                __html: userProfile?.skills,
                                            }}
                                        />
                                    </section>
                                )}

                                <section className="flex flex-col gap-10">
                                    <h2 className="font-semibold text-3xl">{t('userDetailPage.experience')}</h2>

                                    <div className="flex flex-col gap-6">
                                        {profileExperiences && profileExperiences?.length > 0 ? (
                                            profileExperiences?.map((experience) => (
                                                <ExperienceCard key={experience.id} experience={experience} />
                                            ))
                                        ) : (
                                            <p className="text-base-800">{t('userDetailPage.noExperience')}</p>
                                        )}
                                    </div>
                                </section>

                                <section className="flex flex-col gap-10">
                                    <h2 className="font-semibold text-3xl">{t('userDetailPage.education')}</h2>

                                    <div className="flex flex-col gap-6">
                                        {profileEducations && profileEducations?.length > 0 ? (
                                            profileEducations.map((education) => (
                                                <EducationCard key={education.id} education={education} />
                                            ))
                                        ) : (
                                            <p className="text-base-800">{t('userDetailPage.noEducation')}</p>
                                        )}
                                    </div>
                                </section>
                            </div>

                            <div className="flex flex-col gap-12 w-4/12">
                                <InfoBox badge={<InfoBox.Badge>{t('userDetailPage.detailsBadge')}</InfoBox.Badge>}>
                                    <InfoBox.Row icon={<CompassIcon />} value={userProfile?.location} />

                                    <InfoBox.Row
                                        icon={<LanguagesIcon />}
                                        value={
                                            userProfile?.languages &&
                                            userProfile?.languages.length > 0 && (
                                                <span className="flex flex-col gap-2">
                                                    {userProfile?.languages?.map((language) => (
                                                        <span className="flex gap-2 w-full">
                                                            <span className="font-medium">
                                                                {/* @ts-ignore */}
                                                                {t(`common:languageCode.${language.languageCode}`)}
                                                            </span>
                                                            <span className="font-normal">
                                                                {/* @ts-ignore */}
                                                                {t(`common:languageLevel.${language.level}`)}
                                                            </span>
                                                        </span>
                                                    ))}
                                                </span>
                                            )
                                        }
                                    />
                                </InfoBox>

                                <InfoBox
                                    badge={
                                        <InfoBox.Badge className="bg-base-500">
                                            {t('userDetailPage.contactBadge')}
                                        </InfoBox.Badge>
                                    }
                                >
                                    <InfoBox.Row
                                        icon={<LinkIcon />}
                                        value={
                                            userProfile?.website && (
                                                <Anchor href={userProfile?.website} target="_blank">
                                                    {userProfile?.website?.replace(/https?:\/\//, '')}
                                                </Anchor>
                                            )
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<LinkedinIcon />}
                                        value={
                                            userProfile?.linkedin && (
                                                <Anchor href={userProfile?.linkedin} target="_blank">
                                                    {getUsername(userProfile?.linkedin!)}
                                                </Anchor>
                                            )
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<FacebookIcon />}
                                        value={
                                            userProfile?.facebook && (
                                                <Anchor href={userProfile?.facebook} target="_blank">
                                                    {getUsername(userProfile?.facebook!)}
                                                </Anchor>
                                            )
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<TwitterIcon />}
                                        value={
                                            userProfile?.twitter && (
                                                <Anchor href={userProfile?.twitter} target="_blank">
                                                    {getUsername(userProfile?.twitter!)}
                                                </Anchor>
                                            )
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<InstagramIcon />}
                                        value={
                                            userProfile?.instagram && (
                                                <Anchor href={userProfile?.instagram} target="_blank">
                                                    {getUsername(userProfile?.instagram!)}
                                                </Anchor>
                                            )
                                        }
                                    />
                                </InfoBox>

                                {isOrganizationUser(viewer!) && (
                                    <div className="rounded-xl flex flex-col gap-5 px-7">
                                        <SendMessageButton receiver={userProfile?.user!} />

                                        <LinkButton
                                            className="w-full text-base flex gap-2 disabled:cursor-not-allowed min-h-9"
                                            to={
                                                siteUrl(SITE_USER_DETAIL_PATH, { eventId, userId }) +
                                                '?action=note&mode=create'
                                            }
                                            disabled={!isOrganizationUser(viewer!)}
                                        >
                                            <NotebookIcon />
                                            <span>{t('userDetailPage.addNoteButton')}</span>
                                        </LinkButton>

                                        <SaveUserButton
                                            user={userProfile?.user!}
                                            eventId={eventId}
                                            disabled={!isOrganizationUser(viewer!)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <UserNotesDialog
                targetUser={userProfileData?.user!}
                isOpen={showUserNotesDialog}
                onClose={() => navigate(siteUrl(SITE_USER_DETAIL_PATH, { eventId, userId }))}
                onCancel={() =>
                    notes && notes.length === 0 && navigate(siteUrl(SITE_USER_DETAIL_PATH, { eventId, userId }))
                }
            />
        </>
    );
};

export default UserDetailPage;
