import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';

import {
    DateTimeField,
    Form,
    IntegerField,
    MultiSelectField,
    SelectField,
    StringField,
    TextField,
} from '@nicoknoll/forms';
import { useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import { useConfirmationDialogContext } from '../../../../components/ConfirmationDialog.tsx';
import Dialog from '../../../../components/Dialog.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import WysiwygInput from '../../../../components/WysiwygInput.tsx';
import { Event, EventSession, TRANSLATABLE_EVENT_SESSION_KEYS, createTranslatable } from '../../../../data/models.ts';
import { userDetailQuery, userListQuery } from '../../../../data/queries.ts';
import { SESSION_FORMAT_SUGGESTIONS } from '../../../../data/suggestions.ts';
import { valuesToOptions } from '../../../../data/utils.ts';
import downloadCsv from '../../../../utils/downloadCsv.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import { isAdminUser } from '../../../../utils/loaders.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import TranslatableFormField from '../../components/TranslatableFormField.tsx';

const LANGUAGE_CODES = ['de', 'en'];

const SESSION_FORM_DEFAULT_VALUES = createTranslatable(
    {
        title: '',
        summary: '',
        description: '',
        startDatetime: '',
        endDatetime: '',
        location: '',
        format: '',
        languageCode: 'de',
        maxUsers: null,
        userIds: [],
    },
    TRANSLATABLE_EVENT_SESSION_KEYS
);

const BaseEventSessionFormDialog = ({
    isCreate = false,
    session,
    event,
    isOpen = true,
    onIsOpenChange,
    onSubmit,
    onDelete,
    onCancel,
}: {
    isCreate: boolean;
    session?: EventSession;
    event: Event;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onSubmit?: (data: any) => void;
    onDelete?: () => void;
    onCancel?: () => void;
}) => {
    const { t } = useTranslation('admin');

    const { data: viewer } = useQuery(userDetailQuery());

    const formMethods = useForm({
        defaultValues: pickFormValues(SESSION_FORM_DEFAULT_VALUES, session),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(SESSION_FORM_DEFAULT_VALUES, session));
    }, [session]);

    const confirm = useConfirmationDialogContext();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(
                onSubmit?.({ ...values, maxUsers: values.maxUsers ? Number(values.maxUsers) : null })
            );
            toast.success(
                isCreate
                    ? t('eventDetailPage.sessionFormDialog.createSuccess')
                    : t('eventDetailPage.sessionFormDialog.updateSuccess')
            );
        } catch {
            toast.error(
                isCreate
                    ? t('eventDetailPage.sessionFormDialog.createError')
                    : t('eventDetailPage.sessionFormDialog.updateError')
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async () => {
        return confirm(t('eventDetailPage.sessionFormDialog.deleteConfirmMessage'), '', {
            onConfirm: async () => {
                setIsLoading(true);

                try {
                    await Promise.resolve(onDelete?.());
                    toast.success(t('eventDetailPage.sessionFormDialog.deleteSuccess'));
                } catch (error) {
                    console.error(error);
                    setFormErrors(t, formMethods, error);
                    toast.error(t('eventDetailPage.sessionFormDialog.deleteError'));
                } finally {
                    setIsLoading(false);
                }
            },
            danger: true,
        });
    };

    const { data: regularUsers } = useQuery(userListQuery({ eventId: event?.id }));
    const regularUserOptions = regularUsers?.map((u) => ({ value: u.id, label: `${u.firstName} ${u.lastName}` })) || [];

    const highlightedDays = event?.days.map((d) => new Date(d.date)) || [];

    const startDatetime = formMethods.watch('startDatetime');
    const endDatetime = formMethods.watch('endDatetime');

    const [startMonth, setStartMonth] = useState<Date>(new Date());
    const [endMonth, setEndMonth] = useState<Date>(new Date());

    useEffect(() => {
        // we need to manage startMonth and endMonth independently to ensure flipping pages in the calendar works
        if (startDatetime) setStartMonth(new Date(startDatetime));
        if (endDatetime) setEndMonth(new Date(endDatetime));
    }, [startDatetime, endDatetime]);

    const maxUsers = formMethods.watch('maxUsers');

    const handleUsersDownload = () => {
        const userIds: string[] = formMethods.watch('userIds');
        const usersData =
            regularUsers
                ?.filter((u) => userIds.includes(u.id))
                ?.map((u) => ({
                    firstName: u.firstName,
                    lastName: u.lastName,
                    email: u.email,
                })) || [];
        return downloadCsv(usersData, 'users.csv');
    };

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">
                    {isCreate
                        ? t('eventDetailPage.sessionFormDialog.titleCreate')
                        : t('eventDetailPage.sessionFormDialog.titleUpdate')}
                </h1>

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <FormError />

                    <TranslatableFormField
                        name="title"
                        rules={{ required: t('eventDetailPage.sessionFormDialog.titleRequired') }}
                    >
                        <StringField label={t('eventDetailPage.sessionFormDialog.titleLabel')} required />
                    </TranslatableFormField>

                    <div className="flex gap-5 w-full">
                        <Form.Field
                            name="startDatetime"
                            rules={{ required: t('eventDetailPage.sessionFormDialog.startDatetimeRequired') }}
                        >
                            <DateTimeField
                                label={t('eventDetailPage.sessionFormDialog.startDatetimeLabel')}
                                required
                                className="flex-1"
                                calendarProps={{
                                    endMonth: endDatetime ? new Date(endDatetime) : undefined,
                                    disabled: endDatetime ? [{ after: new Date(endDatetime) }] : undefined,
                                    month: startMonth || endMonth || highlightedDays[0],
                                    onMonthChange: setStartMonth,
                                    modifiers: {
                                        highlighted: highlightedDays,
                                    },
                                }}
                            />
                        </Form.Field>

                        <Form.Field
                            name="endDatetime"
                            rules={{ required: t('eventDetailPage.sessionFormDialog.endDatetimeRequired') }}
                        >
                            <DateTimeField
                                label={t('eventDetailPage.sessionFormDialog.endDatetimeLabel')}
                                required
                                className="flex-1"
                                calendarProps={{
                                    startMonth: startDatetime ? new Date(startDatetime) : undefined,
                                    disabled: startDatetime ? [{ before: new Date(startDatetime) }] : undefined,
                                    month: endMonth || startMonth || highlightedDays[0],
                                    onMonthChange: setEndMonth,
                                    modifiers: {
                                        highlighted: highlightedDays,
                                    },
                                }}
                            />
                        </Form.Field>
                    </div>

                    <TranslatableFormField name="summary">
                        <TextField label={t('eventDetailPage.sessionFormDialog.summaryLabel')} maxLength={140} />
                    </TranslatableFormField>

                    <TranslatableFormField name="description">
                        <TextField
                            label={t('eventDetailPage.sessionFormDialog.descriptionLabel')}
                            widget={WysiwygInput}
                        />
                    </TranslatableFormField>

                    <Form.Field
                        name="format"
                        rules={{ required: t('eventDetailPage.sessionFormDialog.formatRequired') }}
                    >
                        <SelectField
                            label={t('eventDetailPage.sessionFormDialog.formatLabel')}
                            options={valuesToOptions(SESSION_FORMAT_SUGGESTIONS)}
                            hideSearch
                            required
                        />
                    </Form.Field>

                    <Form.Field
                        name="languageCode"
                        rules={{ required: t('eventDetailPage.sessionFormDialog.languageCodeRequired') }}
                    >
                        <SelectField
                            label={t('eventDetailPage.sessionFormDialog.languageCodeLabel')}
                            options={LANGUAGE_CODES.map((lc) => ({
                                value: lc,
                                // @ts-ignore
                                label: t(`common:languageCode.${lc}`),
                            }))}
                            hideSearch
                            required
                        />
                    </Form.Field>

                    <TranslatableFormField name="location">
                        <StringField label={t('eventDetailPage.sessionFormDialog.locationLabel')} />
                    </TranslatableFormField>

                    {isAdminUser(viewer!) && (
                        <>
                            <Form.Field name="maxUsers">
                                <IntegerField
                                    min={0}
                                    label={t('eventDetailPage.sessionFormDialog.maxUsersLabel')}
                                    helpText={t('eventDetailPage.sessionFormDialog.maxUsersHelpText')}
                                />
                            </Form.Field>

                            <Form.Field name="userIds">
                                <MultiSelectField
                                    disabled={!maxUsers}
                                    label={t('eventDetailPage.sessionFormDialog.userIdsLabel')}
                                    options={regularUserOptions}
                                    helpText={
                                        !!maxUsers && (
                                            <Trans
                                                t={t}
                                                i18nKey="eventDetailPage.sessionFormDialog.userIdsHelpText"
                                                components={{
                                                    downloadLink: (
                                                        <button
                                                            type="button"
                                                            onClick={handleUsersDownload}
                                                            className="text-theme-500 decoration-theme-500/30 cursor-pointer underline underline-offset-2 hover:text-theme-700 transition-colors"
                                                        />
                                                    ),
                                                }}
                                            />
                                        )
                                    }
                                />
                            </Form.Field>
                        </>
                    )}

                    <div className="flex justify-end gap-2">
                        {!isCreate && (
                            <Button
                                variant="primary"
                                className="bg-red-600 hover:bg-red-700 active:bg-red-800 mr-auto"
                                type="button"
                                onClick={handleDelete}
                                loading={isLoading}
                            >
                                {t('eventDetailPage.sessionFormDialog.deleteButton')}
                            </Button>
                        )}

                        <Button variant="ghost" onClick={onCancel} type="button">
                            {t('eventDetailPage.sessionFormDialog.cancelButton')}
                        </Button>

                        <FormSubmitButton variant="primary" type="submit" loading={isLoading}>
                            {isCreate
                                ? t('eventDetailPage.sessionFormDialog.createButton')
                                : t('eventDetailPage.sessionFormDialog.updateButton')}
                        </FormSubmitButton>
                    </div>
                </Form>
            </Dialog.Content>
        </Dialog>
    );
};

export default BaseEventSessionFormDialog;
