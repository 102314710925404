import React from 'react';
import { Link } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';

import getIsActiveRoute from '../../../utils/getIsActiveRoute.ts';

const NavigationLink = ({
    className,
    children,
    active,
    end = false,
    ...props
}: React.ComponentPropsWithRef<typeof Link> & { active?: boolean; end?: boolean }) => {
    const isActive = getIsActiveRoute(props.to as string, end) || active;

    return (
        <Link
            className={classnames(
                'flex flex-col text-base-600 font-medium transition-colors select-none h-16 group',
                className
            )}
            {...props}
        >
            <span className="block h-[3px] w-full" />

            <span className="flex flex-1 h-full flex-col justify-center">
                <span
                    className={classnames(
                        'text-base-900 rounded mx-1 px-2.5 py-1.5 font-medium text-base inline-flex items-center justify-center !outline-theme-100 data-[state=open]:outline data-[state=open]:outline-3 focus:outline focus:outline-3 outline-offset-0 ',
                        'group-hover:bg-base-100 transition-all',
                        'disabled:pointer-events-none'
                    )}
                >
                    {children}
                </span>
            </span>

            <span className={classnames('block h-[3px] w-full', isActive && 'bg-theme-400')} />
        </Link>
    );
};

const Navigation = ({ className, children, ...props }: React.ComponentPropsWithRef<'nav'>) => {
    return (
        <nav
            className={classnames('flex justify-center px-6 h-16 border-b border-base-200 items-center', className)}
            {...props}
        >
            <div className="w-full max-w-[1120px] flex gap-4 items-center flex-1">{children}</div>
        </nav>
    );
};

export default Object.assign(Navigation, { Link: NavigationLink });
