import React from 'react';
import { Link } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { Slot } from '@radix-ui/react-slot';

import { Slottable } from '../../../components/Button.tsx';
import getIsActiveRoute from '../../../utils/getIsActiveRoute.ts';

const AdminPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div className={classnames('bg-base-50 min-h-[100vh] antialiased', className)} {...props} />
);

const AdminMain = ({ className, children, ...props }: React.ComponentPropsWithRef<'main'>) => (
    <main className={classnames('flex justify-center px-6 min-h-[75vh] items-stretch', className)} {...props}>
        <div className="w-full max-w-[1120px] flex flex-col items-stretch justify-stretch">{children}</div>
    </main>
);

const AdminContent = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div
        data-testid="admin-content"
        className={classnames('w-full flex flex-col gap-5 py-10 justify-start min-h-full', className)}
        {...props}
    />
);

const AdminNavigation = ({ className, children, ...props }: React.ComponentPropsWithRef<'nav'>) => (
    <nav
        data-testid="admin-navigation"
        className={classnames(
            'flex flex-col gap-0 items-start flex-none self-stretch w-[300px] border-r border-base-200 py-10 -ml-4',
            className
        )}
        {...props}
    >
        {children}
    </nav>
);

const AdminNavigationItem = ({
    className,
    children,
    active,
    asChild,
    ...props
}: React.ComponentPropsWithRef<'div'> & Slottable & { active?: boolean }) => {
    const Comp = asChild ? Slot : 'div';

    return (
        <div className={classnames('flex gap-3 w-full items-stretch group text-sm', className)}>
            <Comp
                className={classnames(
                    'flex gap-2 items-center flex-1 px-4 py-2.5 w-full rounded-xl text-base-600 font-medium transition-colors select-none my-0.5 data-[state=open]:bg-base-150',
                    active ? 'bg-base-150 !text-black' : 'group-hover:bg-base-100 group-hover:text-base-800'
                )}
                {...props}
            >
                {children}
            </Comp>
            <span className={classnames('block w-[3px]', active && 'bg-theme-400')} />
        </div>
    );
};

const AdminNavigationLink = ({
    className,
    children,
    active,
    ...props
}: React.ComponentPropsWithRef<typeof Link> & { active?: boolean }) => {
    const isActive = getIsActiveRoute(props.to as string);
    return (
        <AdminNavigationItem asChild active={active !== undefined ? active : isActive} className={className}>
            <Link {...props}>{children}</Link>
        </AdminNavigationItem>
    );
};

const AdminNavigationSeparator = ({ className, ...props }: React.ComponentPropsWithRef<'hr'>) => (
    <hr className={classnames('block border-t border-base-200 my-4 ml-4 w-[256px]', className)} {...props} />
);

const AdminNavigationLabel = ({ className, children, ...props }: React.ComponentPropsWithRef<'span'>) => (
    <span className={classnames('block px-4 py-3 text-base-500 text-sm font-medium select-none', className)} {...props}>
        {children}
    </span>
);

const AdminCard = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div className={classnames('bg-white rounded-xl p-8', className)} {...props} />
);

const AdminCardNavigationWrapper = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div className={classnames('rounded-xl bg-base-100', className)} {...props} />
);

const AdminCardNavigation = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div className={classnames('px-5 flex gap-2', className)} {...props} />
);

const AdminCardNavigationItem = ({
    className,
    children,
    active,
    disabled = false,
    asChild,
    ...props
}: React.ComponentPropsWithRef<'div'> & Slottable & { active?: boolean; disabled?: boolean }) => {
    const Comp = asChild ? Slot : 'div';

    return (
        <Comp
            className={classnames(
                'flex flex-col text-base-600 font-medium transition-colors select-none h-16 group',
                disabled && 'opacity-50 cursor-not-allowed',
                className
            )}
            {...props}
        >
            <span className="block h-[3px] w-full" />

            <span className="flex flex-1 h-full flex-col justify-center">
                <span
                    className={classnames(
                        'text-base-500 rounded px-2 py-1.5 font-medium text-sm inline-flex items-center justify-center !outline-theme-100 data-[state=open]:outline data-[state=open]:outline-3 focus:outline focus:outline-3 outline-offset-0 min-h-8 mx-1',
                        active && 'text-base-900',
                        !disabled && 'group-hover:bg-base-200 group-hover:text-base-900 transition-all',
                        'disabled:pointer-events-none'
                    )}
                >
                    {children}
                </span>
            </span>

            <span className={classnames('block h-[3px] w-full', active && 'bg-theme-400')} />
        </Comp>
    );
};

const AdminCardNavigationLink = ({
    className,
    children,
    active,
    ...props
}: React.ComponentPropsWithRef<typeof Link> & { active?: boolean }) => {
    const isActive = getIsActiveRoute(props.to as string);
    return (
        <Link {...props}>
            <AdminCardNavigationItem active={active !== undefined ? active : isActive} className={className}>
                {children}
            </AdminCardNavigationItem>
        </Link>
    );
};

export default Object.assign(AdminPage, {
    Main: AdminMain,
    Content: AdminContent,
    Navigation: AdminNavigation,
    NavigationItem: AdminNavigationItem,
    NavigationLink: AdminNavigationLink,
    NavigationSeparator: AdminNavigationSeparator,
    NavigationLabel: AdminNavigationLabel,
    Card: AdminCard,
    CardNavigationWrapper: AdminCardNavigationWrapper,
    CardNavigation: AdminCardNavigation,
    CardNavigationItem: AdminCardNavigationItem,
    CardNavigationLink: AdminCardNavigationLink,
});
