import ConfirmEmailPage from './pages/ConfirmEmailPage.tsx';
import ConfirmInvitationPage from './pages/ConfirmInvitationPage.tsx';
import ForgotPasswordPage from './pages/ForgotPasswordPage.tsx';
import LoginPage from './pages/LoginPage.tsx';
import ResetPasswordPage from './pages/ResetPasswordPage.tsx';
import SignupPage from './pages/SignupPage.tsx';

export const ADMIN_LOGIN_PATH = '/admin/login';
export const ADMIN_SIGNUP_PATH = '/admin/signup';
export const ADMIN_CONFIRM_EMAIL_PATH = '/admin/confirm-email/:token';
export const ADMIN_CONFIRM_INVITATION_PATH = '/admin/confirm-invitation/:token';
export const ADMIN_FORGOT_PASSWORD_PATH = '/admin/forgot-password';
export const ADMIN_RESET_PASSWORD_PATH = '/admin/reset-password/:token';

export default [
    {
        handle: {
            title: 'Anmelden',
        },
        path: ADMIN_LOGIN_PATH,
        element: <LoginPage />,
    },
    {
        handle: {
            title: 'Registrieren',
        },
        path: ADMIN_SIGNUP_PATH,
        element: <SignupPage />,
    },
    {
        handle: {
            title: 'E-Mail-Adresse bestätigen',
        },
        path: ADMIN_CONFIRM_EMAIL_PATH,
        element: <ConfirmEmailPage />,
    },
    {
        handle: {
            title: 'Einladung bestätigen',
        },
        path: ADMIN_CONFIRM_INVITATION_PATH,
        element: <ConfirmInvitationPage />,
    },
    {
        handle: {
            title: 'Passwort vergessen',
        },
        path: ADMIN_FORGOT_PASSWORD_PATH,
        element: <ForgotPasswordPage />,
    },
    {
        handle: {
            title: 'Passwort zurücksetzen',
        },
        path: ADMIN_RESET_PASSWORD_PATH,
        element: <ResetPasswordPage />,
    },
];
