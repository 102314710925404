import React from 'react';

import { classnames } from '@nicoknoll/utils';

import Image from '../../../../components/Image.tsx';
import { Organization } from '../../../../data/models.ts';

const OrganizationCard = ({
    className,
    organization,
    button,
    isCurrentOrganization,
    ...props
}: React.ComponentPropsWithRef<'div'> & {
    organization: Organization;
    isCurrentOrganization?: boolean;
    button?: React.ReactNode;
}) => {
    return (
        <div className={classnames('bg-base-100 rounded-xl p-6 flex gap-4 items-center', className)} {...props}>
            <Image
                src={organization.logo?.url}
                alt="-"
                className="w-12 h-12 rounded-full object-cover bg-base-200 text-transparent select-none"
            />

            <h2 className="text text-lg font-semibold flex gap-2 items-center flex-1">
                <span>{organization.name}</span>
            </h2>

            <div className="flex gap-2 h-full items-center">
                <div>{button}</div>
            </div>
        </div>
    );
};

export default OrganizationCard;
