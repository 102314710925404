import { BaseColor, ThemeColor } from '../components/Theme.tsx';

export const DEFAULT_LANGUAGE_CODE = 'de';
export const SUPPORTED_LANGUAGE_CODES = ['de', 'en'] as const;
export type SupportedLanguageCode = (typeof SUPPORTED_LANGUAGE_CODES)[number];

export type WithTranslations<T extends Record<string, any>, TranslatableKeys extends keyof T> = T & {
    [K in TranslatableKeys as `${string & K}${Capitalize<SupportedLanguageCode>}`]: T[K];
};

export function createTranslatable<T extends Record<string, any>, K extends keyof T>(
    obj: T,
    translatableKeys: readonly K[]
): WithTranslations<T, K> {
    const result = { ...obj } as any;
    const languageCodes = SUPPORTED_LANGUAGE_CODES.filter((lang) => lang !== DEFAULT_LANGUAGE_CODE);

    translatableKeys.forEach((key) => {
        languageCodes.forEach((lang) => {
            const capitalizedLang = lang.charAt(0).toUpperCase() + lang.slice(1);
            const translatedKey = `${String(key)}${capitalizedLang}`;
            result[translatedKey] = obj[key];
        });
    });

    return result;
}

export interface Space {
    id: string;
    name: string;
}

export interface SpaceLimits {
    maxEvents?: number;
    maxAdminUsers?: number;
    maxOrganizations?: number;
    maxStorageSpace?: number;

    eventsCount?: number;
    adminUsersCount?: number;
    organizationsCount?: number;
    storageSpaceUsed?: number;

    maxUsersPerOrganization?: number;
    maxJobsPerOrganization?: number;
    maxStorageSpacePerOrganization?: number;
}

interface BaseOrganization {
    id: string;
    name: string;
    summary?: string;
    description?: string;

    industry?: string;
    locationScope?: string;
    organizationSize?: string;
    organizationType?: string;
    organizationLanguage?: string;

    logo?: UploadedFile;
    banner?: UploadedFile;

    website?: string;
    linkedin?: string;
    facebook?: string;
    twitter?: string;
    instagram?: string;
}

export interface Organization extends BaseOrganization {}

export const TRANSLATABLE_ORGANIZATION_KEYS = ['name', 'summary', 'description'] as const;
export interface TranslatableOrganization
    extends WithTranslations<Organization, (typeof TRANSLATABLE_ORGANIZATION_KEYS)[number]> {}

export interface OrganizationLimits {
    maxUsers?: number;
    maxJobs?: number;
    maxStorageSpace?: number;

    usersCount?: number;
    jobsCount?: number;
    storageSpaceUsed?: number;
}

export interface OrganizationProfile extends Omit<BaseOrganization, 'name'> {
    id: string;
    organizationId: string;
    eventId: string;

    isPublic?: boolean;

    eventDays?: EventDay[];
    offlineLocation?: string;

    name?: string;

    // controlled by admin
    maxJobs?: number;
    maxUsers?: number;
    isHighlighted?: boolean;

    // virtual
    organization?: Organization;
    users?: User[];
    userIds?: string[];
    jobs?: Job[];
    jobIds?: string[];
}

export const TRANSLATABLE_ORGANIZATION_PROFILE_KEYS = ['name', 'summary', 'description'] as const; // offlineLocation
export interface TranslatableOrganizationProfile
    extends WithTranslations<OrganizationProfile, (typeof TRANSLATABLE_ORGANIZATION_PROFILE_KEYS)[number]> {}

export interface OrganizationProfileLimits {
    maxUsers?: number;
    maxJobs?: number;
    maxSessions?: number;

    usersCount?: number;
    jobsCount?: number;
    sessionsCount?: number;
}

export interface OrganizationProfileJob {
    id: string;
    jobId: string;
    organizationProfileId: string;
    position?: number;

    // virtual
    job?: Job;
    organizationProfile?: OrganizationProfile;
}

export interface OrganizationProfileUser {
    id: string;
    userId: string;
    organizationProfileId: string;
    position?: number;
    canReceiveMessages?: boolean;

    // virtual
    user?: User;
    organizationProfile?: OrganizationProfile;
}

export interface Job {
    id: string;
    organizationId: string;
    title: string;
    summary: string;
    description?: string;
    employmentType?: string;
    qualification?: string;
    remoteWork?: string;
    remunerationType?: string;
    location: string;
    url?: string;
}

export const TRANSLATABLE_JOB_KEYS = ['title', 'summary', 'description'] as const;
export interface TranslatableJob extends WithTranslations<Job, (typeof TRANSLATABLE_JOB_KEYS)[number]> {}

export interface User {
    id: string;
    organizationId?: string;
    firstName?: string;
    lastName?: string;
    email: string;
    isEmailConfirmed: boolean;
    password?: string;

    position?: string;
    status: 'active' | 'invited' | 'inactive';
    image?: UploadedFile;

    languageCode: string;

    // controlled by admin
    isAdmin?: boolean;
    isSupport?: boolean;

    // virtual
    profileId?: string;
    profile?: UserProfile;
}

export const TRANSLATABLE_USER_KEYS = ['position'] as const;
export interface TranslatableUser extends WithTranslations<User, (typeof TRANSLATABLE_USER_KEYS)[number]> {}

interface UserProfileLanguage {
    id: string;
    languageCode: string;
    level: string;
}

export interface UserProfile {
    id: string;
    userId: string;

    headline?: string;
    careerObjective?: string;
    description?: string;
    skills?: string;
    languages?: UserProfileLanguage[];
    location?: string;

    cv?: UploadedFile;

    website?: string;
    linkedin?: string;
    facebook?: string;
    twitter?: string;
    instagram?: string;

    // data from User
    firstName?: string;
    lastName?: string;
    email?: string;
    image?: UploadedFile;

    isPublic?: boolean;

    // virtual
    user?: User;
    experienceIds?: string[];
    experiences?: Experience[];
    educationIds?: string[];
    educations?: Education[];
}

export const TRANSLATABLE_USER_PROFILE_KEYS = [
    'headline',
    'careerObjective',
    'description',
    'skills',
    'location',
] as const;
export interface TranslatableUserProfile
    extends WithTranslations<UserProfile, (typeof TRANSLATABLE_USER_PROFILE_KEYS)[number]> {}

export interface Education {
    id: string;
    userProfileId: string;
    school: string;
    fieldOfStudy?: string;
    degree?: string;
    startDate?: string;
    endDate?: string;
    description?: string;
}

export const TRANSLATABLE_EDUCATION_KEYS = ['school', 'fieldOfStudy', 'degree', 'description'] as const;
export interface TranslatableEducation
    extends WithTranslations<Education, (typeof TRANSLATABLE_EDUCATION_KEYS)[number]> {}

export interface Experience {
    id: string;
    userProfileId: string;
    position: string;
    employmentStatus?: string;
    company?: string;
    startDate?: string;
    endDate?: string;
    location?: string;
    description?: string;
}

export const TRANSLATABLE_EXPERIENCE_KEYS = ['position', 'description'] as const;
export interface TranslatableExperience
    extends WithTranslations<Experience, (typeof TRANSLATABLE_EXPERIENCE_KEYS)[number]> {}

export interface UserNotificationSettings {
    id: string;

    userId: string;
    user?: User;

    productUpdates: boolean;
    conversationStarted: boolean;
    conversationAssigned: boolean;
    conversationMessageReceived: boolean;
    eventStarted: boolean;
    eventSessionStarted: boolean;
    eventSessionJoined: boolean;
    eventSessionLeft: boolean;
}

export interface UserNote {
    createdAt: string;
    updatedAt: string;

    id: string;
    organizationId: string;

    userId: string;
    user?: User;

    targetUserId: string;
    targetUser?: User;

    text: string;
}

export interface EventDay {
    id: string;
    date: string;
    startTime: string;
    endTime: string;
}

export interface Event {
    id: string;
    slug: string;

    name: string;
    summary?: string;
    description?: string;
    timezoneName: string;
    location?: string;

    logo?: UploadedFile;
    banner?: UploadedFile;
    bannerLogo?: UploadedFile;

    isPublic?: boolean;

    themeColorName?: ThemeColor;
    baseColorName?: BaseColor;

    days: EventDay[];
    contactEmail?: string;
}

export const TRANSLATABLE_EVENT_KEYS = ['name', 'summary', 'description', 'location'] as const;
export interface TranslatableEvent extends WithTranslations<Event, (typeof TRANSLATABLE_EVENT_KEYS)[number]> {}

export interface EventLimits {
    maxUsers?: number;
    maxSessions?: number;
    maxOrganizations?: number;

    usersCount?: number;
    sessionsCount?: number;
    organizationsCount?: number;
}

export interface EventAttendee {
    id: string;
    eventId: string;
    userId: string;

    // virtual
    user?: User;
}

export interface EventSession {
    id: string;
    title: string;
    summary?: string;
    description?: string;
    startDatetime: string;
    endDatetime: string;
    location?: string;

    format?: string;
    languageCode: string;

    eventId: string;
    organizationProfileId?: string;

    maxUsers?: number;
    userIds?: string[];

    // virtual
    isParticipant?: boolean;
    event?: Event;
    organizationProfile?: OrganizationProfile;
    users?: User[];
}

export const TRANSLATABLE_EVENT_SESSION_KEYS = ['title', 'summary', 'description', 'location'] as const;
export interface TranslatableEventSession
    extends WithTranslations<EventSession, (typeof TRANSLATABLE_EVENT_SESSION_KEYS)[number]> {}

export interface EventSessionUser {
    id: string;
    eventSessionId: string;
    userId: string;

    // virtual
    user?: User;
    eventSession?: EventSession;
}

export enum EventPageRole {
    IMPRINT = 'IMPRINT',
    TERMS = 'TERMS',
    PRIVACY = 'PRIVACY',
}

export interface EventPage {
    id: string;
    eventId: string;
    title: string;
    slug: string;
    role?: EventPageRole;
    content: string;
}

export const TRANSLATABLE_EVENT_PAGE_KEYS = ['title', 'content'] as const;
export interface TranslatableEventPage
    extends WithTranslations<EventPage, (typeof TRANSLATABLE_EVENT_PAGE_KEYS)[number]> {}

export enum MessageType {
    USER_MESSAGE = 'USER_MESSAGE',
    USER_ADDED = 'USER_ADDED',
    USER_REMOVED = 'USER_REMOVED',
    USER_JOINED = 'USER_JOINED',
    USER_LEFT = 'USER_LEFT',
    USER_REPORTED = 'USER_REPORTED',
    USER_BLOCKED = 'USER_BLOCKED',
    USER_UNBLOCKED = 'USER_UNBLOCKED',
}

export interface Message {
    id: string;
    conversationId: string;

    createdAt?: string;
    userId: string;
    text: string;
    attachment?: UploadedFile;

    messageType: MessageType;
    targetUserId?: string;

    // virtual
    user?: User;
    targetUser?: User;
}

export interface Conversation {
    id: string;

    createdAt: string;
    isSupport: boolean;
    eventId: string;
    participantIds: string[];

    blockedBy?: User;
    blockedAt?: string;
    unreadCount: number;
    lastMessage?: Message;

    // virtual
    isParticipant: boolean;
    participants: User[];
    messages: Message[];
}

export interface SavedUser {
    id: string;
    eventId: string;
    organizationId: string;

    userId: string;
    user?: User;

    targetUserId: string;
    targetUser?: User;
}

export interface SavedOrganization {
    id: string;
    eventId: string;

    userId: string;
    user?: User;

    targetOrganizationId: string;
    targetOrganization?: User;
}

export interface SavedJob {
    id: string;
    eventId: string;

    userId: string;
    user?: User;

    jobId: string;
    job?: Job;
}

export interface SavedEventSession {
    id: string;
    eventId: string;

    userId: string;
    user?: User;

    eventSessionId: string;
    eventSession?: EventSession;
}

export enum LogEventType {
    // views
    JOB_VIEW = 'JOB_VIEW',
    ORGANIZATION_VIEW = 'ORGANIZATION_VIEW',
    USER_VIEW = 'USER_VIEW',
    EVENT_SESSION_VIEW = 'EVENT_SESSION_VIEW',

    // searches
    JOB_SEARCH = 'JOB_SEARCH',
    ORGANIZATION_SEARCH = 'ORGANIZATION_SEARCH',
    USER_SEARCH = 'USER_SEARCH',
    EVENT_SESSION_SEARCH = 'EVENT_SESSION_SEARCH',

    // saved
    JOB_SAVED = 'JOB_SAVED',
    JOB_UNSAVED = 'JOB_UNSAVED',
    ORGANIZATION_SAVED = 'ORGANIZATION_SAVED',
    ORGANIZATION_UNSAVED = 'ORGANIZATION_UNSAVED',
    USER_SAVED = 'USER_SAVED',
    USER_UNSAVED = 'USER_UNSAVED',
    EVENT_SESSION_SAVED = 'EVENT_SESSION_SAVED',
    EVENT_SESSION_UNSAVED = 'EVENT_SESSION_UNSAVED',

    // messaging
    CONVERSATION_STARTED = 'CONVERSATION_STARTED',
    CONVERSATION_MESSAGE_SENT = 'CONVERSATION_MESSAGE_SENT',
    CONVERSATION_REPORTED = 'CONVERSATION_REPORTED',
    CONVERSATION_BLOCKED = 'CONVERSATION_BLOCKED',
    CONVERSATION_UNBLOCKED = 'CONVERSATION_UNBLOCKED',
}

export interface LogEvent {
    id: string;
    eventType: LogEventType;
    eventId: string;
    organizationId?: string;
    userId: string;
    targetId?: string;
    details?: any;
}

export interface UploadedFile {
    id: string;
    name: string;
    url: string;
    size: number;
    mimeType: string;
    user?: User;
    userId?: string;
    organization?: Organization;
    organizationId?: string;

    // only for create
    file?: File;
}

export interface ScheduledEmail {
    id: string;
    scheduledDatetime: string;
    receiversQuery: string;
    subject: string;
    text: string;
    sentAt?: string;
}

export interface DateEntry {
    date: string;
    count: number;
}

export interface CountEntry {
    count: number;
    value: string;
}

export interface EventAnalytics {
    attendees?: number;
    savedAttendees?: number;
    signupsByDay?: DateEntry[];

    organizations?: number;
    savedOrganizations?: number;
    mostSavedOrganizations?: CountEntry[];
    mostViewedOrganizations?: CountEntry[];
    contactPersons?: number;
    organizationSearchTerms?: CountEntry[];

    sessions?: number;
    savedSessions?: number;
    mostSavedSessions?: CountEntry[];
    mostViewedSessions?: CountEntry[];
    sessionAttendees?: number;
    sessionSearchTerms?: CountEntry[];

    jobs?: number;
    savedJobs?: number;
    mostSavedJobs?: CountEntry[];
    mostViewedJobs?: CountEntry[];
    jobSearchTerms?: CountEntry[];

    conversations?: number;
    messages?: number;
    conversationsPerAttendee?: number;
    conversationsPerOrganization?: number;
    mostConversationsOrganizations?: CountEntry[];
}
