import { PointerIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BooleanField, Field } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import { useConfirmationDialogContext } from '../../../../components/ConfirmationDialog.tsx';
import {
    BaseColor,
    DEFAULT_BASE_COLOR,
    DEFAULT_THEME_COLOR,
    ThemeColor,
    ThemeProvider,
    themeColors,
} from '../../../../components/Theme.tsx';
import { eventDeleteMutation, eventDetailQuery, eventUpdateMutation } from '../../../../data/queries.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import { ADMIN_EVENT_LIST_PATH } from '../routes.tsx';

const ColorTuple = ({
    theme,
    base,
    active,
    ...buttonProps
}: { theme: ThemeColor; base: BaseColor } & React.ComponentProps<typeof Button>) => {
    return (
        <Button className="rounded-full !p-1.5" active={active} {...buttonProps}>
            <div
                className="rounded-full border border-solid p-1.5"
                style={{
                    backgroundColor: themeColors[theme][500],
                    borderColor: themeColors[theme][700],
                }}
            >
                <div className={classnames('w-1.5 h-1.5 rounded-full shadow-sm', active && 'bg-white')} />
            </div>
        </Button>
    );
};

const ThemePreview = ({ theme, base }: { theme: ThemeColor; base: BaseColor }) => {
    const { t } = useTranslation('admin');
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [active, setActive] = useState(true);
    return (
        <div ref={(r) => setRef(r)}>
            {ref && (
                <ThemeProvider theme={theme} base={base} rootNode={ref ? ref : undefined}>
                    <div className="flex flex-col bg-base-50 p-5 gap-5 rounded-lg">
                        <Field.Label className="-my-1 flex gap-1">
                            <span className="text-base-400">{t('eventDetailPage.advancedTab.themePreview')}:</span>
                            {/** @ts-ignore */}
                            <span className="text-theme-600">{t(`common:themeColor.${theme}`)}</span>
                        </Field.Label>

                        <div className="flex">
                            <div className="flex-1 flex items-center">
                                <div className="flex gap-3 w-full items-stretch group text-sm">
                                    <button
                                        className={classnames(
                                            'flex gap-2 items-center flex-1 px-4 py-2.5 w-full rounded-xl text-base-600 font-medium transition-colors select-none my-0.5',
                                            active
                                                ? 'bg-base-150 !text-black'
                                                : 'group-hover:bg-base-100 group-hover:text-base-800'
                                        )}
                                        onClick={() => setActive(!active)}
                                    >
                                        <PointerIcon /> {t('eventDetailPage.advancedTab.exampleButton')}
                                    </button>
                                    <span className={classnames('block w-[3px]', active && 'bg-theme-400')} />
                                </div>
                            </div>

                            <div className="w-px bg-base-150 flex-none mx-5" />

                            <div className="flex-1 flex items-center">
                                <Button className="w-full" variant="primary">
                                    {t('eventDetailPage.advancedTab.exampleButton')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>
            )}
        </div>
    );
};

const AdvancedTab = (props: any) => {
    const { t } = useTranslation('admin');

    const confirm = useConfirmationDialogContext();
    const navigate = useNavigate();

    const { eventId } = useParams();

    const { data: event } = useQuery(eventDetailQuery(eventId!));

    const { mutateAsync: updateEvent } = useMutation(eventUpdateMutation());
    const { mutateAsync: deleteEvent } = useMutation(eventDeleteMutation());

    const handleSubmit = async () => {
        try {
            await updateEvent({
                id: eventId!,
                data: {
                    themeColorName: themeAndBase[0],
                    baseColorName: themeAndBase[1],
                },
            });

            toast.success(t('eventDetailPage.generalTab.submitSuccess'));
        } catch (error) {
            console.error(error);
            // setFormErrors(t, formMethods, error);
            toast.error(t('eventDetailPage.generalTab.submitError'));
        }
    };

    const handleDelete = async (values: any) => {
        return confirm('Möchtest du die Veranstaltung wirklich löschen?', '', {
            onConfirm: async () => {
                try {
                    await deleteEvent({ id: event?.id! });
                    toast.success(t('eventDetailPage.advancedTab.deleteSuccess'));
                    navigate(url(ADMIN_EVENT_LIST_PATH));
                } catch (error) {
                    console.error(error);
                    toast.error(t('eventDetailPage.advancedTab.deleteError'));
                }
            },
            danger: true,
        });
    };

    const [isDeleteChecked, setIsDeleteChecked] = useState(false);

    const colorTuples: [ThemeColor, BaseColor][] = [
        ['red', 'stone'],
        ['orange', 'stone'],
        ['amber', 'stone'],
        ['yellow', 'stone'],

        ['lime', 'gray'],
        ['green', 'gray'],
        ['emerald', 'gray'],
        ['teal', 'gray'],

        ['cyan', 'zinc'],
        ['sky', 'zinc'],
        ['blue', 'zinc'],
        ['indigo', 'zinc'],

        ['violet', 'neutral'],
        ['purple', 'neutral'],
        ['fuchsia', 'neutral'],

        ['pink', 'stone'],
        ['rose', 'stone'],
    ];

    const [themeAndBase, setThemeAndBase] = useState<[ThemeColor, BaseColor]>([
        event?.themeColorName! || DEFAULT_THEME_COLOR,
        event?.baseColorName! || DEFAULT_BASE_COLOR,
    ]);

    useEffect(() => {
        if (!event) return;
        setThemeAndBase([event?.themeColorName! || DEFAULT_THEME_COLOR, event?.baseColorName! || DEFAULT_BASE_COLOR]);
    }, [event]);

    return (
        <div className="w-full flex flex-col gap-10 justify-start">
            <Admin.Card className="flex-none flex flex-col gap-5">
                <h1 className="text-3xl font-semibold">{t('eventDetailPage.advancedTab.title')}</h1>

                <div className="flex flex-col gap-3">
                    <Field.Label>{t('eventDetailPage.advancedTab.colorSchemeLabel')}</Field.Label>

                    <div className="flex flex-wrap gap-2.5 justify-start">
                        {colorTuples.map(([theme, base]) => (
                            <ColorTuple
                                key={theme}
                                theme={theme}
                                base={base}
                                onClick={() => setThemeAndBase([theme, base])}
                                active={theme === themeAndBase[0] && base === themeAndBase[1]}
                            />
                        ))}
                    </div>
                </div>

                <ThemePreview theme={themeAndBase[0]} base={themeAndBase[1]} />

                <div className="flex justify-end mt-4">
                    <Button variant="primary" onClick={handleSubmit}>
                        {t('eventDetailPage.advancedTab.submitButton')}
                    </Button>
                </div>
            </Admin.Card>

            <Admin.Card className="flex-none flex flex-col gap-5">
                <h1 className="text-3xl font-semibold text-red-700">{t('eventDetailPage.advancedTab.deleteTitle')}</h1>

                <p className="text-base-700 text-pretty">{t('eventDetailPage.advancedTab.description')}</p>

                <div className="flex-1 flex justify-between items-center flex-wrap gap-5 rounded-xl bg-base-50 p-5">
                    <BooleanField
                        label={t('eventDetailPage.advancedTab.confirmLabel')}
                        name="deleteEvent"
                        // @ts-ignore
                        value={isDeleteChecked}
                        // @ts-ignore
                        onChange={(e) => setIsDeleteChecked(e.target.checked)}
                    />
                </div>

                <div className="flex justify-end">
                    <Button
                        variant="primary"
                        className="bg-red-600 hover:bg-red-700 active:bg-red-800"
                        disabled={!isDeleteChecked}
                        onClick={handleDelete}
                    >
                        {t('eventDetailPage.advancedTab.deleteButton')}
                    </Button>
                </div>
            </Admin.Card>
        </div>
    );
};

export default AdvancedTab;
