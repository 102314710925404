import { GripVertical } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { classnames } from '@nicoknoll/utils';

import Avatar from '../../../components/Avatar.tsx';
import Image from '../../../components/Image.tsx';
import { User } from '../../../data/models.ts';

export const YouBadge = (props: React.ComponentPropsWithRef<'span'>) => {
    return (
        <span
            className={classnames(
                'bg-theme-200 text-theme-700 rounded px-1.5 py-0.5 text-xs font-medium select-none',
                props.className
            )}
            {...props}
        >
            Du
        </span>
    );
};

const UserCard = ({
    className,
    user,
    button,
    isCurrentUser,
    ...props
}: React.ComponentPropsWithRef<'div'> & { user: User; isCurrentUser?: boolean; button?: React.ReactNode }) => {
    const { t } = useTranslation('admin');
    const fullName = `${user.firstName} ${user.lastName}`.trim();
    return (
        <div
            className={classnames(
                'bg-base-100 rounded-xl p-6 relative group data-[dragging]:hover:bg-base-150 data-[dragging="true"]:bg-base-200',
                className
            )}
            {...props}
        >
            <div className="flex gap-4 items-center flex-1 group-data-[dragging='true']:opacity-0">
                <div className="absolute left-0 h-full flex items-center text-base-400 p-1.5 opacity-0 group-data-[dragging]:group-hover:opacity-100 transition-opacity cursor-grab">
                    <GripVertical />
                </div>

                <Avatar user={user} className="w-12 h-12" />

                <div className="flex flex-col gap-0.5 flex-1">
                    <h2 className="text text-lg font-semibold flex gap-2 items-center">
                        {fullName ? (
                            <>
                                {fullName} {isCurrentUser && <YouBadge />}
                            </>
                        ) : (
                            <span className="text-base-400">{t('components.userCard.unknown')}</span>
                        )}
                    </h2>
                    <p className="text-base-600">
                        {user.email} {user.position && `· ${user.position}`}
                    </p>
                </div>

                <div className="flex gap-2 h-full items-center">
                    <span
                        className={classnames(
                            'flex gap-1.5 items-center text-sm font-medium px-2 select-none',
                            user.status === 'active' && 'text-theme-500',
                            user.status === 'invited' && 'text-base-500',
                            user.status === 'inactive' && 'text-red-500'
                        )}
                    >
                        <span
                            className={classnames(
                                'w-2.5 h-2.5 rounded-full inline-block',
                                user.status === 'active' && 'bg-theme-400',
                                user.status === 'invited' && 'bg-base-400',
                                user.status === 'inactive' && 'bg-red-400'
                            )}
                        />
                        {t(`components.userCard.status.${user.status}`)}
                    </span>

                    {button && <div className="flex gap-2 items-start">{button}</div>}
                </div>
            </div>
        </div>
    );
};

export default UserCard;
