import React, { useEffect, useRef, useState } from 'react';

import { classnames } from '@nicoknoll/utils';

const Image = ({ src, alt, className = '', onLoad, ...props }: React.ComponentPropsWithRef<'img'>) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isCached, setIsCached] = useState(false);
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        // If image is already loaded (from cache), show it immediately
        if (imageRef.current?.complete) {
            setIsLoaded(true);
            setIsCached(true);
        }
    }, [src]);

    if (!src) {
        return (
            <span
                className={classnames(
                    'transition-opacity ease-in border-transparent border-none text-transparent',
                    className
                )}
                {...props}
            />
        );
    }

    return (
        <img
            ref={imageRef}
            src={src}
            alt={alt || '-'}
            className={classnames(
                'transition-opacity ease-in border-transparent border-none text-transparent',
                isCached ? 'duration-0' : 'duration-200',
                isLoaded ? 'opacity-100' : 'opacity-0',
                className
            )}
            onLoad={(e) => {
                setIsLoaded(true);
                onLoad?.(e);
            }}
            {...props}
        />
    );
};

export default Image;
