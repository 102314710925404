import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';

import Image from '../../../components/Image.tsx';
import { OrganizationProfile, SavedOrganization } from '../../../data/models.ts';
import { formatDate } from '../../../utils/time.ts';
import { SITE_ORGANIZATION_DETAIL_PATH } from '../routes.tsx';
import { getBackgroundColor, siteUrl, useEventId } from '../utils.ts';
import { SaveOrganizationRibbon } from './SaveOrganizationButton.tsx';

const isToday = (date: Date | string) => {
    const today = new Date();
    const dateToCheck = new Date(date);
    return (
        today.getDate() === dateToCheck.getDate() &&
        today.getMonth() === dateToCheck.getMonth() &&
        today.getFullYear() === dateToCheck.getFullYear()
    );
};

const OrganizationProfileCard = ({
    organizationProfile,
    savedOrganization,
}: {
    organizationProfile: OrganizationProfile;
    savedOrganization?: SavedOrganization | null;
}) => {
    const eventId = useEventId();

    const logoWrapperRef = React.createRef<HTMLDivElement>();
    useEffect(() => {
        const magicBannerBackground = async () => {
            if (!logoWrapperRef.current || !organizationProfile?.logo?.url) return;
            const colors = await getBackgroundColor(organizationProfile?.logo?.url!);
            if (!colors) return;
            logoWrapperRef.current.style.background = `linear-gradient(180deg, ${colors.leftColor} 0%, ${colors.rightColor} 100%)`;
        };
        magicBannerBackground();
    }, [organizationProfile?.logo?.url]);

    return (
        <div className="relative flex-1 group">
            <SaveOrganizationRibbon
                organization={organizationProfile?.organization!}
                savedOrganization={savedOrganization}
                eventId={eventId}
                className="pt-8"
            />

            <Link
                className="flex flex-col gap-6 flex-1 bg-base-50 rounded-xl p-8 hover:bg-base-100 transition-colors h-full"
                to={siteUrl(SITE_ORGANIZATION_DETAIL_PATH, {
                    organizationId: organizationProfile.organizationId,
                    eventId,
                })}
            >
                <span className="flex flex-col gap-6">
                    <span className="w-full h-32 rounded transition-colors" ref={logoWrapperRef}>
                        <Image
                            className="w-full h-full object-contain"
                            src={organizationProfile.logo?.url}
                            alt={organizationProfile.name}
                        />
                    </span>

                    <span className="flex flex-col gap-2">
                        <span className="font-medium text-lg">{organizationProfile.name}</span>
                        <span className="text-base-500">{organizationProfile.summary}</span>
                    </span>
                </span>

                {organizationProfile?.eventDays && organizationProfile?.eventDays?.length > 0 && (
                    <div className="flex flex-wrap items-start gap-2">
                        {organizationProfile?.eventDays?.map((eventDay) => (
                            <div
                                className={classnames(
                                    'bg-base-150 text-base-900 font-medium rounded-lg px-3 py-1 text-sm ',
                                    isToday(eventDay.date) ? 'bg-theme-100 text-theme-700' : ''
                                )}
                            >
                                {formatDate(eventDay.date, undefined, { weekday: 'short' })}
                            </div>
                        ))}
                    </div>
                )}
            </Link>
        </div>
    );
};

export default OrganizationProfileCard;
