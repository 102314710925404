import { StarIcon, StarOffIcon } from 'lucide-react';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../components/Button.tsx';
import Tooltip from '../../../components/Tooltip.tsx';
import { Job, LogEventType, SavedJob } from '../../../data/models.ts';
import {
    savedJobCreateMutation,
    savedJobDeleteMutation,
    savedJobListQuery,
    userDetailQuery,
} from '../../../data/queries.ts';
import url from '../../../utils/url';
import useLogEvent from '../../../utils/useLogEvent.ts';
import { ADMIN_LOGIN_PATH } from '../../auth/routes.tsx';

interface SaveJobProps {
    job: Job;
    savedJob?: SavedJob | null; // null disables automatic fetching
    eventId: string;
}

const useSaveJob = ({ job, savedJob: propsSavedJob, eventId }: SaveJobProps) => {
    const { t } = useTranslation('site');

    const { data: viewer } = useQuery(userDetailQuery());
    const navigate = useNavigate();
    const location = useLocation();

    const { data: savedJobs, isLoading } = useQuery({
        ...savedJobListQuery({ eventId, userId: viewer?.id, jobId: job?.id }),
        enabled: !!viewer && !!job && propsSavedJob === undefined,
    });
    const savedJob = propsSavedJob || savedJobs?.[0];

    const createLogEvent = useLogEvent();

    const { mutateAsync: saveJob } = useMutation(savedJobCreateMutation());
    const handleSave = async () => {
        if (!viewer) {
            toast.error(t('saveJobButton.notAuthenticatedError'));
            navigate(`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`);
            return;
        }

        try {
            await saveJob({ data: { eventId, userId: viewer?.id, jobId: job.id } });
            void createLogEvent(LogEventType.JOB_SAVED, { eventId, targetId: job.id });
            toast.success(t('saveJobButton.saveSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('saveJobButton.saveError'));
        }
    };

    const { mutateAsync: unsaveJob } = useMutation(savedJobDeleteMutation());
    const handleUnsave = async () => {
        if (!viewer) {
            toast.error(t('saveJobButton.notAuthenticatedError'));
            navigate(`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`);
            return;
        }

        try {
            await unsaveJob({ id: savedJob!.id! });
            void createLogEvent(LogEventType.JOB_UNSAVED, { eventId, targetId: job.id });
            toast.success(t('saveJobButton.unsaveSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('saveJobButton.unsaveError'));
        }
    };

    return {
        isSaved: Boolean(savedJob),
        isLoading,
        saveJob: handleSave,
        unsaveJob: handleUnsave,
    };
};

export const SaveJobButton = ({
    job,
    savedJob,
    eventId,
    className,
    ...props
}: React.ComponentPropsWithRef<'button'> & SaveJobProps) => {
    const { t } = useTranslation('site');

    const { isSaved, isLoading, saveJob, unsaveJob } = useSaveJob({
        eventId,
        job,
        savedJob,
    });

    return (
        <Button
            variant={isSaved ? 'outline' : 'outline'}
            className="w-full text-base flex gap-2 disabled:cursor-not-allowed min-h-9"
            onClick={isSaved ? unsaveJob : saveJob}
            loading={isLoading ? true : undefined}
            {...props}
        >
            {isSaved ? <StarOffIcon /> : <StarIcon fill="currentColor" />}
            {isSaved ? t('saveJobButton.unsave') : t('saveJobButton.save')}
        </Button>
    );
};

export const SaveJobRibbon = ({
    job,
    savedJob,
    eventId,
    className,
    ...props
}: React.ComponentPropsWithRef<'button'> & SaveJobProps) => {
    const { t } = useTranslation('site');

    const { isSaved, isLoading, saveJob, unsaveJob } = useSaveJob({
        eventId,
        job,
        savedJob,
    });

    return (
        <Tooltip>
            <Tooltip.Trigger asChild>
                <Button
                    variant="unstyled"
                    className={classnames(
                        'text-base py-2 flex gap-2 absolute right-4 top-0 rounded-b-lg transition-colors p-3 pb-3.5 pt-4 z-10',
                        isSaved
                            ? 'bg-theme-500 text-white hover:bg-theme-600 active:bg-theme-700'
                            : 'bg-base-100 text-base-400 group-hover:bg-base-200 hover:text-theme-500 hover:!bg-theme-100',
                        className
                    )}
                    onClick={isSaved ? unsaveJob : saveJob}
                    loading={isLoading ? true : undefined}
                >
                    <StarIcon fill="currentColor" />
                </Button>
            </Tooltip.Trigger>
            <Tooltip.Content>{isSaved ? t('saveJobButton.unsave') : t('saveJobButton.save')}</Tooltip.Content>
        </Tooltip>
    );
};
