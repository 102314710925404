import { ArrowDownIcon, ArrowUpIcon, PlusIcon, XIcon } from 'lucide-react';
import React, { createContext, useContext, useRef } from 'react';

import {
    DateTimeInput,
    SingleSelect,
    TextInput,
    Widget,
    WidgetProps,
    setNativeTextareaValue,
    useWidgetState,
} from '@nicoknoll/forms';
import { classnames, useControllableState } from '@nicoknoll/utils';

import Button from './Button.tsx';

export interface IFieldDefinition {
    name: string;
    type: 'text' | 'datetime';
    options?: { label: string; value: string }[];
}

interface ISearchQueryField {
    name: string;
    operator: '' | 'NOT';
    value: string;
}

interface ISearchQueryGroup {
    operator: 'AND' | 'OR';
    children: (ISearchQueryField | ISearchQueryGroup)[];
}

const resolvePath = (obj: any, path: string) => {
    if (!path) return obj;

    const parts = path.split('.');
    let current = obj;

    for (const part of parts) {
        if (current.children) {
            current = current.children[parseInt(part)];
        } else {
            throw new Error('Invalid path');
        }
    }
    return current;
};

const SearchQueryField = ({
    path,
    field,
    className,
    ...props
}: { path: string; field: ISearchQueryField } & React.ComponentProps<'div'>) => {
    const { onFieldChange, onRemove, onMove, searchQuery, fieldDefinitions } = useContext(SearchQueryBuilderContext);

    const parentPath = path.split('.').slice(0, -1).join('.');
    const parent = resolvePath(searchQuery, parentPath);
    const isFirstChild = parent.children.indexOf(field) === 0;
    const isLastChild = parent.children.indexOf(field) === parent.children.length - 1;

    const fieldDefinition = fieldDefinitions.find((f) => f.name === field.name);

    return (
        <div className={classnames('flex gap-4', className)} {...props}>
            <div className="flex gap-2 flex-1">
                <SingleSelect
                    value={field.operator}
                    onChange={(e) => onFieldChange(path, { operator: e.target.value as '' | 'NOT' })}
                    options={[
                        { label: '&nbsp;&nbsp;&nbsp;', value: '' },
                        { label: 'NOT', value: 'NOT' },
                    ]}
                    hideSearch
                    // className="min-w-20"
                />

                <SingleSelect
                    value={field.name}
                    onChange={(e) => onFieldChange(path, { name: e.target.value, value: '' })}
                    options={fieldDefinitions.map((field) => ({ label: field.name, value: field.name }))}
                    hideSearch
                    required
                    className="w-48"
                />

                {fieldDefinition?.type === 'datetime' ? (
                    <DateTimeInput
                        value={field.value}
                        onChange={(e) => onFieldChange(path, { value: e.target.value })}
                    />
                ) : fieldDefinition?.options ? (
                    <SingleSelect
                        value={field.value}
                        onChange={(e) => onFieldChange(path, { value: e.target.value })}
                        options={fieldDefinition.options}
                        required
                        className="min-w-[10rem]"
                        hideSearch
                    />
                ) : (
                    <TextInput value={field.value} onChange={(e) => onFieldChange(path, { value: e.target.value })} />
                )}
            </div>

            <div className="flex gap-2 flex-none">
                <div className="flex">
                    <Button
                        onClick={() => onMove(path, 'up')}
                        className="px-2 rounded-r-none"
                        disabled={isFirstChild}
                        type="button"
                    >
                        <ArrowUpIcon />
                    </Button>
                    <Button
                        onClick={() => onMove(path, 'down')}
                        className="px-2 rounded-l-none -ml-px"
                        disabled={isLastChild}
                        type="button"
                    >
                        <ArrowDownIcon />
                    </Button>
                </div>

                <Button
                    onClick={() => onRemove(path)}
                    className="px-2 text-red-600 focus:bg-red-50 focus:text-red-700 hover:bg-red-50 hover:text-red-700 hover:border-red-700"
                    type="button"
                >
                    <XIcon />
                </Button>
            </div>
        </div>
    );
};

const SearchQueryGroup = ({
    path,
    group,
    className,
    ...props
}: { path: string; group: ISearchQueryGroup } & React.ComponentProps<'div'>) => {
    const { onGroupOperatorChange, onRemove, onFieldAdd, onGroupAdd, onMove, searchQuery } =
        useContext(SearchQueryBuilderContext);

    const parentPath = path.split('.').slice(0, -1).join('.');
    const parent = resolvePath(searchQuery, parentPath);
    const isFirstChild = parent.children.indexOf(group) === 0;
    const isLastChild = parent.children.indexOf(group) === parent.children.length - 1;
    const isOuter = !path;

    return (
        <div className={classnames('flex flex-col gap-4 bg-base-500/5 p-5 rounded-md w-full', className)} {...props}>
            <div className="flex gap-5">
                <div className="flex gap-2 flex-1">
                    <SingleSelect
                        value={group.operator}
                        onChange={(e) => onGroupOperatorChange(path, e.target.value as 'AND' | 'OR')}
                        options={[
                            { label: 'AND', value: 'AND' },
                            { label: 'OR', value: 'OR' },
                        ]}
                        hideSearch
                        required
                    />

                    <Button onClick={() => onFieldAdd(path)} className="gap gap-1 pl-2.5" type="button">
                        <PlusIcon />
                        <span>Add Field</span>
                    </Button>
                    <Button onClick={() => onGroupAdd(path)} className="gap gap-1 pl-2.5" type="button">
                        <PlusIcon />
                        <span>Add Group</span>
                    </Button>
                </div>

                <div className="flex gap-2 flex-none">
                    <div className="flex">
                        <Button
                            onClick={() => onMove(path, 'up')}
                            className="px-2 rounded-r-none"
                            disabled={isOuter || isFirstChild}
                            type="button"
                        >
                            <ArrowUpIcon />
                        </Button>
                        <Button
                            onClick={() => onMove(path, 'down')}
                            className="px-2 rounded-l-none -ml-px"
                            disabled={isOuter || isLastChild}
                            type="button"
                        >
                            <ArrowDownIcon />
                        </Button>
                    </div>

                    <Button
                        onClick={() => onRemove(path)}
                        className={classnames(
                            'px-2',
                            !isOuter &&
                                'text-red-600 focus:bg-red-50 focus:text-red-700 hover:bg-red-50 hover:text-red-700 hover:border-red-700'
                        )}
                        disabled={isOuter}
                        type="button"
                    >
                        <XIcon />
                    </Button>
                </div>
            </div>

            {group.children && group.children.length > 0 && (
                <>
                    <div className="w-full h-px bg-base-500/10" />

                    <div className="flex flex-col gap-3">
                        {group.children.map((child, index) => {
                            const childPath = path ? `${path}.${index}` : `${index}`;
                            if ('children' in child) {
                                return <SearchQueryGroup key={index} path={childPath} group={child} />;
                            } else {
                                return <SearchQueryField key={index} path={childPath} field={child} />;
                            }
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

const SearchQueryBuilderContext = createContext<{
    fieldDefinitions: IFieldDefinition[];
    searchQuery: ISearchQueryGroup;
    onFieldChange: (path: string, value: Record<string, string>) => void;
    onFieldAdd: (path: string) => void;
    onGroupOperatorChange: (path: string, operator: 'AND' | 'OR') => void;
    onGroupAdd: (path: string) => void;
    onRemove: (path: string) => void;
    onMove: (path: string, direction: 'up' | 'down') => void;
}>({
    fieldDefinitions: [],
    searchQuery: { operator: 'AND', children: [] },
    onFieldChange: () => {},
    onFieldAdd: () => {},
    onGroupOperatorChange: () => {},
    onGroupAdd: () => {},
    onRemove: () => {},
    onMove: () => {},
});

const SearchQueryBuilder = ({
    fieldDefinitions = [],
    value,
    onValueChange,
    className,
    ...props
}: {
    fieldDefinitions: IFieldDefinition[];
    value?: ISearchQueryGroup;
    onValueChange?: (value: ISearchQueryGroup) => void;
} & React.ComponentProps<'div'>) => {
    const [searchQuery, setSearchQuery] = useControllableState<ISearchQueryGroup>(
        {
            operator: 'AND',
            children: [],
        },
        value,
        onValueChange
    );

    const handleFieldChange = (path: string, value: Record<string, string>) => {
        const obj = resolvePath(searchQuery, path);
        if (obj) {
            Object.assign(obj, value);
            setSearchQuery({ ...searchQuery });
        }
    };

    const handleFieldAdd = (path: string) => {
        const obj = resolvePath(searchQuery, path);
        if (obj) {
            obj.children = obj.children || [];
            obj.children.push({ name: '', value: '', operator: '' });
            setSearchQuery({ ...searchQuery });
        }
    };

    const handleGroupOperatorChange = (path: string, operator: 'AND' | 'OR') => {
        const obj = resolvePath(searchQuery, path);
        if (obj) {
            obj.operator = operator;
            setSearchQuery({ ...searchQuery });
        }
    };

    const handleGroupAdd = (path: string) => {
        const obj = resolvePath(searchQuery, path);
        if (obj) {
            obj.children = obj.children || [];
            obj.children.push({ operator: 'AND', children: [] });
            setSearchQuery({ ...searchQuery });
        }
    };

    const handleMove = (path: string, direction: 'up' | 'down') => {
        const parts = path.split('.');
        const parentPath = parts.slice(0, parts.length - 1).join('.');
        const parent = resolvePath(searchQuery, parentPath);
        if (parent) {
            const index = parseInt(parts[parts.length - 1]);
            const newIndex = direction === 'up' ? index - 1 : index + 1;
            if (newIndex >= 0 && newIndex < parent.children.length) {
                const temp = parent.children[index];
                parent.children[index] = parent.children[newIndex];
                parent.children[newIndex] = temp;
                setSearchQuery({ ...searchQuery });
            }
        }
    };

    const handleRemove = (path: string) => {
        const parts = path.split('.');
        const parentPath = parts.slice(0, parts.length - 1).join('.');
        const parent = resolvePath(searchQuery, parentPath);
        if (parent) {
            parent.children.splice(parseInt(parts[parts.length - 1]), 1);
            setSearchQuery({ ...searchQuery });
        }
    };
    return (
        <SearchQueryBuilderContext.Provider
            value={{
                fieldDefinitions,
                searchQuery,
                onFieldChange: handleFieldChange,
                onFieldAdd: handleFieldAdd,
                onGroupOperatorChange: handleGroupOperatorChange,
                onGroupAdd: handleGroupAdd,
                onRemove: handleRemove,
                onMove: handleMove,
            }}
        >
            <SearchQueryGroup path="" group={searchQuery} className={className} {...props} />
        </SearchQueryBuilderContext.Provider>
    );
};

export default SearchQueryBuilder;

export interface SearchQueryInputProps extends React.ComponentPropsWithRef<'textarea'>, WidgetProps {
    fieldDefinitions: IFieldDefinition[];
}

export const SearchQueryInput = ({
    className,
    controls,
    fieldDefinitions = [],
    widgetRef,
    ref,
    ...props
}: SearchQueryInputProps) => {
    const nativeRef = useRef<HTMLTextAreaElement>(null);

    const [value, onChange] = useWidgetState('', props.value, props.onChange);
    let parsedValue; // Declare the variable outside the try/catch
    try {
        parsedValue = JSON.parse(value as string);
    } catch (e) {
        parsedValue = { operator: 'AND', children: [] };
    }

    const handleChange = (value: ISearchQueryGroup) => {
        const searchQueryString = JSON.stringify(value);
        setNativeTextareaValue(nativeRef.current!, searchQueryString);
    };

    return (
        <Widget className={className} readOnly={props.readOnly} disabled={props.disabled} ref={widgetRef}>
            <Widget.Native asChild>
                <textarea {...props} disabled={props.disabled} onChange={onChange} ref={nativeRef} />
            </Widget.Native>

            <Widget.Content className="relative">
                <SearchQueryBuilder
                    fieldDefinitions={fieldDefinitions}
                    value={parsedValue}
                    onValueChange={handleChange}
                />
            </Widget.Content>

            <Widget.Controls>{controls}</Widget.Controls>
        </Widget>
    );
};
