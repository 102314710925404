import React from 'react';

const EditBar = ({ className, children, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div className="bg-base-900 text-white flex justify-center items-center min-h-16 px-6" {...props}>
            <div className="w-full max-w-[1120px] flex justify-center items-center text-sm gap-6">{children}</div>
        </div>
    );
};

export default EditBar;
