import React from 'react';

import { classnames } from '@nicoknoll/utils';

const EmptyState = ({
    className,
    title = 'Keine Einträge',
    description = 'Sobald Einträge verfügbar sind, werden sie hier angezeigt.',
    button,
    ...props
}: React.ComponentPropsWithRef<'div'> & {
    title?: string;
    description?: string;
    button?: React.ReactNode;
}) => {
    return (
        <div className={classnames('flex items-center justify-center w-full h-full select-none', className)} {...props}>
            <div className="flex flex-col gap-4 items-center">
                {title && <span className="text-base-400 font-semibold text-xl">{title}</span>}
                {description && (
                    <span className="text-base-400 max-w-[40rem] text-center text-balance">{description}</span>
                )}

                {button && <div className="mt-4">{button}</div>}
            </div>
        </div>
    );
};

export default EmptyState;
