import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { BooleanField, FileField, Form, SelectField, StringField } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import { ADMIN_PATH } from '../../../../App.tsx';
import { WarningAlert } from '../../../../components/Alert.tsx';
import Button from '../../../../components/Button.tsx';
import { useConfirmationDialogContext } from '../../../../components/ConfirmationDialog.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import {
    userDeleteMutation,
    userDetailQuery,
    userResendConfirmEmailMutation,
    userUpdateMutation,
} from '../../../../data/queries.ts';
import { getOrCreateFileId } from '../../../../utils/files.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';

const ACCOUNT_FORM_DEFAULT_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    languageCode: '',
    image: undefined,
};

const AccountTab = (props: any) => {
    const { t, i18n } = useTranslation('admin');

    const confirm = useConfirmationDialogContext();

    const { data: viewer } = useQuery(userDetailQuery());

    const { mutateAsync: updateUser } = useMutation(userUpdateMutation());
    const { mutateAsync: resendConfirmEmail } = useMutation(userResendConfirmEmailMutation());

    const formMethods = useForm({
        defaultValues: pickFormValues(ACCOUNT_FORM_DEFAULT_VALUES, viewer),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(ACCOUNT_FORM_DEFAULT_VALUES, viewer));
    }, [viewer]);

    const handleResendConfirmEmail = async () => {
        try {
            await resendConfirmEmail({ id: viewer?.id! });
            toast.success(t('userSettingsPage.accountTab.resendConfirmationSuccess'));
        } catch {
            toast.error(t('userSettingsPage.accountTab.resendConfirmationError'));
        }
    };

    const handleSubmit = async (values: any) => {
        try {
            const { image, ...formData } = values;
            const imageId = await getOrCreateFileId(image, { userId: viewer?.id! });
            await updateUser({ id: viewer?.id!, data: { imageId, ...formData } });
            await i18n.changeLanguage(formData.languageCode);
            toast.success(t('userSettingsPage.accountTab.submitSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('userSettingsPage.accountTab.submitError'));
        }
    };

    const { mutateAsync: deleteUser } = useMutation(userDeleteMutation());

    const handleDeleteAccount = async (values: any) => {
        return confirm(t('userSettingsPage.accountTab.deleteConfirmMessage'), '', {
            onConfirm: async () => {
                try {
                    await deleteUser({ id: viewer?.id! });
                    // await logout();
                    toast.success(t('userSettingsPage.accountTab.deleteSuccess'));
                    window.location.href = url(ADMIN_PATH);
                } catch (error) {
                    console.error(error);
                    setFormErrors(t, formMethods, error);
                    toast.error(t('userSettingsPage.accountTab.deleteError'));
                }
            },
            danger: true,
        });
    };

    const [isDeleteAccountChecked, setIsDeleteAccountChecked] = useState(false);

    return (
        <div className="w-full flex flex-col gap-10">
            <Admin.Card className="flex flex-col gap-5">
                <h1 className="text-3xl font-semibold mb-2">{t('userSettingsPage.accountTab.title')}</h1>

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <FormError />
                    <div className="flex gap-5 w-full">
                        <Form.Field name="firstName">
                            <StringField label={t('userSettingsPage.accountTab.firstNameLabel')} className="w-full" />
                        </Form.Field>

                        <Form.Field name="lastName">
                            <StringField label={t('userSettingsPage.accountTab.lastNameLabel')} className="w-full" />
                        </Form.Field>
                    </div>
                    <Form.Field name="email" rules={{ required: t('userSettingsPage.accountTab.emailRequired') }}>
                        <StringField
                            label={t('userSettingsPage.accountTab.emailLabel')}
                            required
                            helpText={t('userSettingsPage.accountTab.emailHelpText')}
                        />
                    </Form.Field>

                    {!viewer?.isEmailConfirmed && (
                        <WarningAlert>
                            <div className="flex justify-between flex-wrap w-full">
                                <span>{t('userSettingsPage.accountTab.notConfirmedNote')}</span>
                                <div>
                                    <Button variant="link" onClick={handleResendConfirmEmail}>
                                        {t('userSettingsPage.accountTab.resendConfirmationButton')}
                                    </Button>
                                </div>
                            </div>
                        </WarningAlert>
                    )}

                    <Form.Field
                        name="languageCode"
                        rules={{ required: t('userSettingsPage.accountTab.languageCodeRequired') }}
                    >
                        <SelectField
                            label={t('userSettingsPage.accountTab.languageCodeLabel')}
                            helpText={t('userSettingsPage.accountTab.languageCodeHelpText')}
                            className="w-full"
                            options={[
                                {
                                    value: 'en',
                                    // @ts-ignore
                                    label: t('common:languageCode.en'),
                                },
                                {
                                    value: 'de',
                                    // @ts-ignore
                                    label: t('common:languageCode.de'),
                                },
                            ]}
                            hideSearch
                            required
                        />
                    </Form.Field>

                    <Form.Field name="image">
                        <FileField
                            label={t('userSettingsPage.accountTab.imageLabel')}
                            helpText={t('userSettingsPage.accountTab.imageHelpText')}
                            placeholder={t('userSettingsPage.accountTab.imagePlaceholder')}
                            previewImage
                        />
                    </Form.Field>
                    <div className="flex justify-end">
                        <FormSubmitButton variant="primary">
                            {t('userSettingsPage.accountTab.submitButton')}
                        </FormSubmitButton>
                    </div>
                </Form>
            </Admin.Card>

            <Admin.Card className="flex flex-col gap-5">
                <h1 className="text-3xl font-semibold text-red-700">{t('userSettingsPage.accountTab.deleteTitle')}</h1>

                <p className="text-base-700 text-pretty">{t('userSettingsPage.accountTab.deleteDescription')}</p>

                <div className="flex-1 flex justify-between items-center flex-wrap gap-5 rounded-xl bg-base-50 p-5">
                    <BooleanField
                        label={t('userSettingsPage.accountTab.deleteAccountLabel')}
                        name="deleteAccount"
                        // @ts-ignore
                        value={isDeleteAccountChecked}
                        // @ts-ignore
                        onChange={(e) => setIsDeleteAccountChecked(e.target.checked)}
                    />
                </div>

                <div className="flex justify-end">
                    <Button
                        variant="primary"
                        className="bg-red-600 hover:bg-red-700 active:bg-red-800"
                        disabled={!isDeleteAccountChecked}
                        onClick={handleDeleteAccount}
                    >
                        {t('userSettingsPage.accountTab.deleteButton')}
                    </Button>
                </div>
            </Admin.Card>
        </div>
    );
};

export default AccountTab;
