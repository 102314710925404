import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Form, StringField } from '@nicoknoll/forms';
import { useMutation } from '@tanstack/react-query';

import { SuccessAlert } from '../../../components/Alert.tsx';
import FormError from '../../../components/FormError.tsx';
import FormSubmitButton from '../../../components/FormSubmitButton.tsx';
import PasswordInput from '../../../components/PasswordInput.tsx';
import { setFormErrors } from '../../../utils/forms.ts';
import url from '../../../utils/url';
import Auth from '../components/Auth.tsx';
import { confirmInvitationMutation } from '../queries.ts';
import { ADMIN_LOGIN_PATH } from '../routes.tsx';

const CONFIRM_INVITATION_FORM_DEFAULT_VALUES = {
    newPassword: '',
    confirmPassword: '',
};

const ConfirmInvitationPage = () => {
    const { t } = useTranslation('auth');

    const formMethods = useForm({ defaultValues: { ...CONFIRM_INVITATION_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    const { token } = useParams();
    const { mutateAsync: confirmInvitation } = useMutation(confirmInvitationMutation());

    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleSubmit = async (values: typeof CONFIRM_INVITATION_FORM_DEFAULT_VALUES) => {
        try {
            await confirmInvitation({
                token: token!,
                data: {
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmPassword,
                },
            });
            toast.success(t('confirmInvitationPage.submitSuccess'));
            setIsConfirmed(true);
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('confirmInvitationPage.submitError'));
        }
    };

    return (
        <Auth>
            <Auth.Card>
                <Auth.CardContent>
                    <h2 className="text-3xl font-semibold">{t('confirmInvitationPage.title')}</h2>
                    {isConfirmed ? (
                        <>
                            <SuccessAlert>{t('confirmInvitationPage.successAlert')}</SuccessAlert>
                        </>
                    ) : (
                        <>
                            <p className="text-base-800 text-base">{t('confirmInvitationPage.description')}</p>

                            <Form
                                formMethods={formMethods}
                                onSubmit={handleSubmit}
                                className="flex-1 flex flex-col gap-5"
                            >
                                <FormError />

                                <Form.Field
                                    name="newPassword"
                                    rules={{ required: t('confirmInvitationPage.newPasswordRequired') }}
                                >
                                    <StringField
                                        label={t('confirmInvitationPage.newPasswordLabel')}
                                        widget={PasswordInput}
                                        required
                                    />
                                </Form.Field>
                                <Form.Field
                                    name="confirmPassword"
                                    rules={{ required: t('confirmInvitationPage.confirmPasswordRequired') }}
                                >
                                    <StringField
                                        label={t('confirmInvitationPage.confirmPasswordLabel')}
                                        widget={PasswordInput}
                                        required
                                    />
                                </Form.Field>
                                <FormSubmitButton variant="primary" className="mt-3">
                                    {t('confirmInvitationPage.submitButton')}
                                </FormSubmitButton>
                            </Form>
                        </>
                    )}
                </Auth.CardContent>
                <Auth.CardFooter>
                    <Link
                        to={url(ADMIN_LOGIN_PATH)}
                        className="text-sm text-theme-500 underline underline-offset-2 hover:text-theme-700 transition-colors"
                    >
                        {t('confirmInvitationPage.backToLogin')}
                    </Link>
                </Auth.CardFooter>
            </Auth.Card>
        </Auth>
    );
};

export default ConfirmInvitationPage;
