export const useMetaTag = (name: string): [() => string, (value: string) => void] => {
    const getValue = () => {
        const metaTag = document.querySelector(`meta[name="${name}"]`);
        return metaTag?.getAttribute('content') || '';
    };

    const setValue = (value: string) => {
        const metaTag = document.querySelector(`meta[name="${name}"]`);
        if (metaTag) {
            metaTag.setAttribute('content', value);
        } else {
            const newMetaTag = document.createElement('meta');
            newMetaTag.name = name;
            newMetaTag.content = value;
            document.head.appendChild(newMetaTag);
        }
    };

    return [getValue, setValue];
};

export const useDocumentTitle = (): [() => string, (value: string) => void] => {
    const getValue = () => {
        const titleTag = document.querySelector('title');
        return titleTag?.innerHTML || document.title || '';
    };

    const setValue = (value: string) => {
        const titleTag = document.querySelector('title');
        if (titleTag) {
            titleTag.innerHTML = value;
        } else {
            const newTitleTag = document.createElement('title');
            newTitleTag.innerHTML = value;
            document.head.appendChild(newTitleTag);
        }
        document.title = value;
    };

    return [getValue, setValue];
};
