import {
    userDetailQuery,
    userEducationDetailQuery,
    userEducationListQuery,
    userExperienceDetailQuery,
    userExperienceListQuery,
    userNotificationSettingsListQuery,
    userProfileDetailQuery,
} from '../../../data/queries.ts';
import ensureQueriesData from '../../../utils/ensureQueriesData.ts';

export const accountTabLoader = () =>
    ensureQueriesData({
        organization: userDetailQuery(),
    });

export const contactTabLoader = () =>
    ensureQueriesData({
        userProfile: userProfileDetailQuery(),
    });

export const educationEditLoader = ({ params: { educationId } }: any) =>
    ensureQueriesData({
        education: userEducationDetailQuery(educationId),
    });

export const educationTabLoader = () =>
    ensureQueriesData({
        profile: userProfileDetailQuery(),
    }).then((res: any) =>
        ensureQueriesData({
            educationList: userEducationListQuery({ userProfileId: res.profile.id }),
        })
    );

export const experienceEditLoader = ({ params: { experienceId } }: any) =>
    ensureQueriesData({
        experience: userExperienceDetailQuery(experienceId),
    });

export const experienceTabLoader = () =>
    ensureQueriesData({
        profile: userProfileDetailQuery(),
    }).then((res: any) =>
        ensureQueriesData({
            experienceList: userExperienceListQuery({ userProfileId: res.profile.id }),
        })
    );

export const notificationsTabLoader = () =>
    ensureQueriesData({
        user: userDetailQuery(),
    }).then((res: any) =>
        ensureQueriesData({
            experienceList: userNotificationSettingsListQuery({ userId: res.user.id }),
        })
    );

export const generalTabLoader = () =>
    ensureQueriesData({
        user: userDetailQuery(),
        userProfile: userProfileDetailQuery(),
    });
