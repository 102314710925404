import { CalendarIcon, ClockIcon, MapPinIcon } from 'lucide-react';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../components/Button.tsx';
import Tooltip from '../../../components/Tooltip.tsx';
import { EventSession, LogEventType } from '../../../data/models.ts';
import {
    eventDetailQuery,
    eventSessionDetailQuery,
    eventSessionUserCreateMutation,
    eventSessionUserDeleteMutation,
    eventSessionUserListQuery,
    organizationProfileDetailQuery,
    userDetailQuery,
} from '../../../data/queries.ts';
import { isRegularUser } from '../../../utils/loaders.ts';
import { formatDate, formatTime } from '../../../utils/time.ts';
import url from '../../../utils/url';
import { useLogEventOnMount } from '../../../utils/useLogEvent.ts';
import { ADMIN_LOGIN_PATH } from '../../auth/routes.tsx';
import InfoBox from '../components/InfoBox.tsx';
import OrganizationProfileCard from '../components/OrganizationProfileCard.tsx';
import { SaveEventSessionButton } from '../components/SaveEventSessionButton.tsx';
import { useEventId } from '../utils.ts';

const SessionUsersCard = ({ session }: { session: EventSession }) => {
    const { t } = useTranslation('site');

    const navigate = useNavigate();

    const { data: viewer } = useQuery(userDetailQuery());
    const { data: eventSessionUsers } = useQuery(
        eventSessionUserListQuery({ eventSessionId: session.id, userId: viewer?.id! })
    );
    const eventSessionUser = eventSessionUsers?.[0];

    const { mutateAsync: attend } = useMutation(eventSessionUserCreateMutation());
    const handleAttend = async () => {
        if (!viewer) {
            toast.error(t('sessionUsersCard.attendNotAuthenticatedError'));
            return navigate(`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`);
        }

        try {
            await attend({ data: { eventSessionId: session.id, userId: viewer?.id } });
            toast.success(t('sessionUsersCard.attendSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('sessionUsersCard.attendError'));
        }
    };

    const { mutateAsync: unattend } = useMutation(eventSessionUserDeleteMutation());
    const handleUnattend = async () => {
        try {
            await unattend({ id: eventSessionUser?.id! });
            toast.success(t('sessionUsersCard.unattendSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('sessionUsersCard.unattendError'));
        }
    };

    const isParticipating = session?.userIds?.includes(viewer?.id!);

    return (
        <div className="bg-theme-50 rounded-xl p-8 flex flex-col gap-3">
            <h3 className="font-semibold text-theme-700">
                {t('sessionUsersCard.title')} ({session?.userIds?.length || 0} / {session?.maxUsers})
            </h3>
            <p className="text-base-800 text-balance">
                {t('sessionUsersCard.description', { limit: session.maxUsers })}
            </p>

            <div className="flex justify-start mt-2">
                {!isParticipating ? (
                    viewer && !isRegularUser(viewer) ? (
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <Button className="text-base disabled:cursor-not-allowed" variant="primary" disabled>
                                    {t('sessionUsersCard.attendButton')}
                                </Button>
                            </Tooltip.Trigger>
                            <Tooltip.Content> {t('sessionUsersCard.attendTooltip')}</Tooltip.Content>
                        </Tooltip>
                    ) : (
                        <Button
                            className="text-base"
                            variant="primary"
                            onClick={handleAttend}
                            disabled={Boolean(
                                session?.userIds?.length &&
                                    session?.maxUsers &&
                                    session?.userIds?.length >= session?.maxUsers
                            )}
                        >
                            {t('sessionUsersCard.attendButton')}
                        </Button>
                    )
                ) : (
                    <Button
                        className="text-base bg-white hover:bg-base-200 active:bg-base-300"
                        variant="ghost"
                        onClick={handleUnattend}
                    >
                        {t('sessionUsersCard.unattendButton')}
                    </Button>
                )}
            </div>
        </div>
    );
};

const SessionDetailPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();

    const { sessionId } = useParams();
    const { data: session } = useQuery(eventSessionDetailQuery(sessionId!));
    const { data: event } = useQuery(eventDetailQuery(eventId!));

    const { data: organizationProfile } = useQuery({
        ...organizationProfileDetailQuery(session?.organizationProfileId!),
        enabled: !!session?.organizationProfileId,
    });

    useLogEventOnMount(LogEventType.EVENT_SESSION_VIEW, { targetId: sessionId });

    return (
        <div className={className} {...props}>
            <main className="flex justify-center bg-white px-6 py-24">
                <div className="w-full max-w-[1120px] flex flex-col gap-32">
                    <section className="flex gap-10 -mb-6">
                        <div className="flex flex-col gap-24 w-8/12">
                            <section className="flex flex-col gap-10">
                                <h1 className="font-semibold text-5xl text-balance leading-snug">{session?.title}</h1>

                                {session?.maxUsers && <SessionUsersCard session={session} />}

                                <div
                                    className="styled"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            session?.description ||
                                            session?.summary ||
                                            `<p>${t('sessionDetailPage.noDescription')}</p>`,
                                    }}
                                />
                            </section>

                            {organizationProfile && (
                                <OrganizationProfileCard organizationProfile={organizationProfile} />
                            )}
                        </div>

                        <div className="flex flex-col gap-12 w-4/12">
                            <InfoBox badge={<InfoBox.Badge className="bg-base-500">Details</InfoBox.Badge>}>
                                <div className="flex flex-col gap-3">
                                    <InfoBox.Row
                                        icon={<CalendarIcon />}
                                        label={t('sessionDetailPage.date')}
                                        value={
                                            session?.startDatetime &&
                                            formatDate(session?.startDatetime, event?.timezoneName)
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<ClockIcon />}
                                        label={t('sessionDetailPage.time')}
                                        value={
                                            session?.startDatetime &&
                                            session?.endDatetime &&
                                            `${formatTime(session?.startDatetime, event?.timezoneName)} - ${formatTime(
                                                session?.endDatetime,
                                                event?.timezoneName
                                            )}`
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<MapPinIcon />}
                                        label={t('sessionDetailPage.location')}
                                        value={session?.location}
                                    />
                                </div>
                            </InfoBox>

                            <div className="rounded-xl flex flex-col gap-5 px-7">
                                <SaveEventSessionButton eventSession={session!} eventId={eventId!} />
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default SessionDetailPage;
