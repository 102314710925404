import {
    BriefcaseBusinessIcon,
    BuildingIcon,
    CalendarRangeIcon,
    ExternalLinkIcon,
    EyeIcon,
    EyeOffIcon,
    MailIcon,
    UsersRoundIcon,
} from 'lucide-react';
import React from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { SingleSelect, Switch } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
    eventListQuery,
    organizationDetailQuery,
    organizationProfileDetailQuery,
    organizationProfileListQuery,
    organizationProfileUpdateMutation,
} from '../../../../data/queries.ts';
import url from '../../../../utils/url';
import { SITE_ORGANIZATION_DETAIL_PATH } from '../../../site/routes.tsx';
import { siteUrl } from '../../../site/utils.ts';
import Admin from '../../components/Admin.tsx';
import {
    ADMIN_ORGANIZATION_PROFILE_CONTACT_PATH,
    ADMIN_ORGANIZATION_PROFILE_CONTACT_PERSONS_PATH,
    ADMIN_ORGANIZATION_PROFILE_EVENT_SESSIONS_PATH,
    ADMIN_ORGANIZATION_PROFILE_GENERAL_PATH,
    ADMIN_ORGANIZATION_PROFILE_JOBS_PATH,
} from '../routes.tsx';

const OrganizationProfileDetailPage = (props: any) => {
    const { t } = useTranslation('admin');

    const { profileId } = useParams();

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());

    const { data: profiles } = useQuery(organizationProfileListQuery({ organizationId: viewerOrganization?.id! }));
    const { data: events } = useQuery(eventListQuery());

    const profilesEventIds = profiles?.map((profile) => profile.eventId) || [];
    const organizationEvents = events?.filter((event) => profilesEventIds.includes(event.id)) || [];

    const { data: profile } = useQuery(organizationProfileDetailQuery(profileId!));
    const eventId = profile?.eventId;
    const event = events?.find((event) => event.id === eventId);

    const { mutateAsync: updateProfile } = useMutation(organizationProfileUpdateMutation());

    const handleVisibilityChange = async (e: any) => {
        const value = e.target.checked;

        await updateProfile({ id: profileId!, data: { isPublic: value } });

        if (value) {
            toast(
                <span>
                    <Trans t={t} i18nKey="organizationProfileDetailPage.visibilityChangePublic" />
                </span>,
                { icon: <EyeIcon /> }
            );
        } else {
            toast(
                <span>
                    <Trans t={t} i18nKey="organizationProfileDetailPage.visibilityChangePrivate" />
                </span>,
                { icon: <EyeOffIcon /> }
            );
        }
    };

    const navigate = useNavigate();

    const handleEventChange = (e: any) => {
        const eventId = e.target.value;
        const profileId = profiles?.find((profile) => profile.eventId === eventId)?.id;
        navigate(url(ADMIN_ORGANIZATION_PROFILE_GENERAL_PATH, { profileId }));
    };

    return (
        <div className="flex gap-8 h-full" key={profileId}>
            <Admin.Navigation className="pt-7">
                <Admin.NavigationItem asChild>
                    <div className="!block hover:!bg-transparent w-full">
                        <SingleSelect
                            options={
                                organizationEvents.map((event) => ({
                                    label: event.name,
                                    value: event.id,
                                })) || []
                            }
                            onChange={handleEventChange}
                            value={profiles?.find((profile) => profile.id === profileId)?.eventId}
                            required
                            hideSearch
                        />
                    </div>
                </Admin.NavigationItem>

                <Admin.NavigationSeparator />

                <Admin.NavigationLabel>
                    {t('organizationProfileDetailPage.navigation.visibility')}
                </Admin.NavigationLabel>

                <Admin.NavigationItem asChild>
                    <label className="flex justify-between items-center">
                        <span>{t('organizationProfileDetailPage.navigation.visibilityToggleLabel')}</span>
                        <Switch className="-my-0.5" onChange={handleVisibilityChange} checked={profile?.isPublic} />
                    </label>
                </Admin.NavigationItem>
                <Admin.NavigationItem asChild>
                    <a
                        className="flex justify-between items-center"
                        href={siteUrl(SITE_ORGANIZATION_DETAIL_PATH, {
                            organizationId: profile?.organizationId,
                            eventId: event?.slug || event?.id || eventId,
                        })}
                        target="_blank"
                    >
                        <span>{t('organizationProfileDetailPage.navigation.viewProfile')}</span>
                        <ExternalLinkIcon />
                    </a>
                </Admin.NavigationItem>

                <Admin.NavigationSeparator />

                <Admin.NavigationLabel>
                    {t('organizationProfileDetailPage.navigation.organizationProfile')}
                </Admin.NavigationLabel>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_PROFILE_GENERAL_PATH, { profileId })}>
                    <span className="flex gap-2 items-center">
                        <BuildingIcon /> {t('organizationProfileDetailPage.navigation.general')}
                    </span>
                </Admin.NavigationLink>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_PROFILE_CONTACT_PATH, { profileId })}>
                    <span className="flex gap-2 items-center">
                        <MailIcon /> {t('organizationProfileDetailPage.navigation.contact')}
                    </span>
                </Admin.NavigationLink>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_PROFILE_CONTACT_PERSONS_PATH, { profileId })}>
                    <span className="flex gap-2 items-center">
                        <UsersRoundIcon /> {t('organizationProfileDetailPage.navigation.contactPersons')}
                    </span>
                </Admin.NavigationLink>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_PROFILE_JOBS_PATH, { profileId })}>
                    <span className="flex gap-2 items-center">
                        <BriefcaseBusinessIcon /> {t('organizationProfileDetailPage.navigation.jobs')}
                    </span>
                </Admin.NavigationLink>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_PROFILE_EVENT_SESSIONS_PATH, { profileId })}>
                    <CalendarRangeIcon /> {t('organizationProfileDetailPage.navigation.sessions')}
                </Admin.NavigationLink>
            </Admin.Navigation>

            <Admin.Content>
                <Outlet />
            </Admin.Content>
        </div>
    );
};

export default OrganizationProfileDetailPage;
