import { range } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { classnames } from '@nicoknoll/utils';
import { Slot } from '@radix-ui/react-slot';

import { Slottable } from './Button.tsx';

export interface Session {
    id: string;
    startDatetime: string;
    endDatetime: string;
    [key: string]: any;
}

export interface ColumnSession<T extends Session = Session> {
    session: T;
    columnIndex: number;
    columnSpan: number;
    stackIndex: number;
    startRow: number;
    endRow: number;
}

export const WINDOW_SIZE = 30;

export const getDaysBetween = (start: Date | string, end: Date | string): Date[] => {
    const days: Date[] = [];
    start = new Date(start);
    start.setHours(0, 0, 0, 0);
    end = new Date(end);
    end.setHours(0, 0, 0, 0);
    let currentDate = start;
    while (currentDate <= end) {
        days.push(new Date(currentDate));
        currentDate = new Date(currentDate);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;
};

export const getMinutesFromDateTime = (datetime: string | Date): number => {
    // timestamp in minutes
    const date = new Date(datetime);
    return Math.round(date.getTime() / 1000 / 60);
};

export const isOverlapping = (sessionA: ColumnSession, sessionB: ColumnSession): boolean => {
    // test if overlapping based on start and end
    return sessionA.startRow < sessionB.endRow && sessionB.startRow < sessionA.endRow;
};

export const getOverlappingMinutes = (sessionA: ColumnSession, sessionB: ColumnSession): number => {
    // overlapping minutes based on start time
    return sessionA.startRow < sessionB.startRow
        ? sessionB.startRow - sessionA.startRow
        : sessionA.startRow - sessionB.startRow;
};

export const shouldPlaceInColumn = (column: ColumnSession[], session: ColumnSession): boolean => {
    // return true when the smallest difference between start times is bigger than WINDOW_SIZE
    return getOverlappingMinutes(column[column.length - 1], session) > WINDOW_SIZE;
};

export const getStackIndex = (sessions: ColumnSession[], session: ColumnSession): number => {
    // for all colliding sessions get the biggest stack index + 1
    let stackIndex = 0;
    for (const collidingSession of sessions) {
        if (isOverlapping(collidingSession, session)) {
            stackIndex = Math.max(stackIndex, collidingSession.stackIndex + 1);
        }
    }
    return stackIndex;
};

export const layoutDayCalendar = <T extends Session>(sessions: T[], date?: Date): ColumnSession<T>[] => {
    if (!date) {
        const firstSession = sessions?.sort(
            (a, b) => getMinutesFromDateTime(a.startDatetime) - getMinutesFromDateTime(b.startDatetime)
        )[0];

        if (firstSession) {
            date = new Date(firstSession.startDatetime);
            date.setHours(0, 0, 0, 0);
        }
    }

    const firstDayMinutes = date ? getMinutesFromDateTime(date) : 0;

    const columns: ColumnSession<T>[][] = [];

    // Sort by start time, then by end time
    const sortedColumnSessions: ColumnSession<T>[] = [...sessions]
        .filter(
            (session) =>
                getMinutesFromDateTime(session.endDatetime) - firstDayMinutes >= 0 &&
                getMinutesFromDateTime(session.startDatetime) - firstDayMinutes <= 1440
        )
        .map((session) => ({
            session,
            startRow: Math.max(0, getMinutesFromDateTime(session.startDatetime) - firstDayMinutes),
            endRow: Math.min(1440, getMinutesFromDateTime(session.endDatetime) - firstDayMinutes),
            columnIndex: 0,
            columnSpan: 1,
            stackIndex: 0,
        }))
        .sort((a, b) => (a.startRow !== b.startRow ? a.startRow - b.startRow : a.endRow - b.endRow));

    // foreach column test if we can place it if not create a new column to place it
    for (const columnSession of sortedColumnSessions) {
        let isPlaced = false;

        // test if we can place it in each column
        for (const [columnIndex, column] of columns.entries()) {
            if (shouldPlaceInColumn(column, columnSession)) {
                column.push({
                    ...columnSession,
                    columnIndex,
                    stackIndex: getStackIndex(column, columnSession),
                });
                isPlaced = true;
                break;
            }
        }

        if (!isPlaced) {
            // if it doesn't fit in any column create a new column
            columns.push([{ ...columnSession, columnIndex: columns.length }]);
        }
    }

    // now we need to adjust columnSpan
    for (const column of columns) {
        // for each item see how many columns it can span until a collision happens
        for (const columnSession of column) {
            let columnSpan = 1;

            for (let columnIndex = columnSession.columnIndex + 1; columnIndex < columns.length; columnIndex++) {
                const nextColumn = columns[columnIndex];
                if (isOverlapping(columnSession, nextColumn[nextColumn.length - 1])) {
                    break;
                }
                columnSpan++;
            }
            columnSession.columnSpan = columnSpan;
        }
    }

    return columns.flat();
};

const Schedule = ({ children, style, className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const columnSessions = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return (child.props as any).columnSession;
        }
    })?.filter(Boolean) as ColumnSession[];

    const maxColumnSpan = columnSessions.reduce(
        (max, session) => Math.max(max, session.columnIndex + session.columnSpan),
        0
    );

    return (
        <div
            className="grid grid-rows-[1440] gap-x-2 relative z-0"
            style={{
                gridTemplateColumns: '28px repeat(auto-fit, minmax(0, 1fr))',
                gridTemplateRows: 'repeat(1440, 1px)',
            }}
        >
            {range(0, 25).map((hour) => (
                <React.Fragment key={hour}>
                    <div
                        className="border-b border-base-150"
                        style={{ gridRow: `${hour * 60 + 1}`, gridColumn: `2 / ${2 + maxColumnSpan}` }}
                    />
                    <div
                        key={hour}
                        style={{
                            gridRow: `${hour * 60 + 1} / ${(hour + 1) * 60 + 1}`,
                            gridColumn: 1,
                        }}
                    >
                        <span className="text-xs text-base-500 block -translate-y-1/2 -mt-px tabular-nums text-center w-full">
                            {hour.toString().padStart(2, '0')}
                        </span>
                    </div>
                </React.Fragment>
            ))}

            {children}
        </div>
    );
};

const ScheduleColumnSession = ({
    columnSession,
    style,
    ...props
}: React.ComponentPropsWithRef<'div'> & { columnSession: ColumnSession }) => (
    <div
        style={{
            gridColumn: `${columnSession.columnIndex + 2} / span ${columnSession.columnSpan}`,
            gridRow: `${columnSession.startRow + 1} / ${columnSession.endRow + 1}`,
            marginLeft: columnSession.stackIndex * 10,
            zIndex: columnSession.startRow,
            ...style,
        }}
        {...props}
    />
);

const Session = ({
    className,
    asChild,
    disabled,
    highlighted,
    ...props
}: React.ComponentPropsWithRef<'div'> & Slottable & { disabled?: boolean; highlighted?: boolean }) => {
    const Comp = asChild ? Slot : 'div';
    return (
        <Comp
            className={classnames(
                'flex flex-col rounded p-2 h-full bg-base-300/30 border-l-2 border-base-300 transition-colors',
                !disabled ? 'cursor-pointer hover:bg-base-300/50' : 'opacity-50',
                highlighted && 'bg-blue-300/30 border-blue-300',
                !disabled && highlighted && 'hover:bg-blue-300/50',
                className
            )}
            {...props}
        />
    );
};

const SessionDate = ({ className, datetime, ...props }: React.ComponentPropsWithRef<'div'> & { datetime: Date }) => {
    const { i18n } = useTranslation('components');
    return (
        <span className={classnames('text-sm', className)} {...props}>
            {new Date(datetime).toLocaleTimeString(i18n.language, { hour: '2-digit', minute: '2-digit' })}
        </span>
    );
};

const SessionTitle = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <span className={classnames('font-medium text-sm', className)} {...props} />
);

export default Object.assign(Schedule, { ColumnSession: ScheduleColumnSession, Session, SessionDate, SessionTitle });
