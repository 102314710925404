import {
    ArrowRightIcon,
    BoldIcon,
    CodeIcon,
    ExternalLinkIcon,
    Heading1Icon,
    Heading2Icon,
    Heading3Icon,
    Heading4Icon,
    Heading5Icon,
    ImageIcon,
    ItalicIcon,
    LinkIcon,
    ListIcon,
    ListOrderedIcon,
    MinusIcon,
    PilcrowIcon,
    QuoteIcon,
    Redo2Icon,
    StrikethroughIcon,
    UnderlineIcon,
    Undo2Icon,
    VideoIcon,
} from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Field,
    Form,
    SelectField,
    StringField,
    Widget,
    WidgetProps,
    setNativeTextareaValue,
    useWidgetState,
} from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { Color } from '@tiptap/extension-color';
import { default as ImageExtension } from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Youtube from '@tiptap/extension-youtube';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { UploadedFile } from '../data/models.ts';
import { ADMIN_ORGANIZATION_SETTINGS_FILES_PATH } from '../modules/admin/organization-settings/routes.tsx';
import { filterImage, isImage } from '../utils/files.ts';
import url from '../utils/url';
import Anchor from './Anchor.tsx';
import Button from './Button.tsx';
import Image from './Image.tsx';
import Popover from './Popover.tsx';
import Tooltip from './Tooltip.tsx';

const MenuBarSeparator = () => <div className="h-5 w-px bg-base-300 mx-2" />;

const MenuBarGroup = ({ children }: { children: React.ReactNode }) => {
    return <div className="flex gap-0.5 items-center">{children}</div>;
};

const MenuBarButton = ({
    active,
    disabled,
    label,
    ...props
}: React.ComponentPropsWithRef<'button'> & { active?: boolean; label: string; disabled?: boolean }) => {
    return (
        <Tooltip>
            <Tooltip.Trigger asChild>
                <button
                    {...props}
                    type="button"
                    disabled={disabled}
                    className={classnames(
                        'px-2 py-1.5 text-sm font-medium rounded focus:outline-none focus:ring focus:ring-blue-500 data-[state=open]:bg-blue-600 data-[state=open]:text-white',
                        active && 'bg-blue-600 text-white',
                        disabled && 'text-base-400',
                        active && disabled && 'bg-base-400 text-white'
                    )}
                />
            </Tooltip.Trigger>
            <Tooltip.Content>{label}</Tooltip.Content>
        </Tooltip>
    );
};

const LINK_FORM_DEFAULT_VALUES = {
    href: '',
    fileUrl: '',
};

const LinkPopoverContent = ({
    href: propsHref,
    onSubmit,
    onRemove,
    files,
}: {
    href?: string;
    onSubmit: (values: { href: string }) => void;
    onRemove: () => void;
    files?: UploadedFile[];
}) => {
    const { t } = useTranslation('components');

    const formMethods = useForm({ defaultValues: { ...LINK_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    useEffect(() => {
        const values = { ...LINK_FORM_DEFAULT_VALUES, href: propsHref };
        if (files && files.length > 0 && files.map((file) => file.url).includes(propsHref || '')) {
            // only set value if url is a file
            values['fileUrl'] = propsHref || '';
        }
        formMethods.reset(values);
    }, [propsHref, files]);

    let hostname = propsHref;
    try {
        hostname = propsHref ? new URL(propsHref).hostname : '';
    } catch (e) {}

    return (
        <Popover.Content align="end" className="w-[30rem]">
            <Form
                formMethods={formMethods}
                onSubmit={(values) => onSubmit({ href: values.href })}
                className="flex flex-col gap-3 w-full"
            >
                {files != null && (
                    <Form.Field name="fileUrl">
                        <SelectField
                            label={
                                <span className="flex justify-betwee gap-2 items-center">
                                    <span>{t('wysiwygInput.linkPopover.uploadedFilesLabel')}</span>

                                    <Anchor
                                        className="ml-auto flex gap-1 items-center font-normal"
                                        href={url(ADMIN_ORGANIZATION_SETTINGS_FILES_PATH)}
                                        target="_blank"
                                    >
                                        <span>{t('wysiwygInput.linkPopover.manageFiles')}</span>
                                        <ArrowRightIcon />
                                    </Anchor>
                                </span>
                            }
                            placeholder={t('wysiwygInput.linkPopover.uploadedFilesPlaceholder')}
                            options={files?.map((file) => ({ label: file.name, value: file.url })) || []}
                            onChange={(e) => {
                                formMethods.setValue('href', e.target.value);
                            }}
                        />
                    </Form.Field>
                )}

                <Form.Field name="href">
                    <StringField
                        label={t('wysiwygInput.linkPopover.hrefLabel')}
                        placeholder={t('wysiwygInput.linkPopover.hrefPlaceholder')}
                        onChange={(e) => {
                            formMethods.setValue('fileUrl', '');
                        }}
                        required
                    />
                </Form.Field>

                {hostname && (
                    <Anchor href={propsHref} target="_blank" className="flex gap-1 text-sm">
                        {t('wysiwygInput.linkPopover.openUrl', { url: hostname })} <ExternalLinkIcon />
                    </Anchor>
                )}

                <div className="flex gap-2 justify-end">
                    <Button variant="ghost" onClick={onRemove} disabled={!propsHref}>
                        {t('wysiwygInput.linkPopover.removeButton')}
                    </Button>

                    <Button variant="primary">{t('wysiwygInput.linkPopover.submitButton')}</Button>
                </div>
            </Form>
        </Popover.Content>
    );
};

const IMAGE_FORM_DEFAULT_VALUES = {
    src: '',
    alt: '',
    width: '',
    height: '',
};

const ImagePopoverContent = ({
    src: propsSrc,
    alt: propsAlt,
    width: propsWidth,
    height: propsHeight,
    onSubmit,
    files,
}: {
    src?: string;
    alt?: string;
    width?: string;
    height?: string;
    onSubmit: (values: { src: string; alt: string; width: string; height: string }) => void;
    files?: UploadedFile[];
}) => {
    const { t } = useTranslation('components');

    const images = files?.filter(filterImage);

    const formMethods = useForm({ defaultValues: { ...IMAGE_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    useEffect(() => {
        formMethods.reset({
            ...IMAGE_FORM_DEFAULT_VALUES,
            src: propsSrc || '',
            alt: propsAlt || '',
            width: propsWidth || '',
            height: propsHeight || '',
        });
    }, [propsSrc, propsAlt, propsWidth, propsHeight]);

    return (
        <Popover.Content align="end" className="flex flex-col gap-5 w-[37rem]">
            {images != null && (
                <div className="flex flex-col gap-3 flex-1">
                    <Field.Label className="flex justify-between gap-2 items-center">
                        <span>{t('wysiwygInput.imagePopover.uploadedImagesLabel')}</span>
                        <Anchor
                            className="flex gap-1 items-center font-normal"
                            href={url(ADMIN_ORGANIZATION_SETTINGS_FILES_PATH)}
                            target="_blank"
                        >
                            <span>{t('wysiwygInput.imagePopover.manageImages')}</span>
                            <ArrowRightIcon />
                        </Anchor>
                    </Field.Label>
                    {images && images.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                            {images?.map((image) => (
                                <button
                                    onClick={() => {
                                        formMethods.setValue('src', image.url);
                                        formMethods.setValue('alt', image.name);
                                    }}
                                    key={image.id}
                                >
                                    <Image
                                        src={image.url}
                                        alt={image.name}
                                        className="w-12 h-12 object-cover rounded hover:opacity-50 transition-opacity"
                                    />
                                </button>
                            ))}
                        </div>
                    ) : (
                        <div className="text-base-400 text-center text-sm p-5">
                            {t('wysiwygInput.imagePopover.noImages')}
                        </div>
                    )}
                </div>
            )}

            <Form formMethods={formMethods} onSubmit={onSubmit} className="flex flex-col gap-3 w-full">
                <div
                    className={classnames(
                        'flex flex-col gap-3 w-full',
                        images != null && 'bg-base-50 p-5 pt-3 pb-6 rounded-lg'
                    )}
                >
                    <Form.Field name="src">
                        <StringField
                            label={t('wysiwygInput.imagePopover.srcLabel')}
                            placeholder={t('wysiwygInput.imagePopover.srcPlaceholder')}
                            required
                        />
                    </Form.Field>

                    <div className="flex gap-8">
                        <Form.Field name="alt">
                            <StringField
                                label={t('wysiwygInput.imagePopover.altLabel')}
                                placeholder={t('wysiwygInput.imagePopover.altPlaceholder')}
                                className="flex-1 min-w-0"
                            />
                        </Form.Field>

                        <div className="flex gap-3">
                            <Form.Field name="width">
                                <StringField
                                    label={t('wysiwygInput.imagePopover.widthLabel')}
                                    placeholder={t('wysiwygInput.imagePopover.widthPlaceholder')}
                                    className="min-w-0 w-24"
                                />
                            </Form.Field>

                            <Form.Field name="height">
                                <StringField
                                    label={t('wysiwygInput.imagePopover.heightLabel')}
                                    name="height"
                                    placeholder={t('wysiwygInput.imagePopover.heightPlaceholder')}
                                    className="min-w-0 w-24"
                                />
                            </Form.Field>
                        </div>
                    </div>
                </div>

                <div className="flex gap-2 justify-end">
                    <Button variant="primary">{t('wysiwygInput.imagePopover.submitButton')}</Button>
                </div>
            </Form>
        </Popover.Content>
    );
};

const VIDEO_FORM_DEFAULT_VALUES = {
    src: '',
    width: '',
    height: '',
};

const VideoPopoverContent = ({
    src: propsSrc,
    width: propsWidth,
    height: propsHeight,
    onSubmit,
}: {
    src?: string;
    width?: string;
    height?: string;
    onSubmit: (values: { src: string; width: string; height: string }) => void;
}) => {
    const { t } = useTranslation('components');

    const formMethods = useForm({ defaultValues: { ...VIDEO_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    useEffect(() => {
        formMethods.reset({
            ...VIDEO_FORM_DEFAULT_VALUES,
            src: propsSrc || '',
            width: propsWidth || '',
            height: propsHeight || '',
        });
    }, [propsSrc, propsWidth, propsHeight]);

    return (
        <Popover.Content align="end" className="flex flex-col gap-5 w-[40rem]">
            <Form formMethods={formMethods} onSubmit={onSubmit} className="flex flex-col gap-3 w-full">
                <div className="flex gap-8">
                    <Form.Field name="src">
                        <StringField
                            className="w-full flex-1"
                            label={t('wysiwygInput.videoPopover.srcLabel')}
                            placeholder={t('wysiwygInput.videoPopover.srcPlaceholder')}
                            required
                        />
                    </Form.Field>

                    <div className="flex gap-3">
                        <Form.Field name="width">
                            <StringField
                                label={t('wysiwygInput.videoPopover.widthLabel')}
                                placeholder={t('wysiwygInput.videoPopover.widthPlaceholder')}
                                className="min-w-0 w-24"
                            />
                        </Form.Field>

                        <Form.Field name="height">
                            <StringField
                                label={t('wysiwygInput.videoPopover.heightLabel')}
                                name="height"
                                placeholder={t('wysiwygInput.videoPopover.heightPlaceholder')}
                                className="min-w-0 w-24"
                            />
                        </Form.Field>
                    </div>
                </div>

                <div className="flex gap-2 justify-end">
                    <Button variant="primary">{t('wysiwygInput.videoPopover.submitButton')}</Button>
                </div>
            </Form>
        </Popover.Content>
    );
};

const MenuBar = ({ editor, disabled, files }: { editor: any; disabled?: boolean; files?: UploadedFile[] }) => {
    const { t } = useTranslation('components');

    if (!editor) {
        return null;
    }

    return (
        <div className="flex gap-0.5 flex-wrap items-center">
            <MenuBarGroup>
                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    disabled={disabled || !editor.can().chain().focus().toggleBold().run()}
                    active={editor.isActive('bold')}
                    label={t('wysiwygInput.bold')}
                >
                    <BoldIcon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    disabled={disabled || !editor.can().chain().focus().toggleItalic().run()}
                    active={editor.isActive('italic')}
                    label={t('wysiwygInput.italic')}
                >
                    <ItalicIcon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                    disabled={disabled || !editor.can().chain().focus().toggleUnderline().run()}
                    active={editor.isActive('underline')}
                    label={t('wysiwygInput.underline')}
                >
                    <UnderlineIcon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    disabled={disabled || !editor.can().chain().focus().toggleStrike().run()}
                    active={editor.isActive('strike')}
                    label={t('wysiwygInput.strikeThrough')}
                >
                    <StrikethroughIcon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleCode().run()}
                    disabled={disabled || !editor.can().chain().focus().toggleCode().run()}
                    active={editor.isActive('code')}
                    label={t('wysiwygInput.code')}
                >
                    <CodeIcon />
                </MenuBarButton>

                <MenuBarSeparator />
            </MenuBarGroup>

            <MenuBarGroup>
                <MenuBarButton
                    onClick={() => editor.chain().focus().setParagraph().run()}
                    active={editor.isActive('paragraph')}
                    disabled={disabled}
                    label={t('wysiwygInput.paragraph')}
                >
                    <PilcrowIcon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    active={editor.isActive('heading', { level: 1 })}
                    disabled={disabled}
                    label={t('wysiwygInput.heading1')}
                >
                    <Heading1Icon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    active={editor.isActive('heading', { level: 2 })}
                    disabled={disabled}
                    label={t('wysiwygInput.heading2')}
                >
                    <Heading2Icon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                    active={editor.isActive('heading', { level: 3 })}
                    disabled={disabled}
                    label={t('wysiwygInput.heading3')}
                >
                    <Heading3Icon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                    active={editor.isActive('heading', { level: 4 })}
                    disabled={disabled}
                    label={t('wysiwygInput.heading4')}
                >
                    <Heading4Icon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                    active={editor.isActive('heading', { level: 5 })}
                    disabled={disabled}
                    label={t('wysiwygInput.heading5')}
                >
                    <Heading5Icon />
                </MenuBarButton>

                <MenuBarSeparator />
            </MenuBarGroup>

            <MenuBarGroup>
                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    active={editor.isActive('bulletList')}
                    disabled={disabled}
                    label={t('wysiwygInput.bulletList')}
                >
                    <ListIcon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    active={editor.isActive('orderedList')}
                    disabled={disabled}
                    label={t('wysiwygInput.orderedList')}
                >
                    <ListOrderedIcon />
                </MenuBarButton>

                <MenuBarSeparator />
            </MenuBarGroup>

            <MenuBarGroup>
                <MenuBarButton
                    onClick={() => editor.chain().focus().toggleBlockquote().run()}
                    active={editor.isActive('blockquote')}
                    disabled={disabled}
                    label={t('wysiwygInput.blockquote')}
                >
                    <QuoteIcon />
                </MenuBarButton>

                <MenuBarButton
                    onClick={() => editor.chain().focus().setHorizontalRule().run()}
                    disabled={disabled}
                    label={t('wysiwygInput.horizontalRule')}
                >
                    <MinusIcon />
                </MenuBarButton>

                <Popover>
                    <Popover.Trigger asChild>
                        <MenuBarButton
                            active={editor.isActive('link')}
                            disabled={disabled}
                            label={t('wysiwygInput.link')}
                        >
                            <LinkIcon />
                        </MenuBarButton>
                    </Popover.Trigger>
                    <LinkPopoverContent
                        href={editor.isActive('link') ? editor.getAttributes('link').href : undefined}
                        onSubmit={(attributes) => editor.chain().focus().setLink(attributes).run()}
                        onRemove={() => editor.chain().focus().unsetLink().run()}
                        files={files}
                    />
                </Popover>

                <Popover>
                    <Popover.Trigger asChild>
                        <MenuBarButton
                            active={editor.isActive('image')}
                            disabled={disabled}
                            label={t('wysiwygInput.image')}
                        >
                            <ImageIcon />
                        </MenuBarButton>
                    </Popover.Trigger>
                    <ImagePopoverContent
                        src={editor.isActive('image') ? editor.getAttributes('image').src : undefined}
                        alt={editor.isActive('image') ? editor.getAttributes('image').alt : undefined}
                        width={editor.isActive('image') ? editor.getAttributes('image').width : undefined}
                        height={editor.isActive('image') ? editor.getAttributes('image').height : undefined}
                        onSubmit={(attributes) => editor.chain().focus().setImage(attributes).run()}
                        files={files}
                    />
                </Popover>

                <Popover>
                    <Popover.Trigger asChild>
                        <MenuBarButton
                            active={editor.isActive('youtube')}
                            disabled={disabled}
                            label={t('wysiwygInput.video')}
                        >
                            <VideoIcon />
                        </MenuBarButton>
                    </Popover.Trigger>
                    <VideoPopoverContent
                        src={editor.isActive('youtube') ? editor.getAttributes('youtube').src : undefined}
                        width={editor.isActive('youtube') ? editor.getAttributes('youtube').width : undefined}
                        height={editor.isActive('youtube') ? editor.getAttributes('youtube').height : undefined}
                        onSubmit={(attributes) => editor.chain().focus().setYoutubeVideo(attributes).run()}
                    />
                </Popover>

                <MenuBarSeparator />
            </MenuBarGroup>

            <MenuBarGroup>
                <MenuBarButton
                    onClick={() => editor.chain().focus().undo().run()}
                    disabled={disabled || !editor.can().chain().focus().undo().run()}
                    label={t('wysiwygInput.undo')}
                >
                    <Undo2Icon />
                </MenuBarButton>
                <MenuBarButton
                    onClick={() => editor.chain().focus().redo().run()}
                    disabled={disabled || !editor.can().chain().focus().redo().run()}
                    label={t('wysiwygInput.redo')}
                >
                    <Redo2Icon />
                </MenuBarButton>
            </MenuBarGroup>
        </div>
    );
};

const CustomImage = ImageExtension.extend({
    addAttributes() {
        return {
            src: {
                default: null,
            },
            alt: {
                default: null,
            },
            title: {
                default: null,
            },
            width: {
                default: null,
            },
            height: {
                default: null,
            },
        };
    },
});

const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    // @ts-ignore
    TextStyle.configure({ types: [ListItem.name] }),
    StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },
        orderedList: { keepMarks: true, keepAttributes: false },
    }),
    Underline,
    CustomImage,
    Youtube,
    Link.configure({ openOnClick: false }),
];

export interface WysiwygProps extends React.ComponentPropsWithRef<'textarea'>, WidgetProps {
    files?: UploadedFile[];
}

const WysiwygInput = ({ className, controls, widgetRef, ref, files, ...props }: WysiwygProps) => {
    const nativeRef = useRef<HTMLTextAreaElement>(null);

    const [value, onChange] = useWidgetState('', props.value, props.onChange);

    const handleChange = (value: any) => {
        setNativeTextareaValue(nativeRef.current!, value);
    };

    const editor = useEditor({
        extensions,
        content: value as Content,
        onUpdate: ({ editor }) => {
            handleChange(editor.getHTML());
        },
        editorProps: {
            attributes: {
                class: 'styled outline-none min-h-[10rem] px-2 py-1.5 flex-1 min-w-0 bg-transparent outline-none resize-y overflow-hidden',
            },
        },
        editable: !props.disabled,
    });

    useEffect(() => {
        // keep in sync with external changes
        if (editor && value !== editor.getHTML()) {
            editor.commands.setContent(value as Content);
        }
    }, [value, editor]);

    return (
        <Widget className={className} readOnly={props.readOnly} disabled={props.disabled} ref={widgetRef}>
            <Widget.Native asChild>
                <textarea {...props} disabled={props.disabled} onChange={onChange} ref={nativeRef} />
            </Widget.Native>

            <Widget.Content className="relative">
                <div className="flex flex-col w-full flex-1 min-w-0">
                    <div className="bg-base-50 border-b border-solid outline-transparent border-base-300 p-1.5">
                        <MenuBar editor={editor} disabled={props.disabled} files={files} />
                    </div>
                    <EditorContent className="w-full flex-1 min-w-0" editor={editor} disabled={props.disabled} />
                </div>
            </Widget.Content>

            <Widget.Controls>{controls}</Widget.Controls>
        </Widget>
    );
};

export default WysiwygInput;
