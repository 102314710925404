import { Navigate } from 'react-router-dom';

import { authorized, authorizedLoader, isRegularUser } from '../../../utils/loaders.ts';
import {
    accountTabLoader,
    contactTabLoader,
    educationEditLoader,
    educationTabLoader,
    experienceEditLoader,
    experienceTabLoader,
    generalTabLoader,
    notificationsTabLoader,
} from './loaders.ts';
import UserSettingsPage from './pages/UserSettingsPage.tsx';
import AccountTab from './tabs/AccountTab.tsx';
import ContactTab from './tabs/ContactTab.tsx';
import EducationTab, { AddEducationFormDialog, EditEducationFormDialog } from './tabs/EducationTab.tsx';
import WorkExperienceTab, { AddExperienceFormDialog, EditExperienceFormDialog } from './tabs/ExperienceTab.tsx';
import GeneralTab from './tabs/GeneralTab.tsx';
import NotificationsTab from './tabs/NotificationsTab.tsx';
import PasswordTab from './tabs/PasswordTab.tsx';

export const ADMIN_USER_PATH = '/admin/user';
export const ADMIN_USER_SETTINGS_PATH = '/admin/user/settings';
export const ADMIN_USER_SETTINGS_PROFILE_PATH = '/admin/user/settings/profile';
export const ADMIN_USER_SETTINGS_CONTACT_PATH = '/admin/user/settings/contact';
export const ADMIN_USER_SETTINGS_EXPERIENCE_PATH = '/admin/user/settings/experience';
export const ADMIN_USER_SETTINGS_EXPERIENCE_ADD_PATH = '/admin/user/settings/experience/add';
export const ADMIN_USER_SETTINGS_EXPERIENCE_DETAIL_PATH = '/admin/user/settings/experience/:experienceId';
export const ADMIN_USER_SETTINGS_EXPERIENCE_EDIT_PATH = '/admin/user/settings/experience/:experienceId/edit';
export const ADMIN_USER_SETTINGS_EDUCATION_PATH = '/admin/user/settings/education';
export const ADMIN_USER_SETTINGS_EDUCATION_ADD_PATH = '/admin/user/settings/education/add';
export const ADMIN_USER_SETTINGS_EDUCATION_DETAIL_PATH = '/admin/user/settings/education/:educationId';
export const ADMIN_USER_SETTINGS_EDUCATION_EDIT_PATH = '/admin/user/settings/education/:educationId/edit';
export const ADMIN_USER_SETTINGS_ACCOUNT_PATH = '/admin/user/settings/account';
export const ADMIN_USER_SETTINGS_NOTIFICATIONS_PATH = '/admin/user/settings/notifications';
export const ADMIN_USER_SETTINGS_PASSWORD_PATH = '/admin/user/settings/password';

export default [
    {
        path: ADMIN_USER_PATH,
        element: <Navigate to={ADMIN_USER_SETTINGS_PATH} replace={true} />,
    },
    {
        handle: {
            title: 'Benutzereinstellungen',
        },
        path: ADMIN_USER_SETTINGS_PATH,
        element: <UserSettingsPage />,
        children: [
            {
                index: true,
                element: <Navigate to={ADMIN_USER_SETTINGS_PROFILE_PATH} replace={true} />,
            },
            {
                handle: {
                    title: 'Allgemein',
                },
                path: ADMIN_USER_SETTINGS_PROFILE_PATH,
                element: <GeneralTab />,
                loader: authorizedLoader(generalTabLoader, isRegularUser),
            },
            {
                handle: {
                    title: 'Kontakt',
                },
                path: ADMIN_USER_SETTINGS_CONTACT_PATH,
                element: <ContactTab />,
                loader: authorizedLoader(contactTabLoader, isRegularUser),
            },
            {
                handle: {
                    title: 'Berufserfahrung',
                },
                path: ADMIN_USER_SETTINGS_EXPERIENCE_PATH,
                element: <WorkExperienceTab />,
                loader: authorizedLoader(experienceTabLoader, isRegularUser),
                children: [
                    {
                        handle: {
                            title: 'Berufserfahrung hinzufügen',
                        },
                        path: ADMIN_USER_SETTINGS_EXPERIENCE_ADD_PATH,
                        element: <AddExperienceFormDialog />,
                    },
                    {
                        handle: {
                            title: 'Berufserfahrung bearbeiten',
                        },
                        path: ADMIN_USER_SETTINGS_EXPERIENCE_EDIT_PATH,
                        element: <EditExperienceFormDialog />,
                        loader: authorizedLoader(experienceEditLoader, isRegularUser),
                    },
                ],
            },
            {
                handle: {
                    title: 'Ausbildung',
                },
                path: ADMIN_USER_SETTINGS_EDUCATION_PATH,
                element: <EducationTab />,
                loader: authorizedLoader(educationTabLoader, isRegularUser),
                children: [
                    {
                        handle: {
                            title: 'Ausbildung hinzufügen',
                        },
                        path: ADMIN_USER_SETTINGS_EDUCATION_ADD_PATH,
                        element: <AddEducationFormDialog />,
                    },
                    {
                        handle: {
                            title: 'Ausbildung bearbeiten',
                        },
                        path: ADMIN_USER_SETTINGS_EDUCATION_EDIT_PATH,
                        element: <EditEducationFormDialog />,
                        loader: authorizedLoader(educationEditLoader, isRegularUser),
                    },
                ],
            },
            {
                handle: {
                    title: 'Benutzerkonto',
                },
                path: ADMIN_USER_SETTINGS_ACCOUNT_PATH,
                element: <AccountTab />,
                loader: authorizedLoader(accountTabLoader),
            },
            {
                handle: {
                    title: 'Benachrichtigungen',
                },
                path: ADMIN_USER_SETTINGS_NOTIFICATIONS_PATH,
                element: <NotificationsTab />,
                loader: authorizedLoader(notificationsTabLoader),
            },
            {
                handle: {
                    title: 'Passwort',
                },
                path: ADMIN_USER_SETTINGS_PASSWORD_PATH,
                element: <PasswordTab />,
                loader: authorized(),
            },
        ],
    },
];
