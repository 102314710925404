import {
    Building2Icon,
    CalendarCogIcon,
    CalendarRangeIcon,
    ChartColumnIcon,
    ExternalLinkIcon,
    EyeIcon,
    EyeOffIcon,
    LibraryBigIcon,
    SettingsIcon,
} from 'lucide-react';
import React from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { SingleSelect, Switch } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import { eventDetailQuery, eventListQuery, eventUpdateMutation } from '../../../../data/queries.ts';
import { getEventDomain } from '../../../../utils/domain.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import {
    ADMIN_EVENT_ADVANCED_PATH,
    ADMIN_EVENT_ANALYTICS_PATH,
    ADMIN_EVENT_DETAIL_PATH,
    ADMIN_EVENT_GENERAL_PATH,
    ADMIN_EVENT_ORGANIZATIONS_PATH,
    ADMIN_EVENT_PAGES_PATH,
    ADMIN_EVENT_SESSIONS_PATH,
} from '../routes.tsx';

const EventDetailPage = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();
    const { data: event } = useQuery(eventDetailQuery(eventId!));
    const { data: events } = useQuery(eventListQuery());

    const { mutateAsync: updateProfile } = useMutation(eventUpdateMutation());

    const handleVisibilityChange = async (e: any) => {
        const value = e.target.checked;

        await updateProfile({ id: eventId!, data: { isPublic: value } });

        if (value) {
            toast(
                <span>
                    <Trans t={t} i18nKey="eventDetailPage.visibilityChangePublic" />
                </span>,
                { icon: <EyeIcon /> }
            );
        } else {
            toast(
                <span>
                    <Trans t={t} i18nKey="eventDetailPage.visibilityChangePrivate" />
                </span>,
                { icon: <EyeOffIcon /> }
            );
        }
    };

    const navigate = useNavigate();

    const handleEventChange = (e: any) => {
        const eventId = e.target.value;
        navigate(url(ADMIN_EVENT_DETAIL_PATH, { eventId }));
    };

    return (
        <div className="flex gap-8 h-full" key={eventId}>
            <Admin.Navigation className="pt-7">
                <Admin.NavigationItem asChild>
                    <div className="!block hover:!bg-transparent w-full">
                        <SingleSelect
                            options={
                                events?.map((event) => ({
                                    label: event.name,
                                    value: event.id,
                                })) || []
                            }
                            onChange={handleEventChange}
                            value={eventId}
                            required
                            hideSearch
                            className="w-full"
                        />
                    </div>
                </Admin.NavigationItem>

                <Admin.NavigationSeparator />

                <Admin.NavigationLabel>{t('eventDetailPage.navigation.visibility')}</Admin.NavigationLabel>

                <Admin.NavigationItem asChild>
                    <label className="flex justify-between items-center">
                        <span>{t('eventDetailPage.navigation.visibilityToggleLabel')}</span>
                        <Switch className="-my-0.5" onChange={handleVisibilityChange} checked={event?.isPublic} />
                    </label>
                </Admin.NavigationItem>

                <Admin.NavigationItem asChild>
                    <a
                        className="flex justify-between items-center min-w-0 gap-2"
                        href={`//${getEventDomain(event!)}`}
                        target="_blank"
                    >
                        <div className="flex flex-col gap-1 min-w-0">
                            <span>{t('eventDetailPage.navigation.viewEvent')}</span>
                            <span className="text-xs text-base-500 truncate">{getEventDomain(event!)}</span>
                        </div>

                        <ExternalLinkIcon className="flex-none" />
                    </a>
                </Admin.NavigationItem>

                <Admin.NavigationSeparator />
                <Admin.NavigationLabel>{t('eventDetailPage.navigation.settings')}</Admin.NavigationLabel>

                <Admin.NavigationLink to={url(ADMIN_EVENT_GENERAL_PATH, { eventId })}>
                    <CalendarCogIcon /> {t('eventDetailPage.navigation.general')}
                </Admin.NavigationLink>
                <Admin.NavigationLink to={url(ADMIN_EVENT_ADVANCED_PATH, { eventId })}>
                    <SettingsIcon /> {t('eventDetailPage.navigation.advanced')}
                </Admin.NavigationLink>

                <Admin.NavigationSeparator />
                <Admin.NavigationLabel>{t('eventDetailPage.navigation.resources')}</Admin.NavigationLabel>

                <Admin.NavigationLink to={url(ADMIN_EVENT_SESSIONS_PATH, { eventId })}>
                    <CalendarRangeIcon /> {t('eventDetailPage.navigation.sessions')}
                </Admin.NavigationLink>
                <Admin.NavigationLink to={url(ADMIN_EVENT_ORGANIZATIONS_PATH, { eventId })}>
                    <Building2Icon /> {t('eventDetailPage.navigation.organizations')}
                </Admin.NavigationLink>
                <Admin.NavigationLink to={url(ADMIN_EVENT_PAGES_PATH, { eventId })}>
                    <LibraryBigIcon /> {t('eventDetailPage.navigation.pages')}
                </Admin.NavigationLink>

                <Admin.NavigationSeparator />
                <Admin.NavigationLabel>{t('eventDetailPage.navigation.more')}</Admin.NavigationLabel>

                <Admin.NavigationLink to={url(ADMIN_EVENT_ANALYTICS_PATH, { eventId })}>
                    <ChartColumnIcon /> {t('eventDetailPage.navigation.analytics')}
                </Admin.NavigationLink>
            </Admin.Navigation>

            <Admin.Content>
                <Outlet />
            </Admin.Content>
        </div>
    );
};

export default EventDetailPage;
