import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { BooleanField, Form, StringField } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import { ADMIN_PATH } from '../../../App.tsx';
import FormError from '../../../components/FormError.tsx';
import FormSubmitButton from '../../../components/FormSubmitButton.tsx';
import PasswordInput from '../../../components/PasswordInput.tsx';
import { userDetailQuery } from '../../../data/queries.ts';
import { setFormErrors } from '../../../utils/forms.ts';
import url from '../../../utils/url';
import { SITE_TERMS_PATH } from '../../site/routes.tsx';
import { siteUrl } from '../../site/utils.ts';
import Auth from '../components/Auth.tsx';
import { signupMutation } from '../queries.ts';
import { ADMIN_LOGIN_PATH, ADMIN_SIGNUP_PATH } from '../routes.tsx';

const SIGNUP_FORM_DEFAULT_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    remember: false,
};

const SignupPage = () => {
    const { t } = useTranslation('auth');

    const formMethods = useForm({ defaultValues: { ...SIGNUP_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    const { data: viewer } = useQuery(userDetailQuery());

    const { mutateAsync: signup } = useMutation(signupMutation());

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (viewer?.id) {
            navigate(searchParams.get('next') || ADMIN_PATH);
        }
    }, [viewer]);

    const handleSubmit = async (values: any) => {
        try {
            await signup(values);
            toast.success(t('signupPage.submitSuccess'));
            navigate(searchParams.get('next') || ADMIN_PATH);
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('signupPage.submitError'));
        }
    };

    return (
        <Auth>
            <Auth.Card className="max-w-[30rem]">
                <Auth.CardContent>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-3xl font-semibold">{t('signupPage.title')}</h2>
                    </div>

                    <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                        <FormError />

                        <div className="flex gap-5 w-full">
                            <Form.Field name="firstName">
                                <StringField label={t('signupPage.firstNameLabel')} className="flex-1 min-w-0" />
                            </Form.Field>

                            <Form.Field name="lastName">
                                <StringField label={t('signupPage.lastNameLabel')} className="flex-1 min-w-0" />
                            </Form.Field>
                        </div>

                        <Form.Field name="email" rules={{ required: t('signupPage.emailRequired') }}>
                            <StringField label={t('signupPage.emailLabel')} required />
                        </Form.Field>

                        <Form.Field name="password" rules={{ required: t('signupPage.passwordRequired') }}>
                            <StringField label={t('signupPage.passwordLabel')} widget={PasswordInput} required />
                        </Form.Field>

                        <Form.Field
                            name="confirmPassword"
                            rules={{ required: t('signupPage.confirmPasswordRequired') }}
                        >
                            <StringField label={t('signupPage.confirmPasswordLabel')} widget={PasswordInput} required />
                        </Form.Field>

                        <div className="flex items-center">
                            <Form.Field name="terms" rules={{ required: t('signupPage.termsRequired') }}>
                                <BooleanField
                                    label={
                                        <Trans
                                            t={t}
                                            i18nKey="signupPage.termsLabel"
                                            components={{
                                                termsLink: (
                                                    <Link
                                                        to={siteUrl(SITE_TERMS_PATH)}
                                                        className="text-theme-500 decoration-theme-500/30 cursor-pointer underline underline-offset-2 hover:text-theme-700 transition-colors"
                                                    />
                                                ),
                                            }}
                                        />
                                    }
                                    required
                                />
                            </Form.Field>
                        </div>

                        <FormSubmitButton variant="primary" className="mt-3">
                            {t('signupPage.submitButton')}
                        </FormSubmitButton>
                    </Form>
                </Auth.CardContent>
                <Auth.CardFooter>
                    <p className="text-sm">
                        {t('signupPage.alreadyAccount')}{' '}
                        <Link
                            to={`${url(ADMIN_LOGIN_PATH)}?next=${searchParams.get('next')}`}
                            className="ml-auto text-theme-500 underline underline-offset-2 hover:text-theme-700 transition-colors"
                        >
                            {t('signupPage.gotoLogin')}
                        </Link>
                    </p>
                </Auth.CardFooter>
            </Auth.Card>
        </Auth>
    );
};

export default SignupPage;
