import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Form, StringField } from '@nicoknoll/forms';
import { useMutation } from '@tanstack/react-query';

import FormError from '../../../components/FormError.tsx';
import FormSubmitButton from '../../../components/FormSubmitButton.tsx';
import { setFormErrors } from '../../../utils/forms.ts';
import url from '../../../utils/url';
import Auth from '../components/Auth.tsx';
import { forgotPasswordMutation } from '../queries.ts';
import { ADMIN_LOGIN_PATH } from '../routes.tsx';

const FORGOT_PASSWORD_FORM_DEFAULT_VALUES = {
    email: '',
};

const ForgotPasswordPage = () => {
    const { t } = useTranslation('auth');

    const formMethods = useForm({ defaultValues: { ...FORGOT_PASSWORD_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    const { mutateAsync: forgotPassword } = useMutation(forgotPasswordMutation());

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const handleSubmit = async (values: any) => {
        try {
            await forgotPassword(values);
            toast.success(t('forgotPasswordPage.submitSuccess'));
            setIsSubmitted(true);
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('forgotPasswordPage.submitError'));
        }
    };

    return (
        <Auth>
            <Auth.Card>
                <Auth.CardContent>
                    <h2 className="text-3xl font-semibold">{t('forgotPasswordPage.title')}</h2>

                    {isSubmitted ? (
                        <>
                            <p className="text-base-500 text-sm text-center">
                                {t('forgotPasswordPage.descriptionAfterSubmit')}
                            </p>
                            <p className="text-base-500 text-sm text-center font-medium">
                                {t('forgotPasswordPage.closePageNote')}
                            </p>
                        </>
                    ) : (
                        <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                            <FormError />

                            <Form.Field name="email" rules={{ required: t('forgotPasswordPage.emailRequired') }}>
                                <StringField label={t('forgotPasswordPage.emailLabel')} required />
                            </Form.Field>
                            <p className="text-base-500 text-sm">{t('forgotPasswordPage.description')}</p>
                            <FormSubmitButton variant="primary" className="mt-3">
                                {t('forgotPasswordPage.submitButton')}
                            </FormSubmitButton>
                        </Form>
                    )}
                </Auth.CardContent>
                <Auth.CardFooter>
                    <Link
                        to={url(ADMIN_LOGIN_PATH)}
                        className="text-sm text-theme-500 underline underline-offset-2 hover:text-theme-700 transition-colors"
                    >
                        {t('forgotPasswordPage.backToLogin')}
                    </Link>
                </Auth.CardFooter>
            </Auth.Card>
        </Auth>
    );
};

export default ForgotPasswordPage;
