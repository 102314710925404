import { PlusIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { Form, StringField } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import { InfoAlert } from '../../../../components/Alert.tsx';
import Button from '../../../../components/Button.tsx';
import Dialog from '../../../../components/Dialog.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import LinkButton from '../../../../components/LinkButton.tsx';
import MoreMenu from '../../../../components/MoreMenu.tsx';
import { TRANSLATABLE_USER_KEYS, User, createTranslatable } from '../../../../data/models.ts';
import {
    organizationDetailQuery,
    organizationLimitsQuery,
    userCreateMutation,
    userDeleteMutation,
    userDetailQuery,
    userListQuery,
    userResendInvitationMutation,
    userUpdateMutation,
} from '../../../../data/queries.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import { isSupportUser } from '../../../../utils/loaders.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import TranslatableFormField from '../../components/TranslatableFormField.tsx';
import UserCard from '../../components/UserCard.tsx';
import { useLimitAlert } from '../../utils.tsx';
import {
    ADMIN_ORGANIZATION_SETTINGS_USERS_ADD_PATH,
    ADMIN_ORGANIZATION_SETTINGS_USERS_EDIT_PATH,
    ADMIN_ORGANIZATION_SETTINGS_USERS_PATH,
} from '../routes.tsx';

const USER_FORM_DEFAULT_VALUES = createTranslatable(
    {
        firstName: '',
        lastName: '',
        email: '',
        position: '',
    },
    TRANSLATABLE_USER_KEYS
);

const BaseUserFormDialog = ({
    isCreate = false,
    user,
    isOpen = true,
    onIsOpenChange,
    onSubmit,
    onCancel,
}: {
    isCreate: boolean;
    user?: User;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onSubmit?: (data: any) => void;
    onCancel?: () => void;
}) => {
    const { t } = useTranslation('admin');

    const formMethods = useForm({ defaultValues: pickFormValues(USER_FORM_DEFAULT_VALUES, user), mode: 'onTouched' });
    useEffect(() => {
        formMethods.reset(pickFormValues(USER_FORM_DEFAULT_VALUES, user));
    }, [user]);

    const [isLoading, setIsLoading] = useState(false);

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());
    const { data: limits } = useQuery(organizationLimitsQuery(viewerOrganization?.id!));
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: limits?.usersCount,
        limit: limits?.maxUsers,
        limitTranslationKey: 'organizationSettingsPage.userFormDialog.limitAlert',
        limitReachedTranslationKey: 'organizationSettingsPage.userFormDialog.limitReachedAlert',
    });

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(onSubmit?.(values));
            toast.success(
                isCreate
                    ? t('organizationSettingsPage.userFormDialog.createSuccess')
                    : t('organizationSettingsPage.userFormDialog.updateSuccess')
            );
        } catch (error) {
            setFormErrors(t, formMethods, error);
            toast.error(
                isCreate
                    ? t('organizationSettingsPage.userFormDialog.createError')
                    : t('organizationSettingsPage.userFormDialog.updateError')
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">
                    {isCreate
                        ? t('organizationSettingsPage.userFormDialog.titleCreate')
                        : t('organizationSettingsPage.userFormDialog.titleUpdate')}
                </h1>

                {isCreate && <LimitAlert />}

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <FormError />

                    <div className="flex gap-5 w-full">
                        <Form.Field name="firstName">
                            <StringField
                                label={t('organizationSettingsPage.userFormDialog.firstNameLabel')}
                                className="flex-1"
                            />
                        </Form.Field>

                        <Form.Field name="lastName">
                            <StringField
                                label={t('organizationSettingsPage.userFormDialog.lastNameLabel')}
                                className="flex-1"
                            />
                        </Form.Field>
                    </div>

                    <TranslatableFormField name="position">
                        <StringField label={t('organizationSettingsPage.userFormDialog.positionLabel')} />
                    </TranslatableFormField>

                    <Form.Field
                        name="email"
                        rules={{ required: t('organizationSettingsPage.userFormDialog.emailRequired') }}
                    >
                        <StringField label={t('organizationSettingsPage.userFormDialog.emailLabel')} required />
                    </Form.Field>

                    {isCreate && <InfoAlert>{t('organizationSettingsPage.userFormDialog.confirmEmailNote')}</InfoAlert>}

                    <div className="flex justify-end gap-2">
                        <Button variant="ghost" onClick={onCancel} type="button">
                            {t('organizationSettingsPage.userFormDialog.cancelButton')}
                        </Button>

                        <FormSubmitButton variant="primary" type="submit" loading={isLoading} disabled={isLimitReached}>
                            {isCreate
                                ? t('organizationSettingsPage.userFormDialog.createButton')
                                : t('organizationSettingsPage.userFormDialog.updateButton')}
                        </FormSubmitButton>
                    </div>
                </Form>
            </Dialog.Content>
        </Dialog>
    );
};

export const AddUserFormDialog = (props: any) => {
    const { data: viewerOrganization } = useQuery(organizationDetailQuery());

    const navigate = useNavigate();
    const { mutateAsync: createUser } = useMutation(userCreateMutation());
    return (
        <BaseUserFormDialog
            isCreate
            {...props}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(ADMIN_ORGANIZATION_SETTINGS_USERS_PATH);
                }
            }}
            onSubmit={async (data) => {
                await createUser({ data: { ...data, organizationId: viewerOrganization?.id }, translatable: true });
                return navigate(ADMIN_ORGANIZATION_SETTINGS_USERS_PATH);
            }}
            onCancel={() => navigate(ADMIN_ORGANIZATION_SETTINGS_USERS_PATH)}
        />
    );
};

export const EditUserFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { userId } = useParams();

    const { data: user } = useQuery(userDetailQuery(userId!, { translatable: true }));
    const { mutateAsync: updateUser } = useMutation(userUpdateMutation());

    return (
        <BaseUserFormDialog
            {...props}
            user={user}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(ADMIN_ORGANIZATION_SETTINGS_USERS_PATH);
                }
            }}
            onSubmit={async (data) => {
                await updateUser({ id: userId!, data, translatable: true });
                return navigate(ADMIN_ORGANIZATION_SETTINGS_USERS_PATH);
            }}
            onCancel={() => navigate(ADMIN_ORGANIZATION_SETTINGS_USERS_PATH)}
        />
    );
};

const UsersTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { data: viewer } = useQuery(userDetailQuery());
    const { data: viewerOrganization } = useQuery(organizationDetailQuery());
    const { data: users } = useQuery(userListQuery({ organizationId: viewerOrganization?.id }));

    const { mutateAsync: updateUser } = useMutation(userUpdateMutation());
    const { mutateAsync: deleteUser } = useMutation(userDeleteMutation());
    const { mutateAsync: resendInvitation } = useMutation(userResendInvitationMutation());

    const handleDeleteUser = async (userId: string) => {
        try {
            await deleteUser({ id: userId });
            toast.success(t('organizationSettingsPage.usersTab.deleteSuccess'));
        } catch {
            toast.error(t('organizationSettingsPage.usersTab.deleteError'));
        }
    };

    const handleResendInvitation = async (userId: string) => {
        try {
            await resendInvitation({ id: userId });
            toast.success(t('organizationSettingsPage.usersTab.resendInvitationSuccess'));
        } catch {
            toast.error(t('organizationSettingsPage.usersTab.resendInvitationError'));
        }
    };

    const handleUpdateStatus = async (userId: string, status: 'active' | 'inactive') => {
        try {
            await updateUser({ id: userId, data: { status } });
            toast.success(
                status === 'active'
                    ? t('organizationSettingsPage.usersTab.activateSuccess')
                    : t('organizationSettingsPage.usersTab.deactivateSuccess')
            );
        } catch {
            toast.error(
                status === 'active'
                    ? t('organizationSettingsPage.usersTab.activateError')
                    : t('organizationSettingsPage.usersTab.deactivateError')
            );
        }
    };

    return (
        <>
            <Admin.Card className="flex gap-4 flex-none justify-between">
                <h1 className="text-3xl font-semibold">{t('organizationSettingsPage.usersTab.title')}</h1>
                {users && users?.length > 0 && (
                    <div>
                        <LinkButton to={url(ADMIN_ORGANIZATION_SETTINGS_USERS_ADD_PATH)} className="flex gap-1">
                            <PlusIcon /> {t('organizationSettingsPage.usersTab.createButton')}
                        </LinkButton>
                    </div>
                )}
            </Admin.Card>

            {users && users?.length > 0 ? (
                users.map((user) => {
                    const isCurrentUser = user.id === viewer?.id;

                    return (
                        <UserCard
                            key={user.id}
                            user={user}
                            isCurrentUser={isCurrentUser}
                            button={
                                <MoreMenu>
                                    {user.status === 'invited' ? (
                                        <>
                                            <MoreMenu.Item onClick={() => handleResendInvitation(user.id)}>
                                                {t('organizationSettingsPage.usersTab.resendInvitationButton')}
                                            </MoreMenu.Item>
                                            <MoreMenu.Separator />
                                        </>
                                    ) : null}

                                    <MoreMenu.Item asChild disabled={isSupportUser(user)}>
                                        <Link
                                            to={url(ADMIN_ORGANIZATION_SETTINGS_USERS_EDIT_PATH, { userId: user.id })}
                                        >
                                            {t('organizationSettingsPage.usersTab.editButton')}
                                        </Link>
                                    </MoreMenu.Item>

                                    {user.status === 'active' ? (
                                        <MoreMenu.Item
                                            onClick={() => handleUpdateStatus(user.id, 'inactive')}
                                            disabled={isCurrentUser}
                                        >
                                            {t('organizationSettingsPage.usersTab.deactivateButton')}
                                        </MoreMenu.Item>
                                    ) : (
                                        <MoreMenu.Item
                                            onClick={() => handleUpdateStatus(user.id, 'active')}
                                            disabled={isCurrentUser}
                                        >
                                            {t('organizationSettingsPage.usersTab.activateButton')}
                                        </MoreMenu.Item>
                                    )}

                                    <MoreMenu.Item
                                        danger
                                        onClick={() => handleDeleteUser(user.id)}
                                        disabled={isCurrentUser || isSupportUser(user)}
                                    >
                                        {t('organizationSettingsPage.usersTab.deleteButton')}
                                    </MoreMenu.Item>
                                </MoreMenu>
                            }
                        />
                    );
                })
            ) : (
                <EmptyState
                    className="h-full"
                    title="Keine Benutzer"
                    description="Bis jetzt wurden keine Benutzer hinzugefügt."
                    button={
                        <LinkButton to={url(ADMIN_ORGANIZATION_SETTINGS_USERS_ADD_PATH)} className="flex gap-1">
                            <PlusIcon />
                            {t('organizationSettingsPage.usersTab.createButton')}
                        </LinkButton>
                    }
                />
            )}

            <Outlet />
        </>
    );
};

export default UsersTab;
