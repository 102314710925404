import { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '@nicoknoll/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import Button from '../../../components/Button.tsx';
import LinkButton from '../../../components/LinkButton.tsx';
import Pagination from '../../../components/Pagination.tsx';
import { LogEventType, OrganizationProfile } from '../../../data/models.ts';
import {
    eventDetailQuery,
    organizationProfileSearchQuery,
    savedOrganizationListQuery,
    userDetailQuery,
} from '../../../data/queries.ts';
import { isAuthenticated } from '../../../utils/loaders.ts';
import useLogEvent from '../../../utils/useLogEvent.ts';
import { SimpleFilterSidebar, useSearchParamsFilters } from '../components/FilterSidebar.tsx';
import OrganizationProfileCard from '../components/OrganizationProfileCard.tsx';
import { useEventId } from '../utils.ts';

const formatLabel = (t: TFunction<'site'>) => (filterKey: string) =>
    ({
        search: t('organizationListPage.filters.search'),
        isSaved: t('organizationListPage.filters.isSaved'),
        industry: t('organizationListPage.filters.industry'),
        locationScope: t('organizationListPage.filters.locationScope'),
        organizationType: t('organizationListPage.filters.organizationType'),
        organizationSize: t('organizationListPage.filters.organizationSize'),
        organizationLanguage: t('organizationListPage.filters.organizationLanguage'),
        eventDays: t('organizationListPage.filters.eventDays'),
    })[filterKey] || filterKey;

const formatValue = (t: TFunction<'site'>) => (filterKey: string, value: string) => {
    if (filterKey === 'eventDays') {
        return formatDate(value); // timezone comes from backend already
    } else if (filterKey === 'isSaved') {
        return value === 'true'
            ? t('organizationListPage.filters.isSavedOptions.true')
            : t('organizationListPage.filters.isSavedOptions.false');
    }
    return value;
};

const OrganizationListPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());
    const { data: event } = useQuery(eventDetailQuery(eventId!));

    const { filters, onToggleFilter, searchParams, setSearchParams } = useSearchParamsFilters([
        'search',
        'isSaved',
        'industry',
        'locationScope',
        'organizationType',
        'organizationSize',
        'organizationLanguage',
        'eventDays',
    ]);
    const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
    const pageSize = 20;

    const { data: organizationProfilesData } = useQuery({
        ...organizationProfileSearchQuery({ eventId, page, pageSize, isPublic: true, ...filters }),
        placeholderData: keepPreviousData,
    });
    const organizationProfiles: OrganizationProfile[] = organizationProfilesData?.results || [];

    const handlePageChange = (newPage: number) => {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
    };

    const { data: savedOrganizations } = useQuery({
        ...savedOrganizationListQuery(viewer ? { eventId, userId: viewer.id } : { eventId }),
        placeholderData: keepPreviousData,
        enabled: isAuthenticated(viewer!),
    });
    const savedOrganizationsById = Object.fromEntries(
        savedOrganizations?.map((o) => [o.targetOrganizationId, o]) || []
    );

    const createLogEvent = useLogEvent();
    useEffect(() => {
        createLogEvent(LogEventType.ORGANIZATION_SEARCH, { ...filters, page, pageSize });
    }, [filters, page, pageSize]);

    if (!organizationProfilesData || !event) return null;

    return (
        <div className={className} {...props}>
            <main className="flex justify-center bg-white px-6 py-24">
                <div className="w-full max-w-[1120px] flex flex-col gap-32">
                    <section className="flex flex-col gap-14">
                        <h2 className="font-semibold text-3xl">
                            {t('organizationListPage.title', {
                                organizationsCount: organizationProfilesData?.count,
                                eventName: event?.name,
                            })}
                        </h2>
                        <div className="flex gap-10">
                            <div className="w-80 flex-none border-r border-base-100 pr-9">
                                <SimpleFilterSidebar
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    filtersData={organizationProfilesData?.filters || {}}
                                    filters={filters}
                                    onToggleFilter={onToggleFilter}
                                    formatLabel={formatLabel(t)}
                                    formatValue={formatValue(t)}
                                />
                            </div>

                            <div className="flex flex-col flex-1 gap-10">
                                <div className="grid grid-cols-2 auto-rows-auto gap-10">
                                    {organizationProfiles?.map((organizationProfile: OrganizationProfile) => (
                                        <OrganizationProfileCard
                                            key={organizationProfile.id}
                                            organizationProfile={organizationProfile}
                                            savedOrganization={
                                                savedOrganizationsById[organizationProfile.organizationId] || null
                                            }
                                        />
                                    ))}
                                </div>

                                <Pagination
                                    page={page}
                                    pageSize={pageSize}
                                    total={
                                        organizationProfilesData?.count ? Number(organizationProfilesData?.count) : 0
                                    }
                                    onPageChange={handlePageChange}
                                />

                                {!isAuthenticated(viewer!) && event.contactEmail && (
                                    <div className="bg-theme-50 rounded-xl p-8 flex flex-col gap-3 mt-8">
                                        <h3 className="font-semibold text-theme-700">
                                            {t('organizationListPage.signupBanner.title')}
                                        </h3>
                                        <p className="text-base-800 text-balance">
                                            {t('organizationListPage.signupBanner.description')}
                                        </p>
                                        <p className="mt-2">
                                            <Button variant="primary" className="text-base" asChild>
                                                <a href={`mailto:${event.contactEmail}`}>
                                                    {t('organizationListPage.signupBanner.button')}
                                                </a>
                                            </Button>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default OrganizationListPage;
