import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Form, StringField } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import PasswordInput from '../../../../components/PasswordInput.tsx';
import { userDetailQuery, userUpdatePasswordMutation } from '../../../../data/queries.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import { forgotPasswordMutation } from '../../../auth/queries.ts';
import Admin from '../../components/Admin.tsx';

const DEFAULT_FORM_VALUES = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
};

const PasswordTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { data: viewer } = useQuery(userDetailQuery());

    const formMethods = useForm({ defaultValues: { ...DEFAULT_FORM_VALUES }, mode: 'onTouched' });

    const { mutateAsync: updatePassword } = useMutation(userUpdatePasswordMutation());

    const handleSubmit = async (values: any) => {
        try {
            await updatePassword({ id: viewer?.id!, data: values });
            formMethods.reset({ ...DEFAULT_FORM_VALUES });
            toast.success(t('userSettingsPage.passwordTab.submitSuccess'));
        } catch (error: Response | any) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('userSettingsPage.passwordTab.submitError'));
        }
    };

    const { mutateAsync: forgotPassword } = useMutation(forgotPasswordMutation());

    const handleForgotPassword = async () => {
        try {
            await forgotPassword({ email: viewer?.email });
            toast.success(t('userSettingsPage.passwordTab.forgotPasswordSuccess', { email: viewer?.email }));
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('userSettingsPage.passwordTab.forgotPasswordError'));
        }
    };

    return (
        <Admin.Card className="flex flex-col gap-5">
            <h1 className="text-3xl font-semibold mb-2">{t('userSettingsPage.passwordTab.title')}</h1>

            <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                <FormError />

                <Form.Field
                    name="oldPassword"
                    rules={{ required: t('userSettingsPage.passwordTab.oldPasswordRequired') }}
                >
                    <StringField
                        label={t('userSettingsPage.passwordTab.oldPasswordLabel')}
                        required
                        widget={PasswordInput}
                    />
                </Form.Field>

                <Form.Field
                    name="newPassword"
                    rules={{ required: t('userSettingsPage.passwordTab.newPasswordRequired') }}
                >
                    <StringField
                        label={t('userSettingsPage.passwordTab.newPasswordLabel')}
                        required
                        widget={PasswordInput}
                    />
                </Form.Field>

                <Form.Field
                    name="confirmPassword"
                    rules={{ required: t('userSettingsPage.passwordTab.confirmPasswordRequired') }}
                >
                    <StringField
                        label={t('userSettingsPage.passwordTab.confirmPasswordLabel')}
                        required
                        widget={PasswordInput}
                    />
                </Form.Field>

                <div className="flex justify-end gap-2">
                    <Button variant="ghost" type="button" onClick={handleForgotPassword}>
                        {t('userSettingsPage.passwordTab.forgotPasswordButton')}
                    </Button>
                    <FormSubmitButton variant="primary">
                        {t('userSettingsPage.passwordTab.submitButton')}
                    </FormSubmitButton>
                </div>
            </Form>
        </Admin.Card>
    );
};

export default PasswordTab;
