import { LayoutGridIcon, StretchHorizontal } from 'lucide-react';
import React, { useCallback, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import Pagination from '../../../../components/Pagination.tsx';
import { UploadedFile } from '../../../../data/models.ts';
import { fileCreateMutation, fileSearchQuery, spaceLimitsQuery } from '../../../../data/queries.ts';
import Admin from '../../components/Admin.tsx';
import { FileCard, FileUploadArea } from '../../organization-settings/tabs/FilesTab.tsx';
import { useLimitAlert, useSpaceId } from '../../utils.tsx';

const FilesTab = (props: any) => {
    const { t } = useTranslation('admin');

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
    const pageSize = 30;

    const { data: filesData, refetch: refetchFiles } = useQuery({
        ...fileSearchQuery({ page, pageSize, spaceOnly: true }),
        placeholderData: keepPreviousData,
    });
    const files = filesData?.results;

    const { mutateAsync: createFile } = useMutation(fileCreateMutation());

    const [tempFiles, setTempFiles] = useState<UploadedFile[]>([]);
    const [view, setView] = useState<'list' | 'grid'>('grid');

    const spaceId = useSpaceId();
    const { data: limits } = useQuery(spaceLimitsQuery(spaceId));
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: Math.round((limits?.storageSpaceUsed || 0) / 10000) / 100,
        limit: limits?.maxStorageSpace != null ? Math.round((limits?.maxStorageSpace || 0) / 10000) / 100 : undefined,
        limitTranslationKey: 'spaceSettingsPage.filesTab.limitAlert',
        limitReachedTranslationKey: 'spaceSettingsPage.filesTab.limitReachedAlert',
        displayThreshold: 0.5,
    });

    const handleFilesDropped = useCallback(async (files: File[], fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
            fileRejections.forEach((fileRejection) => {
                fileRejection.errors.forEach((error) => {
                    let translationKey = 'spaceSettingsPage.filesTab.uploadError';

                    if (error.code === 'file-too-large') {
                        translationKey = 'spaceSettingsPage.filesTab.uploadFileTooLargeError';
                    }

                    // @ts-ignore
                    toast.error(t(translationKey, { fileName: fileRejection.file.name }));
                });
            });
        }

        const newFiles: UploadedFile[] = files.map((file) => ({
            id: file.name,
            name: file.name,
            size: file.size,
            mimeType: file.type,
            url: URL.createObjectURL(file),
        }));

        setTempFiles(newFiles);

        await Promise.all(
            files.map(async (file) => {
                try {
                    await createFile({ data: { file: file } });
                    toast.success(t('spaceSettingsPage.filesTab.uploadSuccess', { fileName: file.name }));
                } catch (error) {
                    console.error(error);
                    toast.error(t('spaceSettingsPage.filesTab.uploadError', { fileName: file.name }));
                }
            })
        );

        if (files.length > 0) {
            await refetchFiles();
        }

        setTempFiles([]);
    }, []);

    const handlePageChange = (newPage: number) => {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
    };

    return (
        <div className="flex flex-col gap-5">
            <Admin.Card className="flex justify-between gap-5">
                <h1 className="text-3xl font-semibold">{t('spaceSettingsPage.filesTab.title')}</h1>

                <div className="flex p-1 bg-base-100 rounded-md gap-1">
                    <Button
                        variant="ghost"
                        className={classnames(
                            'flex gap-1 items-center px-0 w-8 hover:bg-base-200 active:bg-base-300',
                            view === 'grid' && 'bg-white shadow-sm'
                        )}
                        onClick={() => setView('grid')}
                    >
                        <LayoutGridIcon />
                    </Button>

                    <Button
                        variant="ghost"
                        className={classnames(
                            'flex gap-1 items-center px-0 w-8 hover:bg-base-200 active:bg-base-300',
                            view === 'list' && 'bg-white shadow-sm'
                        )}
                        onClick={() => setView('list')}
                    >
                        <StretchHorizontal />
                    </Button>
                </div>
            </Admin.Card>

            <LimitAlert />

            <div className="flex flex-col gap-5 relative min-h-[28rem] z-0">
                <div className={classnames('grid grid-cols-3 gap-5', view === 'list' && 'grid-cols-1 gap-1')}>
                    {files?.map((file) => <FileCard key={file.id} file={file} view={view} />)}
                    {tempFiles.map((file) => (
                        <FileCard key={file.id} file={file} view={view} isTemp />
                    ))}
                </div>

                {!isLimitReached && (
                    <FileUploadArea
                        onFilesDropped={handleFilesDropped}
                        isEmpty={files && !files.length && !tempFiles.length}
                    />
                )}
            </div>

            <Pagination
                page={page}
                pageSize={pageSize}
                total={filesData?.count ? Number(filesData?.count) : 0}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default FilesTab;
