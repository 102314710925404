import { TFunction } from 'i18next';
import { camelCase } from 'lodash';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

// by default in django errors come with { detail: message }
const NON_FIELD_ERROR_KEYS = ['nonFieldErrors', 'nonFieldError', 'detail'];

// reverse server translated errors to translation keys
const COMMON_FORM_ERRORS_MAP = {
    'Incorrect authentication credentials.': 'auth:loginPage.formError.incorrectCredentials',
};

export const setFormErrors = <TFieldValues extends FieldValues>(
    t: TFunction<'admin' | 'auth' | 'common' | 'site' | 'components'>,
    formMethods: UseFormReturn<TFieldValues>,
    serverErrors: any
): void => {
    const payload: Record<string, string | string[]> = serverErrors.payload;
    if (!payload) return;

    const formFieldNames = Object.keys(formMethods.getValues());

    for (const key in payload) {
        const formattedKey = camelCase(key) as FieldPath<TFieldValues>;
        const isNonFieldError = NON_FIELD_ERROR_KEYS.includes(formattedKey);
        const isValidFieldName = formFieldNames.includes(formattedKey);

        const fieldName = isNonFieldError || !isValidFieldName ? 'root' : formattedKey;

        let messages = Array.isArray(payload[key]) ? payload[key] : [payload[key]];
        let message = messages
            .map((message) => {
                return Object.keys(COMMON_FORM_ERRORS_MAP).includes(message)
                    ? // @ts-ignore
                      t(COMMON_FORM_ERRORS_MAP[message])
                    : message;
            })
            .join('\n');

        formMethods.setError(fieldName, {
            type: 'validate',
            message: !isValidFieldName && !isNonFieldError ? `${formattedKey}: ${message}` : message,
        });
    }
};
