import { EnsureQueryDataOptions } from '@tanstack/react-query';

import { queryClient } from './queryClient.ts';

interface QueryFn {
    queryFn: (...args: any[]) => any;
}

type Queries = Record<string, EnsureQueryDataOptions & QueryFn>;

const ensureQueriesData = async <T extends Queries>(
    queries: T
): Promise<{
    [K in keyof T]: Awaited<ReturnType<T[K]['queryFn']>>;
}> => {
    const filteredEntries = Object.entries(queries).filter(([, q]) => q != null);
    const results = await Promise.all(
        filteredEntries.map(([, query]) =>
            queryClient.ensureQueryData({
                ...query,
                // staleTime: 0,
                initialData: undefined,
            })
        )
    );
    return Object.fromEntries(filteredEntries.map(([key], i) => [key, results[i]])) as { [K in keyof T]: any };
};

export default ensureQueriesData;
