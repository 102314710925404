import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { classnames } from '@nicoknoll/utils';

import Button from './Button.tsx';
import Dialog from './Dialog.tsx';

interface ConfirmationOptions {
    className?: string;
    onConfirm: () => void;
    onCancel: () => void;
    danger?: boolean;
    confirmLabel?: string;
    cancelLabel?: string;
}

const ConfirmationDialogContext = React.createContext<any>(null);
export const useConfirmationDialogContext = () => {
    const context = React.useContext(ConfirmationDialogContext);
    if (!context) {
        throw new Error('ConfirmationDialogContext must be used within a ConfirmationDialogProvider');
    }
    return context;
};
export const ConfirmationDialogProvider = ({ children }: { children: any }) => {
    const [dialogProps, setDialogProps] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(false);

    const confirm = (title: string, message: string = '', options: ConfirmationOptions = {} as ConfirmationOptions) => {
        const handleConfirm = async () => {
            setIsLoading(true);
            try {
                await Promise.resolve(options?.onConfirm?.());
                setDialogProps(null);
            } finally {
                setIsLoading(false);
            }
        };

        const handleCancel = async () => {
            await Promise.resolve(options?.onCancel?.());
            setDialogProps(null);
        };

        setDialogProps({
            title,
            message,
            ...options,
            isLoading,
            onConfirm: handleConfirm,
            onCancel: handleCancel,
        });
    };

    return (
        <ConfirmationDialogContext.Provider value={confirm}>
            {children}
            {dialogProps && <ConfirmationDialog {...dialogProps} />}
        </ConfirmationDialogContext.Provider>
    );
};

const ConfirmationDialog = ({
    className,
    isLoading,
    onConfirm,
    onCancel,
    title,
    message,
    danger,
    confirmLabel: propsConfirmLabel = '',
    cancelLabel: propsCancelLabel = '',
}: ConfirmationOptions & { isLoading: boolean; title: string; message?: string }) => {
    const { t } = useTranslation('components');
    const confirmLabel = propsConfirmLabel || t('confirmationDialog.confirmLabel');
    const cancelLabel = propsCancelLabel || t('confirmationDialog.cancelLabel');
    return (
        <Dialog open={true}>
            <Dialog.Content className={classnames('w-fit', className)}>
                <div className="flex flex-col gap-6">
                    <Dialog.Title asChild>
                        <h1 className="text-lg font-medium">{title}</h1>
                    </Dialog.Title>

                    {message && <div className="text-sm">{message}</div>}

                    <div className="flex gap-2 justify-end">
                        <Button type="button" onClick={onCancel}>
                            {cancelLabel}
                        </Button>
                        <Button
                            onClick={onConfirm}
                            className={classnames(
                                'inline-flex items-center gap-1.5 !bg-black !text-white !border-black hover:!bg-base-800 shadow-none px-2.5 disabled:opacity-50',
                                danger && '!bg-red-700 !border-red-700 hover:!bg-red-800 hover:!border-red-800'
                            )}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            {confirmLabel}
                        </Button>
                    </div>
                </div>
            </Dialog.Content>
        </Dialog>
    );
};

export default ConfirmationDialog;
