import {
    eventDetailQuery,
    eventListQuery,
    organizationListQuery,
    organizationProfileListQuery,
} from '../../../data/queries.ts';
import ensureQueriesData from '../../../utils/ensureQueriesData.ts';

export const eventListLoader = () => ensureQueriesData({ eventList: eventListQuery() });

export const eventDetailLoader = ({ params }: any) =>
    ensureQueriesData({ eventDetail: eventDetailQuery(params.eventId), eventList: eventListQuery() });

export const advancedTabLoader = ({ params }: any) =>
    ensureQueriesData({ eventDetail: eventDetailQuery(params.eventId) });

export const generalTabLoader = ({ params }: any) =>
    ensureQueriesData({ eventDetail: eventDetailQuery(params.eventId), eventList: eventListQuery() });

export const organizationsTabLoader = ({ params }: any) =>
    ensureQueriesData({
        event: eventDetailQuery(params.eventId),
        organizations: organizationListQuery(),
        organizationProfiles: organizationProfileListQuery(),
    });
