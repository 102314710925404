import { kebabCase } from 'lodash';
import { PlusIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { DateTimeField, Form, StringField } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import Dialog from '../../../../components/Dialog.tsx';
import FormError from '../../../../components/FormError.tsx';
import LinkButton from '../../../../components/LinkButton.tsx';
import { Event } from '../../../../data/models.ts';
import {
    eventCreateMutation,
    eventListQuery,
    organizationListQuery,
    spaceLimitsQuery,
} from '../../../../data/queries.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import { useLimitAlert, useSpaceId } from '../../utils.tsx';
import { ADMIN_EVENT_ADD_PATH, ADMIN_EVENT_DETAIL_PATH, ADMIN_EVENT_LIST_PATH } from '../routes.tsx';

const EVENT_FORM_DEFAULT_VALUES = {
    name: '',
};

const BaseEventFormDialog = ({
    isCreate = false,
    event,
    isOpen = true,
    onIsOpenChange,
    onSubmit,
    onCancel,
}: {
    isCreate: boolean;
    event?: Event;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onSubmit?: (data: any) => void;
    onCancel?: () => void;
}) => {
    const { t } = useTranslation('admin');

    const formMethods = useForm({ defaultValues: pickFormValues(EVENT_FORM_DEFAULT_VALUES, event), mode: 'onTouched' });
    useEffect(() => {
        formMethods.reset(pickFormValues(EVENT_FORM_DEFAULT_VALUES, event));
    }, [event]);

    const [isLoading, setIsLoading] = useState(false);

    const spaceId = useSpaceId();
    const { data: limits } = useQuery(spaceLimitsQuery(spaceId));
    const { data: events } = useQuery(eventListQuery());
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: events?.length,
        limit: limits?.maxOrganizations,
        limitTranslationKey: 'eventListPage.formDialog.limitAlert',
        limitReachedTranslationKey: 'eventListPage.formDialog.limitReachedAlert',
    });

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(onSubmit?.(values));
            toast.success(
                isCreate ? t('eventListPage.formDialog.createSuccess') : t('eventListPage.formDialog.updateSuccess')
            );
        } catch (error) {
            setFormErrors(t, formMethods, error);
            toast.error(
                isCreate ? t('eventListPage.formDialog.createError') : t('eventListPage.formDialog.updateError')
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">
                    {isCreate ? t('eventListPage.formDialog.titleCreate') : t('eventListPage.formDialog.titleUpdate')}
                </h1>

                {isCreate && <LimitAlert />}

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <FormError />

                    <Form.Field name="name">
                        <StringField label={t('eventListPage.formDialog.nameLabel')} required />
                    </Form.Field>

                    <div className="flex justify-end gap-2">
                        <Button variant="ghost" onClick={onCancel} type="button">
                            {t('eventListPage.formDialog.cancelButton')}
                        </Button>

                        <Button variant="primary" type="submit" loading={isLoading} disabled={isLimitReached}>
                            {isCreate
                                ? t('eventListPage.formDialog.createButton')
                                : t('eventListPage.formDialog.updateButton')}
                        </Button>
                    </div>
                </Form>
            </Dialog.Content>
        </Dialog>
    );
};

export const AddEventFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { mutateAsync: createEvent } = useMutation(eventCreateMutation());

    const handleSubmit = async (data: any) => {
        const event = await createEvent({
            data: {
                ...data,
                slug: kebabCase(data.name.toLowerCase()),
            },
        });
        return navigate(url(ADMIN_EVENT_DETAIL_PATH, { eventId: event.id }));
    };

    return (
        <BaseEventFormDialog
            isCreate
            {...props}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(ADMIN_EVENT_LIST_PATH);
                }
            }}
            onSubmit={handleSubmit}
            onCancel={() => navigate(ADMIN_EVENT_LIST_PATH)}
        />
    );
};

const EventCard = ({ event }: { event: Event }) => {
    return (
        <Link
            className="flex flex-col gap-6 flex-1 bg-base-100 rounded-xl p-8 hover:bg-base-150 transition-colors h-full"
            to={url(ADMIN_EVENT_DETAIL_PATH, { eventId: event.id })}
        >
            <span className="flex flex-col gap-6">
                <span
                    className="w-full h-32 bg-contain bg-no-repeat bg-center mix-blend-multiply"
                    style={{ backgroundImage: `url(${event?.logo?.url})` }}
                />

                <span className="flex flex-col gap-2">
                    <span className="font-medium text-lg">{event.name}</span>
                    <span className="text-base-500">{event.summary}</span>
                </span>
            </span>
        </Link>
    );
};

const EventListPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('admin');

    const { data: events } = useQuery(eventListQuery());
    return (
        <>
            <Admin.Content>
                <Admin.Card className="flex gap-4 flex-none justify-between">
                    <h1 className="text-3xl font-semibold">{t('eventListPage.title')}</h1>
                    <div>
                        <LinkButton className="flex gap-1" to={url(ADMIN_EVENT_ADD_PATH)}>
                            <PlusIcon />
                            {t('eventListPage.createButton')}
                        </LinkButton>
                    </div>
                </Admin.Card>

                {events && events.length > 0 ? (
                    <div className={classnames('grid grid-cols-3 gap-5', className)} {...props}>
                        {events?.map((event: any) => <EventCard key={event.id} event={event} />)}
                    </div>
                ) : (
                    <EmptyState
                        title={t('eventListPage.emptyState.title')}
                        description={t('eventListPage.emptyState.description')}
                    />
                )}
            </Admin.Content>

            <Outlet />
        </>
    );
};

export default EventListPage;
