import { intlFormatDistance } from 'date-fns';
import { ArrowRightIcon } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Form, TextField } from '@nicoknoll/forms';
import { classnames, useControllableState } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Avatar from '../../../components/Avatar.tsx';
import Button from '../../../components/Button.tsx';
import Dialog from '../../../components/Dialog.tsx';
import FormSubmitButton from '../../../components/FormSubmitButton.tsx';
import MoreMenu from '../../../components/MoreMenu.tsx';
import { User, UserNote } from '../../../data/models.ts';
import {
    userDetailQuery,
    userNoteCreateMutation,
    userNoteDeleteMutation,
    userNoteListQuery,
} from '../../../data/queries.ts';
import { setFormErrors } from '../../../utils/forms.ts';
import useSearchParamState from '../../../utils/useSearchParamState.tsx';
import EmptyState from '../../admin/components/EmptyState.tsx';
import { useEventId } from '../utils.ts';

const CHAR_LIMIT = 180;
const LINES_LIMIT = 3;

export const UserNoteCard = ({
    note,
    isCollapsed: propsIsCollapsed,
    onIsCollapsedChange,
    className,
    hideMoreMenu = false,
    ...props
}: React.ComponentPropsWithRef<'div'> & {
    note: UserNote;
    isCollapsed?: boolean;
    onIsCollapsedChange?: (isCollapsed: boolean) => void;
    hideMoreMenu?: boolean;
}) => {
    const [isCollapsed, setIsCollapsed] = useControllableState(true, propsIsCollapsed, onIsCollapsedChange);
    const exceedsCharLimit = isCollapsed && note.text && note.text.length > CHAR_LIMIT;
    const exceedsLinesLimit = isCollapsed && note.text && note.text.split('\n').length > LINES_LIMIT;

    const { t, i18n } = useTranslation('site');

    const { mutateAsync: deleteNote } = useMutation(userNoteDeleteMutation());
    const handleDelete = async () => {
        try {
            await deleteNote({ id: note?.id! });
            toast.success(t('userNoteCard.deleteSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('userNoteCard.deleteError'));
        }
    };

    return (
        <div className={classnames('bg-theme-50 rounded-xl p-6 flex gap-5 flex-col flex-1', className)} {...props}>
            {exceedsCharLimit && (
                <p className="text-base-800">
                    {note.text.slice(0, CHAR_LIMIT)}...{' '}
                    <button className="text-theme-600" onClick={() => setIsCollapsed(false)}>
                        {t('userNoteCard.readMore')}
                    </button>
                </p>
            )}

            {exceedsLinesLimit && (
                <p className="text-base-800">
                    {note.text.split('\n').slice(0, LINES_LIMIT).join('\n')}...{' '}
                    <button className="text-theme-600" onClick={() => setIsCollapsed(false)}>
                        {t('userNoteCard.readMore')}
                    </button>
                </p>
            )}

            {!exceedsCharLimit && !exceedsLinesLimit && (
                <p className="text-base-800 whitespace-pre-line">{note.text}</p>
            )}

            <div className="text-base-800 text-sm flex gap-2 items-center -my-1">
                <Avatar user={note.user!} className="w-5 h-5" />
                <span className="font-medium">
                    {note.user?.firstName} {note.user?.lastName}
                </span>

                <time className="opacity-70">
                    {intlFormatDistance(new Date(note.createdAt), new Date(), { locale: i18n.language })}
                </time>

                {!hideMoreMenu && (
                    <MoreMenu className="ml-auto hover:bg-theme-100 data-[state=open]:bg-theme-200">
                        <MoreMenu.Item onClick={handleDelete} danger>
                            {t('userNoteCard.delete')}
                        </MoreMenu.Item>
                    </MoreMenu>
                )}
            </div>
        </div>
    );
};

const FORM_DEFAULT_VALUES = {
    text: '',
};

const UserNotesDialog = ({
    targetUser,
    isOpen = true,
    onIsOpenChange,
    onAfterSubmit,
    onCancel,
    onClose,
}: React.ComponentPropsWithRef<'div'> & {
    targetUser: User;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onAfterSubmit?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
}) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { data: notes } = useQuery({
        ...userNoteListQuery({ eventId, targetUserId: targetUser?.id }),
        enabled: !!targetUser?.id,
    });
    const { data: viewer } = useQuery(userDetailQuery());

    const formMethods = useForm({ defaultValues: { ...FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    const { mutateAsync: createNote } = useMutation(userNoteCreateMutation());
    const handleSubmit = async (values: any) => {
        try {
            await createNote({
                data: {
                    ...values,
                    eventId,
                    targetUserId: targetUser?.id,
                    userId: viewer?.id,
                    organizationId: viewer?.organizationId,
                },
            });
            toast.success(t('userNotesDialog.submitSuccess'));
            formMethods.reset({ ...FORM_DEFAULT_VALUES });
            setMode('list');
            onAfterSubmit?.();
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('userNotesDialog.submitError'));
        }
    };

    const [mode, setMode] = useSearchParamState<'list' | 'create'>('mode', 'list');

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">
                    {t('userNotesDialog.title', { name: targetUser?.firstName })}
                </h1>

                {mode === 'list' ? (
                    <div className="flex flex-col gap-5">
                        {notes?.map((note) => <UserNoteCard key={note.id} note={note} isCollapsed={false} />)}

                        {!notes?.length && (
                            <EmptyState
                                className="my-8"
                                title={t('userNotesDialog.noNotes.title')}
                                description={t('userNotesDialog.noNotes.description')}
                            />
                        )}

                        <div className="flex gap-2 justify-between mt-2">
                            <Button variant="ghost" onClick={onClose} type="button">
                                {t('userNotesDialog.closeButton')}
                            </Button>

                            <Button
                                variant="primary"
                                type="submit"
                                className="flex gap-2 items-center"
                                onClick={() => setMode('create')}
                            >
                                <span>{t('userNotesDialog.submitButton')}</span>
                                <ArrowRightIcon />
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex flex-col gap-5 flex-none">
                        <Form.Field name="text" rules={{ required: t('userNotesDialog.noteRequired') }}>
                            <TextField
                                required={true}
                                className="min-h-[3lh]"
                                label={t('userNotesDialog.noteLabel')}
                                placeholder={t('userNotesDialog.notePlaceholder')}
                            />
                        </Form.Field>

                        <div className="flex gap-2 justify-between">
                            <Button
                                variant="ghost"
                                onClick={() => {
                                    setMode('list');
                                    onCancel?.();
                                }}
                                type="button"
                            >
                                {t('userNotesDialog.cancelButton')}
                            </Button>

                            <FormSubmitButton variant="primary" type="submit">
                                {t('userNotesDialog.submitButton')}
                            </FormSubmitButton>
                        </div>
                    </Form>
                )}
            </Dialog.Content>
        </Dialog>
    );
};

export default UserNotesDialog;
