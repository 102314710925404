import { ChevronRightIcon, ClockIcon, MapPinIcon } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';

import Image from '../../../components/Image.tsx';
import { Job, OrganizationProfile, SavedJob } from '../../../data/models.ts';
import { SITE_JOB_DETAIL_PATH, SITE_ORGANIZATION_DETAIL_PATH } from '../routes.tsx';
import { siteUrl, useEventId } from '../utils.ts';
import { SaveJobRibbon } from './SaveJobButton.tsx';

const JobCard = ({
    className,
    job,
    savedJob,
    organizationProfile,
    ...props
}: React.ComponentPropsWithRef<'div'> & {
    job: Job;
    savedJob?: SavedJob | null;
    organizationProfile?: OrganizationProfile;
}) => {
    const { t } = useTranslation('site');
    const eventId = useEventId();
    return (
        <div className={classnames('bg-base-100 rounded-2xl flex flex-col flex-1 relative', className)} {...props}>
            <SaveJobRibbon job={job} savedJob={savedJob} eventId={eventId} className="pt-8" />

            <Link
                className="bg-base-50 rounded-2xl p-8 flex flex-col gap-8 flex-1 hover:bg-base-100 transition-colors"
                to={siteUrl(SITE_JOB_DETAIL_PATH, { jobId: job.id, eventId })}
            >
                <div className="flex gap-4 flex-col flex-1">
                    <h2 className="text-lg font-semibold pr-12 text-balance">{job.title}</h2>
                    <p className="text-base-600">{job.summary}</p>
                </div>

                <div className="flex flex-col gap-2 h-full">
                    <span className="flex gap-2 items-center">
                        <ClockIcon /> {job.employmentType}
                    </span>
                    <span className="flex gap-2 items-center">
                        <MapPinIcon /> {job.location}
                    </span>
                </div>
            </Link>

            {organizationProfile && (
                <Link
                    className="px-8 py-5 hover:bg-base-150 transition-colors rounded-b-2xl flex items-center gap-4"
                    to={siteUrl(SITE_ORGANIZATION_DETAIL_PATH, {
                        organizationId: organizationProfile.organizationId,
                        eventId,
                    })}
                >
                    <Image
                        src={organizationProfile?.logo?.url}
                        alt="-"
                        className="h-6 w-6 rounded-full bg-base-200 border-transparent text-transparent"
                    />

                    <span className="text-base-900 font-medium">{organizationProfile?.name}</span>

                    <span className="ml-auto">
                        <ChevronRightIcon strokeWidth={2} />
                    </span>
                </Link>
            )}
        </div>
    );
};

export default JobCard;
