import React, { createContext, useContext, useEffect, useState } from 'react';
import colors from 'tailwindcss/colors';

import { useControllableState } from '@nicoknoll/utils';

const entries = Object.entries(Object.getOwnPropertyDescriptors(colors)).filter(([key, descriptor]) => {
    return typeof descriptor.get !== 'function' && typeof descriptor.value !== 'string';
});

const tailwindColors = {
    ...Object.fromEntries(entries.map(([key, descriptor]) => [key, descriptor.value])),
    emerald: {
        50: '#f2fbf3',
        100: '#e1f7e4',
        200: '#c4eecb',
        300: '#96dfa2',
        400: '#6ccc7c',
        500: '#3aad4d',
        600: '#2b8e3c',
        700: '#257032',
        800: '#22592c',
        900: '#1d4a26',
        950: '#0b2811',
    },
} as {
    [key: string]: {
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
        950: string;
    };
};

const {
    slate,
    gray,
    zinc,
    neutral,
    stone,
    red,
    orange,
    amber,
    yellow,
    lime,
    green,
    emerald,
    teal,
    cyan,
    sky,
    blue,
    indigo,
    violet,
    purple,
    fuchsia,
    pink,
    rose,
} = tailwindColors;

export const themeColors = {
    red,
    orange,
    amber,
    yellow,
    lime,
    green,
    emerald,
    teal,
    cyan,
    sky,
    blue,
    indigo,
    violet,
    purple,
    fuchsia,
    pink,
    rose,
};
export const baseColors = {
    slate: { ...slate, 150: '#e9eef4' },
    gray: { ...gray, 150: '#EFF0F2' },
    zinc: { ...zinc, 150: '#ececee' },
    neutral: { ...neutral, 150: '#ededed' },
    stone: { ...stone, 150: '#eeeceb' },
};

// Type-safe color names
export type ThemeColor = keyof typeof themeColors;
export type BaseColor = keyof typeof baseColors;

export const DEFAULT_THEME_COLOR: ThemeColor = 'emerald';
export const DEFAULT_BASE_COLOR: BaseColor = 'gray';

// Helper to convert hex to RGB values
const hexToRgb = (hex: string): string => {
    const cleanHex = hex.replace('#', '');
    const r = parseInt(cleanHex.slice(0, 2), 16);
    const g = parseInt(cleanHex.slice(2, 4), 16);
    const b = parseInt(cleanHex.slice(4, 6), 16);
    return `${r} ${g} ${b}`;
};

// Apply theme colors to CSS variables
export const applyThemeColor = (themeColor: ThemeColor, baseColor: BaseColor, rootNode = document.documentElement) => {
    const shades = [50, 100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 950] as const;

    // Set theme colors
    shades.forEach((shade) => {
        const hex = themeColors[themeColor][shade !== 150 ? shade : 100];
        rootNode.style.setProperty(`--theme-${shade}`, hexToRgb(hex));
    });

    // Set base colors
    shades.forEach((shade) => {
        const hex = baseColors[baseColor][shade];
        rootNode.style.setProperty(`--base-${shade}`, hexToRgb(hex));
    });
};

const ThemeContext = createContext({
    theme: DEFAULT_THEME_COLOR as ThemeColor,
    setTheme: (theme: ThemeColor) => {},
    base: DEFAULT_BASE_COLOR as BaseColor,
    setBase: (base: BaseColor) => {},
});

export const ThemeProvider = ({
    children,
    theme: propsTheme,
    onThemeChange,
    base: propsBase,
    onBaseChange,
    rootNode = document.documentElement,
}: {
    children: React.ReactNode;
    theme?: ThemeColor;
    onThemeChange?: (theme: ThemeColor) => void;
    base?: BaseColor;
    onBaseChange?: (base: BaseColor) => void;
    rootNode?: HTMLElement;
}) => {
    const [theme, setTheme] = useControllableState(DEFAULT_THEME_COLOR, propsTheme, onThemeChange);
    const [base, setBase] = useControllableState(DEFAULT_BASE_COLOR, propsBase, onBaseChange);

    useEffect(() => {
        applyThemeColor(theme, base, rootNode);
    }, [theme, base, rootNode]);

    return <ThemeContext.Provider value={{ theme, setTheme, base, setBase }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
