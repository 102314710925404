import React from 'react';
import { Link } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useQuery } from '@tanstack/react-query';

import CookieBar from '../../../components/CookieBar.tsx';
import Footer from '../../../components/Footer.tsx';
import Image from '../../../components/Image.tsx';
import { spaceDetailQuery } from '../../../data/queries.ts';
import url from '../../../utils/url';
import { HOME_PATH } from '../../site/routes.tsx';

const AuthCard = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div
        className={classnames(
            'w-full max-w-[26rem] max-h-[90vh] overflow-auto bg-base-100 rounded-xl shadow-lg shadow-black/5 flex flex-col border border-base-200 outline outline-8 outline-base-800/5 z-50',
            className
        )}
        {...props}
    />
);

const AuthCardContent = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div
        className={classnames(
            'p-10 flex flex-col gap-7 bg-white rounded-xl shadow-md shadow-base-500/5 z-50',
            className
        )}
        {...props}
    />
);

const AuthCardFooter = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div
        className={classnames(
            'px-10 py-7 flex flex-col gap-7 bg-base-100 text-center text-base-600 rounded-xl',
            className
        )}
        {...props}
    />
);

const Auth = ({ className, children, ...props }: React.ComponentPropsWithRef<'main'>) => {
    const { data: space } = useQuery(spaceDetailQuery());

    return (
        <main className={classnames('w-[100vw] h-[100vh] flex flex-col')} {...props}>
            <div className="w-[100vw] flex flex-1 items-center justify-center relative">
                <Image
                    src="/images/auth-background.png"
                    className="absolute w-[100vw] h-full object-cover select-none pointer-events-none"
                />

                <div className="w-full text-center absolute text-4xl font-bold top-20 text-white z-40">
                    <Link to={url(HOME_PATH)}>{space?.name}</Link>
                </div>

                {children}

                <Footer
                    className="flex-none pb-8 pt-32 absolute z-40 bottom-0 w-full bg-transparent border-none"
                    style={{
                        backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)`,
                    }}
                    isOnDarkBackground
                />

                <CookieBar />
            </div>
        </main>
    );
};

export default Object.assign(Auth, { Card: AuthCard, CardContent: AuthCardContent, CardFooter: AuthCardFooter });
