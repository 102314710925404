// src/modules/i18n/config.ts
import { Locale } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';
import i18n from 'i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { queryClient } from './utils/queryClient.ts';

// Dynamic imports of all locale files
const localeFiles = import.meta.glob('./locales/**/*.json', { eager: true });

// Process the imports into the required format
const resources = Object.keys(localeFiles).reduce(
    (acc, path) => {
        // Extract language and namespace from path
        // e.g., /src/locales/en/auth.json -> ['en', 'auth']
        const [lang, namespace] = path.match(/\/locales\/([^/]+)\/([^/]+)\.json/)?.slice(1) ?? [];

        if (lang && namespace) {
            // Initialize language object if it doesn't exist
            if (!acc[lang]) {
                acc[lang] = {};
            }

            // Add translations for this namespace
            acc[lang][namespace] = (localeFiles[path] as any).default;
        }

        return acc;
    },
    {} as Record<string, Record<string, any>>
);

// Get all unique namespaces
const namespaces = Array.from(new Set(Object.values(resources).flatMap((lang) => Object.keys(lang))));

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        debug: import.meta.env.DEV,

        // React specific options
        react: {
            useSuspense: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'em', 'p', 'span', 'a', 'ul', 'li'],
        },

        // Dynamically set namespaces
        ns: namespaces,
        defaultNS: 'common',

        // allows html
        interpolation: {
            escapeValue: false,
        },
    });

i18next.on('languageChanged', (lng) => {
    void queryClient.invalidateQueries();
});

interface Locales {
    [key: string]: Locale;
}

export const getDateFnsLocale = (): Locale => {
    const locales: Locales = {
        en: dateFnsLocales.enUS,
        de: dateFnsLocales.de,
    };

    return locales[i18n.language];
};

// Export type for each namespace (if you want type safety)
type TranslationResource = typeof resources.en;
export type Namespaces = keyof TranslationResource;
export type TranslationType<T extends Namespaces> = TranslationResource[T];

export default i18n;
