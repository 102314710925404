import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Organization, OrganizationProfile, User, UserProfile } from '../../data/models.ts';
import { eventDetailQuery } from '../../data/queries.ts';
import { isUuid } from '../../data/utils.ts';
import url from '../../utils/url';

const FRONTEND_URL = process.env.FRONTEND_URL;

export const isCustomDomain = () => {
    // TODO: differentiate between event domain and space domain
    return window.location.origin !== FRONTEND_URL;
};

export const siteUrl = (path: string, params?: {}) => {
    params = params
        ? Object.fromEntries(Object.entries(params).map(([key, value]) => [key, value == null ? ':' + key : value]))
        : {};

    // if url starts with /events/:eventId/ then replace it with /
    if (isCustomDomain()) {
        path = path.replace('/events/:eventId/', '/').replace('/events/:eventId', '/');
    } else {
        const isAdmin = window.location.pathname.startsWith('/admin'); // not on admin as this is only for site specific urls

        if (!isAdmin) {
            const eventId = window.location.pathname.split('/')[2];
            if (eventId && !isUuid(eventId)) {
                params = { ...params, eventId };
            }
        }
    }

    return url(path, params);
};

export const useEventId = () => {
    // either from params or from query as we allow for custom domains to have an /events/me endpoint in the api
    const { eventId } = useParams();
    const isAdmin = window.location.pathname.startsWith('/admin'); // not on admin as this is only for site specific urls
    const { data: event } = useQuery({ ...eventDetailQuery(!isAdmin ? eventId : undefined), staleTime: Infinity });
    return event?.id!;
};

export const mergeUserProfile = (user: User, profile: UserProfile): UserProfile => ({
    ...profile,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    image: user.image,
});

const analyzeEdgeColors = (img: HTMLImageElement, stripWidth = 20) => {
    // Create canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const width = img.naturalWidth;
    const height = img.naturalHeight;

    // Set dimensions
    canvas.width = width;
    canvas.height = height;

    // Draw image
    ctx?.drawImage(img, 0, 0);

    // Get left and right strips
    const leftStrip = ctx?.getImageData(0, 0, stripWidth, height).data;
    const rightStrip = ctx?.getImageData(width - stripWidth, 0, stripWidth, height).data;

    // Calculate average colors
    const leftColor = leftStrip ? getAverageColor(leftStrip) : { r: 0, g: 0, b: 0 };
    const rightColor = rightStrip ? getAverageColor(rightStrip) : { r: 0, g: 0, b: 0 };

    return {
        leftColor: `rgb(${leftColor.r}, ${leftColor.g}, ${leftColor.b})`,
        rightColor: `rgb(${rightColor.r}, ${rightColor.g}, ${rightColor.b})`,
    };
};

const getAverageColor = (data: Uint8ClampedArray) => {
    let r = 0,
        g = 0,
        b = 0;
    let count = 0;

    for (let i = 0; i < data.length; i += 4) {
        r += data[i];
        g += data[i + 1];
        b += data[i + 2];
        count++;
    }

    // Calculate averages and round to integers
    return {
        r: Math.round(r / count),
        g: Math.round(g / count),
        b: Math.round(b / count),
    };
};

const colorCache = new Map<string, { leftColor: string; rightColor: string }>();

export const getBackgroundColor = (
    imageUrl: string,
    stripWidth = 20
): Promise<{ leftColor: string; rightColor: string }> => {
    return new Promise<{ leftColor: string; rightColor: string }>((resolve, reject) => {
        const cachedResult = colorCache.get(imageUrl);
        if (cachedResult) {
            resolve(cachedResult);
            return;
        }

        const img = new Image();
        img.crossOrigin = 'Anonymous';

        img.onload = function () {
            try {
                const colors = analyzeEdgeColors(img, stripWidth);
                // Store in cache before returning
                colorCache.set(imageUrl, colors);
                resolve(colors);
            } catch (error) {
                colorCache.delete(imageUrl);
                reject(error);
            }
        };

        img.onerror = function (error) {
            colorCache.delete(imageUrl);
            reject(new Error(`Failed to load image: ${imageUrl}`));
        };

        img.src = imageUrl;
    });
};
