import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { BooleanField, Field, Form } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import {
    userDetailQuery,
    userNotificationSettingsCreateMutation,
    userNotificationSettingsListQuery,
    userNotificationSettingsUpdateMutation,
} from '../../../../data/queries.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import Admin from '../../components/Admin.tsx';

const NOTIFICATIONS_FORM_DEFAULT_VALUES = {
    productUpdates: true,
    conversationStarted: true,
    conversationAssigned: true,
    conversationMessageReceived: true,
    eventStarted: true,
    eventSessionStarted: true,
    eventSessionJoined: true,
    eventSessionLeft: true,
};

const NotificationLabelText = ({ label, helpText }: { label?: React.ReactNode; helpText?: React.ReactNode }) => (
    <div className="flex-1 flex items-center justify-between gap-5 w-full">
        <span className="flex flex-col gap-1">
            {label && <span>{label}</span>}
            {helpText && <span className="text-sm text-base-500 font-normal">{helpText}</span>}
        </span>
    </div>
);

const NotificationLabel = (props: React.ComponentPropsWithRef<typeof Field.Label>) => (
    <Field.Label
        className="flex-1 rounded-xl bg-base-50 p-5 flex gap-5 items-center justify-between w-full"
        {...props}
    />
);

const NotificationsTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { data: viewer } = useQuery(userDetailQuery());
    const { data: userNotificationSettingsList } = useQuery({
        ...userNotificationSettingsListQuery({ userId: viewer?.id! }),
        enabled: Boolean(viewer?.id),
    });
    const userNotificationSettings = userNotificationSettingsList?.[0];

    const { mutateAsync: createUserNotificationSettings } = useMutation(userNotificationSettingsCreateMutation());
    const { mutateAsync: updateUserNotificationSettings } = useMutation(userNotificationSettingsUpdateMutation());

    const formMethods = useForm({
        defaultValues: pickFormValues(NOTIFICATIONS_FORM_DEFAULT_VALUES, userNotificationSettings),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(NOTIFICATIONS_FORM_DEFAULT_VALUES, userNotificationSettings));
    }, [userNotificationSettings]);

    const handleSubmit = async (values: any) => {
        try {
            if (!userNotificationSettings) {
                await createUserNotificationSettings({ data: { userId: viewer?.id!, ...values } });
            } else {
                await updateUserNotificationSettings({ id: userNotificationSettings?.id!, data: values });
            }
            toast.success(t('userSettingsPage.notificationsTab.submitSuccess'));
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('userSettingsPage.notificationsTab.submitError'));
        }
    };

    return (
        <Admin.Card className="flex flex-col gap-5">
            <h1 className="text-3xl font-semibold mb-2">{t('userSettingsPage.notificationsTab.title')}</h1>

            <p className="text-base-700">{t('userSettingsPage.notificationsTab.description')}</p>

            <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                <FormError />

                <h2 className="font-semibold text-lg mt-2">{t('userSettingsPage.notificationsTab.generalTitle')}</h2>

                <NotificationLabel>
                    <NotificationLabelText label={t('userSettingsPage.notificationsTab.productUpdatesLabel')} />

                    <Form.Field name="productUpdates">
                        <BooleanField />
                    </Form.Field>
                </NotificationLabel>

                <h2 className="font-semibold mt-2 text-lg">
                    {t('userSettingsPage.notificationsTab.conversationTitle')}
                </h2>

                <NotificationLabel>
                    <NotificationLabelText label={t('userSettingsPage.notificationsTab.conversationStartedLabel')} />

                    <Form.Field name="conversationStarted">
                        <BooleanField />
                    </Form.Field>
                </NotificationLabel>

                <NotificationLabel>
                    <NotificationLabelText label={t('userSettingsPage.notificationsTab.conversationAssignedLabel')} />
                    <Form.Field name="conversationAssigned">
                        <BooleanField />
                    </Form.Field>
                </NotificationLabel>

                <NotificationLabel>
                    <NotificationLabelText
                        label={t('userSettingsPage.notificationsTab.conversationMessageReceivedLabel')}
                    />
                    <Form.Field name="conversationMessageReceived">
                        <BooleanField />
                    </Form.Field>
                </NotificationLabel>

                <h2 className="font-semibold mt-2 text-lg">{t('userSettingsPage.notificationsTab.eventTitle')}</h2>

                <NotificationLabel>
                    <NotificationLabelText label={t('userSettingsPage.notificationsTab.eventStartedLabel')} />

                    <Form.Field name="eventStarted">
                        <BooleanField />
                    </Form.Field>
                </NotificationLabel>

                <NotificationLabel>
                    <NotificationLabelText label={t('userSettingsPage.notificationsTab.eventSessionStartedLabel')} />

                    <Form.Field name="eventSessionStarted">
                        <BooleanField />
                    </Form.Field>
                </NotificationLabel>

                <NotificationLabel>
                    <NotificationLabelText label={t('userSettingsPage.notificationsTab.eventSessionJoinedLabel')} />

                    <Form.Field name="eventSessionJoined">
                        <BooleanField />
                    </Form.Field>
                </NotificationLabel>

                <NotificationLabel>
                    <NotificationLabelText label={t('userSettingsPage.notificationsTab.eventSessionLeftLabel')} />

                    <Form.Field name="eventSessionLeft">
                        <BooleanField />
                    </Form.Field>
                </NotificationLabel>

                <div className="flex justify-end mt-2">
                    <FormSubmitButton variant="primary">
                        {t('userSettingsPage.notificationsTab.submitButton')}
                    </FormSubmitButton>
                </div>
            </Form>
        </Admin.Card>
    );
};

export default NotificationsTab;
