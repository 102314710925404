import React from 'react';

import { Form } from '@nicoknoll/forms';

import { ErrorAlert } from './Alert.tsx';

const FormError = (props: React.ComponentPropsWithRef<'div'>) => {
    return (
        <Form.Error asChild>
            <ErrorAlert {...props}>
                <Form.ErrorMessage />
            </ErrorAlert>
        </Form.Error>
    );
};

export default FormError;
