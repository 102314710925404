import { MinusIcon, PlusIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
    BooleanField,
    Field,
    FileField,
    Form,
    SelectField,
    StringField,
    TextField,
    useWidgetState,
} from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import WysiwygInput from '../../../../components/WysiwygInput.tsx';
import { TRANSLATABLE_USER_PROFILE_KEYS, createTranslatable } from '../../../../data/models.ts';
import { userDetailQuery, userProfileDetailQuery, userProfileUpdateMutation } from '../../../../data/queries.ts';
import { getOrCreateFileId } from '../../../../utils/files.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import Admin from '../../components/Admin.tsx';
import TranslatableFormField from '../../components/TranslatableFormField.tsx';

const LANGUAGE_CODES = [
    'af',
    'sq',
    'am',
    'ar',
    'hy',
    'az',
    'be',
    'bn',
    'my',
    'bs',
    'bg',
    'zh',
    'da',
    'fa',
    'de',
    'en',
    'et',
    'fo',
    'fi',
    'fr',
    'ka',
    'el',
    'kl',
    'he',
    'hi',
    'id',
    'ga',
    'is',
    'it',
    'ja',
    'kk',
    'ca',
    'km',
    'ky',
    'rn',
    'sw',
    'ko',
    'hr',
    'ku',
    'la',
    'lv',
    'lt',
    'lb',
    'mt',
    'mi',
    'mr',
    'mk',
    'ro',
    'mn',
    'ne',
    'nl',
    'no',
    'pa',
    'ps',
    'pl',
    'pt',
    'ru',
    'sv',
    'sr',
    'sk',
    'sl',
    'so',
    'es',
    'tg',
    'te',
    'th',
    'cs',
    'ce',
    'tr',
    'tk',
    'uk',
    'hu',
    'ur',
    'uz',
    'vi',
];
const LANGUAGE_LEVELS = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

const LANGUAGE_FORM_DEFAULT_VALUES = { languageCode: '', level: '' };

const GENERAL_FORM_DEFAULT_VALUES = createTranslatable(
    {
        headline: '',
        careerObjective: '',
        description: '',
        skills: '',
        location: '',
        cv: undefined,
        languages: [],
        isPublic: true,
    },
    TRANSLATABLE_USER_PROFILE_KEYS
);

const LanguageForm = ({
    languageCode,
    onLanguageCodeChange,
    level,
    onLevelChange,
}: {
    languageCode?: string;
    onLanguageCodeChange?: (value: string) => void;
    level?: string;
    onLevelChange?: (value: string) => void;
}) => {
    const { t } = useTranslation('common');
    return (
        <div className="flex gap-2">
            <SelectField
                className="w-60"
                value={languageCode}
                onChange={(e) => onLanguageCodeChange?.(e.target.value)}
                // @ts-ignore
                options={LANGUAGE_CODES.map((code) => ({ value: code, label: t(`languageCode.${code}`) }))}
            />
            <SelectField
                className="w-60"
                value={level}
                onChange={(e) => onLevelChange?.(e.target.value)}
                // @ts-ignore
                options={LANGUAGE_LEVELS.map((level) => ({ value: level, label: t(`languageLevel.${level}`) }))}
            />
        </div>
    );
};

const LanguagesFields = ({ value: propsValue, onChange, name, ...props }: any) => {
    const { t } = useTranslation('admin');

    const [value, setValue] = useWidgetState([{ ...LANGUAGE_FORM_DEFAULT_VALUES }], propsValue, onChange);
    const languages = value.length > 0 ? value : [{ ...LANGUAGE_FORM_DEFAULT_VALUES }];

    const handleAddLanguage = () => {
        setValue({ target: { value: [...value, { ...LANGUAGE_FORM_DEFAULT_VALUES }] } } as any);
    };

    const handleRemoveLanguage = (index: number) => {
        setValue({ target: { value: value.filter((_: any, i: number) => i !== index) } } as any);
    };

    const handleLanguageChange = (index: number, key: string, newValue: any) => {
        // only submit values if any data is given as this field is optional
        setValue({
            target: {
                value: languages
                    .map((language: any, i: number) => (i === index ? { ...language, [key]: newValue } : language))
                    .filter((language: any) => language.languageCode || language.level),
            },
        } as any);
    };

    return (
        <div className="flex flex-col gap-2">
            <div className="flex gap-2">
                <Field.Label className="w-60">{t('userSettingsPage.generalTab.languageCodeLabel')}</Field.Label>
                <Field.Label className="w-60">{t('userSettingsPage.generalTab.languageLevelLabel')}</Field.Label>
            </div>

            {languages?.map((language: typeof LANGUAGE_FORM_DEFAULT_VALUES, index: number) => (
                <div className="flex gap-2">
                    <LanguageForm
                        key={index}
                        {...language}
                        onLanguageCodeChange={(value) => handleLanguageChange(index, 'languageCode', value)}
                        onLevelChange={(value) => handleLanguageChange(index, 'level', value)}
                    />
                    <Button
                        type="button"
                        onClick={() => handleRemoveLanguage(index)}
                        disabled={languages.length === 1}
                        loadingDelay={0}
                    >
                        <MinusIcon />
                    </Button>

                    {index === languages.length - 1 && (
                        <Button type="button" onClick={handleAddLanguage} loadingDelay={0}>
                            <PlusIcon />
                        </Button>
                    )}
                </div>
            ))}
        </div>
    );
};

const GeneralTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { data: viewer } = useQuery(userDetailQuery());

    const { data: viewerProfile } = useQuery(userProfileDetailQuery(undefined, { translatable: true }));
    const { mutateAsync: updateUserProfile } = useMutation(userProfileUpdateMutation());

    const formMethods = useForm({
        defaultValues: pickFormValues(GENERAL_FORM_DEFAULT_VALUES, viewerProfile),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(GENERAL_FORM_DEFAULT_VALUES, viewerProfile));
    }, [viewerProfile]);

    const handleSubmit = async (values: any) => {
        try {
            const { cv, ...formData } = values;
            const cvId = await getOrCreateFileId(cv, { userId: viewer?.id! });
            await updateUserProfile({ id: viewerProfile?.id!, data: { cvId, ...formData }, translatable: true });
            toast.success(t('userSettingsPage.generalTab.submitSuccess'));
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('userSettingsPage.generalTab.submitError'));
        }
    };

    return (
        <Admin.Card className="flex flex-col gap-5">
            <h1 className="text-3xl font-semibold mb-2">{t('userSettingsPage.generalTab.title')}</h1>

            <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                <FormError />

                <div className="flex gap-5 w-full">
                    <StringField
                        label={t('userSettingsPage.generalTab.firstNameLabel')}
                        value={viewer?.firstName}
                        required
                        className="w-full"
                        disabled={true}
                        helpText={t('userSettingsPage.generalTab.firstNameHelpText')}
                    />

                    <StringField
                        label={t('userSettingsPage.generalTab.lastNameLabel')}
                        value={viewer?.lastName}
                        required
                        className="w-full"
                        disabled={true}
                        helpText={t('userSettingsPage.generalTab.lastNameHelpText')}
                    />
                </div>

                <TranslatableFormField
                    name="headline"
                    rules={{ required: t('userSettingsPage.generalTab.headlineRequired') }}
                >
                    <StringField
                        label={t('userSettingsPage.generalTab.headlineLabel')}
                        required
                        helpText={t('userSettingsPage.generalTab.headlineHelpText')}
                    />
                </TranslatableFormField>

                <TranslatableFormField name="careerObjective">
                    <TextField
                        label={t('userSettingsPage.generalTab.careerObjectiveLabel')}
                        helpText={t('userSettingsPage.generalTab.careerObjectiveHelpText')}
                    />
                </TranslatableFormField>

                <TranslatableFormField name="description">
                    <TextField label={t('userSettingsPage.generalTab.descriptionLabel')} widget={WysiwygInput} />
                </TranslatableFormField>

                <TranslatableFormField name="skills">
                    <TextField
                        label={t('userSettingsPage.generalTab.skillsLabel')}
                        helpText={t('userSettingsPage.generalTab.skillsHelpText')}
                    />
                </TranslatableFormField>

                <Form.Field name="languages">
                    <LanguagesFields />
                </Form.Field>

                <TranslatableFormField
                    name="location"
                    rules={{ required: t('userSettingsPage.generalTab.locationRequired') }}
                >
                    <StringField
                        label={t('userSettingsPage.generalTab.locationLabel')}
                        placeholder={t('userSettingsPage.generalTab.locationPlaceholder')}
                        required
                    />
                </TranslatableFormField>

                <Form.Field name="cv">
                    <FileField
                        label={t('userSettingsPage.generalTab.cvLabel')}
                        placeholder={t('userSettingsPage.generalTab.cvPlaceholder')}
                        helpText={t('userSettingsPage.generalTab.cvHelpText')}
                    />
                </Form.Field>

                <div className="flex-1 flex justify-between items-center flex-wrap gap-5 rounded-xl bg-base-50 p-5">
                    <Form.Field name="isPublic">
                        <BooleanField
                            label={t('userSettingsPage.generalTab.isPublicLabel')}
                            helpText={t('userSettingsPage.generalTab.isPublicHelpText')}
                        />
                    </Form.Field>
                </div>

                <div className="flex justify-end">
                    <FormSubmitButton variant="primary">
                        {t('userSettingsPage.generalTab.submitButton')}
                    </FormSubmitButton>
                </div>
            </Form>
        </Admin.Card>
    );
};

export default GeneralTab;
