import { LayoutGridIcon, StretchHorizontal } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { StringField } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import Dialog from '../../../../components/Dialog.tsx';
import Image from '../../../../components/Image.tsx';
import MoreMenu from '../../../../components/MoreMenu.tsx';
import Pagination from '../../../../components/Pagination.tsx';
import { UploadedFile } from '../../../../data/models.ts';
import {
    fileCreateMutation,
    fileDeleteMutation,
    fileSearchQuery,
    fileUpdateMutation,
    organizationDetailQuery,
    organizationLimitsQuery,
} from '../../../../data/queries.ts';
import { formatFileSize, isImage } from '../../../../utils/files.ts';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import { useLimitAlert } from '../../utils.tsx';

const MAX_FILE_SIZE = 1000 * 1000 * 8; // 8MB

const FilePreview = ({ file }: { file: UploadedFile }) => {
    const fileUrl = file.url;
    const fileName = file.name || fileUrl.split('/').pop() || '';

    const ext = fileName.split('.').pop()?.toLowerCase() || '';
    const basename = fileName.split('/').pop() || '';

    if (isImage(ext)) {
        return (
            <a href={fileUrl} target="_blank" className="block rounded-md overflow-hidden">
                <Image src={fileUrl} alt={basename} className="w-full h-32 block object-cover flex-none" />
            </a>
        );
    }

    return (
        <a
            href={fileUrl}
            target="_blank"
            className={classnames(
                'w-full h-32 flex gap-2 items-center justify-center rounded-md overflow-hidden uppercase bg-base-200/50 text-base-700 font-medium',
                ['pdf'].includes(ext) && 'bg-red-100/50 text-red-700',
                ['doc', 'docx', 'md', 'txt'].includes(ext) && 'bg-blue-100/50 text-blue-700',
                ['xls', 'xlsx', 'csv', 'tsv'].includes(ext) && 'bg-green-100/50 text-green-700',
                ['ppt', 'pptx'].includes(ext) && 'bg-yellow-100/50 text-yellow-700'
            )}
        >
            {ext}
        </a>
    );
};

export const FileCard = ({
    view = 'grid',
    file,
    className,
    isTemp = false,
    ...props
}: { view: 'list' | 'grid'; file: UploadedFile; isTemp?: boolean } & React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('admin');

    const { mutateAsync: deleteFile } = useMutation(fileDeleteMutation());
    const { mutateAsync: updateFile } = useMutation(fileUpdateMutation());

    const [renameActive, setRenameActive] = useState(false);
    const [newName, setNewName] = useState(file.name);
    useEffect(() => {
        setNewName(file.name);
    }, [file.name]);

    const handleRename = async () => {
        try {
            const payload = file.organization?.id ? { organizationId: file.organization?.id } : { spaceOnly: true };
            await updateFile({ id: file.id, data: { name: newName }, ...payload });
            setRenameActive(false);
            toast.success(t('components.fileCard.renameSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('components.fileCard.renameError'));
        }
    };

    const handleOpen = async () => {
        try {
            const link = document.createElement('a');
            link.href = file.url;
            link.target = '_blank';
            link.rel = 'noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success(t('components.fileCard.openSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('components.fileCard.openError'));
        }
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(file.url);
            const blob = await response.blob();

            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = file.name || 'download';
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

            toast.success(t('components.fileCard.downloadSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('components.fileCard.downloadError'));
        }
    };

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(file.url);
            toast.success(t('components.fileCard.copyLinkSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('components.fileCard.copyLinkError'));
        }
    };

    const handleDelete = async () => {
        try {
            await deleteFile({ id: file.id });
            toast.success(t('components.fileCard.deleteSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('components.fileCard.deleteError'));
        }
    };

    return (
        <>
            <div
                className={classnames(
                    'flex flex-col gap-1.5 bg-base-100 p-2 rounded-xl',
                    isTemp && 'animate-pulse',
                    className
                )}
                {...props}
            >
                <div className="px-1.5 py-1 text-sm font-medium flex justify-between gap-1">
                    <a href={file.url} target="_blank" rel="noreferrer" className="flex-1 truncate">
                        {file.name}
                    </a>
                    <div className="text-xs text-base-400 pl-1.5 h-full text-right flex items-center">
                        {formatFileSize(file.size)}
                    </div>

                    {!isTemp && (
                        <MoreMenu className="-mr-2 -my-1.5 hover:bg-base-150 active:bg-base-300 data-[state=open]:bg-base-200">
                            <MoreMenu.Item onClick={handleOpen}>
                                <span>{t('components.fileCard.openButton')}</span>
                            </MoreMenu.Item>

                            <MoreMenu.Separator />

                            <MoreMenu.Item onClick={handleDownload}>
                                <span>{t('components.fileCard.downloadButton')}</span>
                            </MoreMenu.Item>

                            <MoreMenu.Item onClick={() => setRenameActive(true)}>
                                <span>{t('components.fileCard.renameButton')}</span>
                            </MoreMenu.Item>

                            <MoreMenu.Separator />

                            <MoreMenu.Item onClick={handleCopyLink}>
                                <span>{t('components.fileCard.copyLinkButton')}</span>
                            </MoreMenu.Item>

                            <MoreMenu.Separator />

                            <MoreMenu.Item danger onClick={handleDelete}>
                                <span>{t('components.fileCard.deleteButton')}</span>
                            </MoreMenu.Item>
                        </MoreMenu>
                    )}
                </div>

                {view === 'grid' && <FilePreview file={file} />}
            </div>

            {renameActive && (
                <Dialog open={renameActive} onOpenChange={setRenameActive}>
                    <Dialog.Content className="max-w-[40rem]">
                        <div className="flex flex-col gap-4">
                            <Dialog.Title>{t('components.fileCard.renameDialog.title')}</Dialog.Title>

                            <StringField
                                className="w-full"
                                value={newName}
                                name="name"
                                onChange={(e) => setNewName(e.target.value)}
                            />

                            <div className="flex gap-2 justify-end">
                                <Button onClick={() => setRenameActive(false)}>
                                    {t('components.fileCard.renameDialog.cancelButton')}
                                </Button>
                                <Button variant="primary" onClick={handleRename}>
                                    {t('components.fileCard.renameDialog.submitButton')}
                                </Button>
                            </div>
                        </div>
                    </Dialog.Content>
                </Dialog>
            )}
        </>
    );
};

export const FileUploadArea = ({
    onFilesDropped,
    className,
    isEmpty,
    disabled = false,
    ...props
}: {
    onFilesDropped?: (files: File[], fileRejections: FileRejection[]) => void;
    isEmpty?: boolean;
    disabled?: boolean;
} & React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('admin');

    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = (event: DragEvent | React.DragEvent) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event: DragEvent | React.DragEvent) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onFilesDropped,
        maxSize: MAX_FILE_SIZE,
        disabled,
    });

    useEffect(() => {
        document.addEventListener('dragenter', handleDragEnter);
        document.addEventListener('dragover', handleDragEnter);
        document.addEventListener('dragleave', handleDragLeave);
        document.addEventListener('drop', handleDragLeave);

        return () => {
            document.removeEventListener('dragenter', handleDragEnter);
            document.removeEventListener('dragover', handleDragEnter);
            document.removeEventListener('dragleave', handleDragLeave);
            document.removeEventListener('drop', handleDragLeave);
        };
    }, []);

    return (
        <div
            className={classnames(
                'bottom-0 w-full flex items-center justify-center gap-2 p-8 text-base-400 rounded-xl z-10 transition-colors',
                isDragging
                    ? 'absolute border-[3px] border-theme-400 border-solid inset-0 bg-theme-100/50 backdrop-blur font-medium text-theme-600'
                    : 'hover:bg-base-100 hover:text-base-600 cursor-pointer',
                disabled && 'pointer-events-none opacity-50',
                className
            )}
            {...getRootProps(props)}
        >
            <span className="text-center ">
                {isDragging ? (
                    t('organizationSettingsPage.filesTab.uploadAreaDragging')
                ) : isEmpty ? (
                    <EmptyState
                        title={t('organizationSettingsPage.filesTab.uploadAreaEmptyTitle')}
                        description={t('organizationSettingsPage.filesTab.uploadAreaPlaceholder')}
                    />
                ) : (
                    <span className="text-sm">{t('organizationSettingsPage.filesTab.uploadAreaPlaceholder')}</span>
                )}
            </span>
            <input {...getInputProps()} />
        </div>
    );
};

const FilesTab = (props: any) => {
    const { t } = useTranslation('admin');

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
    const pageSize = 30;

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());
    const { data: filesData, refetch: refetchFiles } = useQuery({
        ...fileSearchQuery({ page, pageSize, organizationId: viewerOrganization?.id! }),
        enabled: !!viewerOrganization,
        placeholderData: keepPreviousData,
    });
    const files = filesData?.results;

    const { mutateAsync: createFile } = useMutation(fileCreateMutation());

    const [tempFiles, setTempFiles] = useState<UploadedFile[]>([]);
    const [view, setView] = useState<'list' | 'grid'>('grid');

    const { data: limits } = useQuery(organizationLimitsQuery(viewerOrganization?.id!));
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: Math.round((limits?.storageSpaceUsed || 0) / 10000) / 100,
        limit: limits?.maxStorageSpace != null ? Math.round((limits?.maxStorageSpace || 0) / 10000) / 100 : undefined,
        limitTranslationKey: 'organizationSettingsPage.filesTab.limitAlert',
        limitReachedTranslationKey: 'organizationSettingsPage.filesTab.limitReachedAlert',
        displayThreshold: 0.5,
    });

    const handleFilesDropped = useCallback(async (files: File[], fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
            fileRejections.forEach((fileRejection) => {
                fileRejection.errors.forEach((error) => {
                    let translationKey = 'spaceSettingsPage.filesTab.uploadError';

                    if (error.code === 'file-too-large') {
                        translationKey = 'spaceSettingsPage.filesTab.uploadFileTooLargeError';
                    }

                    // @ts-ignore
                    toast.error(t(translationKey, { fileName: fileRejection.file.name }));
                });
            });
        }

        const newFiles: UploadedFile[] = files.map((file) => ({
            id: file.name,
            name: file.name,
            size: file.size,
            mimeType: file.type,
            url: URL.createObjectURL(file),
            organizationId: viewerOrganization?.id!,
        }));

        setTempFiles(newFiles);

        await Promise.all(
            files.map(async (file) => {
                try {
                    await createFile({
                        data: {
                            file: file,
                            organizationId: viewerOrganization?.id!,
                        },
                    });
                    toast.success(t('organizationSettingsPage.filesTab.uploadSuccess'));
                } catch (error) {
                    console.error(error);
                    toast.error(t('organizationSettingsPage.filesTab.uploadError'));
                }
            })
        );

        if (files.length > 0) {
            await refetchFiles();
        }

        setTempFiles([]);
    }, []);

    const handlePageChange = (newPage: number) => {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
    };

    return (
        <div className="flex flex-col gap-5">
            <Admin.Card className="flex justify-between gap-5">
                <h1 className="text-3xl font-semibold">{t('organizationSettingsPage.filesTab.title')}</h1>

                <div className="flex p-1 bg-base-100 rounded-md gap-1">
                    <Button
                        variant="ghost"
                        className={classnames(
                            'flex gap-1 items-center px-0 w-8 hover:bg-base-200 active:bg-base-300',
                            view === 'grid' && 'bg-white shadow-sm'
                        )}
                        onClick={() => setView('grid')}
                    >
                        <LayoutGridIcon />
                    </Button>

                    <Button
                        variant="ghost"
                        className={classnames(
                            'flex gap-1 items-center px-0 w-8 hover:bg-base-200 active:bg-base-300',
                            view === 'list' && 'bg-white shadow-sm'
                        )}
                        onClick={() => setView('list')}
                    >
                        <StretchHorizontal />
                    </Button>
                </div>
            </Admin.Card>

            <LimitAlert />

            <div className="flex flex-col gap-5 relative min-h-[28rem] z-0">
                <div className={classnames('grid grid-cols-3 gap-5', view === 'list' && 'grid-cols-1 gap-1')}>
                    {files?.map((file) => <FileCard key={file.id} file={file} view={view} />)}
                    {tempFiles.map((file) => (
                        <FileCard key={file.id} file={file} view={view} isTemp />
                    ))}
                </div>

                {!isLimitReached && (
                    <FileUploadArea
                        onFilesDropped={handleFilesDropped}
                        isEmpty={files && !files.length && !tempFiles.length}
                    />
                )}
            </div>

            <Pagination
                page={page}
                pageSize={pageSize}
                total={filesData?.count ? Number(filesData?.count) : 0}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default FilesTab;
