import { EllipsisVerticalIcon } from 'lucide-react';
import React from 'react';

import { classnames } from '@nicoknoll/utils';

import Button from './Button.tsx';
import DropdownMenu from './DropdownMenu.tsx';

const MoreMenu = ({ children, className, ...props }: React.ComponentPropsWithRef<typeof Button>) => {
    return (
        <DropdownMenu>
            <DropdownMenu.Trigger asChild>
                <Button
                    className={classnames(
                        'inline-flex items-center justify-center h-[2.125rem] w-[2.125rem] px-0',
                        className
                    )}
                    variant="ghost"
                    {...props}
                >
                    <EllipsisVerticalIcon />
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end">{children}</DropdownMenu.Content>
        </DropdownMenu>
    );
};

const MoreMenuItem = ({
    className,
    danger,
    ...props
}: React.ComponentPropsWithRef<typeof DropdownMenu.Item> & { danger?: boolean }) => {
    return (
        <DropdownMenu.Item
            {...props}
            className={classnames(danger && 'text-red-600 focus:bg-red-50 focus:text-red-700', className)}
        />
    );
};

export default Object.assign(MoreMenu, {
    Item: MoreMenuItem,
    Separator: DropdownMenu.Separator,
});
