import i18n from 'i18next';
import { isObject, snakeCase } from 'lodash';

import { call } from './utils';

export const API_URL = (import.meta.env.DEV ? 'http://localhost:8000' : window.location.origin) + '/api';

interface URLFieldParams {
    include?: string[];
    exclude?: string[];
}

interface URLTableParams {
    page?: number;
    pageSize?: number;
}

interface URLFilterParams {
    search?: { [key: string]: any };
}

export type InputFile = {
    name: string;
    url: string;
};

export interface SearchFilter {
    value: string;
    count?: number;
}

export interface PaginatedResponse<T> {
    filters: Record<string, SearchFilter[]>;
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export type UrlOptions = URLFieldParams & URLTableParams & URLFilterParams & { [key: string]: any };

export const isFile = (obj: unknown): obj is File => 'File' in window && obj instanceof File;
export const isBlob = (obj: unknown): obj is Blob => 'Blob' in window && obj instanceof Blob;

const deepCamelCaseToSnakeCase = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map((v) => deepCamelCaseToSnakeCase(v));
    } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
        return Object.keys(obj).reduce((result: any, key) => {
            const value = (obj as any)[key];
            const newKey: string = key.replace(/([A-Z])/g, '_$1').toLowerCase();
            result[newKey] = deepCamelCaseToSnakeCase(value);
            return result;
        }, {});
    } else {
        return obj;
    }
};

const deepSnakeCaseToCamelCase = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map((v) => deepSnakeCaseToCamelCase(v));
    } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
        return Object.keys(obj).reduce((result: any, key) => {
            const value: any = (obj as any)[key];
            const newKey: string = key.replace(/(_\w)/g, (k) => k[1].toUpperCase());
            result[newKey] = deepSnakeCaseToCamelCase(value);
            return result;
        }, {});
    } else {
        return obj;
    }
};

const extractFiles = (obj: any): any => {
    const files: any = {};
    Object.entries(obj).forEach(([key, value]) => {
        if (isFile(value)) {
            files[key] = value;
        }
    });
    return files;
};

const getCookie = (name: string) => {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export class BaseAPIClient<T> {
    baseUrl: string = API_URL;
    resource: string = '';
    listDataKey: string = 'results';

    call(path: string, method: string = 'GET', options: any = {}): any {
        const { include, exclude, page, pageSize, headers, body, files, ...search } = options;

        const parsedPath = new URL(path, this.baseUrl);

        if (include) {
            parsedPath.searchParams.set('include', include.join(','));
        }

        if (exclude) {
            parsedPath.searchParams.set('exclude', exclude.join(','));
        }

        if (page) {
            parsedPath.searchParams.set('page', page.toString());
        }

        if (pageSize) {
            parsedPath.searchParams.set('page_size', pageSize.toString());
        }

        if (Object.keys(search).length > 0) {
            Object.entries(search).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach((v) => parsedPath.searchParams.append(snakeCase(key), v as any));
                } else {
                    parsedPath.searchParams.append(snakeCase(key), value as any);
                }
            });
        }

        // TODO: there should be a better way
        if (!window.location.pathname.startsWith('/admin')) {
            parsedPath.searchParams.append('language', i18n.language);
        }

        return call(`${parsedPath.pathname}${parsedPath.search}`, method, {
            headers: {
                'X-CSRFToken': getCookie('csrftoken') || '',
                ...(headers || {}),
            },
            body,
            files,
        });
    }

    list(options: UrlOptions = {}): Promise<PaginatedResponse<T>> {
        return this.call(`/${this.resource}`, 'GET', options).then((data: any) => this.convertResponse(data));
    }

    search(options: UrlOptions = {}): Promise<PaginatedResponse<T>> {
        return this.call(`/${this.resource}/search`, 'GET', options).then((data: any) =>
            this.convertPaginatedList(data)
        );
    }

    create(data: Partial<T>, options: UrlOptions = {}): Promise<T> {
        const payload = deepCamelCaseToSnakeCase(data);
        return this.call(`/${this.resource}`, 'POST', {
            body: payload,
            files: extractFiles(payload),
            ...options,
        }).then((data: any) => this.convertResponse(data));
    }

    get(id: string, options: UrlOptions = {}): Promise<T> {
        return this.call(`/${this.resource}/${id}`, 'GET', options).then((data: any) => this.convertResponse(data));
    }

    update(id: string, data: Partial<T>, partial: boolean = true, options: UrlOptions = {}): Promise<T> {
        const method = partial ? 'PATCH' : 'PUT';
        const payload = deepCamelCaseToSnakeCase(data);

        return this.call(`/${this.resource}/${id}`, method, {
            body: payload,
            files: payload ? extractFiles(payload) : {},
            ...options,
        }).then((data: any) => this.convertResponse(data));
    }

    delete(id: string, options: UrlOptions = {}): Promise<null> {
        return this.call(`/${this.resource}/${id}`, 'DELETE', options);
    }

    protected convertResponse(data: any): any {
        if (Array.isArray(data)) {
            return this.convertList(data);
        } else if (typeof data === 'object' && data?.[this.listDataKey]) {
            return this.convertPaginatedList(data);
        } else if (typeof data === 'object' && data !== null) {
            return this.convertObject(data);
        } else {
            return data;
        }
    }

    protected convertObject(data: any): T {
        return deepSnakeCaseToCamelCase(data);
    }

    protected convertList(data: any): T[] {
        return data.map((item: any) => this.convertObject(item));
    }

    protected convertPaginatedList(data: any): PaginatedResponse<T> {
        return {
            ...deepSnakeCaseToCamelCase(data),
            [this.listDataKey]: data[this.listDataKey].map((item: any) => this.convertObject(item)),
        };
    }
}

class AuthClient extends BaseAPIClient<any> {
    resource = 'auth';

    validate(): Promise<any> {
        return this.call(`/${this.resource}/session`, 'GET').then((data: any) => this.convertResponse(data));
    }

    login(data: any): Promise<any> {
        return this.call(`/${this.resource}/session`, 'POST', { body: deepCamelCaseToSnakeCase(data) }).then(
            (data: any) => this.convertResponse(data)
        );
    }

    logout(): Promise<any> {
        return this.call(`/${this.resource}/session`, 'DELETE');
    }

    startSupportSession(organizationId: string): Promise<any> {
        return this.call(`/${this.resource}/support-session`, 'POST', {
            body: deepCamelCaseToSnakeCase({ organizationId }),
        }).then((data: any) => this.convertResponse(data));
    }

    getSupportSession(): Promise<any> {
        return this.call(`/${this.resource}/support-session`, 'GET').then((data: any) => this.convertResponse(data));
    }

    endSupportSession(): Promise<any> {
        return this.call(`/${this.resource}/support-session`, 'DELETE').then((data: any) => this.convertResponse(data));
    }

    signup(data: any): Promise<any> {
        return this.call(`/${this.resource}/signup`, 'POST', { body: deepCamelCaseToSnakeCase(data) }).then(
            (data: any) => this.convertResponse(data)
        );
    }

    confirmEmail(token: string): Promise<any> {
        return this.call(`/${this.resource}/confirm-email/${token}`, 'POST').then((data: any) =>
            this.convertResponse(data)
        );
    }

    confirmInvitation(token: string, data: any): Promise<any> {
        return this.call(`/${this.resource}/confirm-invitation/${token}`, 'POST', {
            body: deepCamelCaseToSnakeCase(data),
        }).then((data: any) => this.convertResponse(data));
    }

    forgotPassword(data: any): Promise<any> {
        return this.call(`/${this.resource}/forgot-password`, 'POST', { body: deepCamelCaseToSnakeCase(data) }).then(
            (data: any) => this.convertResponse(data)
        );
    }

    resetPassword(token: string, data: any): Promise<any> {
        return this.call(`/${this.resource}/reset-password/${token}`, 'POST', {
            body: deepCamelCaseToSnakeCase(data),
        }).then((data: any) => this.convertResponse(data));
    }
}

class SpacesClient extends BaseAPIClient<any> {
    resource = 'spaces';

    getLimits(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/limits`, 'GET').then((data: any) => this.convertResponse(data));
    }
}

class OrganizationsClient extends BaseAPIClient<any> {
    resource = 'organizations';

    getLimits(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/limits`, 'GET').then((data: any) => this.convertResponse(data));
    }
}

class OrganizationProfilesClient extends BaseAPIClient<any> {
    resource = 'organization-profiles';

    getLimits(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/limits`, 'GET').then((data: any) => this.convertResponse(data));
    }
}

class OrganizationProfileJobsClient extends BaseAPIClient<any> {
    resource = 'organization-profile-jobs';
}

class OrganizationProfileUsersClient extends BaseAPIClient<any> {
    resource = 'organization-profile-users';
}

class JobsClient extends BaseAPIClient<any> {
    resource = 'jobs';
}

class UsersClient extends BaseAPIClient<any> {
    resource = 'users';

    updatePassword(id: string, data: any): Promise<any> {
        return this.call(`/${this.resource}/${id}/password`, 'POST', {
            body: deepCamelCaseToSnakeCase(data),
        }).then((data: any) => this.convertResponse(data));
    }

    resendInvitation(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/resend-invitation`, 'POST').then((data: any) =>
            this.convertResponse(data)
        );
    }

    resendConfirmEmail(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/resend-confirm-email`, 'POST').then((data: any) =>
            this.convertResponse(data)
        );
    }
}

class UserProfilesClient extends BaseAPIClient<any> {
    resource = 'user-profiles';
}

class EducationsClient extends BaseAPIClient<any> {
    resource = 'educations';
}

class ExperiencesClient extends BaseAPIClient<any> {
    resource = 'experiences';
}

class UserNotificationSettingsClient extends BaseAPIClient<any> {
    resource = 'user-notification-settings';
}

class UserNotesClient extends BaseAPIClient<any> {
    resource = 'user-notes';
}

class EventsClient extends BaseAPIClient<any> {
    resource = 'events';

    getLimits(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/limits`, 'GET').then((data: any) => this.convertResponse(data));
    }

    getAnalytics(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/analytics`, 'GET').then((data: any) => this.convertResponse(data));
    }
}

class EventAttendeesClient extends BaseAPIClient<any> {
    resource = 'event-attendees';
}

class EventSessionsClient extends BaseAPIClient<any> {
    resource = 'event-sessions';
}

class EventSessionUsersClient extends BaseAPIClient<any> {
    resource = 'event-session-users';
}

class EventPageClient extends BaseAPIClient<any> {
    resource = 'event-pages';
}

class ConversationsClient extends BaseAPIClient<any> {
    resource = 'conversations';

    markRead(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/mark-read`, 'POST').then((data: any) => this.convertResponse(data));
    }

    report(id: string, data: { targetUserId: string }): Promise<any> {
        return this.call(`/${this.resource}/${id}/report`, 'POST', {
            body: deepCamelCaseToSnakeCase(data),
        }).then((data: any) => this.convertResponse(data));
    }

    block(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/block`, 'POST').then((data: any) => this.convertResponse(data));
    }

    unblock(id: string): Promise<any> {
        return this.call(`/${this.resource}/${id}/unblock`, 'POST').then((data: any) => this.convertResponse(data));
    }
}

class MessagesClient extends BaseAPIClient<any> {
    resource = 'messages';
}

class SavedUsersClient extends BaseAPIClient<any> {
    resource = 'saved-users';
}

class SavedOrganizationsClient extends BaseAPIClient<any> {
    resource = 'saved-organizations';
}

class SavedJobsClient extends BaseAPIClient<any> {
    resource = 'saved-jobs';
}

class SavedEventSessionsClient extends BaseAPIClient<any> {
    resource = 'saved-event-sessions';
}

class LogEventsClient extends BaseAPIClient<any> {
    resource = 'reporting/log-events';

    trackingStatus(): Promise<any> {
        return this.call(`/${this.resource}/tracking-status`, 'GET').then((data: any) => this.convertResponse(data));
    }

    acceptTracking(): Promise<any> {
        return this.call(`/${this.resource}/accept-tracking`, 'POST').then((data: any) => this.convertResponse(data));
    }

    rejectTracking(): Promise<any> {
        return this.call(`/${this.resource}/reject-tracking`, 'POST').then((data: any) => this.convertResponse(data));
    }
}

class FileClient extends BaseAPIClient<any> {
    resource = 'files';
}

class ScheduledEmailClient extends BaseAPIClient<any> {
    resource = 'scheduled-emails';
}

class API {
    public auth: AuthClient;
    public spaces: SpacesClient;
    public organizations: OrganizationsClient;
    public organizationProfiles: OrganizationProfilesClient;
    public organizationProfileJobs: OrganizationProfileJobsClient;
    public organizationProfileUsers: OrganizationProfileUsersClient;
    public jobs: JobsClient;
    public users: UsersClient;
    public userProfiles: UserProfilesClient;
    public educations: EducationsClient;
    public experiences: ExperiencesClient;
    public userNotificationSettings: UserNotificationSettingsClient;
    public userNotes: UserNotesClient;
    public events: EventsClient;
    public eventAttendees: EventAttendeesClient;
    public eventSessions: EventSessionsClient;
    public eventSessionUsers: EventSessionUsersClient;
    public eventPages: EventPageClient;
    public conversations: ConversationsClient;
    public messages: MessagesClient;
    public savedUsers: SavedUsersClient;
    public savedOrganizations: SavedOrganizationsClient;
    public savedJobs: SavedJobsClient;
    public savedEventSessions: SavedEventSessionsClient;
    public logEvents: LogEventsClient;
    public files: FileClient;
    public scheduledEmails: ScheduledEmailClient;

    constructor() {
        this.auth = new AuthClient();
        this.spaces = new SpacesClient();
        this.organizations = new OrganizationsClient();
        this.organizationProfiles = new OrganizationProfilesClient();
        this.organizationProfileJobs = new OrganizationProfileJobsClient();
        this.organizationProfileUsers = new OrganizationProfileUsersClient();
        this.jobs = new JobsClient();
        this.users = new UsersClient();
        this.userProfiles = new UserProfilesClient();
        this.educations = new EducationsClient();
        this.experiences = new ExperiencesClient();
        this.userNotificationSettings = new UserNotificationSettingsClient();
        this.userNotes = new UserNotesClient();
        this.events = new EventsClient();
        this.eventAttendees = new EventAttendeesClient();
        this.eventSessions = new EventSessionsClient();
        this.eventSessionUsers = new EventSessionUsersClient();
        this.eventPages = new EventPageClient();
        this.conversations = new ConversationsClient();
        this.messages = new MessagesClient();
        this.savedUsers = new SavedUsersClient();
        this.savedOrganizations = new SavedOrganizationsClient();
        this.savedJobs = new SavedJobsClient();
        this.savedEventSessions = new SavedEventSessionsClient();
        this.logEvents = new LogEventsClient();
        this.files = new FileClient();
        this.scheduledEmails = new ScheduledEmailClient();
    }
}

export default new API();
