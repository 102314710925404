import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { classnames } from '@nicoknoll/utils';
import { Slot } from '@radix-ui/react-slot';

import { Slottable } from './Button.tsx';

const ItemTypes = {
    CARD: 'card',
};

const DragDropRoot = ({ children }: { children: React.ReactNode }) => (
    <DndProvider backend={HTML5Backend}>{children}</DndProvider>
);

const DragDropContainer = ({
    className,
    children,
    asChild,
    onDrop,
    ...props
}: Omit<React.ComponentPropsWithRef<'div'>, 'onDrop'> & Slottable & { onDrop?: (item: any) => void }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        drop: (item: any, monitor) => {
            onDrop?.(item);
        },
    });

    drop(ref);

    const Comp = asChild ? Slot : 'div';
    return (
        <Comp className={classnames(className)} {...props} ref={ref}>
            {children}
        </Comp>
    );
};

const DragDropItem = ({
    index,
    onIndexChange,
    className,
    children,
    asChild,
    ...props
}: React.ComponentPropsWithRef<'div'> &
    Slottable & { index: number; onIndexChange: (dragIndex: number, hoverIndex: number) => void }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset() || { x: 0, y: 0 };
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            item.index = hoverIndex;
            onIndexChange(dragIndex, hoverIndex);
        },
    });

    drag(drop(ref));

    const Comp = asChild ? Slot : 'div';
    return (
        <Comp
            ref={ref}
            data-handler-id={handlerId}
            data-dragging={isDragging}
            className={classnames(isDragging ? 'cursor-grabbing' : 'cursor-grab', className)}
            {...props}
        >
            {children}
        </Comp>
    );
};

export default Object.assign(DragDropRoot, { Container: DragDropContainer, Item: DragDropItem });
