import { authorized, authorizedLoader, isAdminUser } from '../../../utils/loaders.ts';
import { organizationListLoader } from './loaders.ts';
import OrganizationListPage, {
    AddOrganizationFormDialog,
    EditOrganizationLimitsFormDialog,
} from './pages/OrganizationListPage.tsx';

export const ADMIN_ORGANIZATION_LIST_PATH = '/admin/organizations';
export const ADMIN_ORGANIZATION_ADD_PATH = '/admin/organizations/add';
export const ADMIN_ORGANIZATION_EDIT_LIMITS_PATH = '/admin/organizations/:organizationId/limits';

export default [
    {
        handle: {
            title: 'Unternehmen',
        },
        path: ADMIN_ORGANIZATION_LIST_PATH,
        element: <OrganizationListPage />,
        loader: authorizedLoader(organizationListLoader, isAdminUser),
        children: [
            {
                handle: {
                    title: 'Unternehmen hinzufügen',
                },
                path: ADMIN_ORGANIZATION_ADD_PATH,
                element: <AddOrganizationFormDialog />,
                loader: authorized(isAdminUser),
            },
            {
                handle: {
                    title: 'Unternehmens Limits bearbeiten',
                },
                path: ADMIN_ORGANIZATION_EDIT_LIMITS_PATH,
                element: <EditOrganizationLimitsFormDialog />,
                loader: authorized(isAdminUser),
            },
        ],
    },
];
