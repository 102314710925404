import api from '../../data/api.ts';

export const signupMutation = () => ({
    mutationKey: ['signup'],
    mutationFn: async (data: any) => {
        console.debug('signup', data);
        await api.auth.signup(data);
        return null;
    },
});

export const confirmEmailMutation = () => ({
    mutationKey: ['confirmEmail'],
    mutationFn: async (token: string) => {
        console.debug('confirmEmail', token);
        await api.auth.confirmEmail(token);
        return null;
    },
});

export const confirmInvitationMutation = () => ({
    mutationKey: ['confirmInvitation'],
    mutationFn: async ({ token, data }: { token: string; data: any }) => {
        console.debug('confirmInvitation', token, data);
        await api.auth.confirmInvitation(token, data);
        return null;
    },
});

export const loginMutation = () => ({
    mutationKey: ['login'],
    mutationFn: async ({
        email,
        password,
        remember = false,
    }: {
        email: string;
        password: string;
        remember: boolean;
    }) => {
        console.debug('login', email);
        await api.auth.login({ email, password, remember });
        return null;
    },
});

export const startSupportSessionMutation = () => ({
    mutationKey: ['startSupportSession'],
    mutationFn: async ({ organizationId }: { organizationId: string }) => {
        console.debug('startSupportSession', organizationId);
        await api.auth.startSupportSession(organizationId);
        return null;
    },
});

export const endSupportSessionMutation = () => ({
    mutationKey: ['endSupportSession'],
    mutationFn: async () => {
        console.debug('endSupportSession');
        await api.auth.endSupportSession();
        return null;
    },
});

export const logoutMutation = () => ({
    mutationKey: ['logout'],
    mutationFn: async () => {
        console.debug('logout');
        await api.auth.logout();
        return null;
    },
});

export const forgotPasswordMutation = () => ({
    mutationKey: ['forgotPassword'],
    mutationFn: async (data: any) => {
        console.debug('forgotPassword', data);
        await api.auth.forgotPassword(data);
        return null;
    },
});

export const resetPasswordMutation = () => ({
    mutationKey: ['resetPassword'],
    mutationFn: async ({ token, data }: { token: string; data: any }) => {
        console.debug('resetPassword', token, data);
        await api.auth.resetPassword(token, data);
        return null;
    },
});
