import {
    ClockIcon,
    FactoryIcon,
    GlobeIcon,
    GraduationCapIcon,
    HandCoinsIcon,
    LanguagesIcon,
    LaptopIcon,
    LinkIcon,
    MapPinIcon,
    RulerIcon,
    UsersRoundIcon,
} from 'lucide-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import Anchor from '../../../components/Anchor.tsx';
import Image from '../../../components/Image.tsx';
import { LogEventType } from '../../../data/models.ts';
import { organizationProfileDetailQuery, organizationProfileJobListQuery } from '../../../data/queries.ts';
import { useLogEventOnMount } from '../../../utils/useLogEvent.ts';
import InfoBox from '../components/InfoBox.tsx';
import OrganizationProfileCard from '../components/OrganizationProfileCard.tsx';
import { SaveJobButton } from '../components/SaveJobButton.tsx';
import { getBackgroundColor, useEventId } from '../utils.ts';

const JobDetailPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { jobId } = useParams();
    const { data: profileJobData } = useQuery(organizationProfileJobListQuery({ jobId }));
    const profileJob = profileJobData?.[0];

    const job = profileJob?.job;

    const { data: organizationProfile } = useQuery(organizationProfileDetailQuery(profileJob?.organizationProfileId!));

    const bannerWrapperRef = React.createRef<HTMLDivElement>();
    useEffect(() => {
        const magicBannerBackground = async () => {
            if (!bannerWrapperRef.current || !organizationProfile?.banner?.url) return;
            const colors = await getBackgroundColor(organizationProfile?.banner?.url!);
            if (!colors) return;
            bannerWrapperRef.current.style.background = `linear-gradient(180deg, ${colors.leftColor} 0%, ${colors.rightColor} 100%)`;
        };
        magicBannerBackground();
    }, [organizationProfile?.banner?.url]);

    useLogEventOnMount(LogEventType.JOB_VIEW, { targetId: jobId });

    return (
        <div className={className} {...props}>
            <div className="flex justify-center bg-base-50 px-6 transition-colors" ref={bannerWrapperRef}>
                <Image
                    src={organizationProfile?.banner?.url}
                    alt={organizationProfile?.name}
                    className="w-full max-w-[1120px] h-72 object-cover object-center"
                />
            </div>

            <main className="flex justify-center bg-white px-6 py-24">
                <div className="w-full max-w-[1120px] flex flex-col gap-32">
                    <section className="flex gap-10 -mb-6">
                        <div className="flex flex-col gap-24 w-8/12">
                            <section className="flex flex-col gap-10">
                                <h1 className="font-semibold text-5xl text-balance leading-snug">{job?.title}</h1>
                                <span className="text-base-600 text-2xl">{organizationProfile?.name}</span>

                                <div
                                    className="styled"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            job?.description ||
                                            job?.summary ||
                                            `<p>${t('jobDetailPage.noDescription')}</p>`,
                                    }}
                                />
                            </section>

                            <OrganizationProfileCard organizationProfile={organizationProfile!} />
                        </div>

                        <div className="flex flex-col gap-12 w-4/12">
                            <InfoBox
                                badge={
                                    <InfoBox.Badge className="bg-base-500">
                                        {t('jobDetailPage.detailsBoxBadge')}
                                    </InfoBox.Badge>
                                }
                            >
                                <div className="flex flex-col gap-3">
                                    <InfoBox.Row
                                        icon={<ClockIcon />}
                                        label={t('jobDetailPage.employmentType')}
                                        value={job?.employmentType}
                                    />

                                    <InfoBox.Row
                                        icon={<MapPinIcon />}
                                        label={t('jobDetailPage.location')}
                                        value={job?.location}
                                    />

                                    <InfoBox.Row
                                        icon={<GraduationCapIcon />}
                                        label={t('jobDetailPage.qualification')}
                                        value={job?.qualification}
                                    />

                                    <InfoBox.Row
                                        icon={<LaptopIcon />}
                                        label={t('jobDetailPage.remoteWork')}
                                        value={job?.remoteWork}
                                    />

                                    <InfoBox.Row
                                        icon={<HandCoinsIcon />}
                                        label={t('jobDetailPage.remunerationType')}
                                        value={job?.remunerationType}
                                    />

                                    <InfoBox.Row
                                        icon={<LinkIcon />}
                                        value={job?.url && <Anchor href={job.url}>{job?.url}</Anchor>}
                                        truncate
                                    />
                                </div>
                            </InfoBox>

                            <InfoBox
                                badge={
                                    <InfoBox.Badge className="bg-base-500">
                                        {t('jobDetailPage.organizationBoxBadge')}
                                    </InfoBox.Badge>
                                }
                            >
                                <InfoBox.Row
                                    icon={<FactoryIcon />}
                                    label={t('jobDetailPage.industry')}
                                    value={organizationProfile?.industry}
                                />

                                <InfoBox.Row
                                    icon={<GlobeIcon />}
                                    label={t('jobDetailPage.locationScope')}
                                    value={organizationProfile?.locationScope}
                                />

                                <InfoBox.Row
                                    icon={<UsersRoundIcon />}
                                    label={t('jobDetailPage.organizationSize')}
                                    value={organizationProfile?.organizationSize}
                                />

                                <InfoBox.Row
                                    icon={<RulerIcon />}
                                    label={t('jobDetailPage.organizationType')}
                                    value={organizationProfile?.organizationType}
                                />

                                <InfoBox.Row
                                    icon={<LanguagesIcon />}
                                    label={t('jobDetailPage.organizationLanguage')}
                                    value={organizationProfile?.organizationLanguage}
                                />
                            </InfoBox>

                            <div className="rounded-xl flex flex-col gap-5 px-7">
                                <SaveJobButton job={job!} eventId={eventId} />
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default JobDetailPage;
