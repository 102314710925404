import { ArrowRightIcon, MinusIcon, PlusIcon } from 'lucide-react';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import LinkButton from '../../../../components/LinkButton.tsx';
import {
    eventLimitsQuery,
    organizationListQuery,
    organizationProfileCreateMutation,
    organizationProfileDeleteMutation,
    organizationProfileListQuery,
} from '../../../../data/queries.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import OrganizationCard from '../../organizations/components/OrganizationCard.tsx';
import { ADMIN_ORGANIZATION_LIST_PATH } from '../../organizations/routes.tsx';
import { useLimitAlert } from '../../utils.tsx';

const OrganizationsTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();

    const { data: organizations } = useQuery(organizationListQuery());
    const { data: organizationProfiles } = useQuery(organizationProfileListQuery());

    const addedOrganizationIds =
        organizationProfiles
            ?.filter((organizationProfile) => organizationProfile.eventId === eventId)
            .map((organizationProfile) => organizationProfile.organizationId) || [];
    const addedOrganizations =
        organizations?.filter((organization) => addedOrganizationIds.includes(organization.id)) || [];
    const availableOrganizations =
        organizations?.filter((organization) => !addedOrganizationIds.includes(organization.id)) || [];

    const { data: limits } = useQuery(eventLimitsQuery(eventId!));
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: limits?.organizationsCount,
        limit: limits?.maxOrganizations,
        limitTranslationKey: 'eventDetailPage.organizationsTab.limitAlert',
        limitReachedTranslationKey: 'eventDetailPage.organizationsTab.limitReachedAlert',
    });

    const { mutateAsync: removeOrganizationProfile } = useMutation(organizationProfileDeleteMutation());
    const handleRemoveOrganization = async (organizationProfileId: string) => {
        try {
            await removeOrganizationProfile({ id: organizationProfileId });
            toast.success(t('eventDetailPage.organizationsTab.removeSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('eventDetailPage.organizationsTab.removeError'));
        }
    };

    const { mutateAsync: addOrganizationProfile } = useMutation(organizationProfileCreateMutation());
    const handleAddOrganization = async (organizationId: string) => {
        try {
            await addOrganizationProfile({
                data: {
                    eventId: eventId!,
                    organizationId: organizationId,
                },
            });
            toast.success(t('eventDetailPage.organizationsTab.addSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('eventDetailPage.organizationsTab.addError'));
        }
    };

    return (
        <>
            <div className="w-full flex flex-col gap-5">
                <Admin.Card className="flex gap-4 flex-none justify-between">
                    <h1 className="text-3xl font-semibold">{t('eventDetailPage.organizationsTab.title')}</h1>
                    <div>
                        <LinkButton to={url(ADMIN_ORGANIZATION_LIST_PATH)} className="flex gap-1 items-center">
                            {t('eventDetailPage.organizationsTab.manageButton')} <ArrowRightIcon />
                        </LinkButton>
                    </div>
                </Admin.Card>

                <h3 className="px-6 py-2 font-medium text-base-600">
                    {t('eventDetailPage.organizationsTab.addedOrganizations')}
                </h3>

                <LimitAlert />

                {addedOrganizations.length > 0 ? (
                    addedOrganizations.map((organization) => (
                        <OrganizationCard
                            key={organization.id}
                            organization={organization}
                            button={
                                <Button
                                    type="button"
                                    onClick={() =>
                                        handleRemoveOrganization(
                                            organizationProfiles?.find(
                                                (organizationProfile) =>
                                                    organizationProfile.organizationId === organization.id &&
                                                    organizationProfile.eventId === eventId
                                            )?.id!
                                        )
                                    }
                                    variant="outline"
                                >
                                    <MinusIcon />
                                </Button>
                            }
                        />
                    ))
                ) : (
                    <EmptyState
                        className="py-10"
                        title={t('eventDetailPage.organizationsTab.noAddedOrganizations.title')}
                        description={t('eventDetailPage.organizationsTab.noAddedOrganizations.description')}
                    />
                )}

                <h3 className="px-6 py-2 font-medium text-base-600">
                    {t('eventDetailPage.organizationsTab.availableOrganizations')}
                </h3>

                {availableOrganizations.length > 0 ? (
                    availableOrganizations.map((organization) => (
                        <OrganizationCard
                            key={organization.id}
                            organization={organization}
                            button={
                                <Button
                                    type="button"
                                    onClick={() => handleAddOrganization(organization.id)}
                                    variant="outline"
                                    disabled={isLimitReached}
                                >
                                    <PlusIcon />
                                </Button>
                            }
                        />
                    ))
                ) : (
                    <EmptyState
                        className="py-10"
                        title={t('eventDetailPage.organizationsTab.noAvailableOrganizations.title')}
                        description={t('eventDetailPage.organizationsTab.noAvailableOrganizations.description')}
                    />
                )}
            </div>

            <Outlet />
        </>
    );
};

export default OrganizationsTab;
