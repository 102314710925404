import { isFunction } from 'lodash';
import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
    Navigate,
    Outlet,
    RouteObject,
    RouterProvider,
    createBrowserRouter,
    useLocation,
    useMatches,
    useRouteError,
} from 'react-router-dom';

import { QueryClientProvider, useQuery } from '@tanstack/react-query';

import { ConfirmationDialogProvider } from './components/ConfirmationDialog.tsx';
import Header from './components/Header.tsx';
import LinkButton from './components/LinkButton.tsx';
import { DEFAULT_BASE_COLOR, DEFAULT_THEME_COLOR, ThemeProvider, useTheme } from './components/Theme.tsx';
import { eventDetailQuery, organizationDetailQuery, spaceDetailQuery, userDetailQuery } from './data/queries.ts';
import AdminLayout from './modules/admin/components/AdminLayout.tsx';
import { ADMIN_EVENT_LIST_PATH, default as adminEventsRoutes } from './modules/admin/events/routes.tsx';
import {
    ADMIN_ORGANIZATION_PROFILE_LIST_PATH,
    default as adminOrganizationProfileRoutes,
} from './modules/admin/organization-profiles/routes.tsx';
import { default as adminOrganizationSettingsRoutes } from './modules/admin/organization-settings/routes.tsx';
import { default as adminOrganizationRoutes } from './modules/admin/organizations/routes.tsx';
import { default as adminSpaceSettingsRoutes } from './modules/admin/space-settings/routes.tsx';
import { ADMIN_USER_PATH, default as adminUserSettingsRoutes } from './modules/admin/user-settings/routes.tsx';
import { ADMIN_LOGIN_PATH, default as authRoutes } from './modules/auth/routes.tsx';
import { HOME_PATH, default as siteRoutes } from './modules/site/routes.tsx';
import { isCustomDomain, useEventId } from './modules/site/utils.ts';
import { getEventDomain, isDomainName } from './utils/domain.ts';
import ensureQueriesData from './utils/ensureQueriesData.ts';
import getIsActiveRoute from './utils/getIsActiveRoute.ts';
import { useDocumentTitle } from './utils/htmlDocument.tsx';
import { authorized, authorizedLoader, isAdminUser, isOrganizationUser } from './utils/loaders.ts';
import { queryClient } from './utils/queryClient.ts';
import url from './utils/url';

export const organizationAdminLoader = ({ params }: any) =>
    ensureQueriesData({
        user: userDetailQuery(),
        organization: organizationDetailQuery(),
    });

export const ADMIN_PATH = '/admin';
export const ADMIN_ORGANIZATION_PATH = '/admin/organization';

const AdminRedirect = () => {
    const { data: viewer } = useQuery(userDetailQuery());

    if (isAdminUser(viewer!)) {
        return <Navigate to={ADMIN_EVENT_LIST_PATH} replace={true} />;
    }

    if (isOrganizationUser(viewer!)) {
        return <Navigate to={ADMIN_ORGANIZATION_PATH} replace={true} />;
    }

    return <Navigate to={ADMIN_USER_PATH} replace={true} />;
};

interface DocumentHandle {
    title?: string | ((loaderData: any) => string);
}

const Document = () => {
    const { i18n } = useTranslation();

    const { pathname } = useLocation();
    const matches = useMatches();
    const lastMatch = matches[matches.length - 1];

    const { data: viewer } = useQuery(userDetailQuery());
    const eventId = useEventId();
    const { data: event } = useQuery(eventDetailQuery(eventId!));

    const { setTheme, setBase } = useTheme();

    const isAdmin = pathname.startsWith('/admin');

    const { title = '' } = (lastMatch?.handle || {}) as DocumentHandle;
    const [, setTitle] = useDocumentTitle();
    setTitle(isFunction(title) ? title(lastMatch?.data) : title);

    useEffect(() => {
        if (!isAdmin) {
            if (event?.themeColorName) setTheme(event.themeColorName);
            if (event?.baseColorName) setBase(event.baseColorName);
        } else {
            setTheme(DEFAULT_THEME_COLOR);
            setBase(DEFAULT_BASE_COLOR);
        }
    }, [event, isAdmin]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // useEffect(() => {
    //     if (viewer?.languageCode && i18n.language !== viewer.languageCode) {
    //         void i18n.changeLanguage(viewer.languageCode);
    //     }
    // }, [viewer?.languageCode]);

    const shouldRedirectToCustomDomain =
        event && isDomainName(event.slug) && getEventDomain(event) !== window.location.host;
    if (shouldRedirectToCustomDomain) {
        // remove /events/:eventId from pathname if given in the beginning
        const path = pathname.replace(/^\/events\/[^/]+/, '');
        window.location.replace(`//${getEventDomain(event)}${path}`);
        return null;
    }

    return <Outlet />;
};

const NotFound = () => (
    <div className="max-w-[80%] max-h-[60%] bg-contain bg-center h-full w-full flex flex-col gap-10 items-center">
        <div
            className="bg-contain bg-center h-full w-full mix-blend-multiply bg-no-repeat"
            style={{ backgroundImage: "url('/images/error-not-found.png')" }}
        />

        <div className="flex flex-col gap-4 items-center">
            <span className="text-base-600">Fehlercode 404</span>
            <span className="text-base-900 font-semibold text-xl">Die Seite konnte nicht gefunden werden</span>
        </div>

        <LinkButton to={url(getIsActiveRoute(ADMIN_PATH) ? ADMIN_PATH : HOME_PATH)}>Zurück zur Startseite</LinkButton>
    </div>
);

const Forbidden = () => (
    <div className="max-w-[80%] max-h-[60%] bg-contain bg-center h-full w-full flex flex-col gap-10 items-center">
        <div
            className="bg-contain bg-center h-full w-full mix-blend-multiply bg-no-repeat"
            style={{ backgroundImage: "url('/images/error-forbidden.png')" }}
        />

        <div className="flex flex-col gap-4 items-center">
            <span className="text-base-600">Fehlercode 403</span>
            <span className="text-base-900 font-semibold text-xl">Du hast keinen Zugriff auf diese Seite</span>
        </div>

        <LinkButton to={url(getIsActiveRoute(ADMIN_PATH) ? ADMIN_PATH : HOME_PATH)}>Zurück zur Startseite</LinkButton>
    </div>
);

const ErrorBoundary = () => {
    let error = useRouteError() as any;
    const location = useLocation();

    if (error?.status === 401) {
        return <Navigate to={`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`} replace={true} />;
    }

    return (
        <div className="antialiased">
            <Header />

            <div className="h-[calc(100vh-4rem)] flex items-center justify-center">
                {error?.status === 404 && <NotFound />}
                {error?.status === 403 && <Forbidden />}
            </div>
        </div>
    );
};

const userLoader = () =>
    ensureQueriesData({
        user: userDetailQuery(),
        organization: organizationDetailQuery(),
        event: eventDetailQuery(),
        space: spaceDetailQuery(),
    });

const transformRoutes = (routes: any): RouteObject[] => {
    return routes.map((route: RouteObject) => ({
        ...route,
        // second case is only for initial route
        path: route.path?.replace('/events/:eventId/', '/').replace('/events/:eventId', '/'),
        children: route.children ? transformRoutes(route.children) : route.children,
    }));
};

const AppRouter = () => {
    const router = createBrowserRouter([
        {
            element: <Document />,
            loader: userLoader,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    path: ADMIN_PATH,
                    element: <AdminRedirect />,
                    loader: authorized(),
                },
                {
                    element: <AdminLayout />,
                    loader: authorizedLoader(organizationAdminLoader),
                    children: [
                        {
                            path: ADMIN_ORGANIZATION_PATH,
                            loader: authorized(isOrganizationUser),
                            children: [
                                {
                                    index: true,
                                    element: <Navigate to={ADMIN_ORGANIZATION_PROFILE_LIST_PATH} replace={true} />,
                                },
                                ...adminOrganizationProfileRoutes,
                                ...adminOrganizationSettingsRoutes,
                            ],
                        },
                        ...adminUserSettingsRoutes,
                        ...adminSpaceSettingsRoutes,
                        ...adminEventsRoutes,
                        ...adminOrganizationRoutes,
                    ],
                },

                ...authRoutes,

                ...(isCustomDomain() ? transformRoutes(siteRoutes) : siteRoutes),

                {
                    path: '*',
                    loader: () => {
                        throw new Response('Not Found', { status: 404, statusText: 'Not Found' });
                    },
                },
            ],
        },
    ] as RouteObject[]);

    return <RouterProvider router={router} />;
};

const App = () => {
    return (
        <>
            <ConfirmationDialogProvider>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider>
                        <AppRouter />
                    </ThemeProvider>
                </QueryClientProvider>
            </ConfirmationDialogProvider>

            <Toaster toastOptions={{ className: 'toast' }} />
        </>
    );
};

export default App;
