import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import { ErrorAlert, SuccessAlert } from '../../../components/Alert.tsx';
import url from '../../../utils/url';
import Auth from '../components/Auth.tsx';
import { confirmEmailMutation } from '../queries.ts';
import { ADMIN_LOGIN_PATH } from '../routes.tsx';

const ConfirmEmailPage = () => {
    const { t } = useTranslation('auth');

    const { token } = useParams();
    const { mutateAsync: confirmEmail, isPending } = useMutation(confirmEmailMutation());

    const [isConfirmed, setIsConfirmed] = useState(false);

    useEffect(() => {
        const handleConfirmEmail = async () => {
            try {
                await confirmEmail(token!);
                toast.success(t('confirmEmailPage.confirmSuccess'));
                setIsConfirmed(true);
            } catch (error) {
                console.error(error);
                toast.error(t('confirmEmailPage.confirmError'));
            }
        };

        void handleConfirmEmail();
    }, []);

    return (
        <Auth>
            <Auth.Card>
                <Auth.CardContent>
                    <h2 className="text-3xl font-semibold">{t('confirmEmailPage.title')}</h2>
                    {isPending ? (
                        <p className="text-base-800 text-base">{t('confirmEmailPage.loading')}</p>
                    ) : isConfirmed ? (
                        <SuccessAlert>{t('confirmEmailPage.successAlert')}</SuccessAlert>
                    ) : (
                        <ErrorAlert>{t('confirmEmailPage.errorAlert')}</ErrorAlert>
                    )}
                </Auth.CardContent>
                <Auth.CardFooter>
                    <Link
                        to={url(ADMIN_LOGIN_PATH)}
                        className="text-sm text-theme-500 underline underline-offset-2 hover:text-theme-700 transition-colors"
                    >
                        {t('confirmEmailPage.backToLogin')}
                    </Link>
                </Auth.CardFooter>
            </Auth.Card>
        </Auth>
    );
};

export default ConfirmEmailPage;
