// Types
type HueRange = [number, number];
type RGB = { r: number; g: number; b: number };

// Constants
const HUE_RANGES: HueRange[] = [
    [0, 60], // Red to Yellow
    [120, 180], // Green to Cyan
    [200, 280], // Blue to Purple
    [300, 360], // Purple to Red
];

const SATURATION = 65; // Percentage
const LIGHTNESS = 55; // Percentage

/**
 * Generates a hash number from a string
 */
const hashString = (str: string): number => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
    }
    return Math.abs(hash);
};

/**
 * Gets a hue value from one of the predefined ranges
 */
const getHueFromHash = (hash: number): number => {
    const rangeIndex = hash % HUE_RANGES.length;
    const [min, max] = HUE_RANGES[rangeIndex];
    const hueOffset = hash % (max - min);
    return min + hueOffset;
};

/**
 * Generates an HSL color string for a user identifier
 */
const generateColor = (identifier: string): string => {
    const hash = hashString(identifier);
    const hue = getHueFromHash(hash);
    return `hsl(${hue}, ${SATURATION}%, ${LIGHTNESS}%)`;
};

/**
 * Converts HSL values to RGB
 */
const hslToRgb = (h: number, s: number, l: number): RGB => {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
        r = g = b = l;
    } else {
        const hue2rgb = (p: number, q: number, t: number): number => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return { r, g, b };
};

/**
 * Converts RGB values to hex color string
 */
const rgbToHex = ({ r, g, b }: RGB): string => {
    const toHex = (x: number): string => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

/**
 * Generates a hex color string for a user identifier
 */
const generateHexColor = (identifier: string): string => {
    const hash = hashString(identifier);
    const hue = getHueFromHash(hash);
    const rgb = hslToRgb(hue, SATURATION, LIGHTNESS);
    return rgbToHex(rgb);
};

export default generateHexColor;
