import { MinusIcon, PlusIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
    DateField,
    Field,
    FileField,
    Form,
    SelectField,
    StringField,
    TextField,
    TimeField,
    useWidgetState,
} from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import FileUploadOrSelectInput from '../../../../components/FileUploadOrSelectInput.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import WysiwygInput from '../../../../components/WysiwygInput.tsx';
import { TRANSLATABLE_EVENT_KEYS, createTranslatable } from '../../../../data/models.ts';
import { eventDetailQuery, eventUpdateMutation, fileListQuery } from '../../../../data/queries.ts';
import { filterImage, getOrCreateFileId } from '../../../../utils/files.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import { TIMEZONES } from '../../../../utils/time.ts';
import Admin from '../../components/Admin.tsx';
import TranslatableFormField from '../../components/TranslatableFormField.tsx';

const DEFAULT_DAY_FORM_VALUES = { date: '', startTime: '', endTime: '' };

const GENERAL_FORM_DEFAULT_VALUES = createTranslatable(
    {
        name: '',
        slug: '',
        summary: '',
        description: '',
        timezoneName: 'Europe/Berlin',
        location: '',
        logo: undefined,
        banner: undefined,
        bannerLogo: undefined,
        days: [{ ...DEFAULT_DAY_FORM_VALUES }],
        contactEmail: '',
    },
    TRANSLATABLE_EVENT_KEYS
);

const EventDayForm = ({
    date,
    onDateChange,
    startTime,
    onStartTimeChange,
    endTime,
    onEndTimeChange,
}: {
    date?: string;
    onDateChange?: (value: string) => void;
    startTime?: string;
    onStartTimeChange?: (value: string) => void;
    endTime?: string;
    onEndTimeChange?: (value: string) => void;
}) => {
    return (
        <div className="flex gap-2">
            <DateField
                className="w-40"
                value={date}
                onChange={(e) => onDateChange?.(e.target.value)}
                required
                error={!date && ''}
            />
            <TimeField
                className="w-24"
                value={startTime}
                onChange={(e) => onStartTimeChange?.(e.target.value)}
                required
                error={!startTime && ''}
            />
            <TimeField
                className="w-24"
                value={endTime}
                onChange={(e) => onEndTimeChange?.(e.target.value)}
                required
                error={!endTime && ''}
            />

            {/*  <StringField
                className="w-24 tabular-nums"
                placeholder="hh:mm"
                value={startTime?.split(':').slice(0, 2).join(':')}
                onChange={(e) => onStartTimeChange?.(e.target.value)}
                type="time"
                required
                error={!startTime && ''}
            />
            <StringField
                className="w-24 tabular-nums"
                placeholder="hh:mm"
                value={endTime?.split(':').slice(0, 2).join(':')}
                onChange={(e) => onEndTimeChange?.(e.target.value)}
                type="time"
                required
                error={!endTime && ''}
            />*/}
        </div>
    );
};

const EventDaysFields = ({ value: propsValue, onChange, name, ...props }: any) => {
    const { t } = useTranslation('admin');

    const [value, setValue] = useWidgetState([{ ...DEFAULT_DAY_FORM_VALUES }], propsValue, onChange);
    const days = value.length > 0 ? value : [{ ...DEFAULT_DAY_FORM_VALUES }];

    const handleAddDay = () => {
        setValue({ target: { value: [...value, { ...DEFAULT_DAY_FORM_VALUES }] } } as any);
    };

    const handleRemoveDay = (index: number) => {
        setValue({ target: { value: value.filter((_: any, i: number) => i !== index) } } as any);
    };

    const handleDayChange = (index: number, key: string, newValue: any) => {
        setValue({
            target: { value: days.map((day: any, i: number) => (i === index ? { ...day, [key]: newValue } : day)) },
        } as any);
    };

    return (
        <div className="flex flex-col gap-2">
            <div className="flex gap-2">
                <Field.Label required className="w-40">
                    {t('eventDetailPage.generalTab.dateLabel')}
                </Field.Label>

                <Field.Label required className="w-24">
                    {t('eventDetailPage.generalTab.startTimeLabel')}
                </Field.Label>

                <Field.Label required className="w-24">
                    {t('eventDetailPage.generalTab.endTimeLabel')}
                </Field.Label>
            </div>

            {days?.map((day: any, index: number) => (
                <div className="flex gap-2">
                    <EventDayForm
                        key={index}
                        {...day}
                        onDateChange={(value) => handleDayChange(index, 'date', value)}
                        onStartTimeChange={(value) => handleDayChange(index, 'startTime', value)}
                        onEndTimeChange={(value) => handleDayChange(index, 'endTime', value)}
                    />
                    <Button
                        type="button"
                        onClick={() => handleRemoveDay(index)}
                        disabled={days.length === 1}
                        loadingDelay={0}
                    >
                        <MinusIcon />
                    </Button>

                    {index === days.length - 1 && (
                        <Button type="button" onClick={handleAddDay} loadingDelay={0}>
                            <PlusIcon />
                        </Button>
                    )}
                </div>
            ))}
        </div>
    );
};

const GeneralTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();

    const { data: event } = useQuery(eventDetailQuery(eventId!, { translatable: true }));

    const { data: files } = useQuery(fileListQuery({ pageSize: 100, spaceOnly: true }));
    const images = files?.filter(filterImage) || [];

    const { mutateAsync: updateEvent } = useMutation(eventUpdateMutation());

    const formMethods = useForm({
        defaultValues: pickFormValues(GENERAL_FORM_DEFAULT_VALUES, event),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(GENERAL_FORM_DEFAULT_VALUES, event));
    }, [event]);

    const handleSubmit = async (values: any) => {
        try {
            const { logo, banner, bannerLogo, ...formData } = values;

            const logoId = await getOrCreateFileId(logo, {});
            const bannerId = await getOrCreateFileId(banner, {});
            const bannerLogoId = await getOrCreateFileId(bannerLogo, {});

            await updateEvent({
                id: eventId!,
                data: { logoId, bannerId, bannerLogoId, ...formData },
                translatable: true,
            });

            toast.success(t('eventDetailPage.generalTab.submitSuccess'));
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('eventDetailPage.generalTab.submitError'));
        }
    };

    return (
        <Admin.Card className="flex flex-col gap-7">
            <h1 className="text-3xl font-semibold">{t('eventDetailPage.generalTab.title')}</h1>

            <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                <FormError />

                <TranslatableFormField name="name" rules={{ required: t('eventDetailPage.generalTab.nameRequired') }}>
                    <StringField label={t('eventDetailPage.generalTab.nameLabel')} required />
                </TranslatableFormField>

                <Form.Field name="slug">
                    <StringField
                        label={t('eventDetailPage.generalTab.slugLabel')}
                        helpText={t('eventDetailPage.generalTab.slugHelpText')}
                    />
                </Form.Field>

                <Form.Field name="days">
                    <EventDaysFields />
                </Form.Field>

                <Form.Field name="timezoneName" rules={{ required: t('eventDetailPage.generalTab.timezoneRequired') }}>
                    <SelectField
                        label={t('eventDetailPage.generalTab.timezoneLabel')}
                        required
                        className="flex-1"
                        options={TIMEZONES.map((tz) => ({ value: tz, label: tz }))}
                    />
                </Form.Field>

                <TranslatableFormField name="summary">
                    <TextField
                        label={t('eventDetailPage.generalTab.summaryLabel')}
                        helpText={t('eventDetailPage.generalTab.summaryHelpText')}
                        maxLength={140}
                    />
                </TranslatableFormField>

                <TranslatableFormField name="description">
                    <TextField label={t('eventDetailPage.generalTab.descriptionLabel')} widget={WysiwygInput} />
                </TranslatableFormField>

                <TranslatableFormField name="location">
                    <TextField label={t('eventDetailPage.generalTab.locationLabel')} inputClassName="min-h-[3rlh]" />
                </TranslatableFormField>

                <Form.Field name="logo">
                    <FileField
                        label={t('eventDetailPage.generalTab.logoLabel')}
                        helpText={t('eventDetailPage.generalTab.logoHelpText')}
                        previewImage
                        widget={FileUploadOrSelectInput}
                        // @ts-ignore
                        files={images}
                    />
                </Form.Field>

                <Form.Field name="banner">
                    <FileField
                        label={t('eventDetailPage.generalTab.bannerLabel')}
                        helpText={t('eventDetailPage.generalTab.bannerHelpText')}
                        previewImage
                        widget={FileUploadOrSelectInput}
                        // @ts-ignore
                        files={images}
                    />
                </Form.Field>

                <Form.Field name="bannerLogo">
                    <FileField
                        label={t('eventDetailPage.generalTab.bannerLogoLabel')}
                        helpText={t('eventDetailPage.generalTab.bannerLogoHelpText')}
                        previewImage
                        widget={FileUploadOrSelectInput}
                        // @ts-ignore
                        files={images}
                    />
                </Form.Field>

                <Form.Field name="contactEmail">
                    <StringField
                        label={t('eventDetailPage.generalTab.contactEmailLabel')}
                        helpText={t('eventDetailPage.generalTab.contactEmailHelpText')}
                    />
                </Form.Field>

                <div className="flex justify-end">
                    <FormSubmitButton variant="primary">{t('eventDetailPage.generalTab.saveButton')}</FormSubmitButton>
                </div>
            </Form>
        </Admin.Card>
    );
};

export default GeneralTab;
