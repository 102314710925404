import { ArrowRightIcon, MinusIcon, PlusIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import DragDrop from '../../../../components/DragDrop.tsx';
import LinkButton from '../../../../components/LinkButton.tsx';
import {
    jobListQuery,
    organizationDetailQuery,
    organizationProfileJobCreateMutation,
    organizationProfileJobDeleteMutation,
    organizationProfileJobListQuery,
    organizationProfileJobUpdateMutation,
    organizationProfileLimitsQuery,
} from '../../../../data/queries.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import JobCard from '../../components/JobCard.tsx';
import { ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH } from '../../organization-settings/routes.tsx';
import { useLimitAlert } from '../../utils.tsx';

const JobsTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { profileId } = useParams();

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());

    const { data: jobs } = useQuery(jobListQuery({ organizationId: viewerOrganization?.id }));
    const { data: organizationProfileJobs } = useQuery(
        organizationProfileJobListQuery({ organizationProfileId: profileId! })
    );
    const profileJobs = organizationProfileJobs || [];

    const addedJobIds = profileJobs?.map((job) => job.jobId) || [];
    const availableJobs = jobs?.filter((job) => !addedJobIds.includes(job.id)) || [];

    const [sortedAddedJobIds, setSortedAddedJobIds] = useState<string[]>([]);

    useEffect(() => {
        setSortedAddedJobIds(profileJobs.map((job) => job.jobId));
    }, [organizationProfileJobs]);

    const handleSortChange = (prevIndex: number, nextIndex: number) => {
        const id = sortedAddedJobIds[prevIndex];
        setSortedAddedJobIds((prev) => {
            const newSortedJobIds = [...prev];
            newSortedJobIds.splice(prevIndex, 1);
            newSortedJobIds.splice(nextIndex, 0, id);
            return newSortedJobIds;
        });
    };

    const addedJobs = (jobs?.filter((job) => addedJobIds.includes(job.id)) || []).sort(
        (a, b) => sortedAddedJobIds.indexOf(a.id) - sortedAddedJobIds.indexOf(b.id)
    );

    const { data: limits } = useQuery(organizationProfileLimitsQuery(profileId!));
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: limits?.jobsCount,
        limit: limits?.maxJobs,
        limitTranslationKey: 'organizationProfileDetailPage.jobsTab.limitAlert',
        limitReachedTranslationKey: 'organizationProfileDetailPage.jobsTab.limitReachedAlert',
    });

    const { mutateAsync: removeProfileJob } = useMutation(organizationProfileJobDeleteMutation());
    const handleRemoveProfileJob = async (profileJobId: string) => {
        try {
            await removeProfileJob({ id: profileJobId });
            toast.success(t('organizationProfileDetailPage.jobsTab.removeSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('organizationProfileDetailPage.jobsTab.removeError'));
        }
    };

    const { mutateAsync: updateProfileJob } = useMutation(organizationProfileJobUpdateMutation());
    const handleDrop = async (item: any) => {
        const jobId = sortedAddedJobIds[item.index];
        const profileJobId = profileJobs.find((job) => job.jobId === jobId)?.id;
        try {
            await updateProfileJob({
                id: profileJobId!,
                data: {
                    position: item.index,
                },
            });
            toast.success(t('organizationProfileDetailPage.jobsTab.updateSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('organizationProfileDetailPage.jobsTab.updateError'));
        }
    };

    const { mutateAsync: addProfileJob } = useMutation(organizationProfileJobCreateMutation());
    const handleAddProfileJob = async (jobId: string) => {
        try {
            await addProfileJob({
                data: {
                    jobId: jobId,
                    organizationProfileId: profileId!,
                },
            });
            toast.success(t('organizationProfileDetailPage.jobsTab.addSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('organizationProfileDetailPage.jobsTab.addError'));
        }
    };

    return (
        <>
            <Admin.Card className="flex gap-4 flex-none justify-between">
                <h1 className="text-3xl font-semibold">{t('organizationProfileDetailPage.jobsTab.title')}</h1>
                <div>
                    <LinkButton to={url(ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH)} className="flex gap-1">
                        {t('organizationProfileDetailPage.jobsTab.manageButton')} <ArrowRightIcon />
                    </LinkButton>
                </div>
            </Admin.Card>

            <h3 className="px-6 py-2 font-medium text-base-600">
                {t('organizationProfileDetailPage.jobsTab.addedJobs')}
            </h3>

            <LimitAlert />

            {addedJobs.length > 0 ? (
                <DragDrop>
                    <DragDrop.Container className="w-full flex flex-col gap-5 justify-start" onDrop={handleDrop}>
                        {addedJobs.map((job) => (
                            <DragDrop.Item
                                key={job.id}
                                index={sortedAddedJobIds.indexOf(job.id)}
                                onIndexChange={(prevIndex: number, nextIndex: number) =>
                                    handleSortChange(prevIndex, nextIndex)
                                }
                                asChild
                            >
                                <JobCard
                                    job={job}
                                    {...props}
                                    button={
                                        <Button
                                            type="button"
                                            onClick={() =>
                                                handleRemoveProfileJob(profileJobs.find((p) => p.jobId === job.id)?.id!)
                                            }
                                            variant="outline"
                                        >
                                            <MinusIcon />
                                        </Button>
                                    }
                                />
                            </DragDrop.Item>
                        ))}
                    </DragDrop.Container>
                </DragDrop>
            ) : (
                <EmptyState
                    className="py-10"
                    title={t('organizationProfileDetailPage.jobsTab.noAddedJobs.title')}
                    description={t('organizationProfileDetailPage.jobsTab.noAddedJobs.description')}
                />
            )}
            <h3 className="px-6 py-2 font-medium text-base-600">
                {t('organizationProfileDetailPage.jobsTab.availableJobs')}
            </h3>
            {availableJobs.length > 0 ? (
                availableJobs.map((job) => (
                    <JobCard
                        key={job.id}
                        job={job}
                        {...props}
                        button={
                            <Button
                                type="button"
                                onClick={() => handleAddProfileJob(job.id)}
                                variant="outline"
                                disabled={isLimitReached}
                            >
                                <PlusIcon />
                            </Button>
                        }
                    />
                ))
            ) : (
                <EmptyState
                    className="py-10"
                    title={t('organizationProfileDetailPage.jobsTab.noAvailableJobs.title')}
                    description={t('organizationProfileDetailPage.jobsTab.noAvailableJobs.description')}
                />
            )}
            <Outlet />
        </>
    );
};

export default JobsTab;
