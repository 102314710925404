import { EyeIcon, PlusIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { Form, SelectField, StringField, TextField } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import Dialog from '../../../../components/Dialog.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import LinkButton from '../../../../components/LinkButton.tsx';
import MoreMenu from '../../../../components/MoreMenu.tsx';
import WysiwygInput from '../../../../components/WysiwygInput.tsx';
import {
    EventPage,
    TRANSLATABLE_EVENT_PAGE_KEYS,
    TranslatableEventPage,
    createTranslatable,
} from '../../../../data/models.ts';
import {
    eventPageCreateMutation,
    eventPageDeleteMutation,
    eventPageDetailQuery,
    eventPageListQuery,
    eventPageUpdateMutation,
    fileListQuery,
} from '../../../../data/queries.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import url from '../../../../utils/url';
import { SITE_PAGE_DETAIL_PATH } from '../../../site/routes.tsx';
import { siteUrl } from '../../../site/utils.ts';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import TranslatableFormField from '../../components/TranslatableFormField.tsx';
import { ADMIN_EVENT_PAGES_ADD_PATH, ADMIN_EVENT_PAGES_EDIT_PATH, ADMIN_EVENT_PAGES_PATH } from '../routes.tsx';

const EVENT_PAGE_FORM_DEFAULT_VALUES = createTranslatable(
    {
        title: '',
        slug: '',
        role: '',
        content: '',
    },
    TRANSLATABLE_EVENT_PAGE_KEYS
);

const BaseEventPageFormDialog = ({
    isCreate = false,
    eventPage,
    isOpen = true,
    onIsOpenChange,
    onSubmit,
    onCancel,
}: {
    isCreate: boolean;
    eventPage?: TranslatableEventPage;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onSubmit?: (data: any) => void;
    onCancel?: () => void;
}) => {
    const { t } = useTranslation('admin');

    const formMethods = useForm({
        defaultValues: pickFormValues(EVENT_PAGE_FORM_DEFAULT_VALUES, eventPage),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(EVENT_PAGE_FORM_DEFAULT_VALUES, eventPage));
    }, [eventPage]);

    const { data: files } = useQuery(fileListQuery({ pageSize: 100, spaceOnly: true }));

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(onSubmit?.(values));
            toast.success(
                isCreate
                    ? t('eventDetailPage.eventPageFormDialog.createSuccess')
                    : t('eventDetailPage.eventPageFormDialog.updateSuccess')
            );
        } catch (error) {
            setFormErrors(t, formMethods, error);
            toast.error(
                isCreate
                    ? t('eventDetailPage.eventPageFormDialog.createError')
                    : t('eventDetailPage.eventPageFormDialog.updateError')
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">
                    {isCreate
                        ? t('eventDetailPage.eventPageFormDialog.titleCreate')
                        : t('eventDetailPage.eventPageFormDialog.titleUpdate')}
                </h1>

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <FormError />

                    <TranslatableFormField
                        name="title"
                        rules={{ required: t('eventDetailPage.eventPageFormDialog.titleRequired') }}
                    >
                        <StringField label={t('eventDetailPage.eventPageFormDialog.titleLabel')} required />
                    </TranslatableFormField>

                    <TranslatableFormField
                        name="slug"
                        rules={{ required: t('eventDetailPage.eventPageFormDialog.slugRequired') }}
                    >
                        <StringField
                            label={t('eventDetailPage.eventPageFormDialog.slugLabel')}
                            helpText={t('eventDetailPage.eventPageFormDialog.slugHelpText')}
                            required
                        />
                    </TranslatableFormField>

                    <TranslatableFormField
                        name="content"
                        rules={{ required: t('eventDetailPage.eventPageFormDialog.contentRequired') }}
                    >
                        <TextField
                            label={t('eventDetailPage.eventPageFormDialog.contentLabel')}
                            widget={WysiwygInput}
                            // @ts-ignore
                            files={files}
                        />
                    </TranslatableFormField>

                    <Form.Field name="role">
                        <SelectField
                            label={t('eventDetailPage.eventPageFormDialog.roleLabel')}
                            helpText={t('eventDetailPage.eventPageFormDialog.roleHelpText')}
                            options={[
                                {
                                    label: t('eventDetailPage.eventPageFormDialog.roleOptions.imprint'),
                                    value: 'IMPRINT',
                                },
                                {
                                    label: t('eventDetailPage.eventPageFormDialog.roleOptions.privacy'),
                                    value: 'PRIVACY',
                                },
                                {
                                    label: t('eventDetailPage.eventPageFormDialog.roleOptions.terms'),
                                    value: 'TERMS',
                                },
                            ]}
                        />
                    </Form.Field>

                    <div className="flex justify-end gap-2">
                        <Button variant="ghost" onClick={onCancel} type="button">
                            {t('eventDetailPage.eventPageFormDialog.cancelButton')}
                        </Button>

                        <FormSubmitButton variant="primary" type="submit" loading={isLoading}>
                            {isCreate
                                ? t('eventDetailPage.eventPageFormDialog.createButton')
                                : t('eventDetailPage.eventPageFormDialog.updateButton')}
                        </FormSubmitButton>
                    </div>
                </Form>
            </Dialog.Content>
        </Dialog>
    );
};

export const AddEventPageFormDialog = (props: any) => {
    const navigate = useNavigate();

    const { eventId } = useParams();

    const { mutateAsync: createEventPage } = useMutation(eventPageCreateMutation());
    return (
        <BaseEventPageFormDialog
            isCreate
            {...props}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(url(ADMIN_EVENT_PAGES_PATH, { eventId }));
                }
            }}
            onSubmit={async (data) => {
                await createEventPage({ data: { ...data, eventId } });
                return navigate(url(ADMIN_EVENT_PAGES_PATH, { eventId }));
            }}
            onCancel={() => navigate(url(ADMIN_EVENT_PAGES_PATH, { eventId }))}
        />
    );
};

export const EditEventPageFormDialog = (props: any) => {
    const navigate = useNavigate();

    const { eventId } = useParams();
    const { pageId } = useParams();
    const { data: eventPage } = useQuery(eventPageDetailQuery(pageId!, { translatable: true }));

    const { mutateAsync: updateEventPage } = useMutation(eventPageUpdateMutation());

    return (
        <BaseEventPageFormDialog
            {...props}
            eventPage={eventPage}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(url(ADMIN_EVENT_PAGES_PATH, { eventId }));
                }
            }}
            onSubmit={async (data) => {
                await updateEventPage({ id: pageId!, data, translatable: true });
                return navigate(url(ADMIN_EVENT_PAGES_PATH, { eventId }));
            }}
            onCancel={() => navigate(url(ADMIN_EVENT_PAGES_PATH, { eventId }))}
        />
    );
};

const RoleBadge = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <span
        className={classnames(
            'bg-theme-200 text-theme-700 rounded px-1.5 py-0.5 text-xs font-medium select-none',
            className
        )}
        {...props}
    />
);

const EventPageCard = ({
    eventPage,
    button,
    className,
    ...props
}: React.ComponentPropsWithRef<'div'> & { eventPage: EventPage; button: React.ReactNode }) => {
    const { t } = useTranslation('admin');
    return (
        <div
            className={classnames(
                'flex bg-base-100 rounded-xl p-6 relative group data-[dragging]:hover:bg-base-150 data-[dragging="true"]:bg-base-200',
                className
            )}
            {...props}
        >
            <div className="flex flex-col gap-0.5 flex-1">
                <h2 className="text text-lg font-semibold flex gap-3 items-center">
                    <span>{eventPage.title}</span>
                    {eventPage?.role && (
                        <RoleBadge>
                            {/* @ts-ignore */}
                            {t(`eventDetailPage.eventPageFormDialog.roleOptions.${eventPage.role.toLowerCase()}`)}
                        </RoleBadge>
                    )}
                </h2>
                <p className="text-base-600">
                    <span>/{eventPage.slug}/</span>
                </p>
            </div>
            {button}
        </div>
    );
};

const EventPagesTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { eventId } = useParams();

    const { data: eventPages } = useQuery(eventPageListQuery({ eventId }));

    const { mutateAsync: deleteEventPage } = useMutation(eventPageDeleteMutation());

    const handleDeleteEventPage = async (pageId: string) => {
        try {
            await deleteEventPage({ id: pageId });
            toast.success(t('eventDetailPage.eventPagesTab.deleteSuccess'));
        } catch {
            toast.error(t('eventDetailPage.eventPagesTab.deleteError'));
        }
    };

    return (
        <>
            <Admin.Card className="flex gap-4 flex-none justify-between">
                <h1 className="text-3xl font-semibold">{t('eventDetailPage.eventPagesTab.title')}</h1>
                {eventPages && eventPages?.length > 0 && (
                    <div>
                        <LinkButton to={url(ADMIN_EVENT_PAGES_ADD_PATH, { eventId })} className="flex gap-1">
                            <PlusIcon /> {t('eventDetailPage.eventPagesTab.createButton')}
                        </LinkButton>
                    </div>
                )}
            </Admin.Card>

            {eventPages && eventPages?.length > 0 ? (
                eventPages.map((eventPage) => {
                    return (
                        <EventPageCard
                            key={eventPage.id}
                            eventPage={eventPage}
                            button={
                                <div className="flex gap-2 items-center">
                                    <Button asChild className="flex gap-2 items-center">
                                        <a
                                            href={siteUrl(SITE_PAGE_DETAIL_PATH, { eventId, pageId: eventPage.id })}
                                            target="_blank"
                                        >
                                            <EyeIcon /> {t('eventDetailPage.eventPagesTab.viewButton')}
                                        </a>
                                    </Button>

                                    <MoreMenu>
                                        <MoreMenu.Item asChild>
                                            <Link
                                                to={url(ADMIN_EVENT_PAGES_EDIT_PATH, {
                                                    eventId,
                                                    pageId: eventPage.id,
                                                })}
                                            >
                                                {t('eventDetailPage.eventPagesTab.editButton')}
                                            </Link>
                                        </MoreMenu.Item>

                                        <MoreMenu.Item danger onClick={() => handleDeleteEventPage(eventPage.id)}>
                                            {t('eventDetailPage.eventPagesTab.deleteButton')}
                                        </MoreMenu.Item>
                                    </MoreMenu>
                                </div>
                            }
                        />
                    );
                })
            ) : (
                <EmptyState
                    className="h-full"
                    title={t('eventDetailPage.eventPagesTab.emptyState.title')}
                    description={t('eventDetailPage.eventPagesTab.emptyState.description')}
                    button={
                        <LinkButton to={url(ADMIN_EVENT_PAGES_ADD_PATH, { eventId })} className="flex gap-1">
                            <PlusIcon />
                            {t('eventDetailPage.eventPagesTab.createButton')}
                        </LinkButton>
                    }
                />
            )}

            <Outlet />
        </>
    );
};

export default EventPagesTab;
