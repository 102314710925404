import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FileField, Form, SelectField, StringField, TextField } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import FileUploadOrSelectInput from '../../../../components/FileUploadOrSelectInput.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import WysiwygInput from '../../../../components/WysiwygInput.tsx';
import { TRANSLATABLE_ORGANIZATION_PROFILE_KEYS, createTranslatable } from '../../../../data/models.ts';
import {
    fileListQuery,
    organizationDetailQuery,
    organizationProfileDetailQuery,
    organizationProfileUpdateMutation,
} from '../../../../data/queries.ts';
import {
    INDUSTRY_SUGGESTIONS,
    LOCATION_SCOPE_SUGGESTIONS,
    ORGANIZATION_LANGUAGE_SUGGESTIONS,
    ORGANIZATION_SIZE_SUGGESTIONS,
    ORGANIZATION_TYPE_SUGGESTIONS,
} from '../../../../data/suggestions.ts';
import { valuesToOptions } from '../../../../data/utils.ts';
import { filterImage, getOrCreateFileId } from '../../../../utils/files.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import Admin from '../../components/Admin.tsx';
import TranslatableFormField from '../../components/TranslatableFormField.tsx';

const GENERAL_FORM_DEFAULT_VALUES = createTranslatable(
    {
        name: '',
        summary: '',
        description: '',

        industry: '',
        locationScope: '',
        organizationSize: '',
        organizationType: '',
        organizationLanguage: '',

        logo: undefined,
        banner: undefined,
    },
    TRANSLATABLE_ORGANIZATION_PROFILE_KEYS
);

const GeneralTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { profileId } = useParams();

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());
    const { data: organizationProfile } = useQuery(organizationProfileDetailQuery(profileId!, { translatable: true }));

    const { data: files } = useQuery(fileListQuery({ pageSize: 100, organizationId: viewerOrganization?.id }));
    const images = files?.filter(filterImage) || [];

    const { mutateAsync: updateOrganizationProfile } = useMutation(organizationProfileUpdateMutation());

    const formMethods = useForm({
        defaultValues: pickFormValues(GENERAL_FORM_DEFAULT_VALUES, organizationProfile),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(GENERAL_FORM_DEFAULT_VALUES, organizationProfile));
    }, [organizationProfile]);

    const handleSubmit = async (values: any) => {
        try {
            const { logo, banner, ...formData } = values;

            const logoId = await getOrCreateFileId(logo, { organizationId: viewerOrganization?.id! });
            const bannerId = await getOrCreateFileId(banner, { organizationId: viewerOrganization?.id! });

            await updateOrganizationProfile({
                id: profileId!,
                data: { logoId, bannerId, ...formData },
                translatable: true,
            });

            toast.success(t('organizationProfileDetailPage.generalTab.submitSuccess'));
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('organizationProfileDetailPage.generalTab.submitError'));
        }
    };

    const handleResetToDefaultProfile = async () => {
        formMethods.reset(pickFormValues(GENERAL_FORM_DEFAULT_VALUES, viewerOrganization));
    };

    return (
        <Admin.Card className="flex flex-col gap-7">
            <h1 className="text-3xl font-semibold">{t('organizationProfileDetailPage.generalTab.title')}</h1>

            <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                <div className="flex-1 flex justify-between items-center gap-5 rounded-xl bg-base-50 p-5">
                    <span className="text-sm text-base-600">
                        {t('organizationProfileDetailPage.generalTab.resetToDefaultProfileNote')}
                    </span>

                    <div className="flex-none">
                        <Button onClick={handleResetToDefaultProfile} type="button" loadingDelay={100}>
                            {t('organizationProfileDetailPage.generalTab.resetToDefaultProfileButton')}
                        </Button>
                    </div>
                </div>

                <FormError />

                <TranslatableFormField name="name">
                    <StringField label={t('organizationProfileDetailPage.generalTab.nameLabel')} />
                </TranslatableFormField>

                <TranslatableFormField name="summary">
                    <TextField
                        label={t('organizationProfileDetailPage.generalTab.summaryLabel')}
                        helpText={t('organizationProfileDetailPage.generalTab.summaryHelpText')}
                        maxLength={140}
                    />
                </TranslatableFormField>

                <TranslatableFormField name="description">
                    <TextField
                        label={t('organizationProfileDetailPage.generalTab.descriptionLabel')}
                        widget={WysiwygInput}
                        // @ts-ignore
                        files={files}
                    />
                </TranslatableFormField>

                <Form.Field name="industry">
                    <SelectField
                        label={t('organizationProfileDetailPage.generalTab.industryLabel')}
                        placeholder={t('organizationProfileDetailPage.generalTab.industryPlaceholder')}
                        allowAddOption
                        onAddOption={(value) => formMethods.setValue('industry', value)}
                        options={valuesToOptions(INDUSTRY_SUGGESTIONS)}
                    />
                </Form.Field>

                <Form.Field name="locationScope">
                    <SelectField
                        label={t('organizationProfileDetailPage.generalTab.locationScopeLabel')}
                        placeholder={t('organizationProfileDetailPage.generalTab.locationScopePlaceholder')}
                        hideSearch
                        // allowAddOption
                        // onAddOption={(value) => formMethods.setValue('locationScope', value)}
                        options={valuesToOptions(LOCATION_SCOPE_SUGGESTIONS)}
                    />
                </Form.Field>

                <Form.Field name="organizationSize">
                    <SelectField
                        label={t('organizationProfileDetailPage.generalTab.organizationSizeLabel')}
                        placeholder={t('organizationProfileDetailPage.generalTab.organizationSizePlaceholder')}
                        hideSearch
                        options={valuesToOptions(ORGANIZATION_SIZE_SUGGESTIONS)}
                    />
                </Form.Field>

                <Form.Field name="organizationType">
                    <SelectField
                        label={t('organizationProfileDetailPage.generalTab.organizationTypeLabel')}
                        placeholder={t('organizationProfileDetailPage.generalTab.organizationTypePlaceholder')}
                        allowAddOption
                        onAddOption={(value) => formMethods.setValue('organizationType', value)}
                        options={valuesToOptions(ORGANIZATION_TYPE_SUGGESTIONS)}
                    />
                </Form.Field>

                <Form.Field name="organizationLanguage">
                    <SelectField
                        label={t('organizationProfileDetailPage.generalTab.organizationLanguageLabel')}
                        allowAddOption
                        onAddOption={(value) => formMethods.setValue('organizationLanguage', value)}
                        options={valuesToOptions(ORGANIZATION_LANGUAGE_SUGGESTIONS)}
                    />
                </Form.Field>

                <Form.Field name="logo">
                    <FileField
                        label={t('organizationProfileDetailPage.generalTab.logoLabel')}
                        helpText={t('organizationProfileDetailPage.generalTab.logoHelpText')}
                        previewImage
                        widget={FileUploadOrSelectInput}
                        // @ts-ignore
                        files={images}
                    />
                </Form.Field>

                <Form.Field name="banner">
                    <FileField
                        label={t('organizationProfileDetailPage.generalTab.bannerLabel')}
                        helpText={t('organizationProfileDetailPage.generalTab.bannerHelpText')}
                        previewImage
                        widget={FileUploadOrSelectInput}
                        // @ts-ignore
                        files={images}
                    />
                </Form.Field>

                <div className="flex justify-end">
                    <FormSubmitButton variant="primary">
                        {t('organizationProfileDetailPage.generalTab.submitButton')}
                    </FormSubmitButton>
                </div>
            </Form>
        </Admin.Card>
    );
};

export default GeneralTab;
