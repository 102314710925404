import { FactoryIcon } from 'lucide-react';
import React from 'react';

import { classnames } from '@nicoknoll/utils';

const InfoBoxBadge = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => (
    <div
        className={classnames('bg-theme-500 text-white font-medium uppercase rounded-lg px-3 py-1', className)}
        {...props}
    />
);

const InfoBoxRow = ({
    className,
    children,
    icon,
    label,
    value,
    truncate = false,
    ...props
}: React.ComponentPropsWithRef<'div'> & {
    icon?: React.ReactNode;
    label?: React.ReactNode;
    value?: React.ReactNode;
    truncate?: boolean;
}) => {
    if (!children && !value) return null;

    return (
        <div className={classnames('flex gap-3 items-baseline', className)} {...props}>
            <div className="flex-none translate-y-0.5">{icon}</div>
            <div className="flex flex-col gap-3 flex-1 min-w-0">
                {(label || value) && (
                    <div className={classnames('min-w-0 w-full', truncate && 'truncate')}>
                        {label && <span>{label}:</span>} {value && <span className="font-medium min-w-0">{value}</span>}
                    </div>
                )}
                {children}
            </div>
        </div>
    );
};

const InfoBox = ({
    className,
    children,
    badge,
    ...props
}: React.ComponentPropsWithRef<'div'> & { badge?: React.ReactNode }) => (
    <div className="bg-base-50 rounded-2xl p-8 relative" {...props}>
        {badge && <div className="absolute -top-4 right-8 flex gap-4">{badge}</div>}
        <div className="flex flex-col gap-3">{children}</div>
    </div>
);

export default Object.assign(InfoBox, { Badge: InfoBoxBadge, Row: InfoBoxRow });
