import { InfoIcon, Text } from 'lucide-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import { DateTimeField, Field, Form, StringField, TextField } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { useQuery } from '@tanstack/react-query';

import Avatar from '../../../components/Avatar.tsx';
import Button from '../../../components/Button.tsx';
import { useConfirmationDialogContext } from '../../../components/ConfirmationDialog.tsx';
import Dialog from '../../../components/Dialog.tsx';
import SearchQueryBuilder, { IFieldDefinition, SearchQueryInput } from '../../../components/SearchQueryBuilder.tsx';
import WysiwygInput from '../../../components/WysiwygInput.tsx';
import { ScheduledEmail, User } from '../../../data/models.ts';
import { scheduledEmailDetailQuery, scheduledEmailListQuery, userDetailQuery } from '../../../data/queries.ts';
import { setFormErrors } from '../../../utils/forms.ts';
import { isSupportUser } from '../../../utils/loaders.ts';
import pickFormValues from '../../../utils/pickFormValues.ts';
import { formatDateTime } from '../../../utils/time.ts';
import url from '../../../utils/url';
import Admin from '../../admin/components/Admin.tsx';
import EmptyState from '../../admin/components/EmptyState.tsx';
import { ADMIN_SCHEDULED_EMAILS_DETAIL_PATH } from '../routes.tsx';

const SUPPORTED_PLACEHOLDERS = ['first_name', 'last_name', 'email'];

const SCHEDULED_EMAIL_FORM_DEFAULT_VALUES = {
    scheduledDatetime: '',
    receiversQuery: '',
    subject: '',
    text: '',
};

const ScheduledEmailForm = ({
    isCreate = false,
    scheduledEmail,
    onSubmit,
    onDelete,
}: {
    isCreate?: boolean;
    scheduledEmail?: ScheduledEmail;
    onSubmit?: (data: any) => void;
    onDelete?: () => void;
}) => {
    const { t } = useTranslation('admin');

    const { data: viewer } = useQuery(userDetailQuery());

    const fieldDefinitions: IFieldDefinition[] = [
        {
            name: 'email',
            type: 'text',
        },
        {
            name: 'first_name',
            type: 'text',
        },
        {
            name: 'last_name',
            type: 'text',
        },
        {
            name: 'language',
            type: 'text',
            options: [
                {
                    label: 'English',
                    value: 'en',
                },
                {
                    label: 'German',
                    value: 'de',
                },
            ],
        },
        {
            name: 'session',
            type: 'text',
            options: [
                {
                    label: 'Session 1',
                    value: '1',
                },
                {
                    label: 'Session 2',
                    value: '2',
                },
                {
                    label: 'Session 3',
                    value: '3',
                },
            ],
        },
        {
            name: 'session_joined_before',
            type: 'datetime',
        },
        {
            name: 'session_joined_after',
            type: 'datetime',
        },
        {
            name: 'event',
            type: 'text',
            options: [
                {
                    label: 'Event 1',
                    value: '1',
                },
                {
                    label: 'Event 2',
                    value: '2',
                },
                {
                    label: 'Event 3',
                    value: '3',
                },
            ],
        },
        {
            name: 'event_joined_before',
            type: 'datetime',
        },
        {
            name: 'event_joined_after',
            type: 'datetime',
        },
        {
            name: 'role',
            type: 'text',
            options: [
                {
                    label: 'Admin',
                    value: 'admin',
                },
                {
                    label: 'Exhibitor',
                    value: 'exhibitor',
                },
                {
                    label: 'Attendee',
                    value: 'attendee',
                },
            ],
        },
    ];

    const confirm = useConfirmationDialogContext();

    const formMethods = useForm({
        defaultValues: pickFormValues(SCHEDULED_EMAIL_FORM_DEFAULT_VALUES, scheduledEmail),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(SCHEDULED_EMAIL_FORM_DEFAULT_VALUES, scheduledEmail));
    }, [scheduledEmail]);

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(onSubmit?.(values));
            toast.success(
                isCreate
                    ? t('eventDetailPage.sessionFormDialog.createSuccess')
                    : t('eventDetailPage.sessionFormDialog.updateSuccess')
            );
        } catch {
            toast.error(
                isCreate
                    ? t('eventDetailPage.sessionFormDialog.createError')
                    : t('eventDetailPage.sessionFormDialog.updateError')
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async () => {
        return confirm(t('eventDetailPage.sessionFormDialog.deleteConfirmMessage'), '', {
            onConfirm: async () => {
                setIsLoading(true);

                try {
                    await Promise.resolve(onDelete?.());
                    toast.success(t('eventDetailPage.sessionFormDialog.deleteSuccess'));
                } catch (error) {
                    console.error(error);
                    setFormErrors(t, formMethods, error);
                    toast.error(t('eventDetailPage.sessionFormDialog.deleteError'));
                } finally {
                    setIsLoading(false);
                }
            },
            danger: true,
        });
    };

    const handlePlaceholderClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const element = event.currentTarget;

        const selection = window.getSelection();
        const range = document.createRange();

        if (selection && range) {
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

    return (
        <>
            <Admin.Card className="flex flex-col gap-5">
                <h1 className="text-3xl font-semibold">
                    {isCreate ? t('messagesPage.createScheduledEmail') : t('messagesPage.editScheduledEmail')}
                </h1>

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <Form.Field name="scheduledDatetime">
                        <DateTimeField label="Scheduled date and time" required />
                    </Form.Field>

                    <Form.Field name="receiversQuery">
                        <TextField
                            widget={SearchQueryInput}
                            label="Receivers Query"
                            helpText="This query will be used to determine the receivers of this email. The email will be sent to all users that match the query."
                            required
                            // @ts-ignore
                            fieldDefinitions={fieldDefinitions}
                        />
                    </Form.Field>

                    <pre>{JSON.stringify(JSON.parse(formMethods.watch('receiversQuery') || '{}'), null, 4)}</pre>

                    <div className="w-full h-px bg-base-200 my-2" />

                    <Form.Field name="subject">
                        <StringField label="Subject" required />
                    </Form.Field>

                    <Form.Field name="text">
                        <TextField label="Message" widget={WysiwygInput} required />
                    </Form.Field>

                    <div className="bg-blue-50 rounded-lg p-4 text-sm text-blue-900 flex gap-2">
                        <InfoIcon className="inline flex-none mt-0.5" />
                        <div className="flex flex-col gap-2">
                            <p>
                                You can use the following placeholders in the subject and message to personalize the
                                email:
                            </p>
                            <p className="font-mono">
                                {SUPPORTED_PLACEHOLDERS.map((tag, index) => (
                                    <Fragment key={tag}>
                                        <span
                                            onClick={handlePlaceholderClick}
                                            className="cursor-pointer"
                                        >{`{{${tag}}}`}</span>
                                        {index < SUPPORTED_PLACEHOLDERS.length - 1 && <Fragment>, </Fragment>}
                                    </Fragment>
                                ))}
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-end mt-4 gap-2">
                        <Button
                            type="button"
                            variant="primary"
                            className="bg-red-600 hover:bg-red-700 active:bg-red-800 mr-auto"
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>

                        <Button type="button" onClick={() => setIsPreviewOpen(true)}>
                            Preview
                        </Button>

                        <Button variant="primary">Save</Button>
                    </div>
                </Form>
            </Admin.Card>

            {isPreviewOpen && (
                <ScheduledEmailPreviewDialog
                    scheduledEmail={formMethods.getValues()}
                    receiver={viewer!}
                    isOpen
                    onIsOpenChange={setIsPreviewOpen}
                />
            )}
        </>
    );
};

const replacePlaceholders = (text: string, placeholders: Record<string, string>) => {
    return Object.entries(placeholders).reduce((acc, [key, value]) => {
        return acc.replace(new RegExp(`{{${key}}}`, 'g'), value);
    }, text);
};

const ScheduledEmailPreviewDialog = ({
    scheduledEmail,
    receiver,
    isOpen,
    onIsOpenChange,
}: {
    scheduledEmail: Omit<ScheduledEmail, 'id'>;
    receiver: User;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
}) => {
    const placeholders = {
        first_name: receiver.firstName || '',
        last_name: receiver.lastName || '',
        email: receiver.email || '',
    };

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <div className="flex gap-4 items-center">
                    <div className="flex-none">
                        <Avatar user={receiver} className="w-10 h-10" />
                    </div>

                    <div className="flex flex-col flex-1">
                        <div className="flex gap-1.5 items-baseline">
                            <span className="font-medium">jobwunder Team</span>
                            <span className="text-base-500 text-sm">&lt;info@jobwunder.de&gt;</span>
                        </div>
                        <div className="flex gap-1.5 items-baseline">
                            <span className="text-base-500">To:</span>
                            <span className="text-base-500">
                                {receiver.firstName} {receiver.lastName}
                            </span>
                            <span className="text-base-500 text-sm">&lt;{receiver.email}&gt;</span>
                        </div>
                    </div>

                    <div className="flex-none text-base-500 text-sm mb-auto py-0.5">
                        {scheduledEmail?.scheduledDatetime &&
                            formatDateTime(new Date(scheduledEmail?.scheduledDatetime))}
                    </div>
                </div>

                <div className="flex flex-col gap-3">
                    <div className="font-semibold text-xl">
                        {scheduledEmail?.subject && replacePlaceholders(scheduledEmail?.subject, placeholders)}
                    </div>

                    <div
                        className="styled"
                        dangerouslySetInnerHTML={{
                            __html: scheduledEmail?.text && replacePlaceholders(scheduledEmail?.text, placeholders),
                        }}
                    />
                </div>

                <div className="w-full h-px bg-base-200 mb-2" />

                <div className="flex justify-end gap-2">
                    <Button variant="primary" onClick={() => onIsOpenChange?.(false)}>
                        Close
                    </Button>
                </div>
            </Dialog.Content>
        </Dialog>
    );
};

const ScheduledEmailsPage = ({ add = false }: { add?: boolean }) => {
    const { t } = useTranslation('site');

    const { data: viewer } = useQuery(userDetailQuery());

    const { data: scheduledEmails } = useQuery(scheduledEmailListQuery());

    const { emailId } = useParams();
    const { data: scheduledEmail } = useQuery(scheduledEmailDetailQuery(emailId!));

    return (
        <>
            <main className="flex justify-center px-6 items-stretch">
                <div className="w-full max-w-[1120px] flex flex-col items-stretch justify-stretch">
                    <div
                        className={classnames(
                            'flex gap-8',
                            // 3 rem difference for support bar
                            isSupportUser(viewer!) ? 'h-[calc(100vh-7rem)]' : 'h-[calc(100vh-4rem)]'
                        )}
                    >
                        <Admin.Navigation className="pt-10">
                            <Admin.NavigationLabel>
                                {t('messagesPage.navigation.myConversations')}
                            </Admin.NavigationLabel>

                            {scheduledEmails &&
                                scheduledEmails?.length > 0 &&
                                scheduledEmails.map((email: any) => (
                                    <Admin.NavigationLink
                                        to={url(ADMIN_SCHEDULED_EMAILS_DETAIL_PATH, { emailId: email.id })}
                                        key={email.id}
                                    >
                                        {email.subject}
                                    </Admin.NavigationLink>
                                ))}

                            {scheduledEmails && scheduledEmails?.length === 0 && (
                                <Admin.NavigationItem className="pointer-events-none">
                                    <span className="font-normal text-base-500">
                                        {t('messagesPage.navigation.noConversations')}
                                    </span>
                                </Admin.NavigationItem>
                            )}
                        </Admin.Navigation>

                        <Admin.Content className="pt-0">
                            {scheduledEmail || add ? (
                                <div className="w-full flex flex-col gap-8 py-10 justify-start min-h-full">
                                    <ScheduledEmailForm scheduledEmail={scheduledEmail} isCreate={add} />
                                </div>
                            ) : (
                                <EmptyState
                                    title={t('messagesPage.emptyState.title')}
                                    description={t('messagesPage.emptyState.description')}
                                />
                            )}
                        </Admin.Content>
                    </div>
                </div>
            </main>
            <Outlet />
        </>
    );
};
export default ScheduledEmailsPage;
