import { generatePath } from 'react-router-dom';

const url = (path: string, params = {}) => {
    /*
    Replace variables in paths like:
    url('/processes/:processId', { processId: 123 }) -> '/processes/123'
    */
    return generatePath(path, params);
};

export default url;
