import { ArrowRightIcon, MessageSquareIcon, MessageSquareOffIcon, MinusIcon, PlusIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import DragDrop from '../../../../components/DragDrop.tsx';
import LinkButton from '../../../../components/LinkButton.tsx';
import Tooltip from '../../../../components/Tooltip.tsx';
import {
    organizationDetailQuery,
    organizationLimitsQuery,
    organizationProfileLimitsQuery,
    organizationProfileUserCreateMutation,
    organizationProfileUserDeleteMutation,
    organizationProfileUserListQuery,
    organizationProfileUserUpdateMutation,
    userDetailQuery,
    userListQuery,
} from '../../../../data/queries.ts';
import { isSupportUser } from '../../../../utils/loaders.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import UserCard from '../../components/UserCard.tsx';
import { ADMIN_ORGANIZATION_SETTINGS_USERS_PATH } from '../../organization-settings/routes.tsx';
import { useLimitAlert } from '../../utils.tsx';

const ContactPersonsTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { profileId } = useParams();

    const { data: viewer } = useQuery(userDetailQuery());
    const { data: viewerOrganization } = useQuery(organizationDetailQuery());

    const { data: users } = useQuery(userListQuery({ organizationId: viewerOrganization?.id }));
    const { data: organizationProfileUsers } = useQuery(
        organizationProfileUserListQuery({ organizationProfileId: profileId! })
    );
    const profileUsers = organizationProfileUsers || [];

    const addedUserIds = profileUsers?.map((profileUser) => profileUser.userId) || [];
    const availableUsers = users?.filter((user) => !addedUserIds.includes(user.id)) || [];

    const [sortedAddedUserIds, setSortedAddedUserIds] = useState<string[]>([]);

    useEffect(() => {
        setSortedAddedUserIds(profileUsers.map((user) => user.userId));
    }, [organizationProfileUsers]);

    const handleSortChange = (prevIndex: number, nextIndex: number) => {
        const id = sortedAddedUserIds[prevIndex];
        setSortedAddedUserIds((prev) => {
            const newSortedUserIds = [...prev];
            newSortedUserIds.splice(prevIndex, 1);
            newSortedUserIds.splice(nextIndex, 0, id);
            return newSortedUserIds;
        });
    };

    const addedUsers = (users?.filter((user) => addedUserIds.includes(user.id)) || []).sort(
        (a, b) => sortedAddedUserIds.indexOf(a.id) - sortedAddedUserIds.indexOf(b.id)
    );

    const { data: limits } = useQuery(organizationProfileLimitsQuery(profileId!));
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: limits?.usersCount,
        limit: limits?.maxUsers,
        limitTranslationKey: 'organizationProfileDetailPage.contactPersonsTab.limitAlert',
        limitReachedTranslationKey: 'organizationProfileDetailPage.contactPersonsTab.limitReachedAlert',
    });

    const { mutateAsync: removeProfileUser } = useMutation(organizationProfileUserDeleteMutation());
    const handleRemoveUser = async (profileUserId: string) => {
        try {
            await removeProfileUser({ id: profileUserId });
            toast.success(t('organizationProfileDetailPage.contactPersonsTab.removeSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('organizationProfileDetailPage.contactPersonsTab.removeError'));
        }
    };

    const { mutateAsync: updateProfileUser } = useMutation(organizationProfileUserUpdateMutation());
    const handleDrop = async (item: any) => {
        const userId = sortedAddedUserIds[item.index];
        const profileUserId = profileUsers.find((user) => user.userId === userId)?.id;
        try {
            await updateProfileUser({
                id: profileUserId!,
                data: {
                    position: item.index,
                },
            });
            toast.success(t('organizationProfileDetailPage.contactPersonsTab.updateSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('organizationProfileDetailPage.contactPersonsTab.updateError'));
        }
    };
    const handleChangeCanReceiveMessages = async (profileUserId: string, canReceiveMessages: boolean) => {
        try {
            await updateProfileUser({
                id: profileUserId!,
                data: {
                    canReceiveMessages,
                },
            });
            toast.success(t('organizationProfileDetailPage.contactPersonsTab.updateSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('organizationProfileDetailPage.contactPersonsTab.updateError'));
        }
    };

    const { mutateAsync: addProfileUser } = useMutation(organizationProfileUserCreateMutation());
    const handleAddUser = async (userId: string) => {
        try {
            await addProfileUser({
                data: {
                    userId: userId,
                    organizationProfileId: profileId!,
                },
            });
            toast.success(t('organizationProfileDetailPage.contactPersonsTab.addSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('organizationProfileDetailPage.contactPersonsTab.addError'));
        }
    };

    return (
        <>
            <Admin.Card className="flex gap-4 flex-none justify-between">
                <h1 className="text-3xl font-semibold">{t('organizationProfileDetailPage.contactPersonsTab.title')}</h1>
                <div>
                    <LinkButton to={url(ADMIN_ORGANIZATION_SETTINGS_USERS_PATH)} className="flex gap-1 items-center">
                        {t('organizationProfileDetailPage.contactPersonsTab.manageButton')} <ArrowRightIcon />
                    </LinkButton>
                </div>
            </Admin.Card>

            <h3 className="px-6 py-2 font-medium text-base-600">
                {t('organizationProfileDetailPage.contactPersonsTab.addedContactPersons')}
            </h3>

            <LimitAlert />

            {addedUsers.length > 0 ? (
                <DragDrop>
                    <DragDrop.Container className="w-full flex flex-col gap-5 justify-start" onDrop={handleDrop}>
                        {addedUsers.map((contactPerson) => {
                            const isCurrentUser = contactPerson.id === viewer?.id;
                            const profileUser = profileUsers?.find(
                                (profileUser) => profileUser.userId === contactPerson.id
                            );

                            return (
                                <DragDrop.Item
                                    key={contactPerson.id}
                                    index={sortedAddedUserIds.indexOf(contactPerson.id)}
                                    onIndexChange={(prevIndex: number, nextIndex: number) =>
                                        handleSortChange(prevIndex, nextIndex)
                                    }
                                    asChild
                                >
                                    <UserCard
                                        user={contactPerson}
                                        isCurrentUser={isCurrentUser}
                                        button={
                                            <>
                                                <Tooltip>
                                                    <Tooltip.Trigger asChild>
                                                        <Button
                                                            type="button"
                                                            onClick={() => {
                                                                return handleChangeCanReceiveMessages(
                                                                    profileUser?.id!,
                                                                    !profileUser?.canReceiveMessages
                                                                );
                                                            }}
                                                            variant="outline"
                                                        >
                                                            {profileUser?.canReceiveMessages ? (
                                                                <MessageSquareOffIcon />
                                                            ) : (
                                                                <MessageSquareIcon />
                                                            )}
                                                        </Button>
                                                    </Tooltip.Trigger>
                                                    <Tooltip.Content>
                                                        {profileUser?.canReceiveMessages
                                                            ? t(
                                                                  'organizationProfileDetailPage.contactPersonsTab.deactivateMessagingTooltip'
                                                              )
                                                            : t(
                                                                  'organizationProfileDetailPage.contactPersonsTab.activateMessagingTooltip'
                                                              )}
                                                    </Tooltip.Content>
                                                </Tooltip>

                                                <Button
                                                    type="button"
                                                    onClick={() => handleRemoveUser(profileUser?.id!)}
                                                    variant="outline"
                                                >
                                                    <MinusIcon />
                                                </Button>
                                            </>
                                        }
                                    />
                                </DragDrop.Item>
                            );
                        })}
                    </DragDrop.Container>
                </DragDrop>
            ) : (
                <EmptyState
                    className="py-10"
                    title={t('organizationProfileDetailPage.contactPersonsTab.noAddedContactPersons.title')}
                    description={t('organizationProfileDetailPage.contactPersonsTab.noAddedContactPersons.description')}
                />
            )}

            <h3 className="px-6 py-2 font-medium text-base-600">
                {t('organizationProfileDetailPage.contactPersonsTab.availableContactPersons')}
            </h3>

            {availableUsers.length > 0 ? (
                availableUsers.map((contactPerson) => {
                    const isCurrentUser = contactPerson.id === viewer?.id;

                    return (
                        <UserCard
                            key={contactPerson.id}
                            user={contactPerson}
                            isCurrentUser={isCurrentUser}
                            button={
                                <Button
                                    type="button"
                                    onClick={() => handleAddUser(contactPerson.id!)}
                                    variant="outline"
                                    disabled={isSupportUser(contactPerson) || isLimitReached}
                                >
                                    <PlusIcon />
                                </Button>
                            }
                        />
                    );
                })
            ) : (
                <EmptyState
                    className="py-10"
                    title={t('organizationProfileDetailPage.contactPersonsTab.noAvailableContactPersons.title')}
                    description={t(
                        'organizationProfileDetailPage.contactPersonsTab.noAvailableContactPersons.description'
                    )}
                />
            )}

            <Outlet />
        </>
    );
};

export default ContactPersonsTab;
