import { TFunction } from 'i18next';
import { FileIcon, HeadsetIcon, NotebookIcon, RotateCwIcon } from 'lucide-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { FileField, Form, SingleSelect, TextField } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import Avatar from '../../../components/Avatar.tsx';
import Button from '../../../components/Button.tsx';
import FormError from '../../../components/FormError.tsx';
import FormSubmitButton from '../../../components/FormSubmitButton.tsx';
import Image from '../../../components/Image.tsx';
import LinkButton from '../../../components/LinkButton.tsx';
import MoreMenu from '../../../components/MoreMenu.tsx';
import {
    Conversation,
    LogEventType,
    Message as MessageModel,
    MessageType,
    OrganizationProfile,
    User,
} from '../../../data/models.ts';
import {
    conversationBlockMutation,
    conversationCreateMutation,
    conversationDetailQuery,
    conversationListQuery,
    conversationMarkReadMutation,
    conversationReportMutation,
    conversationUnblockMutation,
    eventAttendeeListQuery,
    eventListQuery,
    messageCreateMutation,
    messagesPaginatedListQuery,
    organizationProfileListQuery,
    userDetailQuery,
    userNoteListQuery,
} from '../../../data/queries.ts';
import { getEventDomain } from '../../../utils/domain.ts';
import { formatFileSize, getOrCreateFileId, getRemoteFileSize, isImage } from '../../../utils/files.ts';
import { setFormErrors } from '../../../utils/forms.ts';
import generateHexColor from '../../../utils/generateColor.ts';
import { isAdminUser, isOrganizationUser, isRegularUser, isSupportUser } from '../../../utils/loaders.ts';
import url from '../../../utils/url';
import useLogEvent from '../../../utils/useLogEvent.ts';
import Admin from '../../admin/components/Admin.tsx';
import EmptyState from '../../admin/components/EmptyState.tsx';
import BaseConversationFormDialog, {
    AttachmentInput,
    CONVERSATION_FORM_DEFAULT_VALUES,
} from '../components/BaseConversationFormDialog.tsx';
import { SaveUserRibbon } from '../components/SaveUserButton.tsx';
import UserNotesDialog from '../components/UserNotesDialog.tsx';
import {
    SITE_CONVERSATION_ADD_PATH,
    SITE_CONVERSATION_DETAIL_PATH,
    SITE_MESSAGES_PATH,
    SITE_ORGANIZATION_DETAIL_PATH,
    SITE_USER_DETAIL_PATH,
} from '../routes.tsx';
import { siteUrl, useEventId } from '../utils.ts';

const getReceiver = (conversation: Conversation, user: User) => {
    let participants = conversation.participants;
    participants = participants.filter((p) => p.id !== user!.id && !isAdminUser(p));
    if (!conversation.isParticipant) {
        // if the user is not a participant, we need to filter out the organization
        participants = participants.filter((p) => !p.organizationId);
    }
    return participants[0] as User;
};

const ConversationNavigationLink = ({
    conversation,
}: Omit<React.ComponentPropsWithRef<typeof Admin.NavigationLink>, 'to'> & { conversation: Conversation }) => {
    const { t } = useTranslation('site');
    const eventId = useEventId();

    const { data: viewer } = useQuery(userDetailQuery());

    const organizationProfile = useOrganizationProfile(conversation);
    const receiver = getReceiver(conversation, viewer!);
    const colleague =
        !conversation.isParticipant &&
        conversation.participants?.find((p) => p.id !== viewer?.id && p.organizationId === viewer?.organizationId);

    const isSupport = conversation?.isSupport && !isAdminUser(viewer!);
    const unreadCount = conversation?.unreadCount || 0;
    const isUnread = unreadCount > 0;

    let description = '';
    if (isSupport) {
        description = 'Support';
    } else if (organizationProfile?.name) {
        description = organizationProfile.name;
    } else if (colleague) {
        const colleagueName = `${colleague.firstName || ''} ${colleague.lastName || ''}`.trim() || colleague.email;
        description = t('messagesPage.navigationLink.by', { name: colleagueName });
    }

    return (
        <Admin.NavigationLink to={siteUrl(SITE_CONVERSATION_DETAIL_PATH, { eventId, conversationId: conversation.id })}>
            <span className="flex gap-2 items-center w-full">
                {isSupport ? (
                    <div className="w-8 h-8 rounded-full flex bg-base-200 text-base-500 items-center justify-center">
                        <HeadsetIcon />
                    </div>
                ) : (
                    <Avatar user={receiver} />
                )}

                <div className="flex flex-col min-w-0">
                    <span>{isSupport ? 'Support' : `${receiver?.firstName} ${receiver?.lastName}`}</span>
                    {description && (
                        <span className="text-xs font-normal truncate flex items-center gap-1">{description}</span>
                    )}
                </div>

                {conversation.isParticipant && isUnread && (
                    <div className="ml-auto">
                        <span className="flex justify-center items-center text-white bg-theme-500 rounded-full font-medium px-2 py-0.5 text-xs">
                            {unreadCount}
                        </span>
                    </div>
                )}
            </span>
        </Admin.NavigationLink>
    );
};

const ConversationMoreMenu = ({ conversation }: { conversation: Conversation }) => {
    const { t } = useTranslation('site');

    const { data: viewer } = useQuery(userDetailQuery());

    const { mutateAsync: reportConversation } = useMutation(conversationReportMutation());
    const { mutateAsync: blockConversation } = useMutation(conversationBlockMutation());
    const { mutateAsync: unblockConversation } = useMutation(conversationUnblockMutation());

    const createLogEvent = useLogEvent();

    const handleReport = async () => {
        try {
            await reportConversation({
                id: conversation.id!,
                data: {
                    targetUserId: getReceiver(conversation, viewer!).id,
                },
            });
            void createLogEvent(LogEventType.CONVERSATION_REPORTED, {
                eventId: conversation.eventId,
                targetId: conversation.id,
            });
            toast.success(t('messagesPage.moreMenu.reportSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('messagesPage.moreMenu.reportError'));
        }
    };

    const handleBlock = async () => {
        try {
            await blockConversation({ id: conversation.id! });
            void createLogEvent(LogEventType.CONVERSATION_BLOCKED, {
                eventId: conversation.eventId,
                targetId: conversation.id,
            });
            toast.success(t('messagesPage.moreMenu.blockSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('messagesPage.moreMenu.blockError'));
        }
    };

    const handleUnblock = async () => {
        try {
            await unblockConversation({ id: conversation.id! });
            void createLogEvent(LogEventType.CONVERSATION_UNBLOCKED, {
                eventId: conversation.eventId,
                targetId: conversation.id,
            });
            toast.success(t('messagesPage.moreMenu.unblockSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('messagesPage.moreMenu.unblockError'));
        }
    };

    const isBlockedByUser = conversation.blockedBy?.id === viewer?.id;
    const canToggleBlock = isBlockedByUser || !conversation.blockedBy;
    const isSupport = conversation?.isSupport && !isAdminUser(viewer!);

    return (
        <MoreMenu className="hover:bg-base-200 active:bg-base-300 data-[state=open]:bg-base-300">
            <MoreMenu.Item danger onClick={handleReport} disabled={isSupport || !conversation.isParticipant}>
                {t('messagesPage.moreMenu.report')}
            </MoreMenu.Item>
            {!isBlockedByUser ? (
                <MoreMenu.Item
                    danger
                    onClick={handleBlock}
                    disabled={!canToggleBlock || isSupport || !conversation.isParticipant}
                >
                    {t('messagesPage.moreMenu.block')}
                </MoreMenu.Item>
            ) : (
                <MoreMenu.Item
                    danger
                    onClick={handleUnblock}
                    disabled={!canToggleBlock || isSupport || !conversation.isParticipant}
                >
                    {t('messagesPage.moreMenu.unblock')}
                </MoreMenu.Item>
            )}
        </MoreMenu>
    );
};

const UserProfile = ({ conversation }: { conversation: Conversation }) => {
    const { t } = useTranslation('site');
    const navigate = useNavigate();

    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());
    const organizationProfile = useOrganizationProfile(conversation);
    const receiver = getReceiver(conversation, viewer!);

    const { data: notes } = useQuery({
        ...userNoteListQuery({ eventId, targetUserId: receiver?.id }),
        enabled: !!receiver?.id && isOrganizationUser(viewer!),
    });
    const [searchParams] = useSearchParams();
    const action = searchParams.get('action');
    const showUserNotesDialog = action === 'note';

    const isSupport = conversation?.isSupport && !isAdminUser(viewer!);

    return (
        <>
            <div className="relative">
                <div className="flex gap-3 items-center bg-base-100 px-4 py-3 rounded-xl mt-8 relative">
                    <Link
                        className="flex gap-3 items-center"
                        to={
                            organizationProfile?.eventId
                                ? siteUrl(SITE_ORGANIZATION_DETAIL_PATH, {
                                      organizationId: organizationProfile?.organizationId,
                                      eventId,
                                  })
                                : eventId
                                  ? siteUrl(SITE_USER_DETAIL_PATH, { userId: receiver?.id, eventId })
                                  : ''
                        }
                    >
                        {isSupport ? (
                            <div className="w-20 h-20 rounded-full flex bg-base-200 text-base-500 items-center justify-center -mt-8">
                                <HeadsetIcon className="!w-10 !h-10" strokeWidth={1} />
                            </div>
                        ) : (
                            <Avatar user={receiver} className="w-20 h-20 -mt-8" />
                        )}

                        <div className="flex flex-col min-w-0">
                            <span className="font-medium">
                                {isSupport ? 'Support' : `${receiver?.firstName} ${receiver?.lastName}`}
                            </span>
                            {organizationProfile?.name && (
                                <span className="text-xs font-normal truncate flex items-center gap-1">
                                    {organizationProfile?.name}
                                </span>
                            )}
                        </div>
                    </Link>

                    <div className="flex gap-3 items-center ml-auto">
                        {!isSupport && isOrganizationUser(viewer!) && (
                            <Button
                                variant="ghost"
                                className="flex items-center gap-2 text-base-800 bg-white hover:text-theme-700 hover:bg-theme-100 active:bg-theme-300 mr-16"
                                onClick={() =>
                                    navigate(
                                        siteUrl(SITE_CONVERSATION_DETAIL_PATH, {
                                            eventId,
                                            conversationId: conversation?.id,
                                        }) + `?action=note&mode=${notes && notes?.length > 0 ? 'list' : 'create'}`
                                    )
                                }
                            >
                                <NotebookIcon />
                                <span>
                                    {notes && notes?.length > 0
                                        ? t('messagesPage.userProfile.showNotes', {
                                              count: notes?.length || 0,
                                          })
                                        : t('messagesPage.userProfile.addNote')}
                                </span>
                            </Button>
                        )}

                        {!isSupport && isRegularUser(receiver) && (
                            <SaveUserRibbon user={receiver} eventId={eventId} className="right-16 pt-7" />
                        )}

                        <ConversationMoreMenu conversation={conversation} />
                    </div>
                </div>
            </div>

            <UserNotesDialog
                targetUser={receiver!}
                isOpen={showUserNotesDialog}
                onClose={() =>
                    navigate(siteUrl(SITE_CONVERSATION_DETAIL_PATH, { eventId, conversationId: conversation?.id }))
                }
                onCancel={() =>
                    notes &&
                    notes.length === 0 &&
                    navigate(siteUrl(SITE_CONVERSATION_DETAIL_PATH, { eventId, conversationId: conversation?.id }))
                }
            />
        </>
    );
};

const formatTime = (time: Date | string) => {
    const date = new Date(time);
    return date.toLocaleTimeString('de-DE', {
        hour: '2-digit',
        minute: '2-digit',
    });
};

const FilePreview = ({ attachmentUrl }: { attachmentUrl: string }) => {
    const ext = attachmentUrl.split('.').pop() || '';
    const basename = attachmentUrl.split('/').pop() || '';

    const [fileSize, setFileSize] = useState<number | null>(null);

    useEffect(() => {
        void getRemoteFileSize(attachmentUrl).then((size) => setFileSize(size));
    }, [attachmentUrl]);

    if (isImage(ext)) {
        return (
            <a
                href={attachmentUrl}
                target="_blank"
                className="w-full flex justify-center items-center bg-black/5 my-1 flex-none rounded-md hover:bg-black/10 transition-colors overflow-hidden"
            >
                <Image
                    src={attachmentUrl}
                    alt={basename}
                    className="block max-h-96 object-cover flex-none rounded-sm"
                />
            </a>
        );
    }

    return (
        <a
            href={attachmentUrl}
            target="_blank"
            className="w-full flex gap-2 items-center bg-black/5 my-1 px-3 py-2 flex-none rounded-md min-w-60 hover:bg-black/10 transition-colors overflow-hidden"
        >
            <FileIcon className="!w-6 !h-6" strokeWidth={1.5} />
            <span className="flex flex-col">
                <span className="font-medium">{basename}</span>
                {fileSize !== null && <span className="text-base-800 text-xs">{formatFileSize(fileSize)}</span>}
            </span>
        </a>
    );
};

const UserMessage = ({ message, showName = false }: { message: MessageModel; showName?: boolean }) => {
    const { data: viewer } = useQuery(userDetailQuery());

    const isMyMessage = message.userId === viewer?.id;

    return (
        <div className={classnames('flex', isMyMessage ? 'justify-end' : 'justify-start')}>
            <div
                className={classnames(
                    'px-2 py-1 rounded-xl text-sm max-w-[90%]',
                    isMyMessage ? 'bg-theme-100' : 'bg-white'
                )}
            >
                {!isMyMessage && showName && (
                    <div
                        className={classnames(
                            'text-xs font-medium text-base-800 px-1 p-1',
                            !message.attachment?.url && '-mb-2'
                        )}
                        style={{
                            color: message.user?.id ? generateHexColor(message.user?.id!) : undefined,
                        }}
                    >
                        {message.user?.firstName} {message.user?.lastName}
                    </div>
                )}

                {message.attachment?.url && <FilePreview attachmentUrl={message.attachment?.url} />}

                <div>
                    <span className="inline-block p-1 whitespace-pre-line">{message.text}</span>

                    <span className="float-end p-1">
                        <span className="text-xs font-normal opacity-40 ml-3">{formatTime(message.createdAt!)}</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

const formatSystemMessage = (t: TFunction<'site'>, message: MessageModel) => {
    const { data: viewer } = useQuery(userDetailQuery());

    const isUser = message.userId === viewer?.id;
    const isTargetUser = message.targetUserId === viewer?.id;

    const targetName = `${message.targetUser?.firstName} ${message.targetUser?.lastName}`;
    const userName = `${message.user?.firstName} ${message.user?.lastName}`;

    switch (message.messageType) {
        case MessageType.USER_ADDED:
            if (isUser) {
                return t('messagesPage.systemMessage.userAdded.asActor', { targetName });
            } else if (isTargetUser) {
                return t('messagesPage.systemMessage.userAdded.asTarget', { userName });
            } else {
                return t('messagesPage.systemMessage.userAdded.asOther', { userName, targetName });
            }

        case MessageType.USER_REMOVED:
            if (isUser) {
                return t('messagesPage.systemMessage.userRemoved.asActor', { targetName });
            } else if (isTargetUser) {
                return t('messagesPage.systemMessage.userRemoved.asTarget', { userName });
            } else {
                return t('messagesPage.systemMessage.userRemoved.asOther', { userName, targetName });
            }

        case MessageType.USER_JOINED:
            return t('messagesPage.systemMessage.userJoined.default', { targetName });

        case MessageType.USER_LEFT:
            return t('messagesPage.systemMessage.userLeft.default', { targetName });

        case MessageType.USER_REPORTED:
            if (isUser) {
                return t('messagesPage.systemMessage.userReported.asActor', { targetName });
            } else if (isTargetUser) {
                return t('messagesPage.systemMessage.userReported.asTarget', { userName });
            } else {
                return t('messagesPage.systemMessage.userReported.asOther', { userName, targetName });
            }

        case MessageType.USER_BLOCKED:
            if (isUser) {
                return t('messagesPage.systemMessage.userBlocked.asActor', { targetName });
            } else if (isTargetUser) {
                return t('messagesPage.systemMessage.userBlocked.asTarget', { userName });
            } else {
                return t('messagesPage.systemMessage.userBlocked.asOther', { userName, targetName });
            }
        case MessageType.USER_UNBLOCKED:
            if (isUser) {
                return t('messagesPage.systemMessage.userUnblocked.asActor', { targetName });
            } else if (isTargetUser) {
                return t('messagesPage.systemMessage.userUnblocked.asTarget', { userName });
            } else {
                return t('messagesPage.systemMessage.userUnblocked.asOther', { userName, targetName });
            }

        default:
            return message.text;
    }
};

const SystemMessage = ({ message }: { message: MessageModel }) => {
    const { t } = useTranslation('site');
    return (
        <div className="flex justify-center py-2">
            <div className="rounded-md bg-base-100 px-2.5 py-1.5 text-xs text-base-600">
                <span>{formatSystemMessage(t, message)}</span>
            </div>
        </div>
    );
};

const Message = ({ message, showName }: { message: MessageModel; showName?: boolean }) => {
    if (message.messageType === MessageType.USER_MESSAGE) {
        return <UserMessage message={message} showName={showName} />;
    } else {
        return <SystemMessage message={message} />;
    }
};

const getPageParamFromUrl = (url: string): number | undefined => {
    const params = new URLSearchParams(new URL(url).search);
    const page = params.get('page');
    return page ? parseInt(page) : undefined;
};

const ConversationMessages = ({
    conversation,
    myConversationId,
}: {
    conversation: Conversation;
    myConversationId?: string;
}) => {
    const { t, i18n } = useTranslation('site');

    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());
    const {
        data: paginatedMessages,
        hasNextPage,
        fetchNextPage,
        refetch: refetchMessages,
    } = useInfiniteQuery({
        ...messagesPaginatedListQuery({ conversationId: conversation.id! }),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) =>
            lastPage.next ? getPageParamFromUrl(lastPage.next) : undefined,
        getPreviousPageParam: (firstPage, allPages, firstPageParam, allPageParams) =>
            firstPage.previous ? getPageParamFromUrl(firstPage.previous) : undefined,
    });
    const messages: MessageModel[] =
        paginatedMessages?.pages.reduce((acc, page) => acc.concat(page.results || []), [] as MessageModel[]) || [];

    const { mutateAsync: createMessage } = useMutation({
        ...messageCreateMutation(),
        meta: { skipInvalidateQueries: true },
    });

    const formMethods = useForm({ defaultValues: { ...CONVERSATION_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    const [isLoading, setIsLoading] = useState(false);

    const createLogEvent = useLogEvent();

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            const attachmentId = await getOrCreateFileId(
                values.attachment,
                isOrganizationUser(viewer!) ? { organizationId: viewer?.organizationId } : { userId: viewer?.id }
            );

            await createMessage({
                data: { conversationId: conversation.id!, ...values, attachmentId },
            });
            void createLogEvent(LogEventType.CONVERSATION_MESSAGE_SENT, {
                eventId,
                targetId: conversation.id,
                hasText: !!values.text,
                hasAttachment: !!values.attachment,
            });
            await refetchMessages();
            formMethods.reset();
            toast.success(t('messagesPage.messages.submitSuccess'));
        } catch (error) {
            setFormErrors(t, formMethods, error);
            toast.error(t('messagesPage.messages.submitError'));
        } finally {
            setIsLoading(false);
        }
    };

    const { mutateAsync: markRead } = useMutation(conversationMarkReadMutation());

    const unreadCount = conversation?.unreadCount || 0;
    const isUnread = unreadCount > 0;
    const showName =
        !conversation.isParticipant ||
        (conversation.participants && conversation.participants.length > 2) ||
        (conversation.isSupport && !isAdminUser(viewer!));
    const receiver = getReceiver(conversation, viewer!);

    useEffect(() => {
        if (conversation.isParticipant && isUnread) {
            const markReadTimeout = setTimeout(() => {
                void markRead({ id: conversation.id! });
            }, 500);
            return () => clearTimeout(markReadTimeout);
        }
    }, [conversation.isParticipant, isUnread]);

    return (
        <div className="flex flex-col h-full flex-1 min-h-0">
            <div className="p-5 flex-none">
                <UserProfile conversation={conversation} />
            </div>

            <div className="flex flex-col-reverse gap-2 overflow-auto min-h-0 flex-1 p-5 pt-10">
                {messages?.map((message) => {
                    const nextMessage = messages[messages.indexOf(message) + 1];
                    const isDifferentDate = nextMessage
                        ? new Date(nextMessage.createdAt!).toDateString() !==
                          new Date(message.createdAt!).toDateString()
                        : false;

                    return (
                        <Fragment key={message.id}>
                            <Message message={message} showName={showName} />

                            {(!nextMessage || isDifferentDate) && (
                                <SystemMessage
                                    // @ts-ignore
                                    message={{
                                        text: new Date(message.createdAt!).toLocaleDateString(i18n.language, {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        } as any),
                                    }}
                                />
                            )}
                        </Fragment>
                    );
                })}

                {hasNextPage && (
                    <div className="flex justify-center py-2">
                        <button
                            className="rounded-md bg-blue-100 px-2.5 py-1.5 text-xs text-base-600 flex gap-2 items-center hover:cursor-pointer hover:bg-blue-200 active:bg-blue-300 transition-colors"
                            type="button"
                            onClick={() => fetchNextPage()}
                        >
                            <RotateCwIcon className="!w-3 !h-3" /> {t('messagesPage.messages.loadMore')}
                        </button>
                    </div>
                )}
            </div>

            {!conversation.isParticipant && (
                <div className="flex justify-center py-4">
                    {!myConversationId ? (
                        <LinkButton
                            variant="primary"
                            to={siteUrl(SITE_CONVERSATION_ADD_PATH, { eventId }) + `?receiver=${receiver?.id}`}
                        >
                            {t('messagesPage.messages.startConversation', { name: receiver?.firstName })}
                        </LinkButton>
                    ) : (
                        <LinkButton
                            to={siteUrl(SITE_CONVERSATION_DETAIL_PATH, { eventId, conversationId: myConversationId })}
                        >
                            {t('messagesPage.messages.gotoConversation', { name: receiver?.firstName })}
                        </LinkButton>
                    )}
                </div>
            )}

            {conversation.isParticipant && !conversation.blockedAt && (
                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex flex-col gap-3 px-5 flex-none">
                    <FormError />

                    <Form.Field name="text">
                        <TextField />
                    </Form.Field>

                    <div className="flex gap-2 justify-between">
                        <Form.Field name="attachment">
                            <FileField widget={AttachmentInput} />
                        </Form.Field>

                        <FormSubmitButton variant="primary" type="submit" loading={isLoading}>
                            {t('messagesPage.messages.submit')}
                        </FormSubmitButton>
                    </div>
                </Form>
            )}
        </div>
    );
};

export const AddConversationFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { mutateAsync: createConversation } = useMutation(conversationCreateMutation());

    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());

    const [searchParams] = useSearchParams();

    const receiverId = searchParams.get('receiver') || '';
    const isSupport = receiverId === 'support';
    const { data: receiver } = useQuery({
        ...userDetailQuery(receiverId),
        enabled: Boolean(receiverId) && !isSupport,
    });

    const createLogEvent = useLogEvent();

    const handleSubmit = async (values: any) => {
        const attachmentId = await getOrCreateFileId(
            values.attachment,
            isOrganizationUser(viewer!) ? { organizationId: viewer?.organizationId } : { userId: viewer?.id }
        );

        const conversation = await createConversation({
            data: {
                receiverId: receiverId,
                eventId,
                ...values,
                attachmentId,
            },
        });
        void createLogEvent(LogEventType.CONVERSATION_STARTED, { targetId: conversation.id, receiverId });
        void createLogEvent(LogEventType.CONVERSATION_MESSAGE_SENT, {
            targetId: conversation.id,
            hasText: !!values.text,
            hasAttachment: !!values.attachment,
        });
        return navigate(siteUrl(SITE_CONVERSATION_DETAIL_PATH, { eventId, conversationId: conversation.id }));
    };

    return (
        <BaseConversationFormDialog
            receiver={
                isSupport
                    ? {
                          firstName: 'Support',
                      }
                    : receiver
            }
            {...props}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(siteUrl(SITE_MESSAGES_PATH, { eventId }));
                }
            }}
            onSubmit={handleSubmit}
            onCancel={() => navigate(siteUrl(SITE_MESSAGES_PATH, { eventId }))}
        />
    );
};

const useOrganizationProfile = (conversation: Conversation) => {
    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());
    const receiver = getReceiver(conversation, viewer!);
    const { data: organizationProfilesData } = useQuery({
        ...organizationProfileListQuery({
            eventId,
            organizationId: receiver?.organizationId,
        }),
        enabled: Boolean(conversation) && Boolean(receiver?.organizationId),
    });
    return organizationProfilesData?.[0] || ({} as OrganizationProfile);
};

const MessagesPage = () => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());

    // for admin users all, for org users only where they have a profile, for students only where they participate
    const { data: allEvents } = useQuery(eventListQuery());
    const { data: organizationProfiles } = useQuery({
        ...organizationProfileListQuery({ eventId, organizationId: viewer?.organizationId }),
        enabled: isOrganizationUser(viewer!),
    });
    const { data: attendees } = useQuery({
        ...eventAttendeeListQuery({ userId: viewer?.id }),
        enabled: isRegularUser(viewer!),
    });
    const events = allEvents?.filter((event) => {
        if (isAdminUser(viewer!)) return true;
        if (isOrganizationUser(viewer!)) return organizationProfiles?.some((op) => op.eventId === event.id);
        if (isRegularUser(viewer!)) return attendees?.some((a) => a.eventId === event.id);
        return false;
    });

    // TODO: allow all support users to see all their conversations
    const [searchParams] = useSearchParams();
    const receiverId = searchParams.get('receiver') || '';
    const { data: allConversations } = useQuery(conversationListQuery());
    const conversations = allConversations?.filter(
        (c) => c.eventId === eventId && (!receiverId || c.participantIds.includes(receiverId))
    );
    const myConversations = conversations?.filter((c) => c.isParticipant);
    const otherConversations = conversations?.filter((c) => !c.isParticipant);
    const supportConversations = myConversations?.filter((c) => c.isSupport);

    const { conversationId } = useParams();
    const { data: conversation } = useQuery(conversationDetailQuery(conversationId!));

    // if organization user lets find out if the user has already a conversation with the same user as the selected one
    const receiver = conversation ? getReceiver(conversation!, viewer!) : undefined;
    const myConversation = receiver ? myConversations?.find((c) => c.participantIds.includes(receiver?.id)) : undefined;

    const handleEventChange = (e: any) => {
        const eventId = e.target.value;
        const event = events?.find((e) => e.id === eventId);
        // getEventDomain is either a plain domain or contains /events/... already,
        // so we need to get rid of it for our new url
        window.location.href = `//${getEventDomain(event!)}${url(SITE_MESSAGES_PATH.replace('/events/:eventId/', '/'))}`;
    };

    return (
        <>
            <main className="flex justify-center px-6 items-stretch">
                <div className="w-full max-w-[1120px] flex flex-col items-stretch justify-stretch">
                    <div
                        className={classnames(
                            'flex gap-8',
                            // 3 rem difference for support bar
                            isSupportUser(viewer!) ? 'h-[calc(100vh-7rem)]' : 'h-[calc(100vh-4rem)]'
                        )}
                    >
                        <Admin.Navigation className="pt-10">
                            {allConversations && events && events.length > 1 && (
                                <>
                                    <Admin.NavigationItem asChild>
                                        <div className="!block hover:!bg-transparent w-full">
                                            <SingleSelect
                                                options={
                                                    events?.map((event) => {
                                                        const unreadCount = allConversations
                                                            ?.filter((c) => c.eventId === event.id)
                                                            ?.reduce((acc, c) => acc + c.unreadCount, 0);

                                                        return {
                                                            label: (
                                                                <span className="flex justify-between gap-2 items-center">
                                                                    <span>{event.name}</span>
                                                                    {unreadCount > 0 ? (
                                                                        <span className="bg-theme-500 w-2 h-2 rounded-full" />
                                                                    ) : null}
                                                                </span>
                                                            ),
                                                            value: event.id,
                                                        };
                                                    }) || []
                                                }
                                                onChange={handleEventChange}
                                                value={eventId}
                                                required
                                                hideSearch
                                            />
                                        </div>
                                    </Admin.NavigationItem>
                                    <Admin.NavigationSeparator />
                                </>
                            )}

                            <Admin.NavigationLabel>
                                {t('messagesPage.navigation.myConversations')}
                            </Admin.NavigationLabel>

                            {myConversations?.map((conversation) => (
                                <ConversationNavigationLink conversation={conversation} key={conversation.id} />
                            ))}

                            {myConversations && myConversations?.length === 0 && (
                                <Admin.NavigationItem className="pointer-events-none">
                                    <span className="font-normal text-base-500">
                                        {t('messagesPage.navigation.noConversations')}
                                    </span>
                                </Admin.NavigationItem>
                            )}

                            {isOrganizationUser(viewer!) && (
                                <>
                                    <Admin.NavigationSeparator />

                                    <Admin.NavigationLabel>
                                        {t('messagesPage.navigation.otherConversations')}
                                    </Admin.NavigationLabel>

                                    {otherConversations?.map((conversation) => (
                                        <ConversationNavigationLink conversation={conversation} key={conversation.id} />
                                    ))}

                                    {otherConversations && otherConversations?.length === 0 && (
                                        <Admin.NavigationItem className="pointer-events-none">
                                            <span className="font-normal text-base-500">
                                                {t('messagesPage.navigation.noConversations')}
                                            </span>
                                        </Admin.NavigationItem>
                                    )}
                                </>
                            )}

                            {!isAdminUser(viewer!) && (
                                <>
                                    <Admin.NavigationSeparator />

                                    <Admin.NavigationLink
                                        to={
                                            supportConversations && supportConversations.length > 0
                                                ? siteUrl(SITE_CONVERSATION_DETAIL_PATH, {
                                                      eventId,
                                                      conversationId: supportConversations[0]?.id,
                                                  })
                                                : siteUrl(SITE_CONVERSATION_ADD_PATH, { eventId }) + '?receiver=support'
                                        }
                                        active={false}
                                    >
                                        <span className="flex gap-2 items-center">
                                            <div className="w-8 h-8 rounded-full flex bg-base-200 text-base-500 items-center justify-center">
                                                <HeadsetIcon />
                                            </div>

                                            <div className="flex flex-col min-w-0">
                                                <span>{t('messagesPage.navigation.contactSupport')}</span>
                                            </div>
                                        </span>
                                    </Admin.NavigationLink>
                                </>
                            )}
                        </Admin.Navigation>

                        <Admin.Content className="pt-0">
                            {conversation ? (
                                <ConversationMessages
                                    conversation={conversation}
                                    myConversationId={myConversation?.id}
                                />
                            ) : (
                                <EmptyState
                                    title={t('messagesPage.emptyState.title')}
                                    description={t('messagesPage.emptyState.description')}
                                />
                            )}
                        </Admin.Content>
                    </div>
                </div>
            </main>
            <Outlet />
        </>
    );
};
export default MessagesPage;
