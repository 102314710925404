import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Form, StringField } from '@nicoknoll/forms';
import { useMutation } from '@tanstack/react-query';

import FormError from '../../../components/FormError.tsx';
import FormSubmitButton from '../../../components/FormSubmitButton.tsx';
import PasswordInput from '../../../components/PasswordInput.tsx';
import { setFormErrors } from '../../../utils/forms.ts';
import url from '../../../utils/url';
import { ADMIN_USER_SETTINGS_ACCOUNT_PATH } from '../../admin/user-settings/routes.tsx';
import Auth from '../components/Auth.tsx';
import { resetPasswordMutation } from '../queries.ts';
import { ADMIN_LOGIN_PATH } from '../routes.tsx';

const RESET_PASSWORD_FORM_DEFAULT_VALUES = {
    newPassword: '',
    confirmPassword: '',
};

const ResetPasswordPage = () => {
    const { t } = useTranslation('auth');

    const formMethods = useForm({ defaultValues: { ...RESET_PASSWORD_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    const { token } = useParams();

    const navigate = useNavigate();

    const { mutateAsync: resetPassword } = useMutation(resetPasswordMutation());

    const handleSubmit = async (values: typeof RESET_PASSWORD_FORM_DEFAULT_VALUES) => {
        try {
            await resetPassword({
                token: token!,
                data: {
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmPassword,
                },
            });
            toast.success(t('resetPasswordPage.submitSuccess'));
            navigate(ADMIN_USER_SETTINGS_ACCOUNT_PATH);
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('resetPasswordPage.submitError'));
        }
    };

    return (
        <Auth>
            <Auth.Card>
                <Auth.CardContent>
                    <h2 className="text-3xl font-semibold">{t('resetPasswordPage.title')}</h2>

                    <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                        <FormError />

                        <Form.Field name="newPassword" rules={{ required: t('resetPasswordPage.newPasswordRequired') }}>
                            <StringField
                                label={t('resetPasswordPage.newPasswordLabel')}
                                widget={PasswordInput}
                                required
                            />
                        </Form.Field>
                        <Form.Field
                            name="confirmPassword"
                            rules={{ required: t('resetPasswordPage.confirmPasswordRequired') }}
                        >
                            <StringField
                                label={t('resetPasswordPage.confirmPasswordLabel')}
                                widget={PasswordInput}
                                required
                            />
                        </Form.Field>
                        <FormSubmitButton variant="primary" className="mt-3">
                            {t('resetPasswordPage.submitButton')}
                        </FormSubmitButton>
                    </Form>
                </Auth.CardContent>
                <Auth.CardFooter>
                    <Link
                        to={url(ADMIN_LOGIN_PATH)}
                        className="text-sm text-theme-500 underline underline-offset-2 hover:text-theme-700 transition-colors"
                    >
                        {t('resetPasswordPage.backToLogin')}
                    </Link>
                </Auth.CardFooter>
            </Auth.Card>
        </Auth>
    );
};

export default ResetPasswordPage;
