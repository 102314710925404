import { ImagesIcon, UsersRoundIcon } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import { ADMIN_SPACE_SETTINGS_FILES_PATH, ADMIN_SPACE_SETTINGS_USERS_PATH } from '../routes.tsx';

const SpaceSettingsPage = (props: any) => {
    const { t } = useTranslation('admin');

    return (
        <div className="flex gap-8 h-full">
            <Admin.Navigation className="pt-10">
                <Admin.NavigationLabel>{t('spaceSettingsPage.navigation.space')}</Admin.NavigationLabel>

                <Admin.NavigationLink to={url(ADMIN_SPACE_SETTINGS_FILES_PATH)}>
                    <span className="flex gap-2 items-center">
                        <ImagesIcon /> {t('spaceSettingsPage.navigation.files')}
                    </span>
                </Admin.NavigationLink>

                <Admin.NavigationLink to={url(ADMIN_SPACE_SETTINGS_USERS_PATH)}>
                    <span className="flex gap-2 items-center">
                        <UsersRoundIcon /> {t('spaceSettingsPage.navigation.users')}
                    </span>
                </Admin.NavigationLink>
            </Admin.Navigation>

            <Admin.Content>
                <Outlet />
            </Admin.Content>
        </div>
    );
};

export default SpaceSettingsPage;
