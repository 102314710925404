import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useQuery } from '@tanstack/react-query';

import { Event, OrganizationProfile } from '../../../../data/models.ts';
import { eventListQuery, organizationDetailQuery, organizationProfileListQuery } from '../../../../data/queries.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import { ADMIN_ORGANIZATION_PROFILE_DETAIL_PATH } from '../routes.tsx';

const EventCard = ({ event, profile }: { event: Event; profile: OrganizationProfile }) => {
    return (
        <Link
            className="flex flex-col gap-6 flex-1 bg-base-100 rounded-xl p-8 hover:bg-base-150 transition-colors h-full"
            to={url(ADMIN_ORGANIZATION_PROFILE_DETAIL_PATH, { profileId: profile.id })}
        >
            <span className="flex flex-col gap-6">
                <span
                    className="w-full h-32 bg-contain bg-no-repeat bg-center mix-blend-multiply"
                    style={{ backgroundImage: `url(${event?.logo?.url})` }}
                />

                <span className="flex flex-col gap-2">
                    <span className="font-medium text-lg">{event.name}</span>
                    <span className="text-base-500">{event.summary}</span>
                </span>
            </span>
        </Link>
    );
};

const OrganizationProfileListPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('admin');

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());
    const { data: events } = useQuery(eventListQuery());
    const { data: organizationProfiles } = useQuery(
        organizationProfileListQuery({ organizationId: viewerOrganization?.id })
    );

    const profileEvents: [Event, OrganizationProfile | undefined][] =
        events
            ?.map((event: Event): [Event, OrganizationProfile | undefined] => [
                event,
                organizationProfiles?.find((profile: any) => profile.eventId === event.id),
            ])
            .filter((profileEvent: [Event, OrganizationProfile | undefined]) => profileEvent[1]) || [];

    return (
        <>
            <Admin.Content>
                <Admin.Card className="flex gap-4 flex-none justify-between">
                    <h1 className="text-3xl font-semibold">{t('organizationProfileListPage.title')}</h1>
                </Admin.Card>

                {profileEvents && profileEvents.length > 0 ? (
                    <div className={classnames('grid grid-cols-3 gap-5', className)} {...props}>
                        {profileEvents?.map(([event, profile]) => (
                            <EventCard key={event.id} event={event} profile={profile!} />
                        ))}
                    </div>
                ) : (
                    <EmptyState
                        title={t('organizationProfileListPage.emptyState.title')}
                        description={t('organizationProfileListPage.emptyState.description')}
                    />
                )}
            </Admin.Content>

            <Outlet />
        </>
    );
};

export default OrganizationProfileListPage;
