import { FacebookIcon, InstagramIcon, LinkIcon, LinkedinIcon, TwitterIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Form, StringField, Widget } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import { organizationDetailQuery, organizationUpdateMutation } from '../../../../data/queries.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import Admin from '../../components/Admin.tsx';

const CONTACT_FORM_DEFAULT_VALUES = {
    website: '',
    linkedin: '',
    facebook: '',
    twitter: '',
    instagram: '',
};

const ContactTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { data: viewerOrganization } = useQuery(organizationDetailQuery());

    const { mutateAsync: updateOrganization } = useMutation(organizationUpdateMutation());

    const formMethods = useForm({
        defaultValues: pickFormValues(CONTACT_FORM_DEFAULT_VALUES, viewerOrganization),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(CONTACT_FORM_DEFAULT_VALUES, viewerOrganization));
    }, [viewerOrganization]);

    const handleSubmit = async (values: any) => {
        try {
            await updateOrganization({ id: viewerOrganization?.id!, data: values });
            toast.success(t('organizationSettingsPage.contactTab.submitSuccess'));
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('organizationSettingsPage.contactTab.submitError'));
        }
    };

    return (
        <Admin.Card className="flex flex-col gap-5">
            <h1 className="text-3xl font-semibold mb-2">{t('organizationSettingsPage.contactTab.title')}</h1>

            <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                <FormError />

                <Form.Field name="website">
                    <StringField
                        label={t('organizationSettingsPage.contactTab.websiteLabel')}
                        placeholder="https://example.com"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <LinkIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <Form.Field name="linkedin">
                    <StringField
                        label={t('organizationSettingsPage.contactTab.linkedinLabel')}
                        placeholder="https://linkedin.com/company/example"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <LinkedinIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <Form.Field name="facebook">
                    <StringField
                        label={t('organizationSettingsPage.contactTab.facebookLabel')}
                        placeholder="https://facebook.com/example"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <FacebookIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <Form.Field name="twitter">
                    <StringField
                        label={t('organizationSettingsPage.contactTab.twitterLabel')}
                        placeholder="https://twitter.com/example"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <TwitterIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <Form.Field name="instagram">
                    <StringField
                        label={t('organizationSettingsPage.contactTab.instagramLabel')}
                        placeholder="https://instagram.com/example"
                        controls={
                            <Widget.ControlButton className="pointer-events-none">
                                <InstagramIcon />
                            </Widget.ControlButton>
                        }
                    />
                </Form.Field>

                <div className="flex justify-end">
                    <FormSubmitButton variant="primary" type="submit">
                        {t('organizationSettingsPage.contactTab.submitButton')}
                    </FormSubmitButton>
                </div>
            </Form>
        </Admin.Card>
    );
};

export default ContactTab;
