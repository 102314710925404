import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { BooleanField, Form, StringField } from '@nicoknoll/forms';
import { useMutation, useQuery } from '@tanstack/react-query';

import { ADMIN_PATH } from '../../../App.tsx';
import FormError from '../../../components/FormError.tsx';
import FormSubmitButton from '../../../components/FormSubmitButton.tsx';
import PasswordInput from '../../../components/PasswordInput.tsx';
import { userDetailQuery } from '../../../data/queries.ts';
import { setFormErrors } from '../../../utils/forms.ts';
import url from '../../../utils/url';
import Auth from '../components/Auth.tsx';
import { loginMutation } from '../queries.ts';
import { ADMIN_FORGOT_PASSWORD_PATH, ADMIN_SIGNUP_PATH } from '../routes.tsx';

const LOGIN_FORM_DEFAULT_VALUES = {
    email: '',
    password: '',
    remember: false,
};

const LoginPage = () => {
    const { t } = useTranslation('auth');

    const formMethods = useForm({ defaultValues: { ...LOGIN_FORM_DEFAULT_VALUES }, mode: 'onTouched' });

    const { data: viewer } = useQuery(userDetailQuery());
    const { mutateAsync: login } = useMutation(loginMutation());

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (viewer?.id) {
            navigate(searchParams.get('next') || ADMIN_PATH);
        }
    }, [viewer]);

    const handleSubmit = async (values: any) => {
        try {
            await login(values);
            toast.success(t('loginPage.submitSuccess'));
            navigate(searchParams.get('next') || ADMIN_PATH);
        } catch (error) {
            console.error(error);
            setFormErrors(t, formMethods, error);
            toast.error(t('loginPage.submitError'));
        }
    };

    return (
        <Auth>
            <Auth.Card>
                <Auth.CardContent>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-3xl font-semibold">{t('loginPage.title')}</h2>
                    </div>

                    <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                        <FormError />

                        <Form.Field name="email" rules={{ required: t('loginPage.emailRequired') }}>
                            <StringField label={t('loginPage.emailLabel')} required />
                        </Form.Field>

                        <Form.Field name="password" rules={{ required: t('loginPage.passwordRequired') }}>
                            <StringField label={t('loginPage.passwordLabel')} widget={PasswordInput} required />
                        </Form.Field>

                        <div className="flex items-center">
                            <Form.Field name="remember">
                                <BooleanField label={t('loginPage.rememberLabel')} />
                            </Form.Field>

                            <Link
                                to={url(ADMIN_FORGOT_PASSWORD_PATH)}
                                className="ml-auto text-sm text-theme-500 underline underline-offset-2 hover:text-theme-700 transition-colors"
                            >
                                {t('loginPage.forgotPassword')}
                            </Link>
                        </div>

                        <FormSubmitButton variant="primary" className="mt-3">
                            {t('loginPage.submitButton')}
                        </FormSubmitButton>
                    </Form>
                </Auth.CardContent>
                <Auth.CardFooter>
                    <p className="text-sm">
                        {t('loginPage.noAccount')}{' '}
                        <Link
                            to={`${url(ADMIN_SIGNUP_PATH)}?next=${searchParams.get('next')}`}
                            className="ml-auto text-theme-500 underline underline-offset-2 hover:text-theme-700 transition-colors"
                        >
                            {t('loginPage.gotoSignup')}
                        </Link>
                    </p>
                </Auth.CardFooter>
            </Auth.Card>
        </Auth>
    );
};

export default LoginPage;
