import {
    eventDetailQuery,
    eventListQuery,
    jobListQuery,
    organizationDetailQuery,
    organizationProfileDetailQuery,
    organizationProfileJobListQuery,
    organizationProfileListQuery,
    organizationProfileUserListQuery,
    userListQuery,
} from '../../../data/queries.ts';
import ensureQueriesData from '../../../utils/ensureQueriesData.ts';

export const organizationProfileListPageLoader = ({ params }: any) =>
    ensureQueriesData({
        organization: organizationDetailQuery(),
        events: eventListQuery(),
    }).then((res: any) =>
        ensureQueriesData({
            organizationProfiles: organizationProfileListQuery({ organizationId: res.organization?.id }),
        })
    );
export const organizationProfileDetailPageLoader = ({ params }: any) =>
    ensureQueriesData({
        organization: organizationDetailQuery(),
        events: eventListQuery(),
        profile: organizationProfileDetailQuery(params.profileId),
    }).then((res: any) =>
        ensureQueriesData({
            organizationProfiles: organizationProfileListQuery({ organizationId: res.organization?.id }),
        })
    );

export const contactPersonsTabLoader = ({ params }: any) =>
    ensureQueriesData({
        organization: organizationDetailQuery(),
        profileUsers: organizationProfileUserListQuery({ organizationProfileId: params.profileId }),
    }).then((res: any) =>
        ensureQueriesData({
            users: userListQuery({ organizationId: res?.organization?.id }),
        })
    );

export const contactTabLoader = ({ params }: any) =>
    ensureQueriesData({
        organization: organizationDetailQuery(),
        organizationProfile: organizationProfileDetailQuery(params.profileId!),
    }).then((res: any) =>
        ensureQueriesData({
            event: eventDetailQuery(res?.organizationProfile?.eventId),
        })
    );

export const generalTabLoader = ({ params }: any) =>
    ensureQueriesData({
        organization: organizationDetailQuery(),
        organizationProfile: organizationProfileDetailQuery(params.profileId!),
    });

export const jobsTabLoader = ({ params }: any) =>
    ensureQueriesData({
        organization: organizationDetailQuery(),
        profileJobs: organizationProfileJobListQuery({ organizationProfileId: params.profileId }),
    }).then((res: any) =>
        ensureQueriesData({
            jobs: jobListQuery({ organizationId: res?.organization?.id }),
        })
    );
