import { isEmpty } from 'lodash';

const pickFormValues = <T extends Record<string, any>>(defaultValues: T, data: any): T => {
    const result = { ...defaultValues } as T;
    if (!data || isEmpty(data)) return result;

    (Object.keys(defaultValues) as Array<keyof T>).forEach((key) => {
        if (data[key] != null) {
            result[key] = data[key]!;
        }
    });

    return result;
};

type InputFile = {
    name: string;
    url: string;
};

export const urlToFile = (url?: string) =>
    url
        ? ({
              name: url.split('/').pop(),
              url,
          } as InputFile)
        : '';

export const fileToUrl = (file?: InputFile) => file?.url || '';

export default pickFormValues;
