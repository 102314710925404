import { PlusIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { DateField, Form, SelectField, TextField } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../../components/Button.tsx';
import Dialog from '../../../../components/Dialog.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import LinkButton from '../../../../components/LinkButton.tsx';
import MoreMenu from '../../../../components/MoreMenu.tsx';
import { Education, TRANSLATABLE_EDUCATION_KEYS, createTranslatable } from '../../../../data/models.ts';
import {
    userEducationCreateMutation,
    userEducationDeleteMutation,
    userEducationDetailQuery,
    userEducationListQuery,
    userEducationUpdateMutation,
    userProfileDetailQuery,
} from '../../../../data/queries.ts';
import { DEGREE_SUGGESTIONS, FIELD_OF_STUDY_SUGGESTIONS, SCHOOL_SUGGESTIONS } from '../../../../data/suggestions.ts';
import { valuesToOptions } from '../../../../data/utils.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import { queryClient } from '../../../../utils/queryClient.ts';
import { formatDate } from '../../../../utils/time.ts';
import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import TranslatableFormField from '../../components/TranslatableFormField.tsx';
import {
    ADMIN_USER_SETTINGS_EDUCATION_ADD_PATH,
    ADMIN_USER_SETTINGS_EDUCATION_EDIT_PATH,
    ADMIN_USER_SETTINGS_EDUCATION_PATH,
} from '../routes.tsx';

const EDUCATION_FORM_DEFAULT_VALUES = createTranslatable(
    {
        school: '',
        fieldOfStudy: '',
        degree: '',
        startDate: undefined,
        endDate: undefined,
        description: '',
    },
    TRANSLATABLE_EDUCATION_KEYS
);

const EducationCard = ({
    className,
    education,
    button,
    ...props
}: React.ComponentPropsWithRef<'div'> & { education: Education; button?: React.ReactNode }) => {
    return (
        <div className={classnames('bg-base-100 rounded-xl p-6 flex gap-4', className)} {...props}>
            <div className="flex gap-5 flex-col flex-1">
                <div className="flex gap-1 flex-col flex-1">
                    <h2 className="text-lg font-semibold">{education.school}</h2>
                    {(education.degree || education.fieldOfStudy) && (
                        <p className="text-base-800">
                            {education.degree && education.degree}
                            {education.degree && education.fieldOfStudy && ', '}
                            {education.fieldOfStudy && education.fieldOfStudy}
                        </p>
                    )}
                    {(education.startDate || education.endDate) && (
                        <p className="text-base-500">
                            {education.startDate && formatDate(education.startDate)}
                            {education.startDate && education.endDate && ' - '}
                            {education.endDate && formatDate(education.endDate)}
                        </p>
                    )}
                </div>

                {education.description && <p className="text-base-800">{education.description}</p>}
            </div>

            <div>{button}</div>
        </div>
    );
};

const BaseEducationFormDialog = ({
    isCreate = false,
    education,
    isOpen = true,
    onIsOpenChange,
    onSubmit,
    onCancel,
}: {
    isCreate: boolean;
    education?: Education;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onSubmit?: (data: any) => void;
    onCancel?: () => void;
}) => {
    const { t } = useTranslation('admin');

    const formMethods = useForm({
        defaultValues: pickFormValues(EDUCATION_FORM_DEFAULT_VALUES, education),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(EDUCATION_FORM_DEFAULT_VALUES, education));
    }, [education]);

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(onSubmit?.(values));
            toast.success(
                isCreate
                    ? t('userSettingsPage.educationFormDialog.createSuccess')
                    : t('userSettingsPage.educationFormDialog.updateSuccess')
            );
        } catch (error) {
            setFormErrors(t, formMethods, error);
            toast.error(
                isCreate
                    ? t('userSettingsPage.educationFormDialog.createError')
                    : t('userSettingsPage.educationFormDialog.updateError')
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">
                    {isCreate
                        ? t('userSettingsPage.educationFormDialog.titleCreate')
                        : t('userSettingsPage.educationFormDialog.titleUpdate')}
                </h1>

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <FormError />

                    <TranslatableFormField
                        name="school"
                        rules={{ required: t('userSettingsPage.educationFormDialog.schoolRequired') }}
                    >
                        <SelectField
                            label={t('userSettingsPage.educationFormDialog.schoolLabel')}
                            required
                            placeholder={t('userSettingsPage.educationFormDialog.schoolPlaceholder')}
                            allowAddOption
                            onAddOption={(value) => formMethods.setValue('school', value)}
                            options={valuesToOptions(SCHOOL_SUGGESTIONS)}
                        />
                    </TranslatableFormField>

                    <TranslatableFormField name="fieldOfStudy">
                        <SelectField
                            label={t('userSettingsPage.educationFormDialog.fieldOfStudyLabel')}
                            placeholder={t('userSettingsPage.educationFormDialog.fieldOfStudyPlaceholder')}
                            allowAddOption
                            onAddOption={(value) => formMethods.setValue('fieldOfStudy', value)}
                            options={valuesToOptions(FIELD_OF_STUDY_SUGGESTIONS)}
                        />
                    </TranslatableFormField>

                    <TranslatableFormField name="degree">
                        <SelectField
                            label={t('userSettingsPage.educationFormDialog.degreeLabel')}
                            placeholder={t('userSettingsPage.educationFormDialog.degreePlaceholder')}
                            hideSearch
                            options={valuesToOptions(DEGREE_SUGGESTIONS)}
                        />
                    </TranslatableFormField>

                    <Form.Field name="startDate">
                        <DateField
                            label={t('userSettingsPage.educationFormDialog.startDateLabel')}
                            calendarProps={{
                                startMonth: new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
                                endMonth: new Date(),
                            }}
                        />
                    </Form.Field>

                    <Form.Field name="endDate">
                        <DateField
                            label={t('userSettingsPage.educationFormDialog.endDateLabel')}
                            calendarProps={{
                                startMonth: new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
                                endMonth: new Date(),
                            }}
                        />
                    </Form.Field>

                    <TranslatableFormField name="description">
                        <TextField
                            label={t('userSettingsPage.educationFormDialog.descriptionLabel')}
                            placeholder={t('userSettingsPage.educationFormDialog.descriptionPlaceholder')}
                        />
                    </TranslatableFormField>

                    <div className="flex justify-end gap-2">
                        <Button variant="ghost" onClick={onCancel} type="button">
                            {t('userSettingsPage.educationFormDialog.cancelButton')}
                        </Button>

                        <FormSubmitButton variant="primary" type="submit" loading={isLoading}>
                            {isCreate
                                ? t('userSettingsPage.educationFormDialog.createButton')
                                : t('userSettingsPage.educationFormDialog.updateButton')}
                        </FormSubmitButton>
                    </div>
                </Form>
            </Dialog.Content>
        </Dialog>
    );
};

export const AddEducationFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { mutateAsync: createEducation } = useMutation(userEducationCreateMutation());

    return (
        <BaseEducationFormDialog
            isCreate
            {...props}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(ADMIN_USER_SETTINGS_EDUCATION_PATH);
                }
            }}
            onSubmit={async (data) => {
                const userProfileId = (await queryClient.ensureQueryData(userProfileDetailQuery()))?.id;
                await createEducation({ data: { ...data, userProfileId }, translatable: true });
                return navigate(ADMIN_USER_SETTINGS_EDUCATION_PATH);
            }}
            onCancel={() => navigate(ADMIN_USER_SETTINGS_EDUCATION_PATH)}
        />
    );
};

export const EditEducationFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { educationId } = useParams();

    const { data: education } = useQuery(userEducationDetailQuery(educationId!, { translatable: true }));
    const { mutateAsync: updateEducation } = useMutation(userEducationUpdateMutation());

    return (
        <BaseEducationFormDialog
            {...props}
            education={education}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(ADMIN_USER_SETTINGS_EDUCATION_PATH);
                }
            }}
            onSubmit={async (data) => {
                await updateEducation({ id: educationId!, data, translatable: true });
                return navigate(ADMIN_USER_SETTINGS_EDUCATION_PATH);
            }}
            onCancel={() => navigate(ADMIN_USER_SETTINGS_EDUCATION_PATH)}
        />
    );
};

const EducationListItem = ({ education }: { education: Education }) => {
    const { t } = useTranslation('admin');

    const { mutateAsync: deleteEducation } = useMutation(userEducationDeleteMutation());

    const handleDelete = async () => {
        try {
            await deleteEducation({ id: education.id });
            toast.success(t('userSettingsPage.educationTab.deleteSuccess'));
        } catch (error) {
            toast.error(t('userSettingsPage.educationTab.deleteError'));
        }
    };

    return (
        <EducationCard
            key={education.id}
            education={education}
            button={
                <MoreMenu>
                    <MoreMenu.Item asChild>
                        <Link
                            to={url(ADMIN_USER_SETTINGS_EDUCATION_EDIT_PATH, {
                                educationId: education.id,
                            })}
                        >
                            {t('userSettingsPage.educationTab.editButton')}
                        </Link>
                    </MoreMenu.Item>
                    <MoreMenu.Item danger onClick={handleDelete}>
                        {t('userSettingsPage.educationTab.deleteButton')}
                    </MoreMenu.Item>
                </MoreMenu>
            }
        />
    );
};

const EducationTab = (props: any) => {
    const { t } = useTranslation('admin');

    const { data: viewerProfile } = useQuery(userProfileDetailQuery());
    const { data: educations } = useQuery(userEducationListQuery({ userProfileId: viewerProfile?.id }));
    return (
        <>
            <div className="w-full flex flex-col gap-5 h-full">
                <Admin.Card className="flex gap-4 flex-none justify-between">
                    <h1 className="text-3xl font-semibold">{t('userSettingsPage.educationTab.title')}</h1>
                    {educations && educations.length > 0 && (
                        <div>
                            <LinkButton to={url(ADMIN_USER_SETTINGS_EDUCATION_ADD_PATH)} className="flex gap-1">
                                <PlusIcon />
                                {t('userSettingsPage.educationTab.createButton')}
                            </LinkButton>
                        </div>
                    )}
                </Admin.Card>

                {educations && educations.length > 0 ? (
                    educations.map((education) => <EducationListItem key={education.id} education={education} />)
                ) : (
                    <EmptyState
                        className="h-full"
                        title={t('userSettingsPage.educationTab.noEducation.title')}
                        description={t('userSettingsPage.educationTab.noEducation.description')}
                        button={
                            <LinkButton to={url(ADMIN_USER_SETTINGS_EDUCATION_ADD_PATH)} className="flex gap-1">
                                <PlusIcon />
                                {t('userSettingsPage.educationTab.createButton')}
                            </LinkButton>
                        }
                    />
                )}
            </div>

            <Outlet />
        </>
    );
};

export default EducationTab;
