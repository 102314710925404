import { LogInIcon, PlusIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { Form, IntegerField, StringField } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import { ADMIN_PATH } from '../../../../App.tsx';
import Button from '../../../../components/Button.tsx';
import { useConfirmationDialogContext } from '../../../../components/ConfirmationDialog.tsx';
import Dialog from '../../../../components/Dialog.tsx';
import FormError from '../../../../components/FormError.tsx';
import FormSubmitButton from '../../../../components/FormSubmitButton.tsx';
import LinkButton from '../../../../components/LinkButton.tsx';
import MoreMenu from '../../../../components/MoreMenu.tsx';
import { Organization, OrganizationLimits, SpaceLimits } from '../../../../data/models.ts';
import {
    organizationCreateMutation,
    organizationDeleteMutation,
    organizationDetailQuery,
    organizationLimitsQuery,
    organizationListQuery,
    organizationUpdateMutation,
    spaceLimitsQuery,
    userListQuery,
} from '../../../../data/queries.ts';
import { setFormErrors } from '../../../../utils/forms.ts';
import { isSupportUser } from '../../../../utils/loaders.ts';
import pickFormValues from '../../../../utils/pickFormValues.ts';
import url from '../../../../utils/url';
import { startSupportSessionMutation } from '../../../auth/queries.ts';
import Admin from '../../components/Admin.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import { useLimitAlert, useSpaceId } from '../../utils.tsx';
import OrganizationCard from '../components/OrganizationCard.tsx';
import {
    ADMIN_ORGANIZATION_ADD_PATH,
    ADMIN_ORGANIZATION_EDIT_LIMITS_PATH,
    ADMIN_ORGANIZATION_LIST_PATH,
} from '../routes.tsx';

const ORGANIZATION_FORM_DEFAULT_VALUES = {
    name: '',
};

const BaseOrganizationFormDialog = ({
    isCreate = false,
    organization,
    isOpen = true,
    onIsOpenChange,
    onSubmit,
    onCancel,
}: {
    isCreate: boolean;
    organization?: Organization;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onSubmit?: (data: any) => void;
    onCancel?: () => void;
}) => {
    const { t } = useTranslation('admin');

    const formMethods = useForm({
        defaultValues: pickFormValues(ORGANIZATION_FORM_DEFAULT_VALUES, organization),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(ORGANIZATION_FORM_DEFAULT_VALUES, organization));
    }, [organization]);

    const [isLoading, setIsLoading] = useState(false);

    const spaceId = useSpaceId();
    const { data: limits } = useQuery(spaceLimitsQuery(spaceId));
    const { isLimitReached, LimitAlert } = useLimitAlert({
        totalCount: limits?.organizationsCount,
        limit: limits?.maxOrganizations,
        limitTranslationKey: 'organizationFormDialog.limitAlert',
        limitReachedTranslationKey: 'organizationFormDialog.limitReachedAlert',
    });

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(onSubmit?.(values));
            toast.success(
                isCreate ? t('organizationFormDialog.createSuccess') : t('organizationFormDialog.updateSuccess')
            );
        } catch (error) {
            setFormErrors(t, formMethods, error);
            toast.error(isCreate ? t('organizationFormDialog.createError') : t('organizationFormDialog.updateError'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">
                    {isCreate ? t('organizationFormDialog.titleCreate') : t('organizationFormDialog.titleUpdate')}
                </h1>

                {isCreate && <LimitAlert />}

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <FormError />

                    <Form.Field name="name">
                        <StringField label={t('organizationFormDialog.nameLabel')} required />
                    </Form.Field>

                    <div className="flex justify-end gap-2">
                        <Button variant="ghost" onClick={onCancel} type="button">
                            {t('organizationFormDialog.cancelButton')}
                        </Button>

                        <FormSubmitButton variant="primary" type="submit" loading={isLoading} disabled={isLimitReached}>
                            {isCreate
                                ? t('organizationFormDialog.createButton')
                                : t('organizationFormDialog.updateButton')}
                        </FormSubmitButton>
                    </div>
                </Form>
            </Dialog.Content>
        </Dialog>
    );
};

export const AddOrganizationFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { mutateAsync: createOrganization } = useMutation(organizationCreateMutation());

    const handleSubmit = async (data: any) => {
        await createOrganization({ data });
        return navigate(ADMIN_ORGANIZATION_LIST_PATH);
    };

    return (
        <BaseOrganizationFormDialog
            isCreate
            {...props}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(ADMIN_ORGANIZATION_LIST_PATH);
                }
            }}
            onSubmit={handleSubmit}
            onCancel={() => navigate(ADMIN_ORGANIZATION_LIST_PATH)}
        />
    );
};

const ORGANIZATION_LIMITS_FORM_DEFAULT_VALUES = {
    maxUsers: null,
    maxJobs: null,
    maxStorageSpace: null,
};

export const BaseOrganizationLimitsFormDialog = ({
    spaceLimits,
    organization,
    isOpen = true,
    onIsOpenChange,
    onSubmit,
    onCancel,
}: {
    spaceLimits: SpaceLimits;
    organization: Organization;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
    onSubmit?: (data: any) => void;
    onCancel?: () => void;
}) => {
    const { t } = useTranslation('admin');

    const formMethods = useForm({
        defaultValues: pickFormValues(ORGANIZATION_LIMITS_FORM_DEFAULT_VALUES, organization),
        mode: 'onTouched',
    });
    useEffect(() => {
        formMethods.reset(pickFormValues(ORGANIZATION_LIMITS_FORM_DEFAULT_VALUES, organization));
    }, [organization]);

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await Promise.resolve(onSubmit?.(values));
            toast.success(t('organizationLimitsFormDialog.updateSuccess'));
        } catch (error) {
            setFormErrors(t, formMethods, error);
            toast.error(t('organizationLimitsFormDialog.updateError'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={!!isOpen} onOpenChange={onIsOpenChange}>
            <Dialog.Content className="w-[40rem] flex-1 flex flex-col gap-5">
                <h1 className="text-2xl font-semibold">{t('organizationLimitsFormDialog.title')}</h1>

                <Form formMethods={formMethods} onSubmit={handleSubmit} className="flex-1 flex flex-col gap-5">
                    <FormError />

                    <Form.Field
                        name="maxUsers"
                        rules={{
                            max: spaceLimits?.maxUsersPerOrganization
                                ? {
                                      value: spaceLimits?.maxUsersPerOrganization!,
                                      message: t('organizationLimitsFormDialog.maxUsersMaxError', {
                                          max: spaceLimits?.maxUsersPerOrganization,
                                      }),
                                  }
                                : undefined,
                        }}
                    >
                        <IntegerField label={t('organizationLimitsFormDialog.maxUsersLabel')} />
                    </Form.Field>

                    <Form.Field
                        name="maxJobs"
                        rules={{
                            max: spaceLimits?.maxJobsPerOrganization
                                ? {
                                      value: spaceLimits?.maxJobsPerOrganization!,
                                      message: t('organizationLimitsFormDialog.maxJobsMaxError', {
                                          max: spaceLimits?.maxJobsPerOrganization,
                                      }),
                                  }
                                : undefined,
                        }}
                    >
                        <IntegerField label={t('organizationLimitsFormDialog.maxJobsLabel')} />
                    </Form.Field>

                    <Form.Field
                        name="maxStorageSpace"
                        rules={{
                            // TODO: mb conversion
                            max: spaceLimits?.maxStorageSpace
                                ? {
                                      value: spaceLimits?.maxStorageSpace!,
                                      message: t('organizationLimitsFormDialog.maxStorageSpaceMaxError', {
                                          max: spaceLimits?.maxStorageSpace,
                                      }),
                                  }
                                : undefined,
                        }}
                    >
                        <IntegerField
                            label={t('organizationLimitsFormDialog.maxStorageSpaceLabel')}
                            helpText={t('organizationLimitsFormDialog.maxStorageSpaceHelpText')}
                        />
                    </Form.Field>

                    <div className="flex justify-end gap-2">
                        <Button variant="ghost" onClick={onCancel} type="button">
                            {t('organizationLimitsFormDialog.cancelButton')}
                        </Button>

                        <FormSubmitButton variant="primary" type="submit" loading={isLoading}>
                            {t('organizationLimitsFormDialog.saveButton')}
                        </FormSubmitButton>
                    </div>
                </Form>
            </Dialog.Content>
        </Dialog>
    );
};

export const EditOrganizationLimitsFormDialog = (props: any) => {
    const navigate = useNavigate();
    const { mutateAsync: updateOrganization } = useMutation(organizationUpdateMutation());

    const spaceId = useSpaceId();
    const { organizationId } = useParams();
    const { data: organization } = useQuery(organizationDetailQuery(organizationId!));
    const { data: spaceLimits } = useQuery(spaceLimitsQuery(spaceId!));

    const handleSubmit = async (data: any) => {
        await updateOrganization({ id: organizationId!, data });
        return navigate(ADMIN_ORGANIZATION_LIST_PATH);
    };

    return (
        <BaseOrganizationLimitsFormDialog
            spaceLimits={spaceLimits}
            organization={organization}
            {...props}
            onIsOpenChange={(isOpen) => {
                if (!isOpen) {
                    navigate(ADMIN_ORGANIZATION_LIST_PATH);
                }
            }}
            onSubmit={handleSubmit}
            onCancel={() => navigate(ADMIN_ORGANIZATION_LIST_PATH)}
        />
    );
};

const OrganizationListItem = ({ organization }: { organization: Organization }) => {
    const { t } = useTranslation('admin');

    const confirm = useConfirmationDialogContext();

    const { data: users } = useQuery(userListQuery({ organizationId: organization.id, status: 'active' }));
    const supportUser = users?.find((user) => isSupportUser(user));

    const { mutateAsync: deleteOrganization } = useMutation(organizationDeleteMutation());
    const handleDeleteOrganization = () => {
        return confirm(t('organizationListPage.deleteConfirmMessage'), '', {
            onConfirm: async () => {
                await deleteOrganization({ id: organization.id });
                toast.success(t('organizationListPage.deleteSuccess'));
            },

            danger: true,
        });
    };

    const { mutateAsync: startSupportSession } = useMutation(startSupportSessionMutation());
    const handleStartSupportSession = async () => {
        try {
            await startSupportSession({ organizationId: organization.id! });
            toast.success(t('organizationListPage.startSupportSessionSuccess'));
            window.location.href = url(ADMIN_PATH);
        } catch (error) {
            toast.error(t('organizationListPage.startSupportSessionError'));
            console.error(error);
        }
    };

    return (
        <OrganizationCard
            key={organization.id}
            organization={organization}
            button={
                <div className="flex gap-2 items-center">
                    <Button
                        className="flex items-center h-8 gap-1.5"
                        disabled={!supportUser?.id}
                        onClick={handleStartSupportSession}
                    >
                        {t('organizationListPage.startSupportSessionButton')} <LogInIcon />
                    </Button>
                    <MoreMenu>
                        <MoreMenu.Item asChild>
                            <Link to={url(ADMIN_ORGANIZATION_EDIT_LIMITS_PATH, { organizationId: organization.id })}>
                                Limits festlegen
                            </Link>
                        </MoreMenu.Item>
                        <MoreMenu.Item danger onClick={() => handleDeleteOrganization()}>
                            {t('organizationListPage.deleteButton')}
                        </MoreMenu.Item>
                    </MoreMenu>
                </div>
            }
        />
    );
};

const OrganizationListPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('admin');

    const { data: organizations } = useQuery(organizationListQuery());

    return (
        <>
            <Admin.Content>
                <Admin.Card className="flex gap-4 flex-none justify-between">
                    <h1 className="text-3xl font-semibold">{t('organizationListPage.title')}</h1>
                    <div>
                        <LinkButton className="flex gap-1" to={url(ADMIN_ORGANIZATION_ADD_PATH)}>
                            <PlusIcon />
                            {t('organizationListPage.createButton')}
                        </LinkButton>
                    </div>
                </Admin.Card>

                {organizations && organizations.length > 0 ? (
                    <div className={classnames('grid grid-cols-2 gap-5', className)} {...props}>
                        {organizations?.map((organization: any) => (
                            <OrganizationListItem key={organization.id} organization={organization} />
                        ))}
                    </div>
                ) : (
                    <EmptyState
                        title={t('organizationListPage.noOrganizations.title')}
                        description={t('organizationListPage.noOrganizations.description')}
                    />
                )}
            </Admin.Content>

            <Outlet />
        </>
    );
};

export default OrganizationListPage;
