import { useEffect } from 'react';

const SYNC_LOGOUT_KEY = 'logout';

const useSyncLogout = () => {
    const syncLogout = (event: StorageEvent): void => {
        if (event.key === SYNC_LOGOUT_KEY) {
            // hard reload to clear state
            window.location.reload();
        }
    };

    useEffect(() => {
        window.addEventListener('storage', syncLogout);
        return () => {
            window.removeEventListener('storage', syncLogout);
            window.localStorage.removeItem(SYNC_LOGOUT_KEY);
        };
    }, []);

    return () => {
        window.localStorage.setItem(SYNC_LOGOUT_KEY, Date.now().toString());
    };
};

export default useSyncLogout;
