import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SelectField } from '@nicoknoll/forms';
import { classnames } from '@nicoknoll/utils';
import { useQuery } from '@tanstack/react-query';

import { eventPageListQuery, spaceDetailQuery } from '../data/queries.ts';
import { HOME_PATH, SITE_PAGE_DETAIL_PATH } from '../modules/site/routes.tsx';
import { siteUrl, useEventId } from '../modules/site/utils.ts';

const Footer = ({
    className,
    isOnDarkBackground,
    ...props
}: Omit<React.ComponentPropsWithRef<'footer'>, 'children'> & {
    isOnDarkBackground?: boolean;
}) => {
    const { t, i18n } = useTranslation('components');

    const handleLanguageChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
        i18n.changeLanguage(e.target.value);

    const eventId = useEventId();
    const { data: pages } = useQuery({ ...eventPageListQuery({ eventId }), enabled: !!eventId });

    const { data: space } = useQuery(spaceDetailQuery());

    const imprint = pages?.find((page) => page.role === 'IMPRINT');
    const terms = pages?.find((page) => page.role === 'TERMS');
    const privacy = pages?.find((page) => page.role === 'PRIVACY');

    return (
        <footer
            className={classnames(
                'flex flex-none justify-center bg-base-50 py-12 px-6 border-t border-base-200 items-center',
                className
            )}
            {...props}
        >
            <div className="w-full max-w-[1120px] flex flex-col gap-6">
                <div className="flex gap-10 justify-between items-center">
                    <section className={classnames('flex flex-col gap-4 items-start')}>
                        <Link
                            className={classnames(
                                'text-lg font-bold hover:text-theme-500 transition-colors select-none',
                                isOnDarkBackground && 'text-white'
                            )}
                            to={HOME_PATH}
                        >
                            {space?.name}
                        </Link>

                        <section className={classnames('text-sm text-base-400', isOnDarkBackground && 'text-white/60')}>
                            &copy; {new Date().getFullYear()}. {t('footer.copyright')}
                        </section>
                    </section>

                    <section className="flex gap-6 text-sm items-center">
                        {terms && (
                            <Link
                                className={classnames(
                                    'text-base-900 font-medium hover:text-theme-500 transition-colors select-none',
                                    isOnDarkBackground && 'text-white'
                                )}
                                to={siteUrl(SITE_PAGE_DETAIL_PATH, { eventId, pageId: terms.slug || terms.id })}
                            >
                                {t('footer.terms')}
                            </Link>
                        )}

                        {privacy && (
                            <Link
                                className={classnames(
                                    'text-base-900 font-medium hover:text-theme-500 transition-colors select-none',
                                    isOnDarkBackground && 'text-white'
                                )}
                                to={siteUrl(SITE_PAGE_DETAIL_PATH, { eventId, pageId: privacy.slug || privacy.id })}
                            >
                                {t('footer.privacy')}
                            </Link>
                        )}

                        {imprint && (
                            <Link
                                className={classnames(
                                    'text-base-900 font-medium hover:text-theme-500 transition-colors select-none',
                                    isOnDarkBackground && 'text-white'
                                )}
                                to={siteUrl(SITE_PAGE_DETAIL_PATH, { eventId, pageId: imprint.slug || imprint.id })}
                            >
                                {t('footer.imprint')}
                            </Link>
                        )}

                        <SelectField
                            className="w-full"
                            value={i18n.language}
                            hideSearch
                            hideClear
                            onChange={handleLanguageChange}
                            options={[
                                {
                                    value: 'en',
                                    // @ts-ignore
                                    label: t('common:languageCode.en'),
                                },
                                {
                                    value: 'de',
                                    // @ts-ignore
                                    label: t('common:languageCode.de'),
                                },
                            ]}
                        />
                    </section>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
