import { TextInput, Widget } from '@nicoknoll/forms';
import React, { useState } from 'react';
import { EyeIcon, EyeOffIcon } from 'lucide-react';

const PasswordInput = (props: React.ComponentProps<typeof TextInput>) => {
    const [visible, setVisible] = useState(false);
    return (
        <TextInput
            type={visible ? 'text' : 'password'}
            controls={
                <Widget.ControlButton onClick={() => setVisible(!visible)} key="togglePasswordVisible">
                    {visible ? <EyeOffIcon className="w-4 h-4" /> : <EyeIcon className="w-4 h-4" />}
                </Widget.ControlButton>
            }
            {...props}
        />
    );
};

export default PasswordInput;
