import { StarIcon, StarOffIcon } from 'lucide-react';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../components/Button.tsx';
import Tooltip from '../../../components/Tooltip.tsx';
import { EventSession, LogEventType, SavedEventSession } from '../../../data/models.ts';
import {
    savedEventSessionCreateMutation,
    savedEventSessionDeleteMutation,
    savedEventSessionListQuery,
    userDetailQuery,
} from '../../../data/queries.ts';
import url from '../../../utils/url';
import useLogEvent from '../../../utils/useLogEvent.ts';
import { ADMIN_LOGIN_PATH } from '../../auth/routes.tsx';

interface SaveEventSessionProps {
    eventSession: EventSession;
    savedEventSession?: SavedEventSession | null; // null disables automatic fetching
    eventId: string;
}

const useSaveEventSession = ({
    eventSession,
    savedEventSession: propsSavedEventSession,
    eventId,
}: SaveEventSessionProps) => {
    const { t } = useTranslation('site');

    const { data: viewer } = useQuery(userDetailQuery());
    const navigate = useNavigate();
    const location = useLocation();

    // only load if not passed in from the outside
    const { data: savedEventSessions, isLoading } = useQuery({
        ...savedEventSessionListQuery({ eventId, userId: viewer?.id, eventSessionId: eventSession?.id }),
        enabled: !!viewer && !!eventSession && propsSavedEventSession === undefined,
    });
    const savedEventSession = propsSavedEventSession || savedEventSessions?.[0];

    const createLogEvent = useLogEvent();

    const { mutateAsync: saveEventSession } = useMutation(savedEventSessionCreateMutation());
    const handleSave = async () => {
        if (!viewer) {
            toast.error(t('saveEventSessionButton.notAuthenticatedError'));
            navigate(`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`);
            return;
        }

        try {
            await saveEventSession({ data: { eventId, userId: viewer?.id, eventSessionId: eventSession.id } });
            void createLogEvent(LogEventType.EVENT_SESSION_SAVED, { eventId, targetId: eventSession.id });
            toast.success(t('saveEventSessionButton.saveSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('saveEventSessionButton.saveError'));
        }
    };

    const { mutateAsync: unsaveEventSession } = useMutation(savedEventSessionDeleteMutation());
    const handleUnsave = async () => {
        if (!viewer) {
            toast.error(t('saveEventSessionButton.notAuthenticatedError'));
            navigate(`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`);
            return;
        }

        try {
            await unsaveEventSession({ id: savedEventSession!.id! });
            void createLogEvent(LogEventType.EVENT_SESSION_UNSAVED, { eventId, targetId: eventSession.id });
            toast.success(t('saveEventSessionButton.unsaveSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('saveEventSessionButton.unsaveError'));
        }
    };

    return {
        isSaved: Boolean(savedEventSession),
        isLoading,
        saveEventSession: handleSave,
        unsaveEventSession: handleUnsave,
    };
};

export const SaveEventSessionButton = ({
    eventSession,
    savedEventSession,
    eventId,
    className,
    ...props
}: React.ComponentPropsWithRef<'button'> & SaveEventSessionProps) => {
    const { t } = useTranslation('site');

    const { isSaved, isLoading, saveEventSession, unsaveEventSession } = useSaveEventSession({
        eventId,
        eventSession,
        savedEventSession,
    });

    return (
        <Button
            variant={isSaved ? 'outline' : 'outline'}
            className="w-full text-base flex gap-2 disabled:cursor-not-allowed min-h-9"
            onClick={isSaved ? unsaveEventSession : saveEventSession}
            loading={isLoading ? true : undefined}
            {...props}
        >
            {isSaved ? <StarOffIcon /> : <StarIcon fill="currentColor" />}
            {isSaved ? t('saveEventSessionButton.unsave') : t('saveEventSessionButton.save')}
        </Button>
    );
};

export const SaveEventSessionRibbon = ({
    eventSession,
    savedEventSession,
    eventId,
    className,
    ...props
}: React.ComponentPropsWithRef<'button'> & SaveEventSessionProps) => {
    const { t } = useTranslation('site');

    const { isSaved, isLoading, saveEventSession, unsaveEventSession } = useSaveEventSession({
        eventId,
        eventSession,
        savedEventSession,
    });

    return (
        <Tooltip>
            <Tooltip.Trigger asChild>
                <Button
                    variant="unstyled"
                    className={classnames(
                        'text-base py-2 flex gap-2 absolute right-4 top-0 rounded-b-lg transition-colors p-3 pb-3.5 pt-4 z-10',
                        isSaved
                            ? 'bg-theme-500 text-white hover:bg-theme-600 active:bg-theme-700'
                            : 'bg-base-100 text-base-400 group-hover:bg-base-200 hover:text-theme-500 hover:!bg-theme-100',
                        className
                    )}
                    onClick={isSaved ? unsaveEventSession : saveEventSession}
                    loading={isLoading ? true : undefined}
                >
                    <StarIcon fill="currentColor" />
                </Button>
            </Tooltip.Trigger>
            <Tooltip.Content>
                {isSaved ? t('saveEventSessionButton.unsave') : t('saveEventSessionButton.save')}
            </Tooltip.Content>
        </Tooltip>
    );
};
