import {
    jobDetailQuery,
    jobListQuery,
    organizationDetailQuery,
    userDetailQuery,
    userListQuery,
} from '../../../data/queries.ts';
import ensureQueriesData from '../../../utils/ensureQueriesData.ts';

export const contactTabLoader = () =>
    ensureQueriesData({
        organization: organizationDetailQuery(),
    });

export const filesTabLoader = () =>
    ensureQueriesData({
        organization: organizationDetailQuery(),
    });

export const jobEditLoader = ({ params: { jobId } }: any) =>
    ensureQueriesData({
        job: jobDetailQuery(jobId),
    });

export const jobTabLoader = () =>
    ensureQueriesData({
        jobList: jobListQuery(),
    });

export const profileTabLoader = () =>
    ensureQueriesData({
        organization: organizationDetailQuery(),
    });

export const userEditLoader = ({ params: { userId } }: any) =>
    ensureQueriesData({
        job: userDetailQuery(userId),
    });

export const userTabLoader = () =>
    ensureQueriesData({
        userList: userListQuery(),
    });
