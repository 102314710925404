import React from 'react';
import { useTranslation } from 'react-i18next';

import { FileInput, SelectField } from '@nicoknoll/forms';

import { UploadedFile } from '../data/models.ts';

const FileUploadOrSelectInput = ({
    value,
    onChange,
    files,
    selectPlaceholder,
    uploadPlaceholder,
    placeholder,
    ...props
}: React.ComponentPropsWithRef<typeof FileInput> & {
    files?: UploadedFile[];
    selectPlaceholder?: string;
    uploadPlaceholder?: string;
}) => {
    const { t } = useTranslation('components');
    return (
        <>
            {!value && files && files.length > 0 && (
                <SelectField
                    options={
                        files?.map((file) => ({
                            value: file.url,
                            label: file.name,
                        })) || []
                    }
                    onChange={(e) => {
                        const file = files?.find((file) => file.url === e.target.value);
                        // @ts-ignore
                        onChange?.({ target: { value: file } });
                    }}
                    placeholder={
                        (selectPlaceholder || placeholder || t('fileUploadOrSelectInput.selectPlaceholder')) as string
                    }
                />
            )}
            <FileInput
                value={value}
                onChange={onChange}
                {...props}
                maxSize={props.maxSize || 1000 * 1000 * 8}
                placeholder={
                    (uploadPlaceholder || placeholder || t('fileUploadOrSelectInput.uploadPlaceholder')) as string
                }
            />
        </>
    );
};

export default FileUploadOrSelectInput;
