import * as React from 'react';

import { classnames } from '@nicoknoll/utils';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

const TooltipContent = ({
    className,
    sideOffset = 4,
    ...props
}: React.ComponentPropsWithRef<typeof TooltipPrimitive.Content>) => (
    <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
            className={classnames('text-sm text-white bg-black/75 backdrop-blur px-2.5 py-1 rounded', className)}
            sideOffset={sideOffset}
            {...props}
        />
    </TooltipPrimitive.Portal>
);

const Tooltip = ({
    children,
    delayDuration = 300,
    ...props
}: React.ComponentPropsWithRef<typeof TooltipPrimitive.Root>) => (
    <TooltipPrimitive.Provider>
        <TooltipPrimitive.Root delayDuration={delayDuration} {...props}>
            {children}
        </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
);

export default Object.assign(Tooltip, { Trigger: TooltipPrimitive.Trigger, Content: TooltipContent });
