import { useEffect } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import { LogEvent, LogEventType } from '../data/models.ts';
import { logEventCreateMutation, logEventTrackingStatusQuery } from '../data/queries.ts';
import { useEventId } from '../modules/site/utils.ts';

export interface CreateLogEventData {
    targetId?: string;
    eventId?: string;
    [key: string]: any;
}

const useLogEvent = () => {
    const eventId = useEventId();
    const { data: trackingStatus } = useQuery(logEventTrackingStatusQuery());
    const { mutationFn: createLogEvent } = logEventCreateMutation();

    return (eventType: LogEventType, { targetId, eventId: forceEventId, ...details }: CreateLogEventData = {}) => {
        if (!trackingStatus?.trackingAccepted) {
            console.debug(`Tracking not accepted, not logging event ${eventType}`);
            return;
        }

        try {
            return createLogEvent({ data: { eventId: forceEventId || eventId, eventType, targetId, details } });
        } catch (error) {
            console.error(error);
        }
    };
};

export const useLogEventOnMount = (eventType: LogEventType, { targetId, ...details }: CreateLogEventData) => {
    const logEvent = useLogEvent();

    useEffect(() => {
        if (!targetId) return;
        void logEvent(eventType, { targetId, ...details });
    }, [targetId]);
};

export default useLogEvent;
