import {
    ArmchairIcon,
    CalendarIcon,
    ChevronRightIcon,
    ClockIcon,
    LanguagesIcon,
    MapPinIcon,
    NotebookIcon,
} from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useQuery } from '@tanstack/react-query';

import Image from '../../../components/Image.tsx';
import { EventSession, OrganizationProfile, SavedEventSession } from '../../../data/models.ts';
import { eventDetailQuery } from '../../../data/queries.ts';
import { formatDate, formatTime } from '../../../utils/time.ts';
import { SITE_ORGANIZATION_DETAIL_PATH, SITE_SESSIONS_DETAIL_PATH } from '../routes.tsx';
import { siteUrl, useEventId } from '../utils.ts';
import { SaveEventSessionButton, SaveEventSessionRibbon } from './SaveEventSessionButton.tsx';

const SessionCard = ({
    className,
    session,
    savedEventSession,
    organizationProfile,
    ...props
}: React.ComponentPropsWithRef<'div'> & {
    session: EventSession;
    savedEventSession?: SavedEventSession | null;
    organizationProfile?: OrganizationProfile;
}) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();

    const { data: event } = useQuery(eventDetailQuery(eventId!));

    return (
        <div className={classnames('bg-base-100 rounded-2xl flex flex-col flex-1 relative', className)} {...props}>
            <SaveEventSessionRibbon
                eventSession={session}
                eventId={eventId}
                savedEventSession={savedEventSession}
                className="pt-7"
            />

            <Link
                className="bg-base-50 rounded-2xl p-8 flex flex-col gap-6 flex-1 hover:bg-base-100 transition-colors"
                to={siteUrl(SITE_SESSIONS_DETAIL_PATH, { sessionId: session.id, eventId })}
            >
                <div className="flex gap-5 h-full">
                    {session.startDatetime && (
                        <span className="flex gap-2 items-center">
                            <span className="text-theme-600">
                                <CalendarIcon />
                            </span>
                            {formatDate(session.startDatetime, event?.timezoneName)}
                        </span>
                    )}
                    {session.startDatetime && (
                        <span className="flex gap-2 items-center">
                            <span className="text-theme-600">
                                <ClockIcon />
                            </span>
                            {formatTime(session.startDatetime, event?.timezoneName)}
                        </span>
                    )}
                    {session.location && (
                        <span className="flex gap-2 items-center">
                            <span className="text-theme-600">
                                <MapPinIcon />
                            </span>
                            {session.location}
                        </span>
                    )}
                    {session.format && (
                        <span className="flex gap-2 items-center">
                            <span className="text-theme-600">
                                <ArmchairIcon />
                            </span>
                            {session.format}
                        </span>
                    )}
                    {session.languageCode && session.languageCode != 'de' && (
                        <span className="flex gap-2 items-center">
                            <span className="text-theme-600">
                                <LanguagesIcon />
                            </span>
                            {/* @ts-ignore */}
                            {t(`common:languageCode.${session.languageCode}`)}
                        </span>
                    )}
                </div>

                <div className="flex gap-3 flex-col flex-1">
                    <h2 className="text-lg font-semibold">{session.title}</h2>
                    {session.summary && <p className="text-base-600">{session.summary}</p>}
                </div>

                {session.maxUsers && (
                    <div className="flex flex-wrap items-start gap-2">
                        <div className="bg-theme-100 text-theme-700 font-medium rounded-lg px-3 py-1 text-sm flex items-center gap-1.5">
                            <span>
                                {session.userIds?.length || 0} / {session.maxUsers} {t('sessionCard.participants')}
                            </span>
                        </div>
                    </div>
                )}
            </Link>

            {organizationProfile && (
                <Link
                    className="px-8 py-5 hover:bg-base-150 transition-colors rounded-b-2xl flex items-center gap-4"
                    to={siteUrl(SITE_ORGANIZATION_DETAIL_PATH, {
                        organizationId: organizationProfile.organizationId,
                        eventId,
                    })}
                >
                    <Image
                        src={organizationProfile?.logo?.url}
                        alt="-"
                        className="h-6 w-6 rounded-full bg-base-200 border-transparent text-transparent"
                    />

                    <span className="text-base-900 font-medium">{organizationProfile?.name}</span>

                    <span className="ml-auto">
                        <ChevronRightIcon strokeWidth={2} />
                    </span>
                </Link>
            )}
        </div>
    );
};

export default SessionCard;
