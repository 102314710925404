import { ArrowRightIcon, CalendarClockIcon, ExternalLinkIcon, EyeIcon, EyeOffIcon, MapPinIcon } from 'lucide-react';
import React from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Anchor from '../../../components/Anchor.tsx';
import Button from '../../../components/Button.tsx';
import CookieBar from '../../../components/CookieBar.tsx';
import EditBar from '../../../components/EditBar.tsx';
import Footer from '../../../components/Footer.tsx';
import Header from '../../../components/Header.tsx';
import Image from '../../../components/Image.tsx';
import LinkButton from '../../../components/LinkButton.tsx';
import Tooltip from '../../../components/Tooltip.tsx';
import { Event, EventAttendee, OrganizationProfile, User } from '../../../data/models.ts';
import {
    eventAttendeeCreateMutation,
    eventAttendeeDeleteMutation,
    eventAttendeeListQuery,
    eventDetailQuery,
    eventLimitsQuery,
    organizationProfileListQuery,
    userDetailQuery,
} from '../../../data/queries.ts';
import { isAdminUser, isRegularUser } from '../../../utils/loaders.ts';
import { formatDate } from '../../../utils/time.ts';
import url from '../../../utils/url';
import { ADMIN_EVENT_DETAIL_PATH } from '../../admin/events/routes.tsx';
import { useLimitAlert } from '../../admin/utils.tsx';
import { ADMIN_LOGIN_PATH } from '../../auth/routes.tsx';
import InfoBox from '../components/InfoBox.tsx';
import { SiteNavigation } from '../components/Site.tsx';
import { SITE_ORGANIZATION_LIST_PATH } from '../routes.tsx';
import { siteUrl, useEventId } from '../utils.ts';

const ImageList = ({ imageUrls }: { imageUrls: string[] }) => {
    return (
        <div className="flex">
            {imageUrls.splice(0, 7).map((imageUrl, index) => (
                <Image
                    key={index}
                    src={imageUrl}
                    alt=""
                    className="w-12 h-12 rounded-full border-white border-2 -mr-2 bg-base-150 object-contain"
                />
            ))}
        </div>
    );
};

const EventInfoBox = ({ event, user, eventAttendee }: { event: Event; user: User; eventAttendee?: EventAttendee }) => {
    const { t } = useTranslation('site');

    const isParticipating = Boolean(eventAttendee);

    const navigate = useNavigate();
    const location = useLocation();

    const eventId = useEventId();
    const { data: limits } = useQuery(eventLimitsQuery(eventId!));
    const totalCount = limits?.usersCount;
    const limit = limits?.maxUsers;
    const isLimitReached = totalCount != null && limit != null && totalCount >= limit;
    const isFewSpotsLeft = totalCount != null && limit != null && !isLimitReached && limit - totalCount <= 20;

    const { mutateAsync: attend } = useMutation(eventAttendeeCreateMutation());
    const handleAttend = async () => {
        if (!user) {
            toast.error(t('eventDetailPage.eventInfoBox.attendNotAuthenticatedError'));
            return navigate(`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`);
        }

        try {
            await attend({ data: { eventId: event.id, userId: user.id } });
            toast.success(t('eventDetailPage.eventInfoBox.attendSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('eventDetailPage.eventInfoBox.attendError'));
        }
    };

    const { mutateAsync: unattend } = useMutation(eventAttendeeDeleteMutation());
    const handleUnattend = async () => {
        try {
            await unattend({ id: eventAttendee?.id! });
            toast.success(t('eventDetailPage.eventInfoBox.unattendSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('eventDetailPage.eventInfoBox.unattendError'));
        }
    };

    return (
        <InfoBox
            badge={
                <>
                    <InfoBox.Badge>{t('eventDetailPage.eventInfoBox.onSiteBadge')}</InfoBox.Badge>
                    <InfoBox.Badge className="bg-base-500">
                        {t('eventDetailPage.eventInfoBox.onlineBadge')}
                    </InfoBox.Badge>
                </>
            }
        >
            <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-6">
                    <InfoBox.Row
                        icon={<CalendarClockIcon />}
                        value={
                            event?.days &&
                            event?.days.length > 0 && (
                                <span className="flex flex-col gap-2">
                                    {event?.days?.map((day) => (
                                        <span className="flex flex-col">
                                            <span className="font-medium">
                                                {formatDate(day?.date, event?.timezoneName, { weekday: 'long' })}
                                            </span>
                                            <span className="font-normal">
                                                {day?.startTime.split(':').slice(0, 2).join(':')} -{' '}
                                                {day?.endTime.split(':').slice(0, 2).join(':')}
                                            </span>
                                        </span>
                                    ))}
                                </span>
                            )
                        }
                    />

                    <InfoBox.Row
                        icon={<MapPinIcon />}
                        value={
                            event?.location && (
                                <div className="flex flex-col gap-2">
                                    <span className="whitespace-pre-line">{event?.location}</span>
                                    <Anchor
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event?.location || '').replace(/%20/g, '+')}`}
                                        target="_blank"
                                        className="flex gap-1 items-center font-normal"
                                    >
                                        <span>{t('eventDetailPage.eventInfoBox.openMap')}</span>
                                        <ExternalLinkIcon />
                                    </Anchor>
                                </div>
                            )
                        }
                    />
                </div>
                {!isParticipating ? (
                    user && !isRegularUser(user) ? (
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <Button className="text-base disabled:cursor-not-allowed" variant="primary" disabled>
                                    {t('eventDetailPage.eventInfoBox.attendButton')}
                                </Button>
                            </Tooltip.Trigger>
                            <Tooltip.Content> {t('eventDetailPage.eventInfoBox.attendTooltip')}</Tooltip.Content>
                        </Tooltip>
                    ) : (
                        <div className="flex flex-col gap-4">
                            <Button
                                className="text-base"
                                variant="primary"
                                onClick={handleAttend}
                                disabled={isLimitReached}
                            >
                                {t('eventDetailPage.eventInfoBox.attendButton')}
                            </Button>

                            {isFewSpotsLeft && (
                                <div className="text-sm text-base-600">
                                    {t('eventDetailPage.eventInfoBox.fewSpotsLeft', {
                                        spotsLeftCount: limit - totalCount,
                                    })}
                                </div>
                            )}

                            {isLimitReached && (
                                <div className="text-sm text-base-600">
                                    {t('eventDetailPage.eventInfoBox.limitReached', { limit })}
                                </div>
                            )}
                        </div>
                    )
                ) : (
                    <Button
                        className="text-base bg-base-100 hover:bg-base-200 active:bg-base-300"
                        variant="ghost"
                        onClick={handleUnattend}
                    >
                        {t('eventDetailPage.eventInfoBox.unattendButton')}
                    </Button>
                )}
            </div>
        </InfoBox>
    );
};

const OrganizationProfilesBox = ({
    eventId,
    organizationProfiles,
}: {
    eventId: string;
    organizationProfiles: OrganizationProfile[];
}) => {
    const { t } = useTranslation('site');
    const additionalCount = Math.max(organizationProfiles.length - 1, 0);
    return (
        <div className="flex flex-col gap-6 px-8">
            <Link to={siteUrl(SITE_ORGANIZATION_LIST_PATH, { eventId })} className="font-medium">
                {t('eventDetailPage.organizationProfileBox.title', {
                    name: organizationProfiles?.[0]?.name,
                    count: additionalCount,
                })}
            </Link>

            <ImageList imageUrls={organizationProfiles.map((o) => o.logo?.url || '') || []} />

            <div>
                <Anchor asChild>
                    <Link to={siteUrl(SITE_ORGANIZATION_LIST_PATH, { eventId })} className="flex gap-1 items-center">
                        {t('eventDetailPage.organizationProfileBox.button')} <ArrowRightIcon />
                    </Link>
                </Anchor>
            </div>
        </div>
    );
};

const EventDetailPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const eventId = useEventId();

    const { t } = useTranslation('site');

    const { data: event } = useQuery(eventDetailQuery(eventId!));
    const { data: eventAttendeesData } = useQuery(eventAttendeeListQuery({ eventId }));

    const { data: organizationProfiles } = useQuery(organizationProfileListQuery({ eventId, isPublic: true }));

    const { data: viewer } = useQuery(userDetailQuery());
    const eventAttendee = eventAttendeesData?.find((u: EventAttendee) => u.userId === viewer?.id);

    return (
        <div className="antialiased min-h-[100vh] flex flex-col">
            <Header />

            {isAdminUser(viewer!) && (
                <EditBar>
                    <div>
                        {event?.isPublic ? (
                            <span className="flex items-center gap-2">
                                <EyeIcon />
                                <span>
                                    <Trans t={t} i18nKey="eventDetailPage.editBar.eventIsPublic" />
                                </span>
                            </span>
                        ) : (
                            <span className="flex items-center gap-2">
                                <EyeOffIcon />
                                <span>
                                    <Trans t={t} i18nKey="eventDetailPage.editBar.eventIsNotPublic" />
                                </span>
                            </span>
                        )}
                    </div>
                    <LinkButton
                        className="text-white bg-base-800 hover:bg-base-700 active:bg-base-600"
                        variant="ghost"
                        to={url(ADMIN_EVENT_DETAIL_PATH, { eventId })}
                    >
                        {t('eventDetailPage.editBar.editButton')}
                    </LinkButton>
                </EditBar>
            )}

            <div className={classnames('flex-1', className)} {...props}>
                <div className="flex justify-center bg-base-50 border-b border-base-200">
                    <div className="w-full h-[18rem] bg-cover bg-center mix-blend-multiply flex flex-col justify-end relative">
                        <Image
                            src={event?.banner?.url}
                            alt={event?.name}
                            className="absolute top-0 w-full h-full object-cover object-center bg-base-50"
                        />

                        <div className="absolute top-0 left-0 w-full h-full">
                            {event?.bannerLogo?.url && (
                                <div
                                    className="absolute w-full max-w-[80%] h-16 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-contain bg-center bg-no-repeat -mt-2 z-10"
                                    style={{
                                        backgroundImage: `url(${event?.bannerLogo?.url})`,
                                    }}
                                />
                            )}
                        </div>

                        <div
                            className="absolute top-0 w-full h-full"
                            style={{
                                backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.3) 100%)`,
                            }}
                        />
                    </div>
                </div>

                <SiteNavigation />

                <main className="flex justify-center bg-white px-6 py-16">
                    <div className="w-full max-w-[1120px] flex flex-col gap-32">
                        <section className="flex gap-10">
                            <div className="flex flex-col gap-10 w-8/12">
                                <div className="styled" dangerouslySetInnerHTML={{ __html: event?.description! }} />
                            </div>

                            <div className="flex flex-col gap-12 w-4/12">
                                <EventInfoBox event={event!} user={viewer!} eventAttendee={eventAttendee} />

                                {organizationProfiles && organizationProfiles.length > 0 && (
                                    <OrganizationProfilesBox
                                        eventId={eventId!}
                                        organizationProfiles={organizationProfiles}
                                    />
                                )}
                            </div>
                        </section>
                    </div>
                </main>
            </div>

            <Footer />

            <CookieBar />
        </div>
    );
};

export default EventDetailPage;
