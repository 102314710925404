import { camelCase } from 'lodash';
import { ClockIcon, GripVertical, MapPinIcon } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { classnames } from '@nicoknoll/utils';

import { Job } from '../../../data/models.ts';

const JobCard = ({
    className,
    job,
    button,
    ...props
}: React.ComponentPropsWithRef<'div'> & { job: Job; button?: React.ReactNode }) => {
    const { t } = useTranslation('admin');
    return (
        <div
            className={classnames(
                'bg-base-100 rounded-xl p-6 relative group data-[dragging]:hover:bg-base-150 data-[dragging="true"]:bg-base-200',
                className
            )}
            {...props}
        >
            <div className="flex gap-4 flex-1 group-data-[dragging='true']:opacity-0">
                <div className="absolute left-0 top-6 text-base-400 p-1.5 opacity-0 group-data-[dragging]:group-hover:opacity-100 transition-opacity cursor-grab">
                    <GripVertical />
                </div>

                <div className="flex gap-4 flex-col flex-1">
                    <h2 className="text-lg font-semibold">{job.title}</h2>
                    <p className="text-base-600">{job.summary}</p>

                    <div className="flex flex-col gap-1 h-full">
                        <span className="flex gap-2 items-center">
                            <ClockIcon /> {job.employmentType}
                        </span>
                        <span className="flex gap-2 items-center">
                            <MapPinIcon /> {job.location}
                        </span>
                    </div>
                </div>

                {button && <div className="flex gap-2 items-start">{button}</div>}
            </div>
        </div>
    );
};

export default JobCard;
