import { CircleAlertIcon, CircleCheckIcon, InfoIcon, TriangleAlertIcon } from 'lucide-react';
import React from 'react';

import { classnames } from '@nicoknoll/utils';

export const ErrorAlert = ({ className, children, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div className={classnames('bg-red-50 rounded-lg p-4 text-sm text-red-900 flex gap-2', className)} {...props}>
            <CircleAlertIcon className="inline flex-none mt-0.5" />
            <div className="flex-1">{children}</div>
        </div>
    );
};

export const WarningAlert = ({ className, children, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div
            className={classnames('bg-yellow-50 rounded-lg p-4 text-sm text-yellow-900 flex gap-2', className)}
            {...props}
        >
            <TriangleAlertIcon className="inline flex-none mt-0.5" />
            <div className="flex-1">{children}</div>
        </div>
    );
};

export const InfoAlert = ({ className, children, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div className={classnames('bg-blue-50 rounded-lg p-4 text-sm text-blue-900 flex gap-2', className)} {...props}>
            <InfoIcon className="inline flex-none mt-0.5" />
            <div className="flex-1">{children}</div>
        </div>
    );
};

export const SuccessAlert = ({ className, children, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div
            className={classnames('bg-green-50 rounded-lg p-4 text-sm text-green-900 flex gap-2', className)}
            {...props}
        >
            <CircleCheckIcon className="inline flex-none mt-0.5" />
            <div className="flex-1">{children}</div>
        </div>
    );
};
