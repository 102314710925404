import { MinusIcon, PlusIcon, SearchIcon } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { BooleanField, StringField, Widget } from '@nicoknoll/forms';
import { classnames, useControllableState } from '@nicoknoll/utils';

import { SearchFilter } from '../../../data/api.ts';

const MAX_INITIAL_OPTIONS = 3;

const FilterSection = ({
    className,
    children,
    label,
    initialIsOpen = true,
    isCollapsible = true,
    isOpen: propsIsOpen,
    onIsOpenChange,
    ...props
}: React.ComponentPropsWithRef<'section'> & {
    label: React.ReactNode;
    initialIsOpen?: boolean;
    isCollapsible?: boolean;
    isOpen?: boolean;
    onIsOpenChange?: (isOpen: boolean) => void;
}) => {
    const [isOpen, setIsOpen] = useControllableState(initialIsOpen, propsIsOpen, onIsOpenChange);
    return (
        <section className={classnames('flex flex-col gap-4', className)} {...props}>
            <h4
                onClick={() => isCollapsible && setIsOpen(!isOpen)}
                className={classnames(
                    'flex justify-between gap-2 items-center flex-1 w-full rounded-xl text-base-600 font-medium transition-colors select-none',
                    isCollapsible && 'cursor-pointer'
                    // active ? 'bg-base-150 !text-black' : 'hover:bg-base-50 hover:text-base-800'
                )}
            >
                <div className="flex-1 w-full">{label}</div>
                {isCollapsible && <div>{isOpen ? <MinusIcon /> : <PlusIcon />}</div>}
            </h4>

            {(!isCollapsible || isOpen) && children}
        </section>
    );
};

const FilterSidebar = ({ className, ...props }: React.ComponentPropsWithRef<'aside'>) => {
    return <aside className={classnames('w-full flex flex-col gap-10', className)} {...props} />;
};

export default Object.assign(FilterSidebar, { Section: FilterSection });

export const SearchField = ({
    value,
    onChange,
    controls,
    onSearchValueChange,
    ...props
}: React.ComponentPropsWithRef<typeof StringField> & {
    onSearchValueChange?: (value: string) => void;
}) => {
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                onSearchValueChange?.(inputValue || '');
            }}
        >
            <StringField
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                    onChange?.(e);
                }}
                controls={
                    <>
                        <Widget.ControlButton type="submit">
                            <SearchIcon />
                        </Widget.ControlButton>
                        {controls}
                    </>
                }
                hideClear
                {...props}
            />
        </form>
    );
};

export const useSearchParamsFilters = (filterNames: string[] = []) => {
    let [searchParams, setSearchParams] = useSearchParams();

    const onToggleFilter = (key: string, value: string) => {
        // support multi value for the same filter
        let values = searchParams.getAll(key);
        if (values.includes(value)) {
            searchParams.delete(key, value);
        } else {
            searchParams.append(key, value);
        }
        setSearchParams(searchParams);
    };

    // support for multiple values for the same filter
    const filters = useMemo(() => {
        // Initialize ordered object with empty arrays
        const orderedFilters = Object.fromEntries(filterNames.map((key) => [key, [] as string[]]));

        // Populate with values from searchParams
        Array.from(searchParams.entries())
            .filter(([key]) => filterNames.includes(key))
            .forEach(([key, value]) => {
                orderedFilters[key].push(value);
            });

        return orderedFilters;
    }, [filterNames.join(';'), searchParams]);

    return { filters, onToggleFilter, searchParams, setSearchParams };
};

const SimpleFilterSidebarSection = ({
    filterName,
    values,
    filters,
    onToggleFilter,
    formatLabel,
    formatValue,
    onClearFilter,
}: {
    filterName: string;
    values: SearchFilter[];
    filters: Record<string, string[]>;
    onToggleFilter?: (key: string, value: string) => void;
    formatLabel: (filterKey: string) => string;
    formatValue: (filterKey: string, value: string) => string;
    onClearFilter: (key: string) => void;
}) => {
    const { t } = useTranslation('site');

    const [isShowAllOptions, setIsShowAllOptions] = useState(false);
    const checkedCount = values.filter((searchFilter) => filters[filterName]?.includes(searchFilter.value)).length;
    const sliceTo = isShowAllOptions
        ? values.length
        : Math.max(checkedCount, Math.min(MAX_INITIAL_OPTIONS, values.length));

    const displayValues = [...values]
        .toSorted((a, b) => {
            // sort by first checked, then by count, then by formatted Value
            const aChecked = filters[filterName]?.includes(a.value) || false;
            const bChecked = filters[filterName]?.includes(b.value) || false;

            const aCount = a.count || 0;
            const bCount = b.count || 0;

            const aFormattedValue = formatValue(filterName, a.value).toLowerCase();
            const bFormattedValue = formatValue(filterName, b.value).toLowerCase();

            if (aChecked && !bChecked) {
                return -1;
            }
            if (!aChecked && bChecked) {
                return 1;
            }
            if (aCount > bCount) {
                return -1;
            }
            if (aCount < bCount) {
                return 1;
            }
            return aFormattedValue.localeCompare(bFormattedValue);
        })
        .slice(0, sliceTo);

    return (
        <FilterSection
            key={filterName}
            label={
                <span className="flex gap-2 items-center">
                    <span>{formatLabel(filterName)}</span>
                    {filterName in filters && filters[filterName].length > 0 && (
                        <span>({filters[filterName].length})</span>
                    )}
                </span>
            }
            initialIsOpen={true}
            // index === 0 || (key in filters && filters[key].length > 0)
        >
            {displayValues.map((searchFilter) => (
                <BooleanField
                    key={searchFilter.value}
                    label={
                        <span className="font-normal text-sm leading-relaxed text-balance block pt-0.5">
                            {formatValue(filterName, searchFilter.value)} ({searchFilter.count})
                        </span>
                    }
                    checked={Boolean(filters[filterName]?.includes(searchFilter.value))}
                    onChange={() => {
                        onToggleFilter?.(filterName, searchFilter.value);
                    }}
                />
            ))}

            {!values.length && <span className="text-sm text-base-400">{t('simpleFilterSidebar.noFilters')}</span>}

            {values.length > MAX_INITIAL_OPTIONS && (
                <button
                    onClick={() => setIsShowAllOptions(!isShowAllOptions)}
                    className="w-auto text-sm text-left text-blue-500 font-medium py-0.5 -mb-1"
                    type="button"
                >
                    {isShowAllOptions
                        ? t('simpleFilterSidebar.showLessOptionsButton')
                        : t('simpleFilterSidebar.showMoreOptionsButton')}
                </button>
            )}

            {filterName in filters && filters[filterName].length > 0 && (
                <button
                    onClick={() => onClearFilter(filterName)}
                    className="w-auto text-sm text-left text-blue-500 font-medium py-0.5"
                    type="button"
                >
                    {t('simpleFilterSidebar.resetFiltersButton')}
                </button>
            )}
        </FilterSection>
    );
};

export const SimpleFilterSidebar = ({
    searchParams,
    setSearchParams,
    filtersData = {},
    filters = {},
    onToggleFilter,
    formatLabel = (filterKey: string) => filterKey,
    formatValue = (filterKey: string, value: string) => value,
}: {
    searchParams: URLSearchParams;
    setSearchParams: (searchParams: URLSearchParams) => void;
    filtersData?: Record<string, SearchFilter[]>;
    filters: Record<string, string[]>;
    onToggleFilter?: (key: string, value: string) => void;
    formatLabel?: (filterKey: string) => string;
    formatValue?: (filterKey: string, value: string) => string;
}) => {
    const { t } = useTranslation('site');

    const filterKeys = Object.keys(filters);
    const filterSections: [string, SearchFilter[]][] = Object.entries(filtersData)
        .filter(
            ([key, searchFilters]) => filterKeys.includes(key) && searchFilters != null && Array.isArray(searchFilters)
        )
        .map(([key, searchFilters]) => {
            // add selected filters with count 0
            const values = searchFilters.map((filter) => filter.value);
            const missingValues = filters[key]?.filter((v) => !values.includes(v)) || [];
            return [key, [...searchFilters, ...missingValues.map((v) => ({ value: v, count: 0 }))]] as [
                string,
                SearchFilter[],
            ];
        })
        .sort((a, b) => {
            return filterKeys.indexOf(a[0]) - filterKeys.indexOf(b[0]);
        });

    const searchValue = searchParams.get('search') || '';
    const handleSearchChange = (value: string) => {
        if (value) {
            searchParams.set('search', value);
        } else {
            searchParams.delete('search');
        }
        setSearchParams(searchParams);
    };

    const handleClearFilter = (key: string) => {
        searchParams.delete(key);
        setSearchParams(searchParams);
    };

    return (
        <FilterSidebar>
            <FilterSection label={formatLabel('search')} isCollapsible={false}>
                <SearchField
                    placeholder={t('simpleFilterSidebar.searchPlaceholder')}
                    value={searchValue}
                    onSearchValueChange={handleSearchChange}
                />

                {searchValue && (
                    <button
                        onClick={() => handleClearFilter('search')}
                        className="w-auto text-sm text-left text-blue-500 font-medium py-0.5"
                        type="button"
                    >
                        {t('simpleFilterSidebar.resetSearchButton')}
                    </button>
                )}
            </FilterSection>

            {filterSections.length > 0 &&
                filterSections.map(([key, values], index) => (
                    <SimpleFilterSidebarSection
                        key={key}
                        filterName={key}
                        values={values}
                        filters={filters}
                        onToggleFilter={onToggleFilter}
                        formatLabel={formatLabel}
                        formatValue={formatValue}
                        onClearFilter={handleClearFilter}
                    />
                ))}
        </FilterSidebar>
    );
};
