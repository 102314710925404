import React from 'react';

import { classnames } from '@nicoknoll/utils';

import { User } from '../data/models.ts';
import Image from './Image.tsx';

const UserIcon = ({ className, ...props }: React.ComponentPropsWithRef<'svg'>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 20"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={classnames('lucide lucide-user-round', className)}
        {...props}
    >
        <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2Z" fill="currentColor" />
        <circle cx="12" cy="7" r="4" fill="currentColor" />
    </svg>
);

const Avatar = ({ className, user, ...props }: React.ComponentPropsWithRef<'div'> & { user: User }) => (
    <div
        className={classnames(
            'w-8 h-8 rounded-full overflow-hidden bg-base-200 text-base-400 flex items-center justify-center text-lg font-semibold',
            className
        )}
        {...props}
    >
        {user?.image?.url ? (
            <Image src={user?.image?.url} alt="-" className="w-full h-full object-cover" />
        ) : (
            <UserIcon className="!w-[87.5%] !h-[87.5%] mt-1" />
        )}
    </div>
);

export default Avatar;
