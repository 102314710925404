import { ChevronDownIcon } from 'lucide-react';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import { ADMIN_PATH } from '../App.tsx';
import { Event, Organization, User } from '../data/models.ts';
import {
    conversationListQuery,
    eventListQuery,
    organizationDetailQuery,
    spaceDetailQuery,
    userDetailQuery,
} from '../data/queries.ts';
import { ADMIN_EVENT_LIST_PATH } from '../modules/admin/events/routes.tsx';
import { ADMIN_ORGANIZATION_PROFILE_LIST_PATH } from '../modules/admin/organization-profiles/routes.tsx';
import {
    ADMIN_ORGANIZATION_SETTINGS_CONTACT_PATH,
    ADMIN_ORGANIZATION_SETTINGS_FILES_PATH,
    ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH,
    ADMIN_ORGANIZATION_SETTINGS_PATH,
    ADMIN_ORGANIZATION_SETTINGS_PROFILE_PATH,
    ADMIN_ORGANIZATION_SETTINGS_USERS_PATH,
} from '../modules/admin/organization-settings/routes.tsx';
import { ADMIN_ORGANIZATION_LIST_PATH } from '../modules/admin/organizations/routes.tsx';
import {
    ADMIN_SPACE_SETTINGS_FILES_PATH,
    ADMIN_SPACE_SETTINGS_PATH,
    ADMIN_SPACE_SETTINGS_USERS_PATH,
} from '../modules/admin/space-settings/routes.tsx';
import {
    ADMIN_USER_SETTINGS_ACCOUNT_PATH,
    ADMIN_USER_SETTINGS_CONTACT_PATH,
    ADMIN_USER_SETTINGS_EDUCATION_PATH,
    ADMIN_USER_SETTINGS_EXPERIENCE_PATH,
    ADMIN_USER_SETTINGS_NOTIFICATIONS_PATH,
    ADMIN_USER_SETTINGS_PASSWORD_PATH,
    ADMIN_USER_SETTINGS_PATH,
    ADMIN_USER_SETTINGS_PROFILE_PATH,
} from '../modules/admin/user-settings/routes.tsx';
import { useSpaceId } from '../modules/admin/utils.tsx';
import { endSupportSessionMutation, logoutMutation } from '../modules/auth/queries.ts';
import { ADMIN_LOGIN_PATH, ADMIN_SIGNUP_PATH } from '../modules/auth/routes.tsx';
import { HOME_PATH, SITE_EVENT_DETAIL_PATH, SITE_MESSAGES_PATH } from '../modules/site/routes.tsx';
import { siteUrl, useEventId } from '../modules/site/utils.ts';
import getIsActiveRoute from '../utils/getIsActiveRoute.ts';
import { isAdminUser, isOrganizationUser, isRegularUser, isSupportUser } from '../utils/loaders.ts';
import url from '../utils/url';
import useSyncLogout from '../utils/useSyncLogout.ts';
import Avatar from './Avatar.tsx';
import Button from './Button.tsx';
import DropdownMenu from './DropdownMenu.tsx';
import Image from './Image.tsx';
import LinkButton from './LinkButton.tsx';

const SupportUserBar = () => {
    const { t } = useTranslation('components');

    const { data: viewer } = useQuery(userDetailQuery());
    const { data: viewerOrganization } = useQuery(organizationDetailQuery());

    const { mutateAsync: endSupportSession } = useMutation(endSupportSessionMutation());
    const handleEndSupportSession = async () => {
        try {
            await endSupportSession();
            toast.success(t('supportUserBar.endSupportSessionSuccess'));
            window.location.href = url(ADMIN_ORGANIZATION_LIST_PATH);
        } catch (e) {
            console.error(e);
            toast.error(t('supportUserBar.endSupportSessionError'));
        }
    };

    if (!viewer || !isSupportUser(viewer)) return null;

    return (
        <div className="w-full h-12 bg-theme-500 text-white flex justify-center items-center font-medium text-sm">
            <div className="w-full max-w-[1120px] flex gap-1.5 justify-center items-center ">
                <div className="basis-52 grow-0 shrink w-52 min-w-0" />
                <div className="flex-1 text-center min-w-96">
                    <span className="opacity-70">{t('supportUserBar.text')}</span>{' '}
                    <span>{viewerOrganization?.name}</span>
                </div>
                <div className="flex-grow-0 flex-shrink w-52 min-w-52">
                    <Button
                        onClick={handleEndSupportSession}
                        className="flex-0 bg-transparent hover:bg-theme-600 shadow-none border-white/20 hover:border-theme-700 active:border-theme-800 active:bg-theme-700 text-white px-3"
                    >
                        {t('supportUserBar.endSupportSessionButton')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const HeaderLink = ({
    className,
    children,
    active,
    disabled = false,
    ...props
}: React.ComponentPropsWithRef<typeof Link> & { active?: boolean; disabled?: boolean }) => {
    const isActive = getIsActiveRoute(props.to as string) || active;
    const Comp = disabled ? 'span' : Link;
    return (
        <Comp
            className={classnames(
                'flex flex-col text-base-600 font-medium transition-colors select-none h-16 group',
                disabled && 'opacity-50 cursor-not-allowed',
                className
            )}
            {...props}
        >
            <span className="block h-[3px] w-full" />

            <span className="flex flex-1 h-full flex-col justify-center">
                <span
                    className={classnames(
                        'text-base-800 rounded px-2 py-1.5 font-medium text-sm inline-flex items-center justify-center !outline-theme-100 data-[state=open]:outline data-[state=open]:outline-3 focus:outline focus:outline-3 outline-offset-0 min-h-8 mx-1',
                        !disabled && 'group-hover:bg-base-100 transition-all',
                        'disabled:pointer-events-none'
                    )}
                >
                    {children}
                </span>
            </span>

            <span className={classnames('block h-[3px] w-full', isActive && 'bg-theme-400')} />
        </Comp>
    );
};

const AdminUserDropdownItems = ({ user }: { user: User }) => {
    const { t } = useTranslation('components');
    return (
        <>
            <DropdownMenu.Label>
                {user?.firstName} {user?.lastName}
            </DropdownMenu.Label>

            <DropdownMenu.Item asChild active={getIsActiveRoute(ADMIN_USER_SETTINGS_PATH)}>
                <Link to={ADMIN_USER_SETTINGS_ACCOUNT_PATH}>{t('header.userMenu.settings')}</Link>
            </DropdownMenu.Item>

            <DropdownMenu.Separator />

            <DropdownMenu.Label>Space</DropdownMenu.Label>

            <DropdownMenu.Item asChild active={getIsActiveRoute(ADMIN_SPACE_SETTINGS_FILES_PATH)}>
                <Link to={ADMIN_SPACE_SETTINGS_FILES_PATH}>{t('header.userMenu.files')}</Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild active={getIsActiveRoute([ADMIN_SPACE_SETTINGS_USERS_PATH])}>
                <Link to={url(ADMIN_SPACE_SETTINGS_USERS_PATH)}>{t('header.userMenu.users')}</Link>
            </DropdownMenu.Item>
        </>
    );
};

const OrganizationUserDropdownItems = ({ user, organization }: { user: User; organization: Organization }) => {
    const { t } = useTranslation('components');
    return (
        <>
            <DropdownMenu.Label>
                {user?.firstName} {user?.lastName}
            </DropdownMenu.Label>

            <DropdownMenu.Item asChild active={getIsActiveRoute(ADMIN_USER_SETTINGS_PATH)}>
                <Link to={url(ADMIN_USER_SETTINGS_ACCOUNT_PATH)}>{t('header.userMenu.settings')}</Link>
            </DropdownMenu.Item>

            <DropdownMenu.Separator />

            <DropdownMenu.Label>{organization?.name}</DropdownMenu.Label>

            <DropdownMenu.Item
                asChild
                active={getIsActiveRoute([
                    ADMIN_ORGANIZATION_SETTINGS_PROFILE_PATH,
                    ADMIN_ORGANIZATION_SETTINGS_CONTACT_PATH,
                    ADMIN_ORGANIZATION_SETTINGS_FILES_PATH,
                ])}
            >
                <Link to={url(ADMIN_ORGANIZATION_SETTINGS_PROFILE_PATH)}>{t('header.userMenu.masterData')}</Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild active={getIsActiveRoute([ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH])}>
                <Link to={url(ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH)}>{t('header.userMenu.jobs')}</Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild active={getIsActiveRoute([ADMIN_ORGANIZATION_SETTINGS_USERS_PATH])}>
                <Link to={url(ADMIN_ORGANIZATION_SETTINGS_USERS_PATH)}>{t('header.userMenu.users')}</Link>
            </DropdownMenu.Item>
        </>
    );
};

const RegularUserDropdownItems = ({ user }: { user: User }) => {
    const { t } = useTranslation('components');
    return (
        <>
            <DropdownMenu.Label>
                {user?.firstName} {user?.lastName}
            </DropdownMenu.Label>

            <DropdownMenu.Item
                asChild
                active={getIsActiveRoute([
                    ADMIN_USER_SETTINGS_PROFILE_PATH,
                    ADMIN_USER_SETTINGS_CONTACT_PATH,
                    ADMIN_USER_SETTINGS_EDUCATION_PATH,
                    ADMIN_USER_SETTINGS_EXPERIENCE_PATH,
                ])}
            >
                <Link to={ADMIN_USER_SETTINGS_PROFILE_PATH}>{t('header.userMenu.userProfile')}</Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item
                asChild
                active={getIsActiveRoute([
                    ADMIN_USER_SETTINGS_ACCOUNT_PATH,
                    ADMIN_USER_SETTINGS_NOTIFICATIONS_PATH,
                    ADMIN_USER_SETTINGS_PASSWORD_PATH,
                ])}
            >
                <Link to={ADMIN_USER_SETTINGS_ACCOUNT_PATH}>{t('header.userMenu.settings')}</Link>
            </DropdownMenu.Item>
        </>
    );
};

const HeaderUserSection = ({ user }: { user?: User }) => {
    const { t } = useTranslation('components');

    const isSettingsActive = getIsActiveRoute([
        ADMIN_USER_SETTINGS_PATH,
        ADMIN_ORGANIZATION_SETTINGS_PATH,
        ADMIN_SPACE_SETTINGS_PATH,
    ]);

    const { data: organization } = useQuery({
        ...organizationDetailQuery(user?.organizationId),
        enabled: Boolean(user && isOrganizationUser(user)),
    });

    const { mutateAsync: logout } = useMutation(logoutMutation());
    const syncLogout = useSyncLogout();

    const handleLogout = async () => {
        await logout();
        toast.success(t('header.userMenu.logoutSuccess'));
        syncLogout();
        window.location.href = url(ADMIN_PATH);
    };

    return user?.id ? (
        <span className="flex flex-col text-base-600 font-medium transition-colors select-none h-16 group flex-none">
            <span className="block h-[3px] w-full" />

            <span className="flex flex-1 h-full flex-col justify-center mx-2" data-testid="header-user-section">
                <DropdownMenu>
                    <DropdownMenu.Trigger
                        data-testid="header-user-section-trigger"
                        className="hover:opacity-80 transition-opacity"
                    >
                        <Avatar user={user} className="w-8 h-8" />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content data-testid="header-user-section-menu" align="end" className="min-w-40">
                        {isOrganizationUser(user) && organization && (
                            <OrganizationUserDropdownItems user={user} organization={organization} />
                        )}
                        {isAdminUser(user) && <AdminUserDropdownItems user={user} />}
                        {isRegularUser(user) && <RegularUserDropdownItems user={user} />}

                        <DropdownMenu.Separator />

                        <DropdownMenu.Item onClick={handleLogout}>{t('header.userMenu.logout')}</DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu>
            </span>

            <span className={classnames('block h-[3px] w-full', isSettingsActive && 'bg-theme-400')} />
        </span>
    ) : (
        <section className="flex gap-4">
            <LinkButton variant="ghost" to={url(ADMIN_LOGIN_PATH)}>
                {t('header.login')}
            </LinkButton>
            <LinkButton variant="primary" to={url(ADMIN_SIGNUP_PATH)}>
                {t('header.signup')}
            </LinkButton>
        </section>
    );
};

export const getViewerEventsFilter = (viewer: User) => {
    const eventsFilter: any = {};
    if (viewer && isRegularUser(viewer)) {
        eventsFilter.userId = viewer.id;
    } else if (viewer && isOrganizationUser(viewer)) {
        eventsFilter.organizationId = viewer.organizationId;
    }
    return eventsFilter;
};

export const useViewerEventsQuery = () => {
    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());
    return useQuery({
        ...eventListQuery(eventId ? { id: eventId } : getViewerEventsFilter(viewer!)),
        enabled: Boolean(viewer || eventId),
    });
};

const EventSwitcher = ({ isAdmin = false }: { isAdmin: boolean }) => {
    const { t } = useTranslation('components');
    const { data: events } = useViewerEventsQuery();

    const { data: space } = useQuery(spaceDetailQuery());

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger asChild>
                <Button variant="ghost" className="flex items-center gap-3 group data-[state=open]:bg-base-100 -ml-3">
                    <span className="text-xl font-bold transition-colors">{space?.name}</span>

                    <ChevronDownIcon />
                </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content align="start" sideOffset={4}>
                {events && events.length > 0 ? (
                    events?.map((event) => (
                        <DropdownMenu.Item asChild>
                            <Link
                                className="select-none px-4 py-3 flex flex-col gap-1 !items-start"
                                to={
                                    event?.id
                                        ? siteUrl(SITE_EVENT_DETAIL_PATH, { eventId: event?.slug || event?.id })
                                        : siteUrl(HOME_PATH)
                                }
                            >
                                {event?.logo?.url ? (
                                    <Image src={event?.logo?.url} className="object-contain max-h-5 duration-0" />
                                ) : (
                                    <span className="font-medium text-sm">{event.name}</span>
                                )}
                            </Link>
                        </DropdownMenu.Item>
                    ))
                ) : (
                    <span className="block px-4 py-3 text-sm text-base-400 text-center">
                        {t('header.eventSwitcher.noEvents')}
                    </span>
                )}
            </DropdownMenu.Content>
        </DropdownMenu>
    );
};

const Header = ({ className, ...props }: Omit<React.ComponentPropsWithRef<'header'>, 'children'>) => {
    const { t } = useTranslation('components');

    const eventId = useEventId();

    const { data: viewer } = useQuery(userDetailQuery());
    const { data: events, isLoading } = useViewerEventsQuery();
    const event = events?.find((e) => e.id === eventId) || null;

    const { data: conversations } = useQuery(
        conversationListQuery(event ? { eventId: event.id, isParticipant: true } : { isParticipant: true })
    );
    const unreadConversationsCount = (conversations?.filter((c) => c.unreadCount && c.unreadCount > 0) || []).length;
    const hasUnreadConversations = unreadConversationsCount > 0;

    const location = useLocation();
    const isAdmin = location.pathname.startsWith('/admin');

    return (
        <>
            <SupportUserBar />
            <header
                data-testid="header"
                className={classnames(
                    'flex flex-none justify-center bg-white px-6 h-16 border-b border-base-200 items-center',
                    className
                )}
                {...props}
            >
                <div className="w-full max-w-[70rem]">
                    <div className="flex items-center gap-4">
                        <div>
                            {event ? (
                                <Link
                                    className="select-none"
                                    to={
                                        event?.id
                                            ? siteUrl(SITE_EVENT_DETAIL_PATH, { eventId: event?.slug || event?.id })
                                            : siteUrl(HOME_PATH)
                                    }
                                >
                                    {event?.logo?.url ? (
                                        <Image
                                            src={event?.logo?.url}
                                            className="object-contain max-h-7 max-w-40 duration-0"
                                        />
                                    ) : (
                                        <span className="font-semibold text-lg">{event.name}</span>
                                    )}
                                </Link>
                            ) : (
                                (!isLoading || isAdmin) && <EventSwitcher isAdmin={isAdmin} />
                            )}
                        </div>
                        <div className="flex gap-2 items-center flex-1 justify-end">
                            {viewer?.id && (
                                <>
                                    <HeaderLink
                                        to={siteUrl(SITE_MESSAGES_PATH, {
                                            eventId: (event || events?.[0])?.slug || (event || events?.[0])?.id,
                                        })}
                                    >
                                        <div className="w-full flex justify-between items-center gap-3">
                                            <span>{t('header.messages')}</span>

                                            {hasUnreadConversations && (
                                                <div className="ml-auto">
                                                    <span className="flex justify-center items-center text-white bg-theme-500 rounded-full font-medium px-2 py-0.5 text-xs">
                                                        {unreadConversationsCount}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </HeaderLink>

                                    {isAdminUser(viewer) || isOrganizationUser(viewer) ? (
                                        <div className="w-px bg-base-150 h-6" />
                                    ) : null}

                                    {/*{isAdminUser(viewer) && (*/}
                                    {/*    <HeaderLink to={url(ADMIN_SCHEDULED_EMAILS_PATH)}>*/}
                                    {/*        {t('header.scheduledEmails')}*/}
                                    {/*    </HeaderLink>*/}
                                    {/*)}*/}

                                    {isAdminUser(viewer) && (
                                        <HeaderLink to={url(ADMIN_EVENT_LIST_PATH)}>{t('header.events')}</HeaderLink>
                                    )}
                                    {isAdminUser(viewer) && (
                                        <HeaderLink to={url(ADMIN_ORGANIZATION_LIST_PATH)}>
                                            {t('header.organizations')}
                                        </HeaderLink>
                                    )}
                                    {isOrganizationUser(viewer) && (
                                        <HeaderLink to={url(ADMIN_ORGANIZATION_PROFILE_LIST_PATH)}>
                                            {t('header.events')}
                                        </HeaderLink>
                                    )}
                                </>
                            )}
                            <HeaderUserSection user={viewer} />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Object.assign(Header, { Link: HeaderLink });
