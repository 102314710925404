import React from 'react';

import { Form } from '@nicoknoll/forms';

import Button from './Button.tsx';

const FormSubmitButton = ({ disabled, children, ...props }: React.ComponentPropsWithRef<typeof Button>) => {
    return (
        <Form.Button asChild {...props} disabled={disabled}>
            <Button type="submit" {...props} disabled={disabled}>
                {children}
            </Button>
        </Form.Button>
    );
};

export default FormSubmitButton;
