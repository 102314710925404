import { Navigate, useParams } from 'react-router-dom';

import { authorized, authorizedLoader, isAdminUser, isOrganizationUser } from '../../../utils/loaders.ts';
import url from '../../../utils/url';
import {
    contactPersonsTabLoader,
    contactTabLoader,
    generalTabLoader,
    jobsTabLoader,
    organizationProfileDetailPageLoader,
    organizationProfileListPageLoader,
} from './loaders.ts';
import OrganizationProfileDetailPage from './pages/OrganizationProfileDetailPage.tsx';
import OrganizationProfileListPage from './pages/OrganizationProfileListPage.tsx';
import ContactPersonsTab from './tabs/ContactPersonsTab.tsx';
import ContactTab from './tabs/ContactTab.tsx';
import EventSessionsTab, { AddEventSessionFormDialog, EditEventSessionFormDialog } from './tabs/EventSessionsTab.tsx';
import GeneralTab from './tabs/GeneralTab.tsx';
import JobsTab from './tabs/JobsTab.tsx';

export const ADMIN_ORGANIZATION_PROFILE_LIST_PATH = '/admin/organization/profiles';
export const ADMIN_ORGANIZATION_PROFILE_DETAIL_PATH = '/admin/organization/profiles/:profileId';
export const ADMIN_ORGANIZATION_PROFILE_GENERAL_PATH = '/admin/organization/profiles/:profileId/general';
export const ADMIN_ORGANIZATION_PROFILE_CONTACT_PATH = '/admin/organization/profiles/:profileId/contact';
export const ADMIN_ORGANIZATION_PROFILE_CONTACT_PERSONS_PATH =
    '/admin/organization/profiles/:profileId/contact-persons';
export const ADMIN_ORGANIZATION_PROFILE_JOBS_PATH = '/admin/organization/profiles/:profileId/jobs';
export const ADMIN_ORGANIZATION_PROFILE_EVENT_SESSIONS_PATH = '/admin/organization/profiles/:profileId/sessions';
export const ADMIN_ORGANIZATION_PROFILE_EVENT_SESSIONS_ADD_PATH =
    '/admin/organization/profiles/:profileId/sessions/add';
export const ADMIN_ORGANIZATION_PROFILE_EVENT_SESSIONS_EDIT_PATH =
    '/admin/organization/profiles/:profileId/sessions/:sessionId/edit';

const IndexRedirect = () => {
    const { profileId } = useParams();
    return <Navigate to={url(ADMIN_ORGANIZATION_PROFILE_GENERAL_PATH, { profileId })} replace={true} />;
};

export default [
    {
        handle: {
            title: 'Profile',
        },
        path: ADMIN_ORGANIZATION_PROFILE_LIST_PATH,
        element: <OrganizationProfileListPage />,
        loader: authorizedLoader(organizationProfileListPageLoader, isOrganizationUser),
    },
    {
        path: ADMIN_ORGANIZATION_PROFILE_DETAIL_PATH,
        element: <OrganizationProfileDetailPage />,
        loader: authorizedLoader(organizationProfileDetailPageLoader, isOrganizationUser),
        children: [
            {
                handle: {
                    title: 'Profil',
                },
                index: true,
                element: <IndexRedirect />,
                loader: authorizedLoader(organizationProfileDetailPageLoader, isOrganizationUser),
            },
            {
                handle: {
                    title: 'Allgemein',
                },
                path: ADMIN_ORGANIZATION_PROFILE_GENERAL_PATH,
                element: <GeneralTab />,
                loader: authorizedLoader(generalTabLoader, isOrganizationUser),
            },
            {
                handle: {
                    title: 'Kontakt',
                },
                path: ADMIN_ORGANIZATION_PROFILE_CONTACT_PATH,
                element: <ContactTab />,
                loader: authorizedLoader(contactTabLoader, isOrganizationUser),
            },
            {
                handle: {
                    title: 'Ansprechpartner',
                },
                path: ADMIN_ORGANIZATION_PROFILE_CONTACT_PERSONS_PATH,
                element: <ContactPersonsTab />,
                loader: authorizedLoader(contactPersonsTabLoader, isOrganizationUser),
            },
            {
                handle: {
                    title: 'Jobs',
                },
                path: ADMIN_ORGANIZATION_PROFILE_JOBS_PATH,
                element: <JobsTab />,
                loader: authorizedLoader(jobsTabLoader, isOrganizationUser),
            },
            {
                handle: {
                    title: 'Programm',
                },
                path: ADMIN_ORGANIZATION_PROFILE_EVENT_SESSIONS_PATH,
                element: <EventSessionsTab />,
                loader: authorized(isOrganizationUser),
                children: [
                    {
                        handle: {
                            title: 'Programmpunkt hinzufügen',
                        },
                        path: ADMIN_ORGANIZATION_PROFILE_EVENT_SESSIONS_ADD_PATH,
                        element: <AddEventSessionFormDialog />,
                        loader: authorized(isOrganizationUser),
                    },
                    {
                        handle: {
                            title: 'Programmpunkt bearbeiten',
                        },
                        path: ADMIN_ORGANIZATION_PROFILE_EVENT_SESSIONS_EDIT_PATH,
                        element: <EditEventSessionFormDialog />,
                        loader: authorized(isOrganizationUser),
                    },
                ],
            },
        ],
    },
];
