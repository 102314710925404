import api, { BaseAPIClient, InputFile, PaginatedResponse, UrlOptions } from './api.ts';
import {
    Conversation,
    Education,
    Event,
    EventAnalytics,
    EventAttendee,
    EventLimits,
    EventPage,
    EventSession,
    EventSessionUser,
    Experience,
    Job,
    LogEvent,
    Message,
    Organization,
    OrganizationLimits,
    OrganizationProfile,
    OrganizationProfileJob,
    OrganizationProfileLimits,
    OrganizationProfileUser,
    SavedEventSession,
    SavedJob,
    SavedOrganization,
    SavedUser,
    ScheduledEmail,
    Space,
    SpaceLimits,
    UploadedFile,
    User,
    UserNote,
    UserNotificationSettings,
    UserProfile,
} from './models.ts';
import { APIError, isUuid } from './utils.ts';

type QueryFilterValue = string | number | boolean | null | undefined;

interface QueryFilters {
    [key: string]: QueryFilterValue | QueryFilterValue[];
}

const generatePaginatedListQuery =
    <T extends { id: string }>(resourceClient: BaseAPIClient<T>) =>
    (filters: QueryFilters = {}, options?: UrlOptions) => ({
        queryKey: [resourceClient.resource, 'list', filters, options],
        queryFn: async ({ pageParam }: { pageParam?: number }): Promise<PaginatedResponse<T>> => {
            console.debug(
                `fetch ${resourceClient.resource} paginated list, filters: ${JSON.stringify(filters)}, pageParam: ${pageParam}, options: ${options}`
            );
            try {
                return resourceClient.list({
                    ...filters,
                    page: (pageParam ? pageParam : filters.page) as number | undefined,
                    ...options,
                });
            } catch (e) {
                console.error(e);
                return {} as PaginatedResponse<T>;
            }
        },
        // disabled if any filter is undefined
        enabled: filters ? !Object.values(filters).some((v) => v === undefined) : true,
        // TODO: maybe there is a better way to handle stale data
        // staleTime: Object.keys(filters).length > 0 ? 0 : Infinity,
    });

const generateListQuery =
    <T extends { id: string }>(resourceClient: BaseAPIClient<T>) =>
    (filters: QueryFilters = {}, options?: UrlOptions) => ({
        queryKey: [resourceClient.resource, 'list', filters, options],
        queryFn: async (): Promise<T[]> => {
            console.debug(
                `fetch ${resourceClient.resource} list, filters: ${JSON.stringify(filters)}, options: ${options}`
            );
            try {
                const data = await resourceClient.list({ ...filters, ...options });
                return (data.results ? data.results : data) as T[];
            } catch (e) {
                console.error(e);
                return [];
            }
        },
        // disabled if any filter is undefined
        enabled: filters ? !Object.values(filters).some((v) => v === undefined) : true,
        // TODO: maybe there is a better way to handle stale data
        // staleTime: Object.keys(filters).length > 0 ? 0 : Infinity,
    });

const generateSearchQuery =
    <T extends { id: string }>(resourceClient: BaseAPIClient<T>) =>
    (filters: QueryFilters = {}, options?: UrlOptions) => ({
        queryKey: [resourceClient.resource, 'search', filters, options],
        queryFn: async (): Promise<PaginatedResponse<T>> => {
            console.debug(
                `fetch ${resourceClient.resource} search, filters: ${JSON.stringify(filters)}, options: ${options}`
            );
            try {
                return await resourceClient.search({ ...filters, ...options });
            } catch (e) {
                console.error(e);
                return {} as PaginatedResponse<T>;
            }
        },
        // disabled if any filter is undefined
        enabled: filters ? !Object.values(filters).some((v) => v === undefined) : true,
        // TODO: maybe there is a better way to handle stale data
        // staleTime: Object.keys(filters).length > 0 ? 0 : Infinity,
    });

const generateDetailQuery =
    <T extends { id: string }>(resourceClient: BaseAPIClient<T>) =>
    (id: string, options?: UrlOptions) => ({
        queryKey: [resourceClient.resource, 'detail', id, options],
        queryFn: async (): Promise<T> => {
            console.debug(`fetch ${resourceClient.resource} detail`, id, options);
            let item;

            try {
                item = await resourceClient.get(id, options);
            } catch (e) {
                console.error(e);
                item = {};
            }

            if (id && id !== 'me' && !item?.id) {
                throw new Response('Not Found', { status: 404, statusText: 'Not Found' });
            }

            return item as T;
        },
        enabled: !!id,
        // staleTime: Infinity,
    });

const generateLimitsQuery =
    <T extends {}>(resourceClient: BaseAPIClient<T> & { getLimits: (id: string) => Promise<T> }) =>
    (id: string, options?: UrlOptions) => ({
        queryKey: [resourceClient.resource, 'limits', id, options],
        queryFn: async (): Promise<T> => {
            console.debug(`fetch ${resourceClient.resource} limits`, id, options);
            const item = await resourceClient.getLimits(id);
            return item as T;
        },
        enabled: !!id,
        // staleTime: Infinity,
    });

// "/me" is to get the current user or organization
const generateMeDetailQuery =
    <T extends { id: string }>(resourceClient: BaseAPIClient<T>) =>
    (id: string = 'me', options?: UrlOptions) => ({
        ...generateDetailQuery(resourceClient)(id, options),
        staleTime: Infinity,
    });

const generateCreateMutation =
    <T extends { id: string }>(resourceClient: BaseAPIClient<T>) =>
    () => ({
        mutationKey: [resourceClient.resource, 'create'],
        mutationFn: async ({ data, ...options }: { data: Partial<Omit<T, 'id'>>; [key: string]: any }) => {
            console.debug(`create ${resourceClient.resource}`, data, options);
            try {
                return await resourceClient.create(data as T, options);
            } catch (e) {
                console.error(e);
                throw new APIError('Failed to create resource', e);
            }
        },
    });

const generateUpdateMutation =
    <T extends { id: string }>(resourceClient: BaseAPIClient<T>) =>
    () => ({
        mutationKey: [resourceClient.resource, 'update'],
        mutationFn: async ({
            id,
            data,
            partial,
            ...options
        }: {
            id: string;
            data: Partial<Omit<T, 'id'>>;
            partial?: boolean;
            [key: string]: any;
        }) => {
            console.debug(`update ${resourceClient.resource}`, data);
            try {
                return await resourceClient.update(id, data as T, partial, options);
            } catch (e) {
                console.error(e);
                throw new APIError('Failed to update resource', e);
            }
        },
    });

const generateDeleteMutation =
    <T extends { id: string }>(resourceClient: BaseAPIClient<T>) =>
    () => ({
        mutationKey: [resourceClient.resource, 'delete'],
        mutationFn: async ({ id, ...options }: { id: string; [key: string]: any }) => {
            console.debug(`delete ${resourceClient.resource}`, id);
            try {
                return await resourceClient.delete(id, options);
            } catch (e) {
                console.error(e);
                throw new APIError('Failed to delete resource', e);
            }
        },
    });

// SPACES

export const spaceListQuery = generateListQuery<Space>(api.spaces);
export const spaceDetailQuery = generateMeDetailQuery<Space>(api.spaces);

export const spaceLimitsQuery = generateLimitsQuery<SpaceLimits>(api.spaces);

// JOBS

export const jobListQuery = generateListQuery<Job>(api.jobs);
export const jobDetailQuery = generateDetailQuery<Job>(api.jobs);
export const jobCreateMutation = generateCreateMutation<Job>(api.jobs);
export const jobUpdateMutation = generateUpdateMutation<Job>(api.jobs);
export const jobDeleteMutation = generateDeleteMutation<Job>(api.jobs);

// USERS

export const userListQuery = generateListQuery<User>(api.users);
export const userDetailQuery = generateMeDetailQuery<User>(api.users);
export const userCreateMutation = generateCreateMutation<User>(api.users);
export const userUpdateMutation = generateUpdateMutation<User>(api.users);
export const userDeleteMutation = generateDeleteMutation<User>(api.users);

export const userResendInvitationMutation = () => ({
    mutationKey: ['user', 'resend-invitation'],
    mutationFn: async ({ id }: { id: string }) => {
        try {
            return await api.users.resendInvitation(id);
        } catch (e) {
            console.error(e);
            throw new APIError('Failed to resend invitation', e);
        }
    },
});

export const userResendConfirmEmailMutation = () => ({
    mutationKey: ['user', 'resend-confirm-email'],
    mutationFn: async ({ id }: { id: string }) => {
        try {
            return await api.users.resendConfirmEmail(id);
        } catch (e) {
            console.error(e);
            throw new APIError('Failed to resend confirm email', e);
        }
    },
});

export const userUpdatePasswordMutation = () => ({
    mutationKey: ['user', 'update-password'],
    mutationFn: async ({
        id,
        data,
    }: {
        id: string;
        data: { oldPassword: string; newPassword: string; confirmPassword: string };
    }) => {
        return await api.users.updatePassword(id, data);
    },
});

// EDUCATIONS

export const userEducationListQuery = generateListQuery<Education>(api.educations);
export const userEducationDetailQuery = generateDetailQuery<Education>(api.educations);
export const userEducationCreateMutation = generateCreateMutation<Education>(api.educations);
export const userEducationUpdateMutation = generateUpdateMutation<Education>(api.educations);
export const userEducationDeleteMutation = generateDeleteMutation<Education>(api.educations);

// EXPERIENCES

export const userExperienceListQuery = generateListQuery<Experience>(api.experiences);
export const userExperienceDetailQuery = generateDetailQuery<Experience>(api.experiences);
export const userExperienceCreateMutation = generateCreateMutation<Experience>(api.experiences);
export const userExperienceUpdateMutation = generateUpdateMutation<Experience>(api.experiences);
export const userExperienceDeleteMutation = generateDeleteMutation<Experience>(api.experiences);

// USER NOTIFICATION SETTINGS

export const userNotificationSettingsListQuery = generateListQuery<UserNotificationSettings>(
    api.userNotificationSettings
);
export const userNotificationSettingsDetailQuery = generateDetailQuery<UserNotificationSettings>(
    api.userNotificationSettings
);
export const userNotificationSettingsCreateMutation = generateCreateMutation<UserNotificationSettings>(
    api.userNotificationSettings
);
export const userNotificationSettingsUpdateMutation = generateUpdateMutation<UserNotificationSettings>(
    api.userNotificationSettings
);
export const userNotificationSettingsDeleteMutation = generateDeleteMutation<UserNotificationSettings>(
    api.userNotificationSettings
);

// ORGANIZATIONS

export const organizationListQuery = generateListQuery<Organization>(api.organizations);
export const organizationDetailQuery = generateMeDetailQuery<Organization>(api.organizations);
export const organizationCreateMutation = generateCreateMutation<Organization>(api.organizations);
export const organizationUpdateMutation = generateUpdateMutation<Organization>(api.organizations);
export const organizationDeleteMutation = generateDeleteMutation<Organization>(api.organizations);

export const organizationLimitsQuery = generateLimitsQuery<OrganizationLimits>(api.organizations);

// ORGANIZATION PROFILES

export const organizationProfileListQuery = generateListQuery<OrganizationProfile>(api.organizationProfiles);
export const organizationProfileSearchQuery = generateSearchQuery<OrganizationProfile>(api.organizationProfiles);
export const organizationProfileDetailQuery = generateDetailQuery<OrganizationProfile>(api.organizationProfiles);
export const organizationProfileCreateMutation = generateCreateMutation<OrganizationProfile>(api.organizationProfiles);
export const organizationProfileUpdateMutation = generateUpdateMutation<OrganizationProfile>(api.organizationProfiles);
export const organizationProfileDeleteMutation = generateDeleteMutation<OrganizationProfile>(api.organizationProfiles);

export const organizationProfileLimitsQuery = generateLimitsQuery<OrganizationProfileLimits>(api.organizationProfiles);

// USER PROFILE

export const userProfileListQuery = generateListQuery<UserProfile>(api.userProfiles);
export const userProfileSearchQuery = generateSearchQuery<UserProfile>(api.userProfiles);
export const userProfileDetailQuery = generateMeDetailQuery<UserProfile>(api.userProfiles);
export const userProfileCreateMutation = generateCreateMutation<UserProfile>(api.userProfiles);
export const userProfileUpdateMutation = generateUpdateMutation<UserProfile>(api.userProfiles);
export const userProfileDeleteMutation = generateDeleteMutation<UserProfile>(api.userProfiles);

// USER NOTES

export const userNoteListQuery = generateListQuery<UserNote>(api.userNotes);
export const userNoteDetailQuery = generateDetailQuery<UserNote>(api.userNotes);
export const userNoteCreateMutation = generateCreateMutation<UserNote>(api.userNotes);
export const userNoteUpdateMutation = generateUpdateMutation<UserNote>(api.userNotes);
export const userNoteDeleteMutation = generateDeleteMutation<UserNote>(api.userNotes);

// EVENTS

export const eventListQuery = generateListQuery<Event>(api.events);
export const eventDetailQuery = (id: string = 'me', options?: UrlOptions) => ({
    ...(isUuid(id) || id === 'me'
        ? generateDetailQuery(api.events)(id, options)
        : {
              ...generateListQuery(api.events)({ slug: id }, options),
              queryFn: async () => {
                  const events = await generateListQuery(api.events)({ slug: id }, options).queryFn();
                  return events?.[0];
              },
          }),
    staleTime: Infinity,
});

export const eventCreateMutation = generateCreateMutation<Event>(api.events);
export const eventUpdateMutation = generateUpdateMutation<Event>(api.events);
export const eventDeleteMutation = generateDeleteMutation<Event>(api.events);

export const eventLimitsQuery = generateLimitsQuery<EventLimits>(api.events);

export const eventAnalyticsQuery = (id: string) => ({
    queryKey: ['event', id, 'analytics'],
    queryFn: async () => {
        console.debug(`fetch ${api.events.resource} analytics`, id);
        const item = await api.events.getAnalytics(id);
        return item as EventAnalytics;
    },
    staleTime: Infinity,
});

// EVENT USER

export const eventAttendeeListQuery = generateListQuery<EventAttendee>(api.eventAttendees);
export const eventAttendeeDetailQuery = generateDetailQuery<EventAttendee>(api.eventAttendees);
export const eventAttendeeCreateMutation = generateCreateMutation<EventAttendee>(api.eventAttendees);
export const eventAttendeeUpdateMutation = generateUpdateMutation<EventAttendee>(api.eventAttendees);
export const eventAttendeeDeleteMutation = generateDeleteMutation<EventAttendee>(api.eventAttendees);

// EVENT SESSION

export const eventSessionListQuery = generateListQuery<EventSession>(api.eventSessions);
export const eventSessionSearchQuery = generateSearchQuery<EventSession>(api.eventSessions);
export const eventSessionDetailQuery = generateDetailQuery<EventSession>(api.eventSessions);
export const eventSessionCreateMutation = generateCreateMutation<EventSession>(api.eventSessions);
export const eventSessionUpdateMutation = generateUpdateMutation<EventSession>(api.eventSessions);
export const eventSessionDeleteMutation = generateDeleteMutation<EventSession>(api.eventSessions);

// EVENT SESSION USER

export const eventSessionUserListQuery = generateListQuery<EventSessionUser>(api.eventSessionUsers);
export const eventSessionUserDetailQuery = generateDetailQuery<EventSessionUser>(api.eventSessionUsers);
export const eventSessionUserCreateMutation = generateCreateMutation<EventSessionUser>(api.eventSessionUsers);
export const eventSessionUserUpdateMutation = generateUpdateMutation<EventSessionUser>(api.eventSessionUsers);
export const eventSessionUserDeleteMutation = generateDeleteMutation<EventSessionUser>(api.eventSessionUsers);

// EVENT PAGES

export const eventPageListQuery = generateListQuery<EventPage>(api.eventPages);
export const eventPageDetailQuery = generateDetailQuery<EventPage>(api.eventPages);
export const eventPageCreateMutation = generateCreateMutation<EventPage>(api.eventPages);
export const eventPageUpdateMutation = generateUpdateMutation<EventPage>(api.eventPages);
export const eventPageDeleteMutation = generateDeleteMutation<EventPage>(api.eventPages);

// ORGANIZATION PROFILE JOBS

export const organizationProfileJobListQuery = generateListQuery<OrganizationProfileJob>(api.organizationProfileJobs);
export const organizationProfileJobSearchQuery = generateSearchQuery<OrganizationProfileJob>(
    api.organizationProfileJobs
);
export const organizationProfileJobDetailQuery = generateDetailQuery<OrganizationProfileJob>(
    api.organizationProfileJobs
);
export const organizationProfileJobCreateMutation = generateCreateMutation<OrganizationProfileJob>(
    api.organizationProfileJobs
);
export const organizationProfileJobUpdateMutation = generateUpdateMutation<OrganizationProfileJob>(
    api.organizationProfileJobs
);
export const organizationProfileJobDeleteMutation = generateDeleteMutation<OrganizationProfileJob>(
    api.organizationProfileJobs
);

// ORGANIZATION PROFILE USERS

export const organizationProfileUserListQuery = generateListQuery<OrganizationProfileUser>(
    api.organizationProfileUsers
);
export const organizationProfileUserDetailQuery = generateDetailQuery<OrganizationProfileUser>(
    api.organizationProfileUsers
);
export const organizationProfileUserCreateMutation = generateCreateMutation<OrganizationProfileUser>(
    api.organizationProfileUsers
);
export const organizationProfileUserUpdateMutation = generateUpdateMutation<OrganizationProfileUser>(
    api.organizationProfileUsers
);
export const organizationProfileUserDeleteMutation = generateDeleteMutation<OrganizationProfileUser>(
    api.organizationProfileUsers
);

// CONVERSATIONS

interface CreateConversation extends Omit<Conversation, 'participants' | 'participantIds' | 'messages'> {
    receiverId: string;
    text?: string;
    attachment?: string;
}

export const conversationListQuery = generateListQuery<Conversation>(api.conversations);
export const conversationDetailQuery = generateDetailQuery<Conversation>(api.conversations);
export const conversationCreateMutation = generateCreateMutation<CreateConversation>(api.conversations);
export const conversationUpdateMutation = generateUpdateMutation<Conversation>(api.conversations);
export const conversationDeleteMutation = generateDeleteMutation<Conversation>(api.conversations);

export const conversationMarkReadMutation = () => ({
    mutationKey: ['conversation', 'mark-read'],
    mutationFn: async ({ id }: { id: string }) => {
        try {
            return await api.conversations.markRead(id);
        } catch (e) {
            console.error(e);
            throw new APIError('Failed to mark conversation as read', e);
        }
    },
});

export const conversationReportMutation = () => ({
    mutationKey: ['conversation', 'report'],
    mutationFn: async ({ id, data }: { id: string; data: { targetUserId: string } }) => {
        try {
            return await api.conversations.report(id, data);
        } catch (e) {
            console.error(e);
            throw new APIError('Failed to report', e);
        }
    },
});

export const conversationBlockMutation = () => ({
    mutationKey: ['conversation', 'block'],
    mutationFn: async ({ id }: { id: string }) => {
        try {
            return await api.conversations.block(id);
        } catch (e) {
            console.error(e);
            throw new APIError('Failed to block', e);
        }
    },
});

export const conversationUnblockMutation = () => ({
    mutationKey: ['conversation', 'block'],
    mutationFn: async ({ id }: { id: string }) => {
        try {
            return await api.conversations.unblock(id);
        } catch (e) {
            console.error(e);
            throw new APIError('Failed to unblock', e);
        }
    },
});

// MESSAGES

export const messageListQuery = generateListQuery<Message>(api.messages);
export const messagesPaginatedListQuery = generatePaginatedListQuery<Message>(api.messages);
export const messageDetailQuery = generateDetailQuery<Message>(api.messages);
export const messageCreateMutation = generateCreateMutation<Message>(api.messages);
export const messageUpdateMutation = generateUpdateMutation<Message>(api.messages);
export const messageDeleteMutation = generateDeleteMutation<Message>(api.messages);

// SAVED USERS

export const savedUserListQuery = generateListQuery<SavedUser>(api.savedUsers);
export const savedUserDetailQuery = generateDetailQuery<SavedUser>(api.savedUsers);
export const savedUserCreateMutation = generateCreateMutation<SavedUser>(api.savedUsers);
export const savedUserUpdateMutation = generateUpdateMutation<SavedUser>(api.savedUsers);
export const savedUserDeleteMutation = generateDeleteMutation<SavedUser>(api.savedUsers);

// SAVED ORGANIZATIONS

export const savedOrganizationListQuery = generateListQuery<SavedOrganization>(api.savedOrganizations);
export const savedOrganizationDetailQuery = generateDetailQuery<SavedOrganization>(api.savedOrganizations);
export const savedOrganizationCreateMutation = generateCreateMutation<SavedOrganization>(api.savedOrganizations);
export const savedOrganizationUpdateMutation = generateUpdateMutation<SavedOrganization>(api.savedOrganizations);
export const savedOrganizationDeleteMutation = generateDeleteMutation<SavedOrganization>(api.savedOrganizations);

// SAVED JOBS

export const savedJobListQuery = generateListQuery<SavedJob>(api.savedJobs);
export const savedJobDetailQuery = generateDetailQuery<SavedJob>(api.savedJobs);
export const savedJobCreateMutation = generateCreateMutation<SavedJob>(api.savedJobs);
export const savedJobUpdateMutation = generateUpdateMutation<SavedJob>(api.savedJobs);
export const savedJobDeleteMutation = generateDeleteMutation<SavedJob>(api.savedJobs);

// SAVED EVENT SESSIONS

export const savedEventSessionListQuery = generateListQuery<SavedEventSession>(api.savedEventSessions);
export const savedEventSessionDetailQuery = generateDetailQuery<SavedEventSession>(api.savedEventSessions);
export const savedEventSessionCreateMutation = generateCreateMutation<SavedEventSession>(api.savedEventSessions);
export const savedEventSessionUpdateMutation = generateUpdateMutation<SavedEventSession>(api.savedEventSessions);
export const savedEventSessionDeleteMutation = generateDeleteMutation<SavedEventSession>(api.savedEventSessions);

// Files

export const fileListQuery = generateListQuery<UploadedFile>(api.files);
export const fileSearchQuery = generateSearchQuery<UploadedFile>(api.files);
export const fileDetailQuery = generateDetailQuery<UploadedFile>(api.files);
export const fileCreateMutation = generateCreateMutation<UploadedFile>(api.files);
export const fileUpdateMutation = generateUpdateMutation<UploadedFile>(api.files);
export const fileDeleteMutation = generateDeleteMutation<UploadedFile>(api.files);

// SCHEDULED EMAILS

export const scheduledEmailListQuery = generateListQuery<ScheduledEmail>(api.scheduledEmails);
export const scheduledEmailSearchQuery = generateSearchQuery<ScheduledEmail>(api.scheduledEmails);
export const scheduledEmailDetailQuery = generateDetailQuery<ScheduledEmail>(api.scheduledEmails);
export const scheduledEmailCreateMutation = generateCreateMutation<ScheduledEmail>(api.scheduledEmails);
export const scheduledEmailUpdateMutation = generateUpdateMutation<ScheduledEmail>(api.scheduledEmails);
export const scheduledEmailDeleteMutation = generateDeleteMutation<ScheduledEmail>(api.scheduledEmails);

// LOG EVENTS

export const logEventCreateMutation = () => ({
    ...generateCreateMutation<LogEvent>(api.logEvents)(),
    meta: { skipInvalidateQueries: true },
});

export const logEventAcceptTrackingMutation = () => ({
    mutationKey: ['log-event', 'accept-tracking'],
    mutationFn: async () => {
        try {
            return await api.logEvents.acceptTracking();
        } catch (e) {
            console.error(e);
            throw new APIError('Failed to accept tracking', e);
        }
    },
});

export const logEventRejectTrackingMutation = () => ({
    mutationKey: ['log-event', 'reject-tracking'],
    mutationFn: async () => {
        try {
            return await api.logEvents.rejectTracking();
        } catch (e) {
            console.error(e);
            throw new APIError('Failed to reject tracking', e);
        }
    },
});

export const logEventTrackingStatusQuery = () => ({
    queryKey: ['log-event', 'tracking-status'],
    queryFn: async () => {
        try {
            return await api.logEvents.trackingStatus();
        } catch (e) {
            console.error(e);
            throw new APIError('Failed to get tracking status', e);
        }
    },
    staleTime: Infinity,
});
