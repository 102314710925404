import React from 'react';
import { Link } from 'react-router-dom';

import Button from './Button.tsx';

const LinkButton = ({
    disabled,
    to,
    children,
    ...props
}: React.ComponentPropsWithRef<typeof Button> & React.ComponentPropsWithRef<typeof Link>) => {
    const Comp = disabled ? 'span' : Link;

    return (
        <Button asChild {...props} disabled={disabled}>
            <Comp to={to}>{children}</Comp>
        </Button>
    );
};

export default LinkButton;
