import { LoaderIcon } from 'lucide-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { classnames, mergeRefs, useControllableState } from '@nicoknoll/utils';
import { Slot } from '@radix-ui/react-slot';

export interface Slottable {
    asChild?: true;
}

export interface ButtonProps extends Omit<React.ComponentPropsWithRef<'button'>, 'onClick'>, Slottable {
    active?: boolean;
    variant?: 'unstyled' | 'outline' | 'ghost' | 'link' | 'primary';
    loadingDelay?: number;

    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
    loading?: boolean;
    onLoadingChange?: (loading: boolean | undefined) => void;
    disabled?: boolean;
}

const DEFAULT_LOADING_DELAY = 400;

const isPromise = (value: Promise<any> | any) => Boolean(value && typeof value.then === 'function');

const Button = ({
    ref,
    asChild,
    className,
    active,
    variant = 'outline',
    loading,
    onLoadingChange,
    disabled: propsDisabled,
    onClick,
    loadingDelay = DEFAULT_LOADING_DELAY,
    ...props
}: ButtonProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [isLoading, setIsLoading] = useControllableState(false, loading, onLoadingChange);
    const [loadingDate, setLoadingDate] = useState<Date | null>(null); // store the date when loading started

    useEffect(() => {
        // on external loading change

        if (loading === true) {
            setLoadingDate(new Date());
        } else if (loading === false) {
            const now = new Date();
            const delay = loadingDate ? Math.max(0, loadingDelay - (now.getTime() - loadingDate.getTime())) : 0;
            setTimeout(() => {
                setLoadingDate(null);
            }, delay);
        } else {
            // on undefined directly to null
            setLoadingDate(null);
        }
    }, [loading]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!onClick) return;

        const result = onClick?.(e);

        setIsLoading(isPromise(result));
        const minimumLoadingDelayPromise = new Promise((resolve) =>
            setTimeout(resolve, isPromise(result) ? loadingDelay : 0)
        );

        return Promise.all([Promise.resolve(result), minimumLoadingDelayPromise])
            .finally(() => {
                setIsLoading(false);
            })
            .then((result: any) => {
                return result;
            })
            .catch((error: any) => {
                console.error(error);
            });
    };

    const showLoading = isLoading || loadingDate !== null;
    const disabled = propsDisabled || showLoading;

    const Comp = asChild ? Slot : 'button';
    return (
        <Comp
            ref={mergeRefs(buttonRef, ref)}
            onClick={handleClick}
            disabled={disabled}
            className={
                variant !== 'unstyled'
                    ? classnames(
                          'bg-white text-base-800 rounded px-3 py-1.5 font-medium text-sm border border-solid inline-flex items-center justify-center select-none',
                          !disabled && 'hover:bg-black/5 transition-all',
                          'data-[state=open]:bg-black/10',
                          // 'disabled:pointer-events-none disabled:opacity-50',
                          // disabled && 'pointer-events-none opacity-50',
                          variant === 'primary' &&
                              'bg-theme-500 border-transparent data-[state=open]:bg-theme-700 text-white shadow-none',
                          variant === 'primary' && !disabled && 'hover:bg-theme-600 active:bg-theme-700',
                          variant === 'primary' && disabled && 'bg-base-300',
                          variant === 'outline' &&
                              'disabled:text-base-500 bg-white border-base-300 shadow-sm data-[state=open]:border-base-400',
                          variant === 'outline' && disabled && 'text-base-500 bg-base-100 shadow-none',
                          variant === 'outline' &&
                              !disabled &&
                              'hover:border-base-400 active:border-base-400 active:bg-base-200 active:shadow-inner-sm',
                          variant === 'outline' && !disabled && active && 'border-base-400 shadow-inner-sm bg-base-200',
                          variant === 'ghost' && 'bg-transparent border-transparent',
                          variant === 'ghost' && disabled && 'text-base-500',
                          variant === 'link' && 'bg-transparent border-none p-0 text-theme-600 disabled:text-theme-500',
                          variant === 'link' && !disabled && 'hover:bg-transparent hover:text-theme-800',
                          !disabled && active && '!bg-base-200 !border-base-400 shadow-inner-sm',
                          disabled && 'cursor-not-allowed',
                          className
                      )
                    : className
            }
            {...props}
        >
            {showLoading ? (
                <span className="relative flex">
                    <span className="block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <span className="animate-spin-slow block">
                            <LoaderIcon />
                        </span>
                    </span>

                    <span className="invisible pointer-events-none inline-flex items-center justify-center">
                        {props.children}
                    </span>
                </span>
            ) : (
                props.children
            )}
        </Comp>
    );
};

export default Button;
