import React from 'react';
import { useTranslation } from 'react-i18next';

import CookieBar from '../../../components/CookieBar.tsx';
import Footer from '../../../components/Footer.tsx';
import Header from '../../../components/Header.tsx';
import EmptyState from '../../admin/components/EmptyState.tsx';

const HomePage = () => {
    const { t } = useTranslation('site');

    return (
        <div className="antialiased min-h-[100vh] flex flex-col">
            <Header />

            <EmptyState
                className="flex-1"
                title={t('homePage.emptyState.title')}
                description={t('homePage.emptyState.description')}
            />

            <Footer />

            <CookieBar />
        </div>
    );
};

export default HomePage;
