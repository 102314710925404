import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from './Button.tsx';

const Pagination = ({
    page,
    pageSize,
    total,
    onPageChange,
}: {
    page: number;
    pageSize: number;
    total: number;
    onPageChange?: (page: number) => void;
}) => {
    const { t } = useTranslation('components');

    const numPages = Math.ceil(total / pageSize);

    const isFirstPage = page === 1 || !total;
    const isLastPage = page === numPages || !total;

    const fromCursor = page * pageSize - pageSize;
    const fromUserCount = fromCursor > 0 ? fromCursor + 1 : 0;
    const toUserCount = total < page * pageSize ? total : page * pageSize;

    return (
        <div className="flex gap-5 items-center justify-center">
            <Button
                disabled={isFirstPage}
                onClick={() => onPageChange?.(page - 1)}
                className="flex gap-1 items-center pl-1.5"
            >
                <ChevronLeftIcon />
                {t('pagination.previous')}
            </Button>

            <span className="text-sm text-base-500">
                {t('pagination.text', { fromUserCount, toUserCount, totalUserCount: total })}
            </span>

            <Button
                disabled={isLastPage}
                onClick={() => onPageChange?.(page + 1)}
                className="flex gap-1 items-center pr-1.5"
            >
                {t('pagination.next')}
                <ChevronRightIcon />
            </Button>
        </div>
    );
};

export default Pagination;
