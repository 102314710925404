import { isFile } from '../data/api.ts';
import { UploadedFile } from '../data/models.ts';
import { fileCreateMutation } from '../data/queries.ts';
import { APIError } from '../data/utils.ts';

export const isImage = (ext: string) => ['jpg', 'jpeg', 'png', 'gif'].includes(ext.toLowerCase());

export const filterImage = (file: UploadedFile) => isImage(file.name.split('.').pop() || '');

export const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 B';

    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    const k = 1000; // or 1024
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${(bytes / Math.pow(k, i)).toFixed(2)} ${units[i]}`;
};

export const getRemoteFileSize = async (url: string) => {
    const response = await fetch(url, {
        method: 'HEAD',
    });

    if (!response.ok) {
        throw new APIError(`Ein Fehler ist aufgetreten: ${response.statusText} (${response.status})`);
    }

    return parseInt(response.headers.get('content-length') || '0', 10);
};

export const getOrCreateFileId = async (
    file: UploadedFile | File | null | undefined,
    createPayload: { [key: string]: any } = {}
) => {
    if (!file) return null;

    if ('id' in file && file.id) {
        // return existing file id
        return file.id;
    }

    if (isFile(file)) {
        // create new file and return id - should raise error if file is not valid
        const { mutationFn: createFile } = fileCreateMutation();
        const response = await createFile({ data: { file, ...createPayload } });
        return response.id;
    }

    return null;
};
