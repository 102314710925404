import { Event } from '../data/models.ts';

const FRONTEND_URL = process.env.FRONTEND_URL;

export const isDomainName = (slug: string) => slug != null && slug.includes('.');

export const getEventDomain = (event: Event) => {
    // either slug is a domain name so we return it or we do {slug}.{currentDomain}
    if (isDomainName(event.slug)) {
        return event.slug;
    }

    // domain without http(s):// but including port or subdomain
    const currentDomain = FRONTEND_URL?.replace(/(^\w+:|^)\/\//, '');
    return `${currentDomain}/events/${event.slug || event.id}`; // event.slug
};
