import {
    BriefcaseBusinessIcon,
    BuildingIcon,
    FilesIcon,
    FolderIcon,
    ImagesIcon,
    MailIcon,
    UsersRoundIcon,
} from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import url from '../../../../utils/url';
import Admin from '../../components/Admin.tsx';
import {
    ADMIN_ORGANIZATION_SETTINGS_CONTACT_PATH,
    ADMIN_ORGANIZATION_SETTINGS_FILES_PATH,
    ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH,
    ADMIN_ORGANIZATION_SETTINGS_PROFILE_PATH,
    ADMIN_ORGANIZATION_SETTINGS_USERS_PATH,
} from '../routes.tsx';

const OrganizationSettingsPage = (props: any) => {
    const { t } = useTranslation('admin');

    return (
        <div className="flex gap-8 h-full">
            <Admin.Navigation className="pt-10">
                <Admin.NavigationLabel>{t('organizationSettingsPage.navigation.masterData')}</Admin.NavigationLabel>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_SETTINGS_PROFILE_PATH)}>
                    <span className="flex gap-2 items-center">
                        <BuildingIcon /> {t('organizationSettingsPage.navigation.profile')}
                    </span>
                </Admin.NavigationLink>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_SETTINGS_CONTACT_PATH)}>
                    <span className="flex gap-2 items-center">
                        <MailIcon /> {t('organizationSettingsPage.navigation.contact')}
                    </span>
                </Admin.NavigationLink>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_SETTINGS_FILES_PATH)}>
                    <span className="flex gap-2 items-center">
                        <ImagesIcon /> {t('organizationSettingsPage.navigation.files')}
                    </span>
                </Admin.NavigationLink>

                <Admin.NavigationSeparator />

                <Admin.NavigationLabel>{t('organizationSettingsPage.navigation.recruiting')}</Admin.NavigationLabel>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_SETTINGS_JOBS_PATH)}>
                    <span className="flex gap-2 items-center">
                        <BriefcaseBusinessIcon /> {t('organizationSettingsPage.navigation.jobs')}
                    </span>
                </Admin.NavigationLink>

                <Admin.NavigationSeparator />

                <Admin.NavigationLabel>{t('organizationSettingsPage.navigation.access')}</Admin.NavigationLabel>

                <Admin.NavigationLink to={url(ADMIN_ORGANIZATION_SETTINGS_USERS_PATH)}>
                    <span className="flex gap-2 items-center">
                        <UsersRoundIcon /> {t('organizationSettingsPage.navigation.users')}
                    </span>
                </Admin.NavigationLink>
            </Admin.Navigation>

            <Admin.Content>
                <Outlet />
            </Admin.Content>
        </div>
    );
};

export default OrganizationSettingsPage;
