import { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '@nicoknoll/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import Pagination from '../../../components/Pagination.tsx';
import { EventSession, LogEventType, SavedEventSession } from '../../../data/models.ts';
import {
    eventSessionSearchQuery,
    organizationProfileDetailQuery,
    savedEventSessionListQuery,
    userDetailQuery,
} from '../../../data/queries.ts';
import { isAuthenticated } from '../../../utils/loaders.ts';
import useLogEvent from '../../../utils/useLogEvent.ts';
import { SimpleFilterSidebar, useSearchParamsFilters } from '../components/FilterSidebar.tsx';
import SessionCard from '../components/SessionCard.tsx';
import { useEventId } from '../utils.ts';

const formatLabel = (t: TFunction<'site'>) => (filterKey: string) =>
    ({
        search: t('sessionListPage.filters.search'),
        isSaved: t('sessionListPage.filters.isSaved'),
        isParticipant: t('sessionListPage.filters.isParticipant'),
        date: t('sessionListPage.filters.date'),
        sessionFormat: t('sessionListPage.filters.sessionFormat'),
        languageCode: t('sessionListPage.filters.languageCode'),
        location: t('sessionListPage.filters.location'),
    })[filterKey] || filterKey;

const formatValue = (t: TFunction<'site'>) => (filterKey: string, value: string) => {
    if (filterKey === 'date') {
        return formatDate(value); // timezone comes from backend already
    } else if (filterKey === 'isSaved') {
        return value === 'true'
            ? t('sessionListPage.filters.isSavedOptions.true')
            : t('sessionListPage.filters.isSavedOptions.false');
    } else if (filterKey === 'isParticipant') {
        return value === 'true'
            ? t('sessionListPage.filters.isParticipantOptions.true')
            : t('sessionListPage.filters.isParticipantOptions.false');
    } else if (filterKey === 'languageCode') {
        // @ts-ignore
        return t(`common:languageCode.${value}`) as string;
    }
    return value;
};

const SessionListCard = ({
    session,
    savedEventSession,
}: {
    session: EventSession;
    savedEventSession?: SavedEventSession | null;
}) => {
    const { data: organizationProfile } = useQuery({
        ...organizationProfileDetailQuery(session.organizationProfileId!),
        enabled: !!session.organizationProfileId,
    });

    return (
        <SessionCard
            key={session.id}
            session={session}
            savedEventSession={savedEventSession}
            organizationProfile={organizationProfile}
        />
    );
};

const SessionListPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());

    const { filters, onToggleFilter, searchParams, setSearchParams } = useSearchParamsFilters([
        'search',
        'isSaved',
        'isParticipant',
        'date',
        'sessionFormat',
        'languageCode',
        'location',
    ]);
    const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
    const pageSize = 20;

    const { data: sessionsData } = useQuery({
        ...eventSessionSearchQuery({ eventId, page, pageSize, ...filters, isPublic: true }),
        placeholderData: keepPreviousData,
    });

    const { data: savedEventSessions } = useQuery({
        ...savedEventSessionListQuery(viewer ? { eventId, userId: viewer.id } : { eventId }),
        placeholderData: keepPreviousData,
        enabled: isAuthenticated(viewer!),
    });
    const savedEventSessionsById = Object.fromEntries(savedEventSessions?.map((s) => [s.eventSessionId, s]) || []);

    const handlePageChange = (newPage: number) => {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
    };

    const createLogEvent = useLogEvent();
    useEffect(() => {
        createLogEvent(LogEventType.EVENT_SESSION_SEARCH, { ...filters, page, pageSize });
    }, [filters, page, pageSize]);

    if (!sessionsData) return null;

    return (
        <div className={className} {...props}>
            <main className="flex justify-center bg-white px-6 py-24">
                <div className="w-full max-w-[1120px] flex flex-col gap-32">
                    <section className="flex flex-col gap-14">
                        <h2 className="font-semibold text-3xl">{t('sessionListPage.title')}</h2>

                        <div className="flex gap-10 items-start">
                            <div className="w-80 flex-none border-r border-base-100 pr-9">
                                <SimpleFilterSidebar
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    filtersData={sessionsData?.filters || {}}
                                    filters={filters}
                                    onToggleFilter={onToggleFilter}
                                    formatLabel={formatLabel(t)}
                                    formatValue={formatValue(t)}
                                />
                            </div>

                            <div className="flex flex-col gap-5 flex-1">
                                {sessionsData?.results?.map((session) => (
                                    <SessionListCard
                                        key={session.id}
                                        session={session}
                                        savedEventSession={savedEventSessionsById[session.id] || null}
                                    />
                                ))}

                                <div className="mt-5">
                                    <Pagination
                                        page={page}
                                        pageSize={pageSize}
                                        total={sessionsData?.count ? Number(sessionsData?.count) : 0}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default SessionListPage;
