import { User } from '../data/models.ts';
import { userDetailQuery } from '../data/queries.ts';
import { queryClient } from './queryClient.ts';

export const isAdminUser = (user: User) => Boolean(user?.isAdmin);
export const isOrganizationUser = (user: User) => Boolean(user?.organizationId);
export const isSupportUser = (user: User) => Boolean(user?.isSupport);
export const isRegularUser = (user: User) => Boolean(user) && !Boolean(user?.organizationId) && !Boolean(user?.isAdmin);
export const isAuthenticated = (user: User) => Boolean(user);

export const authorized =
    (condition?: (user: User) => boolean) =>
    async ({ params }: any) =>
        authorizedLoader(({ params }: any) => Promise.resolve(null), condition)({ params });

export const authorizedLoader =
    (loader: ({ params }: any) => Promise<any>, condition?: (user: User) => boolean) =>
    async ({ params }: any) => {
        const user: User = await queryClient.ensureQueryData({
            ...userDetailQuery(),
            staleTime: 0,
            initialData: undefined as any,
        });
        if (!user?.id) {
            throw new Response('Unauthorized', { status: 401, statusText: 'Unauthorized' });
        }
        if (condition && !condition(user)) {
            throw new Response('Forbidden', { status: 403, statusText: 'Forbidden' });
        }
        return await loader({ params });
    };
