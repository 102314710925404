import { StarIcon, StarOffIcon } from 'lucide-react';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '../../../components/Button.tsx';
import Tooltip from '../../../components/Tooltip.tsx';
import { LogEventType, SavedUser, User } from '../../../data/models.ts';
import {
    savedUserCreateMutation,
    savedUserDeleteMutation,
    savedUserListQuery,
    userDetailQuery,
} from '../../../data/queries.ts';
import url from '../../../utils/url';
import useLogEvent from '../../../utils/useLogEvent.ts';
import { ADMIN_LOGIN_PATH } from '../../auth/routes.tsx';

interface SaveUserProps {
    user: User;
    savedUser?: SavedUser | null; // null disables automatic fetching
    eventId: string;
}

const useSaveUser = ({ user, savedUser: propsSavedUser, eventId }: SaveUserProps) => {
    const { t } = useTranslation('site');

    const { data: viewer } = useQuery(userDetailQuery());
    const navigate = useNavigate();
    const location = useLocation();

    const { data: savedUsers, isLoading } = useQuery({
        ...savedUserListQuery({ eventId, userId: viewer?.id, targetUserId: user?.id }),
        enabled: !!viewer && !!user && propsSavedUser === undefined,
    });
    const savedUser = propsSavedUser || savedUsers?.[0];

    const createLogEvent = useLogEvent();

    const { mutateAsync: saveUser } = useMutation(savedUserCreateMutation());
    const handleSave = async () => {
        if (!viewer) {
            toast.error(t('saveUserButton.notAuthenticatedError'));
            navigate(`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`);
            return;
        }

        try {
            await saveUser({
                data: { eventId, userId: viewer!.id, organizationId: viewer.organizationId!, targetUserId: user.id },
            });
            void createLogEvent(LogEventType.USER_SAVED, {
                eventId,
                organizationId: viewer.organizationId!,
                targetId: user.id,
            });
            toast.success(t('saveUserButton.saveSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('saveUserButton.saveError'));
        }
    };

    const { mutateAsync: unsaveUser } = useMutation(savedUserDeleteMutation());
    const handleUnsave = async () => {
        if (!viewer) {
            toast.error(t('saveUserButton.notAuthenticatedError'));
            navigate(`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`);
            return;
        }

        try {
            await unsaveUser({ id: savedUser!.id! });
            void createLogEvent(LogEventType.USER_UNSAVED, { eventId, targetId: user.id });
            toast.success(t('saveUserButton.unsaveSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('saveUserButton.unsaveError'));
        }
    };

    return { isSaved: Boolean(savedUser), isLoading, saveUser: handleSave, unsaveUser: handleUnsave };
};

export const SaveUserButton = ({
    user,
    savedUser,
    eventId,
    className,
    ...props
}: React.ComponentPropsWithRef<'button'> & SaveUserProps) => {
    const { t } = useTranslation('site');

    const { isSaved, isLoading, saveUser, unsaveUser } = useSaveUser({ user, savedUser, eventId });

    return (
        <Button
            className="w-full text-base flex gap-2 disabled:cursor-not-allowed min-h-9"
            onClick={isSaved ? unsaveUser : saveUser}
            loading={isLoading ? true : undefined}
            {...props}
        >
            {isSaved ? <StarOffIcon /> : <StarIcon fill="currentColor" />}
            {isSaved ? t('saveUserButton.unsave') : t('saveUserButton.save')}
        </Button>
    );
};

export const SaveUserRibbon = ({
    user,
    savedUser,
    eventId,
    className,
    ...props
}: React.ComponentPropsWithRef<'button'> & SaveUserProps) => {
    const { t } = useTranslation('site');

    const { isSaved, isLoading, saveUser, unsaveUser } = useSaveUser({ user, savedUser, eventId });

    return (
        <Tooltip>
            <Tooltip.Trigger asChild>
                <Button
                    variant="unstyled"
                    className={classnames(
                        'text-base py-2 flex gap-2 absolute right-4 top-0 rounded-b-lg transition-colors p-3 pb-3.5 pt-4 z-0',
                        isSaved
                            ? 'bg-theme-500 text-white hover:bg-theme-600 active:bg-theme-700'
                            : 'bg-base-100 text-base-400 group-hover:bg-base-200 hover:text-theme-500 hover:!bg-theme-100 ',
                        className
                    )}
                    onClick={isSaved ? unsaveUser : saveUser}
                    loading={isLoading ? true : undefined}
                >
                    <StarIcon fill="currentColor" />
                </Button>
            </Tooltip.Trigger>
            <Tooltip.Content>{isSaved ? t('saveUserButton.unsave') : t('saveUserButton.save')}</Tooltip.Content>
        </Tooltip>
    );
};
